import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import passwordicon from '../../../assets/image/password1.svg';
import eyehideicon from '../../../assets/image/eye-hide1.svg';
import eyeshowicon from '../../../assets/image/eye-show2.svg';
import { useDispatch } from "react-redux";
import { AdminChangePasswordAction } from "../../../Redux/Actions";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from 'formik';
import { changepasswordValidationSchema } from "../../../validationSchema/index";
import PageLoader from "../../customcomponent/loader";

const ChangePassword = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    password1: '',
    password2: '',
    password3: '',
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: changepasswordValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)

      const data = {
        current_password: values.password1,
        new_password: values.password2
      }
      try {
        const res = await dispatch(AdminChangePasswordAction(data));
        console.log(res, "ADMIN CHANGE PASSWORD");
        if (res.status === 0) {
          setLoading(false)

          toast.error(res.msg, {
            position: 'top-right',
          });        
        } else {
          setLoading(false)

          toast.success("Password updated successfully", {
            position: 'top-right',
          });
          values.password1="";
          values.password2="";
          values.password3=""
        }
      } catch (err) {
        setLoading(false)

        setError(err);
        console.error(err, "ERROR");
      }
    },
  });

  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPassword3, setShowPassword3] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const togglePasswordVisibility3 = () => {
    setShowPassword3(!showPassword3);
  };

  return (
    <>
      <div className="instructorpanel ">
        <h2 className="headingmain mb-3">Change Password</h2>
        <Form onSubmit={handleSubmit}>

          <Row>
            <Col md="12">
              <label className="formgroup_label">Current Password</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={passwordicon} alt="" />
                </div>
                <Input
                  name="password1"  
                  placeholder="Current Password"
                  type={showPassword1 ? "text" : "password"}
                  value={values.password1}
                  onChange={handleChange}
                  className="input-error"
                />
                <div
                  className="iconright"
                  onClick={togglePasswordVisibility1}
                >
                  {showPassword1 ? (
                    <img src={eyeshowicon} alt="" />
                  ) : (
                    <img src={eyehideicon} alt="" />
                  )}
                </div>

              </FormGroup>
              <p className="input-error">{errors.password1}</p>

            </Col>
            <Col md="12">
              <label className="formgroup_label">New Password</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={passwordicon} alt="" />
                </div>
                <Input
                  name="password2"
                  placeholder="New Password"
                  type={showPassword2 ? "text" : "password"}
                  value={values.password2}
                  onChange={handleChange}
                  className="input-error"

                />
               
                <div
                  className="iconright"
                  onClick={togglePasswordVisibility2}
                >
                  {showPassword2 ? (
                    <img src={eyeshowicon} alt="" />
                  ) : (
                    <img src={eyehideicon} alt="" />
                  )}
                </div>
              </FormGroup>
              <p className="input-error">{errors.password2}</p>

            </Col>
            <Col md="12">
              <label className="formgroup_label">Confirm New Password</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={passwordicon} alt="" />
                </div>
                <Input
                  name="password3"
                  placeholder="Confirm New Password"
                  type={showPassword3 ? "text" : "password"}
                  value={values.password3}
                  onChange={handleChange}
                  className="input-error"
                />
                <div
                  className="iconright"
                  onClick={togglePasswordVisibility3}
                >
                  {showPassword3 ? (
                    <img src={eyeshowicon} alt="" />
                  ) : (
                    <img src={eyehideicon} alt="" />
                  )}
                </div>
              </FormGroup>
              <p className="input-error">{errors.password3}</p>

            </Col>
          </Row>
          {error && <p className="text-danger">{error !== "" ? error : error.msg}</p>}

          <div className="text-center mt-5">
            <Button
              type="submit"
              className="primarybutton maxwidth-555"
            >
                {loading ? <PageLoader/> : "Save"}
              
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
  );
}

export default ChangePassword;
