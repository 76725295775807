import React from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import data from "./data";
import Table from "./Datatables";

const Reservations = (args) => {

  return (
    <>
      <div className='instructorpanel'>
      <Row className="mb-4">
        <Col xl="6" md="6" className="align-self-center">
          <h4 className="card-title my-1  headingmain">Reservations</h4>
        </Col>
      </Row>
      <div>
        <Table data={data} />
      </div>
      </div>
    </>
  );
}

export default Reservations;
