import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
const Flighthistorytransactions = () => {
  return (
    <>
      <div className="instructorpanel invoicemain_div">
      <Row className="mb-4">
        <Col xl="6" md="6" xs="6" className="align-self-center">
          <h4 className="card-title my-1  headingmain">Flight History Details</h4>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end">
          <Link to="/Viewaircraft"><Button className="btn-add">Back</Button></Link>
        </Col>
      </Row>
        <Row className="mb-4">
          <Col sm="6">
            <h5>John Doe</h5>
            <p>+1 12345-4569</p>
            <p>johndoe@gmail.com</p>
            <p>New York, USA</p>
          </Col>

          <Col sm="6" className="text-end">
            <p>2210 Patisserie</p>
            <p> +1 12345-4569</p>
            <h3>INVOICE # 2294170</h3>
          </Col>
        </Row>
        <div className="table-responsive">
          <table className="table table-bordered table-nowrap table-centered m-0">
            <thead className="thead-light">
              <tr>
                <th>Description</th>
                <th>Qty</th>
                <th>Rate</th>
                <th>Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Aircraft: Cessna 172N 739ZZ</td>
                <td>1</td>
                <td>$140.00</td>
                <td>$126.00</td>
              </tr>
              <tr>
                <td>Ground: Club Premium InstructorTIme</td>
                <td>1</td>
                <td>$100.00</td>
                <td>$116.00</td>
              </tr>
              <tr>
                <td colspan="3" className="text-end">
                  <p className="fw-bold">Total Amount:</p>
                  <p className="fw-bold">Tax:</p>
                </td>
                <td>
                  <p className="fw-bold">$266.00</p>
                  <p className="fw-bold">$50.00</p>
                </td>
              </tr>
              <tr>
                <td colspan="3" className="text-end">
                  <h5 className="fw-bold">Total:</h5>
                </td>
                <td>
                  <h5 className="fw-bold">$316.00</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row className="row mt-4">
          <Col sm="8">
            <div className="receipt-left">
              <p><b>Payment :</b> <br />Credit Card - 236***********928</p>
              <p><b>Date :</b> 15 / 08 / 2023</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Flighthistorytransactions;
