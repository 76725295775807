import React from "react";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { IMAGE_URL } from "../../../constant/Endpoints";
import profileimg from "../../../assets/image/default.jpg";

function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const formattedDate = date.toLocaleDateString(); // Format the date
  const formattedTime = date.toLocaleTimeString(); // Format the time
  return `${formattedDate} ${formattedTime}`;
}

const DetailUserList = ({ user }) => {
    console.log("User Data:", user);
    
    return (
      <Row>
        <Col sm="12">
          <h4 className="card-title mb-3 headingcard">Users</h4>
          <div className="table-responsive">
            {user.length > 0 ? (
              <table className="table">
                <thead className="table-light">
                  <tr>
                    <th className="text-nowrap">Name</th>
                    <th className="text-nowrap">Email</th>
                    <th className="text-nowrap">Phone Number</th>
                  </tr>
                </thead>
                <tbody>
                  {user.map((item, index) => (
                    <tr key={index}>
                      <td>
                          <Link className="linkadd" to={`/Viewuser/${item.userId}`}>
                            <img
                            className="table_img rounded-circle img-thumbnail me-1"
                              src={item.userProfilePic ? IMAGE_URL + item?.userProfilePic : profileimg}
                              alt="Profile"
                            />
                            {item?.userFirstName} {item?.userLastName}
                          </Link>
                        
                      </td>
                      <td>
                        <Link className="linkadd" to={`/Viewuser/${item.userId}`}>
                          {item.userEmail}
                        </Link>
                      </td>
                      <td>
                        <Link className="linkadd" to={`/Viewuser/${item.userId}`}>
                          {item.userPhoneNumber}
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
<p style={{ textAlign: "center" }}>No user enrolled yet.</p>

            )}
          </div>
        </Col>
      </Row>
    );
  };
  

export default DetailUserList;
