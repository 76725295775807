import img1 from '../../../assets/image/listimg1.jpg'
import img2 from '../../../assets/image/listimg2.jpg'
import img3 from '../../../assets/image/listimg3.jpg'
import img4 from '../../../assets/image/listimg4.jpg'
const data = [
  {
    id: 1,
    imgurl: img1,
    name: "Cessna 172 739BN",
    tail: "8116Q",
    status: 'Online',
  },
  {
    id: 2,
    imgurl: img2,
    name: "Cessna 152 959GT",
    tail: "64971",
    status: 'Staff Only',
  },
  {
    id: 3,
    imgurl: img3,
    name: "Cessna 152 24981",
    tail: "LZ2",
    status: 'Offline',
  },
  {
    id: 4,
    imgurl: img4,
    name: "Beech A36 TN 2653M",
    tail: "7T-WIV",
    status: 'Online',
  },
  {
    id: 5,
    imgurl: img1,
    name: "Cessna 172 739BN",
    tail: "N16-100",
    status: 'Offline',
  },
];

export default data;
