import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import data from "./data";
import Table from "./Datatables";
import { Link } from "react-router-dom"
const Instructor = (args) => {

  return (
    <>
      <div className='instructorpanel'>
      <Row className="mb-4">
        <Col xl="6" md="6" xs="6" className="align-self-center">
          <h4 className="card-title my-1  headingmain">Instructors</h4>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end">
          <Link to="/Addinstructor"><Button className="btn-add">Add</Button></Link>
        </Col>
      </Row>
        <div>
          <Table data={data} />
        </div>
      </div>
    </>
  );
}

export default Instructor;
