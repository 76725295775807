import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { User, Lock, LogOut } from 'react-feather'
import logoimg from '../../../assets/image/logo2.png';
import userimg from '../../../assets/image/user1.png';
import Sidebar from "../Sidebar";
import { useSelector } from 'react-redux';
import OnBoardingReducer from '../../../Redux/Reducers/OnBoardingReducer';
import {IMAGE_URL} from "../../../constant/Endpoints"
import profileimg from "../../../assets/image/default.jpg";


const Header = (args) => {

  const navigate = useNavigate();

  const profileData = useSelector(state => state.onBoardingReducer.Profile)

  const handleLogout = (e) => {
    e.preventDefault()
    console.log("TOKENN======", localStorage.getItem("token"))
    localStorage.removeItem("token")
    console.log("TOKENN", localStorage.getItem("token"))
    window.location.href = "/";
    // navigate('/', {
    //   replace: true
    // })
  };



  return (
 
    <div className='header'>
      <Navbar {...args} expand="md">
        <Container className='containercustom'>
          <div style={{ display: 'none' }} className='dblocksidebar'> <Sidebar /></div>
          <Link to="/Dashboard">
            <NavbarBrand>
              <img src={logoimg} alt="" />
            </NavbarBrand>
          </Link>
          <Collapse navbar>
            <Nav className="ms-auto" navbar>
              {/* <div style={{ display: 'none' }} className='dblocksidebar'> <Sidebar /></div> */}
              <UncontrolledDropdown nav inNavbar className='headerdropdown_menu'>
                <DropdownToggle nav>
                  <div className='headeruserimg'>
                             <img src={profileData?.profilePic ? IMAGE_URL+profileData?.profilePic : profileimg} alt="" />
                    <h3>{profileData?.firstName} {profileData?.lastName}</h3>
                  </div>
                </DropdownToggle>
                <DropdownMenu end style={{ right: 0, position: 'absolute' }}>
                  <DropdownItem><Link to="/ProfileSetting"><User size={18} /> Profile Setting</Link></DropdownItem>
                  <DropdownItem><Link to="/ChangePassword"><Lock size={18} /> Change Password</Link></DropdownItem>
                  <DropdownItem><Link onClick={handleLogout}><LogOut size={18} /> Logout</Link></DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>

          </Collapse>
        </Container>
      </Navbar>

    </div>
  );
}

export default Header;
