import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import Loader from "../../customcomponent/pageLoader";
import aircraftimg from "../../../assets/image/plane.svg";
import { IMAGE_URL } from "../../../constant/Endpoints";
import { AircraftNameEditAction } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";

const Table = (props) => {
  const columns = [
    {
      name: "Name",
      minWidth: "300px",
      selector: "imgurl",
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-1"
            src={
              row.aircraftImage != null
                ? IMAGE_URL + row.aircraftImage
                : aircraftimg
            }
            alt=""
          />
          {row?.name}
        </>
      ),
    },

    {
      name: "Status",
      minWidth: "300px",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <span className={row?.status + " badge"}>{row?.status}</span>
      ),
    },
    {
      name: "Actions",
      minWidth: "280px",
      center: "center",
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link>
              <Button
                className="tablebtnedit"
                onClick={() =>
                  handleEditClick(row?._id, row?.name, row?.status)
                }
              >
                <Edit className="tableicon" />
              </Button>
            </Link>
          </>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");

  const [status, setStatus] = useState("");

  const [id, setcategoryId] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [modal2, setModal2] = useState(false);
  const [modal3, setModal3] = useState(false);

  const [loading, setLoading] = useState(false);
  const [editcategory, setEditcategory] = useState("");
  const [editAircraftName, setEditAircraftName] = useState("");

  const [modal, setModal] = useState(false);

  const categoryList = useMemo(() => {
    if (!filterText) return props.data;
    return props.data.filter(
      (item) =>
        JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
        -1
    );
  }, [props.data, filterText]);

  const toggle2 = () => setModal2(!modal2);
  const modaltoggle = () => setModal(!modal);
  const modaltoggle3 = () => setModal3(!modal3);

  const dispatch = useDispatch();

  const handleEditClick = (
    aircraftNameId,
    aircraftName,
    aircraftNameStatus
  ) => {
    setcategoryId(aircraftNameId);
    setEditAircraftName(aircraftName);
    setStatus(aircraftNameStatus);
    modaltoggle3();
  };


  const editName = () => {
    const data = {
      aircraftNameId: id,
      name: editAircraftName,
      aircraftNameStatus: status,
    };

    dispatch(AircraftNameEditAction(data))
      .then((res) => {
        if (res.status === 1) {
          // toast.success("Category updated successfully", {
          //   position: "top-right",
          // });
          modaltoggle3();
          window.location.reload();
        } else {
          toast.error(res.msg, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
        });
      });
  };

  useEffect(() => {}, [filterText]);

  return loading ? (
    <Loader />
  ) : (
    <React.Fragment>
      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={categoryList}
        defaultSortField="name"
        pagination
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalBody className="p-4">
          <div className="Emailmodal">
            <h2 className="email_head pb-3 text-center">Edit Category</h2>
            <Row>
              <Col sm="12">
                <label className="formgroup_label">Category</label>
                <FormGroup className="form-group">
                  <Input
                    name="editcategory"
                    placeholder="Enter category name"
                    className="px-4"
                    value={editcategory}
                    defaultValue={editcategory}
                    onChange={(e) => setEditcategory(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="btn-cancel-ok pt-3 text-center">
              <Button className="btn-cancel me-1" onClick={modaltoggle}>
                Cancel
              </Button>
              <Button className="btn-save">Update</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal centered isOpen={modal2} toggle={() => setModal2(!modal2)}>
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure?</h2>
            <p>
              You want to delete this record. You will not be able to recover
              it.
            </p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>
                Cancel
              </Button>
              <Button className="btn-ok" onClick={toggle2}>
                Proceed
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal centered isOpen={modal3} toggle={() => setModal3(!modal3)}>
        <ModalBody className="p-4">
          <div className="Emailmodal">
            <h2 className="email_head pb-3 text-center">Edit Aircraft Name</h2>
            <form>
              <Row>
                <Col sm="12">
                  <label className="formgroup_label">Aircraft Name</label>
                  <FormGroup className="form-group">
                    <Input
                      name="editAircraftName"
                      placeholder="Enter aircraft name"
                      className="px-4"
                      value={editAircraftName}
                      defaultValue={editAircraftName}
                      onChange={(e) => setEditAircraftName(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <label className="formgroup_label">
                    Aircraft Name Status
                  </label>
                  <FormGroup className="form-group">
                    <select
                      name="status"
                      className="px-4 form-control"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                    >
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-cancel-ok pt-3 text-center">
                <Button className="btn-cancel me-1" onClick={modaltoggle3}>
                  Cancel
                </Button>
                <Button className="btn-save" onClick={editName}>
                  Update
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Table;
