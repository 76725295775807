import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
  Input,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, XCircle } from 'react-feather'
import { Link } from "react-router-dom"
import { DeleteInstructorAction, DeleteUserAction, InstructorListAction } from "../../Redux/Actions/InstructorAction";
import { useDispatch } from "react-redux";
import { IMAGE_URL } from "../../constant/Endpoints";
import profileimg from "../../assets/image/default.jpg";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
import { UpdateUserStatusAction } from "../../Redux/Actions";

const Table = props => {
  const [id, setid] = useState("");


  const columns = [
    {
      name: "Name",
      minWidth: "220px",
      selector: "name",
      sortable: true,
      cell: (row) =>
        <>
          <img className="table_img rounded-circle img-thumbnail me-1" src={row?.profilePic != null ? IMAGE_URL + row?.profilePic :profileimg }alt="" />
          {row.firstName} {row.lastName}
        </>
    },
    {
      name: "Email",
      minWidth: "138px",
      selector: "email",
      sortable: true,
      cell: (row) => row.email
    },
    {
      name: "Phone No.",
      minWidth: "80px",
      selector: "phone",
      sortable: true,
      cell: (row) => row.phone_number
    },
    {
      name: "Status",
      minWidth: "80px",
      selector: "status",
      sortable: true,
      cell: (row) => <span className={row.account_status + ' badge'}  >{row.account_status}</span>
    },
    {
      name: "",
      minWidth: "100px",
      selector: "status",
      sortable: true,
      center: 'center',
      cell: (row) => (
        <div className="status-cell">
        <Input
          type="select"
          name="status"
          id={`status-${row._id}`}
          style={{ height: '32px', width: '110px' }}
          value={row.account_status}
          onChange={(e) => handleStatusChange(row._id, e.target.value)}
          // disabled={row.account_status === 'Active'} // Disable 'Active' status change
        >
          <option value="" disabled={!row.account_status}>Select Status</option>
          <option value="Active" disabled={row.account_status === 'Active'}>Active</option>
          <option value="Inactive" disabled={row.account_status === 'Inactive'}>Inactive</option>
          <option value="pending" disabled={row.account_status === 'pending'}>Pending</option>
        </Input>
        </div>
      )
    },
    
        {
      name: "Actions",
      minWidth: "180px",
      center: 'center',
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/Viewinstructor/" + row._id}><Button className="tablebtnview"><Eye className="tableicon" /></Button></Link>
            <Link to={"/Editinstructor/" + row._id}><Button className="tablebtnedit"><Edit className="tableicon" /></Button></Link>
            <Button className="tablebtndelete" onClick={() => {
               setModal2(!modal2)
               setid(row?._id)}} ><Trash className="tableicon" /></Button>
          </>
        </div>
      )
    }
  ]
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch()
  const [usersList, setUsersList] = useState([])
  const [stausFilter, setStausFilter] = React.useState("");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        stausFilter={stausFilter}
        onStatus={e => setStausFilter(e.target.value)}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const DeleteUserList = (id) => {
    let data = {
      userID: id
    }
    console.log(usersList)
    dispatch(DeleteInstructorAction(data)).then(res => {
      // setUsersList(res.data)
      setModal2(!modal2)
      HandleUserList()
    }).catch(err => console.log(err))

  };


  const handleStatusChange =async (userId, newStatus) => {
    const currentUser = usersList.find(user => user._id === userId);

  if (newStatus === currentUser.account_status) {
    return; // If selected status is the same as current status, do nothing
  }

  // Check for valid status transitions based on current status
  if (currentUser.account_status === 'Active' && newStatus === 'Active') {
    return; // Prevent selecting 'Active' if current status is 'Active'
  }
  
    // Make API call to update status
    const data = {
      userId: userId,
      account_status: newStatus
    };
  
    // Example: Update status via API
      dispatch( UpdateUserStatusAction(data)).then((response) => {
      // Handle success response
      if (response.status === 1) {
        // For example, update usersList state or reload data
        HandleUserList();
      } else {
        // Handle API error response
        console.error("Failed to update user status:", response.msg);
      }
    }).catch((error) => {
      console.error("Error updating user status:", error);
    });
  };
  


  useEffect(() => {
    HandleUserList(filterText)
  }, [filterText,stausFilter])


  const HandleUserList = async () => {
    setLoading(true)

    let data = {
      search: filterText,
      filter: stausFilter
    }
    dispatch(InstructorListAction(data)).then(res => {
      if(res.status === 1){
        setLoading(false)

      console.log(res)
      setUsersList(res.data)
      }
    }).catch(err => console.log(err))
  };



  return (
    <React.Fragment>

      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={usersList}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader/>}
        progressPending={loading} 
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure  ?</h2>
            <p>You want to delete this record. You will not be able to recover</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>Cancel</Button>
              <Button className="btn-ok" onClick={()=>DeleteUserList(id)}>Proceed</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Table;
