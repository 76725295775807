import React from 'react';
// import classnames from 'classnames';
import Aircraftlist from "./Aircraftlist";
// import Servicetracker from "./Servicetracker";
const Aircrafts = (args) => {
  // State for current active Tab
  // const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // // Toggle active state for Tab
  // const toggle = tab => {
  //   if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  // }
  return (

    <>
      <div className='instructorpanel'>
        <Aircraftlist />
        {/* <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab === '1'
              })}
              onClick={() => { toggle('1'); }}
            >
              Aircraft
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab === '2'
              })}
              onClick={() => { toggle('2'); }}
            >
              Service Tracker
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1">
            <div>
              <Aircraftlist />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div>
              <Servicetracker />
            </div>
          </TabPane>
        </TabContent> */}
      </div>
    </>
  );
}

export default Aircrafts;
