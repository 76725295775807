const data = [
  {
    id: 1,
    transactionID: '#12345678',
    reservationID: 'IPT15BA',
    username: 'Maria James',
    date: '	Aug 21, 2023',
    status: 'Completed',
  },
  {
    id: 2,
    transactionID: '#12345679',
    reservationID: 'IPT15BA',
    username: 'Johnson Charles',
    date: '	Aug 20, 2023',
    status: 'Hold',
  },
  {
    id: 3,
    transactionID: '#12345680',
    reservationID: 'IPT15BA',
    username: 'Steve Jones',
    date: '	Aug 19, 2023',
    status: 'Pending',
  },
  {
    id: 4,
    transactionID: '#12345681',
    reservationID: 'IPT15BA',
    username: 'David Smith',
    date: '	Aug 18, 2023',
    status: 'Completed',
  },
  {
    id: 5,
    transactionID: '#12345682',
    reservationID: 'IPT15BA',
    username: 'James Vince',
    date: '	Aug 17, 2023',
    status: 'Hold',
  },
  {
    id: 6,
    transactionID: '#12345683',
    reservationID: 'IPT15BA',
    username: 'Maria James',
    date: '	Aug 16, 2023',
    status: 'Completed',
  },
  {
    id: 7,
    transactionID: '#12345684',
    reservationID: 'IPT15BA',
    username: 'Johnson Charles',
    date: '	Aug 15, 2023',
    status: 'Hold',
  },  
];

export default data;
