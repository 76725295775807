//*****************************************Register ****************************************************/

export const LOG_IN_SUCCESS = 'Log_In_Success';
export const VERIFY_OTP = 'Verify_Otp';
export const FORGOT_PASSWORD = 'Forgot_Password';
export const RESET_PASSWORD = 'Reset_Password';
export const USER_LIST_SUCCESS = 'User_List_Success';
export const USER_DEATIL_SUCCESS = 'User_Detail_Success';
export const ADD_USER_SUCCESS = 'Add_User_Success';
export const EDIT_USER_SUCCESS = 'Edit_User_Success';
export const INSTRUCTOR_LIST_SUCCESS = 'Instructor_List_Success';
export const INSTRUCTOR_DEATIL_SUCCESS = 'Instructor_Detail_Success';
export const RESERVATION_SUCCESS = 'Reservation_Success';
export const RESERVATION_DEATIL_SUCCESS = 'Reservation_Detail_Success';
export const RESERVATION_DELETE_SUCCESS = 'Reservation_Delete_Success';
export const TRANSACTION_SUCCESS = 'Transaction_Success';
export const AIRCRAFT_LIST_SUCCESS = 'Aircraft_List_Success';
export const AIRCRAFT_DETAIL_SUCCESS = 'Aircraft_Detail_Success';
export const ADMIN_LIST_SUCCESS = 'Admin_List_Success';
export const ADMIN_DETAIL_SUCCESS = 'Admin_Detail_Success';
export const ADMIN_DELETE_SUCCESS = 'Admin_Delete_Success';
export const ADD_ADMIN_SUCCESS = 'Add_Admin_Success';
export const GET_CMS_SUCCESS = 'Get_Cms_Success';
export const SINGLE_CMS_SUCCESS = 'Single_Cms_Success';
export const EDIT_CMS_SUCCESS = 'Edit_Cms_Success';
export const ANNOUNCEMENT = 'Announcement';
export const ADD_ANNOUNCEMENT = 'addAnnouncement';

export const DASHBORAD = 'Dashboard';




















