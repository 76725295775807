import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import resetpasswordimg from "../../assets/image/reset-password.svg";
import passwordicon from "../../assets/image/password.svg";
import eyehideicon from "../../assets/image/eye-hide.svg";
import SuccessImg from "../../assets/image/success.png";
import backicon from "../../assets/image/back-icon.svg";
import eyeshowicon from '../../assets/image/eye-show.svg';
import { useDispatch } from "react-redux";
import { ResetPasswordAction } from "../../Redux/Actions/onBoardingAction";
import PageLoader from "../customcomponent/loader";
const Resetpassword = (args) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');

  const togglePasswordVisibility1 = () => {
    setShowPassword1(!showPassword1);
  };
  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };
  const toggle = () => setModal(!modal);

  const onResetPass = () => {
    setLoading(true);

    const query = new URLSearchParams(window.location.search);
    const email = query.get('email');
   
    let data = {
      email: email,
      newPassword: password1
    }

    if (data.newPassword != '' && data.newPassword == password2) {

    dispatch(ResetPasswordAction(data))
      .then((res) => {
        console.log(res.data, "RR")
        if (res.status) {
          setLoading(false);

          toggle()
          // navigate("/Resetpassword?email=" + email,)
          // Singleton.getInstance().saveData('token', res.data.userToken)
          // window.location.reload()
        } else {
          setLoading(false);

          setError (res.msg);
        }
      }).catch(err => setError("Something went wrong")
      )
    }else{ 
      setLoading(false);

          setError('Please provide required fields');
      
    
  }
  }



  return (
    <>
      <div>
        <Container fluid>
          <Row>
            <Col md="6" className="p-0">
              <div className="loginlogo_div">
                <img className="forgotimg" src={resetpasswordimg} alt="" />
              </div>
            </Col>
            <Col className="loginfield_bg" md="6">
              <div className="loginmain_div position_relative">
                <div className="backbtn_div">
                  <Link to="/">
                    <img src={backicon} alt="" />
                  </Link>
                </div>
                <div className="login_headsec">
                  <h4>Reset Password</h4>
                </div>
                <Form>
                  <FormGroup className="form-group">
                    <div className="iconleft">
                      <img src={passwordicon} alt="" />
                    </div>
                    <Input
                      name="password"
                      placeholder="New Password"
                      type={showPassword1 ? "text" : "password"}
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility1}
                    >
                      {showPassword1 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup className="form-group">
                    <div className="iconleft">
                      <img src={passwordicon} alt="" />
                    </div>
                    <Input
                      name="password"
                      placeholder="Confirm Password"
                      type={showPassword2 ? "text" : "password"}
                      value={password2}
                      onChange={(e) => setPassword2(e.target.value)}
                    />
                    <div
                      className="iconright"
                      onClick={togglePasswordVisibility2}
                    >
                      {showPassword2 ? (
                        <img src={eyeshowicon} alt="" />
                      ) : (
                        <img src={eyehideicon} alt="" />
                      )}
                    </div>
                  </FormGroup>
                  {error && <div className="text-danger" style={{ textAlign: 'center'}}>{error}</div>}

                  <div className="login_btn">
                    <Button className="whitebutton mt_120" onClick={() => { onResetPass() }}>
                    {loading ? <PageLoader /> : "Reset Password"}
                      
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
        <Modal centered isOpen={modal} toggle={toggle} {...args}>
          <ModalBody>
            <div className="modalmain">
              <img src={SuccessImg} alt="" />
              <h1 className="modalhead mt-4">Reset Password Successful!</h1>
              <p className="modalsubhead">
                You password has been reset successfully. Kindly re-login to
                your account with new password.
              </p>
              <Link to="/">
                <Button className="primarybutton mb-3" onClick={toggle}>
                  Login
                </Button>
              </Link>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default Resetpassword;
