import React, { useState } from "react"
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalBody,
} from "reactstrap";
import classnames from 'classnames';
import avatarimg1 from '../../../../assets/image/avatar/2.png'
import docimg1 from "../../../../assets/image/doc1.jpg";
import docimg2 from "../../../../assets/image/doc2.jpg";
import docimg3 from "../../../../assets/image/doc3.jpg";
import zoomdoc1 from "../../../../assets/image/zoomdoc.jpg";
import DetailworkingschedulePages from "./detailworkingschedule";
import DetailrecentbookingPages from "./detailrecentbooking";
import { XCircle } from 'react-feather'
import { IMAGE_URL } from "../../../../constant/Endpoints";
import profileimg from "../../../../assets/image/default.jpg";
import moment from "moment";


const Instructorpersonal = ({ personal }) => {
  const [selectedDate,setSelectedDate] = useState("");

  const [modal2, setModal2] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const toggle2 = () => setModal2(!modal2);

  const handleClick = (imagePath) => {
    setCurrentImage(imagePath);
    toggle2();
  };


  const [modal4, setModal4] = useState(false)

  const toggle4 = () => setModal4(!modal4)

  const handleClickGovt = (imagePath) => {
    setCurrentImage(imagePath);
    toggle4();
  };


  const [modal3, setModal3] = useState(false);

  const toggle3 = () => setModal3(!modal3);

  const handleClickMedical = (imagePath) => {
    setCurrentImage(imagePath);
    toggle3();
  };


  const [currentActiveTab, setCurrentActiveTab] = useState('1');
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }
  return (
    <>
      <div className="d-flex mb-3 userview_div">
        <div className="flex-shrink-0 schooldetailimg_div">
          <img className="schooldetailimg" src={personal[0]?.profilePic ? IMAGE_URL + personal[0]?.profilePic:profileimg  } alt="" />
        </div>
        <div className="flex-grow-1">
          <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Name:</span> {personal[0]?.firstName} {personal?.lastName}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Email:</span> {personal[0]?.email}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Phone Number:</span> {personal[0]?.phone_number}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Date Of Birth:</span> {personal[0]?.DOB}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Liscence Level:</span> {personal[0]?.licencseLevel}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Gender:</span> {personal[0]?.gender ? personal[0]?.gender : ""}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Address:</span> {personal[0]?.address ? personal[0]?.address : ""}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Flying Experience:</span> {personal[0]?.flyingExperience}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Instructor Flight Rate:</span> {personal[0]?.instructorFlightRate ? personal[0]?.instructorFlightRate : ""}</h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Instructor Ground Rate:</span> {personal[0]?.instructorRate}</h5>
          {/* <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Craft Category:</span> {personal[0]?.craftCategory ? personal[0]?.craftCategory : ""}</h5> */}
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Status:</span> <span className="badge bg-light-success">{personal[0]?.account_status}</span></h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Certificate:</span>
            <div class="idproof_div">
            {personal[0]?.certificate_path.map((img, index) => (
        <img
          key={index}
          className="me-1 mb-1"
          src={IMAGE_URL + img?.path}
          alt=""
          onClick={() => handleClick(IMAGE_URL + img?.path)}
        />
      ))}</div>
          </h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Government Certificate:</span>
            <div class="idproof_div">
            {personal[0]?.govt_id_path.map((img, index) => (
        <img
          key={index}
          className="me-1 mb-1"
          src={IMAGE_URL + img?.path}
          alt=""
          onClick={() => handleClickGovt(IMAGE_URL + img?.path)}
        />
      ))}</div>
          </h5>
          <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Medical Certificate:</span>
            <div class="idproof_div">
            {personal[0]?.medical_certificate_path.map((img, index) => (
        <img
          key={index}
          className="me-1 mb-1"
          src={IMAGE_URL + img?.path}
          alt=""
          onClick={() => handleClickMedical(IMAGE_URL + img?.path)}
        />
      ))}</div>
          </h5>
        </div>
      </div>
      <hr className="section_breakline" />
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({
              active:
                currentActiveTab === '1'
            })}
            onClick={() => { toggle('1'); }}
          >
            Work Schedule
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active:
                currentActiveTab === '2'
            })}
            onClick={() => { toggle('2'); }}
          >
            Recent Reservation
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={currentActiveTab}>
        <TabPane tabId="1">
          <div>
            <DetailworkingschedulePages schedule={personal[0]?.instructorTimmings} />
          </div>
        </TabPane>
        <TabPane tabId="2">
          <div>
            <DetailrecentbookingPages reservationDetail={personal[0]?.bookedReservation} />
          </div>
        </TabPane>
      </TabContent>
      <Modal centered className="modalimg_zoom" isOpen={modal2} toggle={toggle2}>
        <XCircle className="modalzoom_close_icon" onClick={toggle2} />
        <ModalBody>
          <img src={currentImage} alt="" />
        </ModalBody>
      </Modal>

      <Modal centered className="modalimg_zoom" isOpen={modal4} toggle={toggle4}>
        <XCircle className="modalzoom_close_icon" onClick={toggle4} />
        <ModalBody>
          <img src={currentImage} alt="" />
        </ModalBody>
      </Modal>

      <Modal centered className="modalimg_zoom" isOpen={modal3} toggle={toggle3}>
        <XCircle className="modalzoom_close_icon" onClick={toggle3} />
        <ModalBody>
          <img src={currentImage} alt="" />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Instructorpersonal;
