import React, { useState, useRef, useEffect } from 'react';
import {
  Modal,
  FormGroup,
  Input,
  ModalBody,
  Row,
  Col,
  Button,
} from 'reactstrap';
import data from "./data";
import Table from "./Datatables";
import JoditEditor from 'jodit-react';
import usericon from "../../assets/image/profileuser-1.svg";
import pageicon from "../../assets/image/pagefield.svg";
import cameraicon from "../../assets/image/profilecamera-1.svg";
import { useDispatch } from 'react-redux';
import { AddAnnouncedAction, AnnouncementListAction } from '../../Redux/Actions/AnnouncementAction';
import { AdminListAction } from '../../Redux/Actions';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Announcement = () => {
  const [selectannouncement, setSelectannouncement] = useState("select")
  const [From, setFrom] = useState();
  const [subject, setSubject] = useState('');
  const [emailmodal, setEmailmodal] = useState(false);
  const emailtoggle = () => setEmailmodal(!emailmodal);
  const editor = useRef(null);
  const dispatch = useDispatch()
  const [content, setContent] = useState('');
  const config = {
    statusbar: false,
    placeholder: 'Enter Here...',
  }
  const [selectedFile, setSelectedFile] = useState(null);


  const [announceList, setAnnounceList] = useState([])

  const getAdminList = () => {
    dispatch(AdminListAction()).then(res => {
      setFrom(res?.data?.email)
    }).catch(err => {
      console.log(err)
    })
  }


  const handleFileChange = (event) => {
    let a = event.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a)//show 
    setSelectedFile(null); 
    setSelectedFile(b);
  };

  const adminAnnounceList = () => {
    dispatch(AnnouncementListAction()).then(res => {
      setAnnounceList(res?.data)
      setFrom(res?.adminEmail)
      adminAnnounceList()
      console.log(res?.adminEmail)
    }).catch(err => {
      console.log(err)
    })
  }

  useEffect(() => {
    getAdminList()

    // adminAnnounceList()
  }, [])


  const AddAnnouncementList = (status) => {
    const formdata = new FormData()
    formdata.append('to', selectannouncement)
    formdata.append('body', content)
    formdata.append('subject', subject)
    formdata.append('attachement', selectedFile)
    formdata.append('announcementStatus', status)

    // console.log(formdata, "FF", subject)
    dispatch(AddAnnouncedAction(formdata)).then(res => {
      console.log("AddAnnouncment", res);
      toast.success("Announcement sent successfully", {
        position: 'top-right',
      });
      setEmailmodal(!emailmodal)
      setSelectannouncement();
      setSubject();
      setContent();
      
      // setAnnounceList(res?.data)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }








  return (
    <>
      <div className='instructorpanel'>
        <Row className="mb-4">
          <Col xl="6" md="6" sm="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Announcements</h4>
          </Col>
          <Col xl="6" md="6" sm="6" className="text-end">
            <Button className="btn-add" onClick={emailtoggle}>Add</Button>
          </Col>
        </Row>
        <div>
          <Table data={data} />
        </div>
      </div>
      <Modal centered
        isOpen={emailmodal}
        className="modal-lg emailannounce_modal"
        toggle={() => setEmailmodal(!emailmodal)}
      >
        <ModalBody className="p-4">
          <div className="Emailmodal">
            <h2 className="email_head pb-3 text-center">Add Announcement</h2>
            <Row>
              <Col sm="12">
                <label className="formgroup_label">From</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    name="From"
                    placeholder=""
                    value={From}
                    defaultValue={From}
                    disabled
                    // onChange={(e) => setFrom(e.target.value)}
                    // type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm="12">
                <label className="formgroup_label">To</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="selectannouncement"
                    value={selectannouncement}
                    onChange={(e) => setSelectannouncement(e.target.value)}
                  >
                    <option value="select">Select Here</option>
                    <option value="Users">Users</option>
                    <option value="Instructors">Instructors</option>
                    <option value="All">All</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col sm="12">
                <label className="formgroup_label">Subject</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={pageicon} alt="" />
                  </div>
                  <Input
                    name="subject"
                    placeholder="Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col sm="12">
                <label className="formgroup_label">Body</label>
                <FormGroup className="form-group">
                  <JoditEditor
                    ref={editor}
                    value={content}
                    tabIndex={1}
                    // config={config}
                    onBlur={newContent => setContent(newContent)}
                  />
                </FormGroup>
              </Col>
              <Col sm="12">
                <label className="formgroup_label">Attachment File</label>
                <FormGroup className="form-group">
  <label htmlFor="uploadimg1" className='fileupload'>
    <div>
      <img 
        src={selectedFile ? selectedFile : cameraicon} 
        alt="" 
        style={{ maxWidth: '100px', maxHeight: '100px' }} // 
      />
      {selectedFile != null ? null : <h3>Upload File Here</h3> }
    </div>
  </label>
  <input type="file" id="uploadimg1" name="uploadimg1" style={{ display: 'none' }} onChange={handleFileChange} />
</FormGroup>
              
              </Col>
            </Row>
            <div className="btn-cancel-ok pt-3 text-center">
              <Button className="btn-cancel me-1" onClick={emailtoggle}>Cancel</Button>
              <Button className="btn-save me-1" onClick={() => { AddAnnouncementList(false) }}>Save Draft</Button>
              <Button className="btn-save" onClick={() => { AddAnnouncementList(true) }}>Send</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />

    </>
  );
}

export default Announcement;
