
import img1 from '../../assets/image/img1.webp';
import img2 from '../../assets/image/img2.webp';
import img3 from '../../assets/image/img3.webp';
import img4 from '../../assets/image/img4.webp';
import img5 from '../../assets/image/img5.webp';
const data = [
  {
    id: 1,
    title: "Pilot Training",
    imgurl: img1,
    place: "New York City, USA ",
    date: '08 May 2024',
    time: '09:00 am - 11:00 pm',
  },
  {
    id: 2,
    title: "Pilot Training",
    imgurl: img2,
    place: "Tokyo, Japan",
    date: '05 May 2024',
    time: '09:17 am - 08:13 pm',
  },
  {
    id: 3,
    title: "Pilot Training",
    imgurl: img3,
    place: "Sydney, Australia",
    date: '01 May 2024',
    time: '08:45 am - 05:45 pm',
  },
  {
    id: 4,
    title: "Pilot Training",
    imgurl: img4,
    place: "Rio de Janeiro, Brazil",
    date: '28 April 2024',
    time: '10:12 am - 09:25 pm',
  },
  {
    id: 5,
    title: "Pilot Training",
    imgurl: img5,
    place: "Moscow, Russia",
    date: '22 April2024',
    time: '11:00 am - 10:00 pm',
  },
];

export default data;
