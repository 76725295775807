import React, { useState } from 'react';
import {
	Container,
	Row,
	Col,
	Form,
	FormGroup,
	Input,
	Button,
} from 'reactstrap';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import forgotpasswordimg from '../../assets/image/forgot-password.svg';
import emailicon from '../../assets/image/email.svg';
import backicon from '../../assets/image/back-icon.svg';
import { useDispatch } from 'react-redux';
import { ForgotAction } from '../../Redux/Actions/onBoardingAction';
import PageLoader from '../customcomponent/loader';
const Forgotpassword = () => {
	const [loading, setLoading] = useState(false);
	const [inputValue1, setInputValue1] = useState('');
	const dispatch = useDispatch()
	const navigate = useNavigate()
    const [error, setError] = useState('');

	const handleChange1 = (e) => {
		setInputValue1(e.target.value);
	};

	const forgotPass = () => {
		setLoading(true);

		let data = {
			email: inputValue1,
		}
		if (Object.values(data).every(field => field.length > 0)) {
			setError('');

		dispatch(ForgotAction(data))
			.then((res) => {
				console.log(res.data, "RR")
				if (res.status) {
					setLoading(false);

					navigate("/VerifyOTP?email=" + inputValue1,)
					// Singleton.getInstance().saveData('token', res.data.userToken)
					// window.location.reload()
				} else {
					setLoading(false);

					setError("Email is not registered");

				}
			}).catch(err => setError("Something went wrong"))
	
		}else{ 
			setLoading(false);
            for (const key in data) {
            if (data[key]?.length === 0) {
                setError('Please enter email');
            }
          }
        }

		}




	return (
		<>
			<div>
				<Container fluid>
					<Row>
						<Col md="6" className="p-0">
							<div className="loginlogo_div">
								<img className="forgotimg" src={forgotpasswordimg} alt="" />
							</div>
						</Col>
						<Col className="loginfield_bg" md="6">
							<div className="loginmain_div position_relative">
								<div className="backbtn_div">
									<Link to="/"><img src={backicon} alt="" /></Link>
								</div>
								<div className="login_headsec">
									<h4>Forgot Password</h4>
									<p>Please enter your email address to receive an OTP for password reset.</p>
								</div>
								<Form>
									<FormGroup className="form-group">
										<div className="iconleft emailicon">
											<img src={emailicon} alt="" />
										</div>

										<Input
											name="email"
											placeholder="Email"
											value={inputValue1} onChange={handleChange1}
											type="email"
										/>

									</FormGroup>
									{error && <div className="text-danger">{error}</div>}

									<div className="login_btn">
										<Link
											onClick={() => { forgotPass() }}
										// to="/VerifyOTP"
										>
											<Button
												className="whitebutton mt_120">
													 {loading ? <PageLoader /> : "Proceed"}
											</Button>
										</Link>

									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default Forgotpassword;
