import React, { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from 'classnames';
import "react-datepicker/dist/react-datepicker.css";
import Instructorpersonal from "./Instructorpersonal";
import InstructortransactionPages from "./Instructortransaction";
import { InstructorDetailAction } from "../../../Redux/Actions/InstructorAction";
import { useDispatch } from "react-redux";
import { ReservationListAction, TransactionListAction } from "../../../Redux/Actions";
// import InstructoremailPages from "./Instructoremail";
// import InstructorchargePages from "./Instructorcharge";

const Viewinstructor = () => {
  const [currentActiveTab1, setCurrentActiveTab1] = useState('Personal');
  const toggle1 = tab => {
    if (currentActiveTab1 !== tab) setCurrentActiveTab1(tab);
  }
  const params = useParams();
  const dispatch = useDispatch()
  const [userDetail, setUserDetail] = useState()
  // const [reservationList, setReservationList] = useState()
  const [transactionList, setTransactionList] = useState()



  const getUserData = () => {
    const id = params.id;
    let data = {
      instructorID: id
    }
    dispatch(InstructorDetailAction(data)).then((res) => {
      setUserDetail(res?.data)
      console.log(res.data)
    }).catch((err) => { console.log(err) })
    console.log(id)
  }

  // const getReservationData = () => {
  //   dispatch(ReservationListAction()).then((res) => {
  //     setReservationList(res?.data)
  //     console.log(res.data)
  //   }).catch((err) => { console.log(err) })
  //   // console.log(id)
  // }

  const getTransactionData = () => {
    const id = params.id;
    let data = {
      userId: id
    }
    dispatch(TransactionListAction(data)).then((res) => {
      setTransactionList(res?.data)
      console.log(res.data,"-=================>")
    }).catch((err) => { console.log(err) })
    // console.log(id)
  }


  useEffect(() => {
    getUserData()
    // getReservationData()
    getTransactionData()
  }, [])

  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Instructor Details</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Instructor"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Nav tabs className="instructor_tab">
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab1 === 'Personal'
              })}
              onClick={() => { toggle1('Personal'); }}
            >
              Personal
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab1 === 'Transactions'
              })}
              onClick={() => { toggle1('Transactions'); }}
            >
              Transactions
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab1 === 'Email'
              })}
              onClick={() => { toggle1('Email'); }}
            >
              Email
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab1 === 'Charge'
              })}
              onClick={() => { toggle1('Charge'); }}
            >
              Charge
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={currentActiveTab1}>
          <TabPane tabId="Personal">
            <div>
              <Instructorpersonal personal={userDetail?.length > 0 && userDetail} />
            </div>
          </TabPane>
          <TabPane tabId="Transactions">
            <div>
              <InstructortransactionPages
                transaction={transactionList}
              />
            </div>
          </TabPane>
      
        </TabContent>

      </div>
    </>
  );
};

export default Viewinstructor;
