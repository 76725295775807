import React from "react"
import {
	Row,
	Col,
	Button,
} from "reactstrap"
import { Eye } from 'react-feather';
import { Link } from "react-router-dom";
const data = [
	{ 
		id: 1, 
		date: '	23 August 2023 - 24 August 2023 ', 
		user: 'Stephen George', 
		hobbsin: '4,273.80',
		hobbsout: '6,044.70',
	},
	{ 
		id: 2, 
		date: '	21 August 2023 - 22 August 2023 ', 
		user: 'Maria James', 
		hobbsin: '4,273.80',
		hobbsout: '6,044.70',
	},
	{ 
		id: 3, 
		date: '	19 August 2023 - 20 August 2023 ', 
		user: 'Johnson Charles', 
		hobbsin: '4,273.80',
		hobbsout: '6,044.70',
	},
	{ 
		id: 4, 
		date: '	17 August 2023 - 18 August 2023 ', 
		user: 'James Vince', 
		hobbsin: '4,273.80',
		hobbsout: '6,044.70',
	},
	{ 
		id: 5, 
		date: '	15 August 2023 - 16 August 2023 ', 
		user: 'David Smith', 
		hobbsin: '4,273.80',
		hobbsout: '6,044.70',
	},
];
const AircraftflighthistoryPages = () => {
	return (
		<Row>
			<Col sm="12">
				<div className="table-responsive">
					<table class="table">
						<thead class="table-light">
							<tr>
								<th className="text-nowrap">Date</th>
								<th className="text-nowrap">User</th>
								<th className="text-nowrap">Hobbs In</th>
								<th className="text-nowrap">Hobbs Out</th>
								<th className="text-nowrap text-center">Action</th>
							</tr>
						</thead>
						<tbody>
							{data.map((item, index) => (
								<tr key={index}>
									<td><b>{item.date}</b></td>
									<td>{item.user}</td>
									<td>{item.hobbsin}</td>
									<td>{item.hobbsout}</td>
									<td class="tableaction text-center">
										<Link to="/Flighthistorytransactions"><Button className="tablebtnview"><Eye className="tableicon" /></Button></Link>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Col>
		</Row>
	)
}

export default AircraftflighthistoryPages;
