import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import statusicon from "../../../assets/image/status-icon.svg";
import usericon from "../../../assets/image/profileuser-1.svg";
import calendaricon from "../../../assets/image/profilecalendar-1.svg";
import clockicon from "../../../assets/image/clock_icon.svg";
import FlyingIcon from "../../../assets/image/flying.svg";
import { AircraftListAction, EditReservationAction, ReservationDetailAction } from "../../../Redux/Actions";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import moment from "moment";

import { InstructorListAction } from "../../../Redux/Actions/InstructorAction";

const Editreservation = (args) => {
  const params = useParams()

  const dispatch = useDispatch()
  const [userDetail, setUserDetail] = useState()

  const [status, setStatus] = useState("")
  const [aircraft, setAircraft] = useState("")
  const [username, setUsername] = useState('');
  const [schedule, setSchedule] = useState('');
  const [InstructorRate, setInstructorRate] = useState('');
  const [HobbsIn, setHobbsIn] = useState('');
  const [HobbsOut, setHobbsOut] = useState('');
  const [TechIn, setTechIn] = useState('');
  const [TechOut, setTechOut] = useState('');
  const [AirportTax, setAirportTax] = useState('');
  const [AirportRate, setAirportRate] = useState('');
  const [Totalhours, setTotalhours] = useState('');
  const [FlightHours, setFlightHours] = useState('');
  const [GrandTotal, setGrandTotal] = useState('');
  const [InstructorNotes, setInstructorNotes] = useState('');
  const [PublicNotes, setPublicNotes] = useState('');
  const [ReservationDate, setReservationDate] = useState(null);
  const [DispatchDate, setDispatchDate] = useState(null);
  const [CheckinDate, setCheckinDate] = useState(null);
  const [Checkintime, setCheckintime] = useState(null);
  const [Groundtime, setGroundtime] = useState(null);
  const [Dispatchtime, setDispatchtime] = useState(null);
  const [AircraftList, setAircraftList] = useState(null);
  const [userList, setUsersList] = useState(null);
  const ReservationDateChange = (date) => {
    setReservationDate(date);
  }; 
  const DispatchDateChange = (date) => {
    setDispatchDate(date);
  };  

  const getReservationDetail = () => {
    const id = params.booking_id;
    let data = {
      booking_id: id
      // booking_id: "8X05433T"
    }
    dispatch(ReservationDetailAction(data)).then((res) => {
      setUserDetail(res?.data[0])
      setInstructorRate(res?.data[0].instructorRate)
      setDispatchtime(res?.data[0].dispatchTime)
      setGroundtime(res?.data[0].groundTime)
      setCheckintime(res?.data[0].checkInTime)
      setCheckinDate(res?.data[0].checkInDate)
      setReservationDate(moment(res?.data[0].bookingDate).format("DD-MM-YYYY"))
      setPublicNotes()
      setInstructorNotes(res?.data[0].instructorNotes)
      setGrandTotal(res?.data[0].totalPrice)
      setFlightHours(res?.data[0].flightHours)
      setTotalhours(res?.data[0].totalHours)
      setAirportRate()
      setAirportTax(res?.data[0].airportTax)
      setTechOut(res?.data[0].tachTotal)
      setTechIn(res?.data[0].TachIn)
      setHobbsOut(res?.data[0].hobbsTotal)
      setStatus(res?.data[0].booking_status)
      setAircraft(res?.data[0]?.aircraft_id)
      setHobbsIn(res?.data[0].hobbsIN)
      setSchedule(res?.data[0]?.instructor_id)
      setUsername(res?.data[0].userName)
    }).catch((err) => { console.log(err) })
    console.log(id)
  }

  
  const EditReservationn = () => {
    // const formData = new FormData();
    const id = params.booking_id;

    const formData = new FormData()
    const data= {
      booking_id: id,
      booking_status: status,
      userName:username,
      reservationInstructorRate:InstructorRate,
      hobbsIN: HobbsIn,
      hobbsOUT: HobbsOut,
      TachIn:TechIn,
      TachOut:TechOut,
      totalHours:Totalhours,
      totalPrice: GrandTotal,
      instructorNotes:InstructorNotes,
      bookingDate:ReservationDate,
      dispatchDate: DispatchDate,
      checkInDate: CheckinDate,
      checkInTime:Checkintime,
      groundTime: Groundtime,
      dispatchTime:Dispatchtime,
      aircraft_id:aircraft,
      instructor_id:schedule
    }
    
    dispatch(EditReservationAction(data)).then((res) => {
      if (res.status === 1) {
        toast.success("Reservation updated successfully", {
          position: 'top-right',
        });      
      }else{

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }     
    }).catch((err) => {
      toast.error(err, {
        position: 'top-right',
      });  
            console.log(err)
    })

  }

  const getAircraft = () => {

    dispatch(AircraftListAction()).then(res => {
      setAircraftList(res?.data)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })

  }


  const getInstructorList = async () => {
    dispatch(InstructorListAction()).then(res => {
      setUsersList(res.data)
    }).catch(err => console.log(err))
  };



  
  useEffect(() => {
    getReservationDetail()
    getAircraft()
    getInstructorList()
  }, [])


  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit Reservation</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Reservations"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md="12" lg="6">
							<label className="formgroup_label">User Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="username"
                  placeholder="User Name"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Reservation Date</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  // selected={ }
                  onChange={ReservationDateChange}
                  value={ReservationDate}
                  // value={moment(ReservationDate,"YYYY-MM-DD").format('DD/MM/YYYY')}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Reservation Date"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Dispatch Date</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  // selected={DispatchDate}
                  value={DispatchDate}

                  onChange={DispatchDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Dispatch Date"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Dispatch Time</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                  <DatePicker
                    id="dateField"
                    // selected={Dispatchtime}
                    value={Dispatchtime}
                    onChange={(date) => setDispatchtime(date)}
                    placeholderText="Dispatch Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"                          
                  /> 
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Check In Date</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  // selected={CheckinDate}
                  value={CheckinDate}

                  onChange={(date) => setCheckinDate(date)}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Check In Date"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Check In Time</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                  <DatePicker
                    id="dateField"
                    // selected={Checkintime}
                    value={Checkintime}

                    onChange={(date) => setCheckintime(date)}
                    placeholderText="Check In Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"                          
                  /> 
              </FormGroup>
            </Col>
              <Col md="12" lg="6">
							<label className="formgroup_label">Aircraft</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={FlyingIcon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="aircraft"
                    value={aircraft}
                    onChange={(e) => setAircraft(e.target.value)}
                  >

                    {/* {AircraftList} */}
                    <option value="select aircraft" disabled selected>Select Aircraft</option>
                    {AircraftList?.map((aircraft) => (
                      
      <option key={aircraft._id} value={aircraft._id}>
        
        {aircraft.aircraftName} ({aircraft.TailNumber})
      </option>
    ))}
                    {/* <option value="select">Select Aircraft</option>
                    <option value="aircraft1">Cessna 172 739BN</option>
                    <option value="aircraft2">Cessna 152 24981</option>
                    <option value="aircraft3">Cessna 152 959GT</option> */}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
							<label className="formgroup_label">Schedule</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="schedule"
                    value={schedule}
                    onChange={(e) => setSchedule(e.target.value)}
                  >
                    
                    <option value="select" disabled selected>Select Instructor</option>
                    {userList?.map((list) => (
                      
      <option key={list._id} value={list._id}>
        {list.firstName} {list.lastName}
      </option>
    ))}
                    {/* <option value="select">Select Schedule</option>
                    <option value="schedule1">David Smith</option>
                    <option value="schedule2">Steve Jones</option>
                    <option value="schedule3">Maria James</option>
                    <option value="schedule4">James Vince</option> */}
                  </Input>
                </FormGroup>
              </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Instructor Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="InstructorRate"
                  placeholder="Instructor Rate"
                  value={InstructorRate}
                  onChange={(e) => setInstructorRate(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Ground Time</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                  <DatePicker
                    id="dateField"
                    // selected={Groundtime}
                    value={Groundtime}

                    onChange={(date) => setGroundtime(date)}
                    placeholderText="Ground Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"                          
                  /> 
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
							<label className="formgroup_label">Instructor Notes</label>
              <FormGroup className="form-group">
                <Input
                  name="InstructorNotes"
                  placeholder="Instructor Notes"
                  rows="6"
                  value={InstructorNotes}
                  onChange={(e) => setInstructorNotes(e.target.value)}
                  type="textarea"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Hobbs In</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="HobbsIn"
                  placeholder=" Hobbs In"
                  value={HobbsIn}
                  onChange={(e) => setHobbsIn(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Hobbs Out</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="HobbsOut"
                  placeholder="Hobbs Out"
                  value={HobbsOut}
                  onChange={(e) => setHobbsOut(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Tach In</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="TechIn"
                  placeholder="Tach In"
                  value={TechIn}
                  onChange={(e) => setTechIn(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Tach Out</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="TechOut"
                  placeholder="Tach Out"
                  value={TechOut}
                  onChange={(e) => setTechOut(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Airport Tax</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="AirportTax"
                  placeholder="Airport Tax"
                  value={AirportTax}
                  onChange={(e) => setAirportTax(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Airport Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="AirportRate"
                  placeholder="Airport Rate"
                  value={AirportRate}
                  onChange={(e) => setAirportRate(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Airport Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <Input
                  name="Totalhours"
                  placeholder="Total hours"
                  value={Totalhours}
                  onChange={(e) => setTotalhours(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Flight Hours</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <Input
                  name="FlightHours"
                  placeholder="Flight Hours"
                  value={FlightHours}
                  onChange={(e) => setFlightHours(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
							<label className="formgroup_label">Grand Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <Input
                  name="GrandTotal"
                  placeholder="Grand Total"
                  value={GrandTotal}
                  onChange={(e) => setGrandTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
              <Col md="12" lg="6">
							<label className="formgroup_label">Status</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={statusicon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option >Select Status</option>
                    <option value="Requested">Requested</option>
                    <option value="Pending">Pending</option>
                    <option value="Tutorial-started">Tutorial-started</option>

                    <option value="Dispatch">Dispatch</option>
                    <option value="Check-In">CheckIn</option>
                    <option value="Rejected">Cancel</option>
                    <option value="Completed">Completed</option>
                  </Input>
                </FormGroup>
              </Col>
            {/* <Col md="12" lg="12">
							<label className="formgroup_label">Public Notes</label>
              <FormGroup className="form-group">
                <Input
                  name="InstructorNotes"
                  placeholder="Public Notes"
                  rows="6"
                  value={PublicNotes}
                  onChange={(e) => setPublicNotes(e.target.value)}
                  type="textarea"
                />
              </FormGroup>
            </Col> */}
          </Row>
          <div className="text-center mt-5">
            <Button className="primarybutton  maxwidth-555"
             onClick={() => {
              EditReservationn()
            }}>
              Update Now
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />

    </>
  );
};

export default Editreservation;
