import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import FlyingIcon from "../../../../assets/image/flying.svg";
import Select from 'react-select'
const AircraftType = [
  { value: 'Full Flight Simulator (FFS)', label: 'Full Flight Simulator (FFS)', color: '#00B8D9', isFixed: true },
  { value: 'Flight Training Device (FTD)', label: 'Flight Training Device (FTD)', color: '#00B8D9', isFixed: false },
  { value: 'Advanced Aircraft Training Device (AATD)', label: 'Advanced Aircraft Training Device (AATD)', color: '#00B8D9', isFixed: false },
  { value: 'Basic Aviation Training Device (BATD)', label: 'Basic Aviation Training Device (BATD)', color: '#00B8D9', isFixed: false },
  { value: 'Simulator Uncertified', label: 'Simulator Uncertified', color: '#00B8D9', isFixed: false },
]
const orderOptions = values => {
  if (values.length > 0) return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed))
}
const Addservicetracker = (args) => {
  const [label, setLabel] = useState('Fuel Added');
  const [description, setDescription] = useState('Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut');
  const [fixedValue, setFixedValue] = useState(orderOptions([AircraftType[0], AircraftType[1]]))
  const fixedOnChange = (value, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        value = AircraftType.filter(v => v.isFixed)
        break
      default:
        break
    }

    value = orderOptions(value)
    setFixedValue(value)
  }   
  return (
    <>
      <div className="instructorpanel">
      <Row className="mb-4">
        <Col xl="6" md="6" xs="6" className="align-self-center">
          <h4 className="card-title my-1  headingmain">Add Service Tracker</h4>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end">
          <Link to="/Aircrafts"><Button className="btn-add">Back</Button></Link>
        </Col>
      </Row>
        <Form>
          <Row>
            <Col md="12" lg="12">
							<label className="formgroup_label">Label</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="label"
                  placeholder="Label"
                  value={label}
                  onChange={(e) => setLabel(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
							<label className="formgroup_label">Aircraft</label>
              <FormGroup className="form-group">
                <Select
                        isClearable={false}
                        value={fixedValue}
                        isMulti
                        name='colors'
                        options={AircraftType}
                        onChange={fixedOnChange}
                        className='multiselect'
                        classNamePrefix='select'
                      />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
							<label className="formgroup_label">Description</label>
              <FormGroup className="form-group">
                <Input
                  name="description"
                  placeholder="Description"
                  value={description}
                  rows='4'
                  onChange={(e) => setDescription(e.target.value)}
                  type="textarea"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button className="primarybutton  maxwidth-555">
              Save Now
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Addservicetracker;
