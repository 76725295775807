// import Singleton from '../../../src/Singleton';
import { EDIT_USER_SUCCESS, RESERVATION_DEATIL_SUCCESS, RESERVATION_SUCCESS } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_DELETE_RESERVATION, ADMIN_RESERVATION, ADMIN_RESERVATION_DETAIL } from '../../constant/Endpoints';
import Singleton from '../../Singleton';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const ReservationListSuccess = (data) => {
    return {
        type: RESERVATION_SUCCESS,
        payload: data
    };
};

const ReservationDetailSuccess = (data) => {
    return {
        type: RESERVATION_DEATIL_SUCCESS,
        payload: data
    };
};


export const ReservationListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_RESERVATION, data)
                .then(response => {
                    dispatch(ReservationListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const ReservationDetailAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_RESERVATION_DETAIL, data)
                .then(response => {
                    dispatch(ReservationDetailSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const DeleteReservationAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DELETE_RESERVATION, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    alert(error.msg)
                })
        })
    }
}







// export const ForgotAction = (data) => {
//     return  (dispatch) => {
//         return new Promise((resolve, reject) => {
//             APIClient.getInstance()
//                 .post(ADMIN_FORGOT_PASSWORD, data, '')
//                 .then(response => {
//                     ForgotSuccess({ data: response })
//                     console.log('getAllStories --->>>', response);
//                     resolve(response);
//                 })
//                 .catch(error => {
//                     console.log('getAllStories error--->>>', error);
//                     reject('error');
//                 })
//         })
//     }
// }





// APIClient.getInstance().post(SIGN_UP, data).then(res => dispatch(SignInSuccess({ data: res }))).catch(err => console.log(err))


