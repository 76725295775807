import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/authentication/Login';
import Forgotpassword from './pages/authentication/Forgotpassword';
import TermsCondition from './pages/termsCondition';
import Privacypolicy from './pages/privacypolicy';
import Resetpassword from './pages/authentication/Resetpassword';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users';
import Events from './pages/Events';
import Addevent from './pages/Events/Addevent';
import Editevent from './pages/Events/Editevent';
import Viewevent from './pages/Events/Viewevent';
import Adduser from './pages/Users/Adduser';
import Edituser from './pages/Users/Edituser';
import Viewuser from './pages/Users/Viewuser';
import Instructor from './pages/Instructor';
import Addinstructor from './pages/Instructor/Addinstructor';
import Editinstructor from './pages/Instructor/Editinstructor';
import Viewinstructor from './pages/Instructor/Viewinstructor';
import Allpages from './pages/Allpages';
import Editpage from './pages/Allpages/Editpage';
import Reservations from './pages/Reservations';
import Viewreservation from './pages/Reservations/Viewreservation';
import Editreservation from './pages/Reservations/Editreservation';
import Aircrafts from './pages/Aircrafts';
import Addaircraft from './pages/Aircrafts/Aircraftlist/Addaircraft';
import Editaircraft from './pages/Aircrafts/Aircraftlist/Editaircraft';
import Viewaircraft from './pages/Aircrafts/Aircraftlist/Viewaircraft';
import Flighthistorytransactions from './pages/Aircrafts/Aircraftlist/Viewaircraft/Flighthistorytransactions';
import Addservicetracker from './pages/Aircrafts/Servicetracker/Addservicetracker';
import Transactions from './pages/Transactions';
import Viewtransactions from './pages/Transactions/Viewtransactions';
import Administrator from './pages/Administrator';
import Addadministrator from './pages/Administrator/Addadministrator';
import Editadministrator from './pages/Administrator/Editadministrator';
import Viewadministrator from './pages/Administrator/Viewadministrator';
import Permissions from './pages/Administrator/Permissions';
import Announcement from './pages/Announcement';
import ProfileSetting from './pages/Setting/ProfileSetting';
import ChangePassword from './pages/Setting/ChangePassword';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/css/style.css";
import Layout from './components/Layout';
import { Provider, useDispatch } from 'react-redux';
import { store, persistor } from '../src/Redux/Reducers';
import { PersistGate } from 'redux-persist/integration/react';
import VerifyOTP from './pages/Verify';
import { AdminListAction } from './Redux/Actions';
import Singleton from './Singleton';
import Post from './pages/Community';
import GlobalSetting from './pages/Setting/GlobalSetting';
import AircraftCategory from './pages/Category';
import ViewCategory from './pages/Category/View';



function App() {


  const dispatch = useDispatch()


  const profileList = () => {
    Singleton.getInstance().getData('token').then(res => {
      if (res) {
        dispatch(AdminListAction()).then(res => {
        }).catch(err => {
          // console.log(err)
        })
      }
      // console.log(res)
    }).catch(err => {
      // console.log(err)
    })

  }

  useEffect(() => {
    profileList()
  }, [])





  return (
    <>
      <Routes>
        <Route path="/" element={localStorage.getItem('token') !== null ? <Navigate to={"/Dashboard"} /> : <Login />} />
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/Dashboard" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Dashboard"}><Dashboard /></Layout>} />
        <Route path="/Post" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Community"}><Post /></Layout>} />
        <Route path="/Users"  element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Users"}><Users /></Layout>} />
        <Route path="/Events"  element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Events"}><Events /></Layout>} />
        <Route path="/Adduser" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> :  <Layout title={"Add User"}><Adduser /></Layout>} />
        <Route path="/Addevent" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> :  <Layout title={"Add Event"}><Addevent /></Layout>} />
        <Route path="/Editevent/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> :  <Layout title={"Edit Event"}><Editevent /></Layout>} />
        <Route path="/Viewevent/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> :  <Layout title={"View Event"}><Viewevent /></Layout>} />
        <Route path="/VerifyOTP" element={<VerifyOTP />} />
        <Route path="/Edituser/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Edit User"}><Edituser /></Layout>} />
        <Route path="/Viewuser/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"User Details"}><Viewuser /></Layout>} />
        <Route path="/ProfileSetting" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Profile Setting"}><ProfileSetting /></Layout>} />
        <Route path="/ChangePassword" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Change Password"}><ChangePassword /></Layout>} />
        <Route path="/Instructor" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Instructors"}><Instructor /></Layout>} />
        <Route path="/Addinstructor" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Add Instructor"}><Addinstructor /></Layout>} />
        <Route path="/Editinstructor/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Edit Instructor"}><Editinstructor /></Layout>} />
        <Route path="/Viewinstructor/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Instructor Details"}><Viewinstructor /></Layout>} />
        <Route path="/Allpages" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"All Pages"}><Allpages /></Layout>} />
        <Route path="/Editpage/:cms_type" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Edit Page"}><Editpage /></Layout>} />
        <Route path="/Reservations" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Reservations"}><Reservations /></Layout>} />
        <Route path="/Viewreservation/:booking_id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Reservation Details"}><Viewreservation /></Layout>} />
        <Route path="/Editreservation/:booking_id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Edit Reservation"}><Editreservation /></Layout>} />
        <Route path="/Aircrafts" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Aircrafts"}><Aircrafts /></Layout>} />
        <Route path="/Addaircraft" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Add Aircraft"}><Addaircraft /></Layout>} />
        <Route path="/Editaircraft/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Edit Aircraft"}><Editaircraft /></Layout>} />
        <Route path="/Viewaircraft/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Aircraft Details"}><Viewaircraft /></Layout>} />
        <Route path="/Flighthistorytransactions" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Flight History Details"}><Flighthistorytransactions /></Layout>} />
        <Route path="/Addservicetracker" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Add Service Tracker"}><Addservicetracker /></Layout>} />
        <Route path="/Transactions" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Transactions"}><Transactions /></Layout>} />
        <Route path="/Viewtransactions/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Transaction Details"}><Viewtransactions /></Layout>} />
        <Route path="/Administrator" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Administrators"}><Administrator /></Layout>} />
        <Route path="/Addadministrator" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Add Administrator"}><Addadministrator /></Layout>} />
        <Route path="/Editadministrator/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Edit Administrator"}><Editadministrator /></Layout>} />
        <Route path="/Viewadministrator/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Administrator Details"}><Viewadministrator /></Layout>} />
        <Route path="/Permissions/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Permissions"}><Permissions /></Layout>} />
        <Route path="/Announcement" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Announcements"}><Announcement /></Layout>} />
        <Route path="/GlobalSetting" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Global Setting"}><GlobalSetting /></Layout>} />
        <Route path="/AircraftCategory" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"Aircraft Category"}><AircraftCategory /></Layout>} />
        <Route path="/ViewCategory/:id" element={localStorage.getItem('token') == null ? <Navigate to={"/"} /> : <Layout title={"View Category"}><ViewCategory /></Layout>} />
        <Route path="/Forgotpassword" element={<Forgotpassword />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<Privacypolicy />} />
        <Route path="/Resetpassword" element={<Resetpassword />} />
      </Routes>

    </>
  );
}

export default App;
