
import img1 from '../../assets/image/listimg1.jpg'
import img2 from '../../assets/image/avatar/2.png'
import img3 from '../../assets/image/listimg2.jpg'
import img4 from '../../assets/image/avatar/1.png'
import img5 from '../../assets/image/listimg3.jpg'
const data = [
  {
    id: 1,
    imgurl: img1,
    reservationid: "IPT15BA",
    reservedBy: 'Stephen George',
    aircraftname: 'Cessna 172 739BN',
    instructorname: '-',
    reservedDate: '17 August 2023',
    status: 'Completed',
  },
  {
    id: 2,
    imgurl: img2,
    reservationid: "IPT15BA",
    reservedBy: 'Stephen George',
    aircraftname: 'Cessna 172 739BN',
    instructorname: 'Johnson Charles',
    reservedDate: '16 August 2023',
    status: 'Pending',
  },
  {
    id: 3,
    imgurl: img3,
    reservationid: "IPT15BA",
    reservedBy: 'David Smith',
    aircraftname: '-',
    instructorname: 'Johnson Charles',
    reservedDate: '15 August 2023',
    status: 'Dispatch',
  },
  {
    id: 4,
    imgurl: img4,
    reservationid: "IPT15BA",
    reservedBy: 'Stephen George',
    aircraftname: 'Cessna 172 739BN',
    instructorname: 'Maria James',
    reservedDate: '14 August 2023',
    status: 'Completed',
  },
  {
    id: 5,
    imgurl: img5,
    reservationid: "IPT15BA",
    reservedBy: 'Stephen George',
    aircraftname: 'Cessna 172 739BN',
    instructorname: '-',
    reservedDate: '13 August 2023',
    status: 'Pending',
  },
];

export default data;
