import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Label,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { AdminFetchPermissionAction, AdminUpdatePermissionAction } from "../../../Redux/Actions";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
const Editadministrator = (args) => {

  const param = useParams()
  const dispatch = useDispatch()

  const [fpermission, setFPermission] = useState({})
  const [adminPersmission, setAdminPermission] = useState([])
  const [state, setState] = useState({
    isListChecked: false,
    isAddEditDeleteChecked: false
  });


  const handleState = (id) => {
    // setState(prevState => ({
    //   ...prevState,
    //   [key]: value
    // }));
    let arr = [...adminPersmission];
    if (arr.includes(id)) {
      arr.splice(arr.indexOf(id), 1)
    } else {
      arr.push(id)
    }
    setAdminPermission(arr)
  };

  const navigate = useNavigate()


  const fetchPermission = () => {
    let data = {
      adminID: param.id
    }
    dispatch(AdminFetchPermissionAction(data)).then(res => {
      // console.log(res)
      const obj = {};
      res.permissions?.map((i) => {
        obj[i.resource] = [...(obj[i.resource] || [])];
        obj[i.resource].push({ action: i.actions, _id: i._id });
      });
      setFPermission(obj)
      setAdminPermission(res.adminAssignedPermission.permissions)

    }).catch(err => {
      // console.log(err)
    })
  }

  const addPermission = () => {

    let data = {
      adminID: param.id,
      permissionID: adminPersmission
    }

    dispatch(AdminUpdatePermissionAction(data)).then(res => {
      if (res.status === 1) {
        toast.success("Permission updated successfully", {
          position: 'top-right',
        });          
        // navigate(-1)
      }else{
        // console.log("res=============?",res)

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }     
    }).catch(err => {
      toast.error("Something went wrong", {
        position: 'top-right',
      }); 
    })
  }


  useEffect(() => {
    fetchPermission()
  }, [])





  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit Administrator</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Administrator"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form>
          {Object.keys(fpermission).map((key, idx) => (
            <FormGroup className="form-group">
              <Row>
                <Col sm="4">
                  <span className="permissionleft_head">{key}</span>
                </Col>
                <Col sm="8">
                  {fpermission[key].map(item => (
                    <div className='form-check form-check-inline'>
                      <Input
                        type='checkbox'
                        name='permission'
                        id='Userlist'
                        checked={adminPersmission.includes(item._id)}
                        onChange={() => handleState(item._id)}
                      />
                      <Label className='form-check-label' for='Userlist'>
                        {item.action}
                      </Label>
                    </div>
                  ))}

                </Col>
              </Row>
            </FormGroup>
          ))}
          <div className="text-center mt-5">
            <Button
              onClick={() => {
                addPermission()
              }}
              className="primarybutton  maxwidth-555">
              Update Now
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />

    </>
  );
};

export default Editadministrator;
