import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import { DetailTransactionAction } from "../../../Redux/Actions";
import moment from "moment";
const Viewtransactions = () => {

  const dispatch = useDispatch()
  const param = useParams()
  const [transactionDetail, setTransactionDetail] = useState([])

  const getTransactionDetail = () => {
    let data = {
      transactionId: param.id
    }

    dispatch(DetailTransactionAction(data)).then((res) => {
      setTransactionDetail(res?.data)
      console.log(res.data)
    }).catch((err) => { console.log(err) })
    // console.log(id)
  }


  useEffect(() => {
    getTransactionDetail()
  }, [])



  return (
    <>
      <div className="instructorpanel invoicemain_div">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Transaction Details</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Transactions"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Row className="mb-4">
          <Col sm="6">
            <h5>{transactionDetail?.userName}</h5>
            <p>{transactionDetail?.userPhoneNumber}</p>
            <p>{transactionDetail?.userEmail}</p>
            <p>{transactionDetail?.userAddress}</p>
          </Col>

          <Col sm="6" className="text-end">
            <p>{transactionDetail?.instructorAddress}</p>
            <p> {transactionDetail?.instructorPhoneNumber}</p>
            <h3>INVOICE # {transactionDetail?.invoiceNumber}</h3>
          </Col>
        </Row>
        <div className="table-responsive">
          <table className="table table-bordered table-nowrap table-centered m-0">
            <thead className="thead-light">
              <tr>
                <th>Description</th>
                <th>Hour</th>
                <th>Rate</th>
                <th>Charge</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{transactionDetail?.aircraftName}</td>
                <td>1</td>
                <td>${transactionDetail?.aircraftRate}</td>
                <td>$126.00</td>
              </tr>
              <tr>
                <td>Ground: Club Premium InstructorTIme</td>
                <td>1</td>
                <td>$100.00</td>
                <td>$116.00</td>
              </tr>
              <tr>
                <td colspan="3" className="text-end">
                  <p className="fw-bold">Total Amount:</p>
                  <p className="fw-bold">Tax:</p>
                </td>
                <td>
                  <p className="fw-bold">${transactionDetail?.amount}</p>
                  <p className="fw-bold">${transactionDetail?.airportTax}</p>
                </td>
              </tr>
              <tr>
                <td colspan="3" className="text-end">
                  <h5 className="fw-bold">Total:</h5>
                </td>
                <td>
                  <h5 className="fw-bold">${transactionDetail?.totalAmount}</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Row className="row mt-4">
          <Col sm="8">
            <div className="receipt-left">
              <p><b>Payment :</b> <br />{transactionDetail?.funding} Card - 236***********{transactionDetail?.cardNumber}</p>
              <p><b>Date :</b> {moment(transactionDetail?.transactionTime).format('DD/MM/YYYY hh:mm A')}</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Viewtransactions;
