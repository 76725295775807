// import Singleton from '../../../src/Singleton';
import { ADD_USER_SUCCESS, EDIT_USER_SUCCESS, FORGOT_PASSWORD, LOG_IN_SUCCESS, RESET_PASSWORD, USER_DEATIL_SUCCESS, USER_LIST_SUCCESS, VERIFY_OTP } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_CREATE_USER, ADMIN_DELETE_ADMIN, ADMIN_DELETE_USER, ADMIN_UPDATE_USER, ADMIN_USER_DETAIL, ADMIN_USER_LIST ,ADMIN_RESERVATION_EDIT, DELETE_CERTIFICATE, DELETE_GOVERNMENTCERTIFICATE, DELETE_MEDICALCERTIFICATE, USER_STATUS_UPDATE, FETCH_INSTRUCTORRATE, UPDATE_INSTRUCTORRATE
} from '../../constant/Endpoints';
import Singleton from '../../Singleton';
import data from '../../pages/Aircrafts/Aircraftlist/data';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const UserListSuccess = (data) => {
    return {
        type: USER_LIST_SUCCESS,
        payload: data
    };
};

const UserDetailSuccess = (data) => {
    return {
        type: USER_DEATIL_SUCCESS,
        payload: data
    };
};


const AddUserSuccess = (data) => {
    return {
        type: ADD_USER_SUCCESS,
        payload: data
    };
};


const EditUserSuccess = (data) => {
    console.log('UpdateProps---', data);
    return {
        type: EDIT_USER_SUCCESS,
        payload: data
    };
};



export const UserListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_USER_LIST, data)
                .then(response => {
                    dispatch(UserListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const UserDetailAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_USER_DETAIL, data)
                .then(response => {
                    dispatch(UserDetailSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}

export const DeleteCertificate = (data)=>{
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(DELETE_CERTIFICATE, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('error--->>>', error);
                    reject("error");
                    // alert(error.msg)
                })
        })
    }
}

export const DeleteGovernmentCertificate = (data)=>{
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(DELETE_GOVERNMENTCERTIFICATE, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('error--->>>', error);
                    reject("error");
                    // alert(error.msg)
                })
        })
    }
}

export const DeleteMedicalCertificate = (data)=>{
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(DELETE_MEDICALCERTIFICATE, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('error--->>>', error);
                    reject("error");
                    // alert(error.msg)
                })
        })
    }
}

export const AddUserAction = (data) => {
    // console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .filePost(ADMIN_CREATE_USER, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject("error");
                    // alert(error.msg)
                })
        })
    }
}

export const EditUserAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .filePost(ADMIN_UPDATE_USER, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}

export const EditReservationAction = (data) => {
    // console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_RESERVATION_EDIT
                    , data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}
export const DeleteUserAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DELETE_USER, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const UpdateUserStatusAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(USER_STATUS_UPDATE, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}

export const GetInstructorRateAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .get(FETCH_INSTRUCTORRATE, data)
                .then(response => {
                   
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}

export const UpdateInstructorRateAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(UPDATE_INSTRUCTORRATE, data)
                .then(response => {
                   
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}






// export const ForgotAction = (data) => {
//     return  (dispatch) => {
//         return new Promise((resolve, reject) => {
//             APIClient.getInstance()
//                 .post(ADMIN_FORGOT_PASSWORD, data, '')
//                 .then(response => {
//                     ForgotSuccess({ data: response })
//                     console.log('getAllStories --->>>', response);
//                     resolve(response);
//                 })
//                 .catch(error => {
//                     console.log('getAllStories error--->>>', error);
//                     reject('error');
//                 })
//         })
//     }
// }





// APIClient.getInstance().post(SIGN_UP, data).then(res => dispatch(SignInSuccess({ data: res }))).catch(err => console.log(err))


