// import Singleton from '../../../src/Singleton';
import { GET_CMS_SUCCESS } from "./types";
import { APIClient } from "../../Api/ApiClient";
import {
  ADMIN_ADD_COMMENT,
  ADMIN_ADD_EVENT,
  ADMIN_DELETE_EVENT,
  ADMIN_DELETE_POST,
  ADMIN_DETAIL_EVENT,
  ADMIN_EDIT_CMS,
  ADMIN_EDIT_EVENT,
  ADMIN_FETCH_COMMENT,
  ADMIN_FETCH_COMMENT_REPLY,
  ADMIN_FETCH_EVENT,
  ADMIN_FETCH_POST,
  ADMIN_GET_CMS,
  ADMIN_GET_SINGLE_CMS,
  ADMIN_LIKE_UNLIKE,
  ADMIN_POST_ADD,
  ADMIN_POST_DETAIL,
  CALLFUNC,
  PRESIGNEDURL,
} from "../../constant/Endpoints";
import Singleton from "../../Singleton";

// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const ManagementListSuccess = (data) => {
  return {
    type: GET_CMS_SUCCESS,
    payload: data,
  };
};

export const ManagementListAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_GET_CMS, data)
        .then((response) => {
          dispatch(ManagementListSuccess({ data: response.data }));
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const EditManagementAction = (data) => {
  console.log(data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_EDIT_CMS, data)
        .then((response) => {
          // dispatch(AddUserSuccess({ data: response.data }))
          // console.log('getAllStories --->>>', response);
          resolve(response);
          // alert(response.msg)
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
          alert(error.msg);
        });
    });
  };
};

export const SingleManagementAction = (data) => {
  console.log(data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_GET_SINGLE_CMS, data)
        .then((response) => {
          // dispatch(AddUserSuccess({ data: response.data }))
          // console.log('getAllStories --->>>', response);
          resolve(response);
          // alert(response.msg)
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
          alert(error.msg);
        });
    });
  };
};

export const EventListAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_FETCH_EVENT, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const PostListAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_FETCH_POST, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const CommentListAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_FETCH_COMMENT, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminAddComment = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_ADD_COMMENT, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminLikePOSTCOMMENT = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_LIKE_UNLIKE, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminDELETEPOST = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_DELETE_POST, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminFetchCommentReply = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_FETCH_COMMENT_REPLY, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject("error");
        });
    });
  };
};

export const AdminDetailPOST = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_POST_DETAIL, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const PresignedURL = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(PRESIGNEDURL, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const CallFunction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(CALLFUNC, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminAddPOST = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .filePost(ADMIN_POST_ADD, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const DeleteEventAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_DELETE_EVENT, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminFetchEventDetail = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_DETAIL_EVENT, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminEditEventDetail = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .filePost(ADMIN_EDIT_EVENT, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AdminAddEventDetail = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .filePost(ADMIN_ADD_EVENT, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};
