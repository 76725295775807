import React, { useEffect, useState } from "react"
import {
	Row,
	Col,
} from "reactstrap"
import avatarimg1 from '../../../../assets/image/listimg1.jpg'
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AircraftDetailAction } from "../../../../Redux/Actions/AircraftAction";
import { IMAGE_URL } from "../../../../constant/Endpoints";
import aircraftimg from "../../../../assets/image/plane.svg";

const data = [
	{
		id: 1,
		day: 'Monday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 2,
		day: 'Tuesday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 3,
		day: 'Wednesday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 4,
		day: 'Thursday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 5,
		day: 'Friday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 6,
		day: 'Saturday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 7,
		day: 'Sunday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
];
const AircraftworkingschedulePages = ({ aircraft }) => {

	// console.log(aircraft, "AA")

	return (
		<Row>
			<Col sm="12">
				<div className="d-flex mb-3 userview_div">
					<div className="flex-shrink-0 schooldetailimg_div">
						<img className="schooldetailimg" src={aircraft?.aircraftImage?IMAGE_URL + aircraft?.aircraftImage:aircraftimg } alt="" />
					</div>
					<div className="flex-grow-1">
						<h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Aircraft Name:</span> {aircraft?.aircraftName?.name}</h5>
						{/* <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Aircraft Type:</span> {aircraft?.aircraftType ? aircraft?.aircraftType?.type : ""}</h5> */}
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Aircraft Rate:</span> ${aircraft?.aircraftRate}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Aircraft Tax:</span> {aircraft?.airportTax}%</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Preferred Name:</span> {aircraft?.preferredName ? aircraft?.preferredName : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Hourly Rate:</span>{aircraft?.hourlyRate ? aircraft?.hourlyRate : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Engines:</span> {aircraft?.engines ? aircraft?.engines : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Category/ Class:</span> {aircraft?.aircraftCategory ? aircraft?.aircraftCategory?.categoryName : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>ICAO Model:</span> {aircraft?.icaoModel ? aircraft?.icaoModel : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Model:</span> {aircraft?.model ? aircraft?.model : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Make:</span> {aircraft?.make ? aircraft?.make : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Year:</span> {aircraft?.year ? aircraft?.year : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Serial Number:</span> {aircraft?.serialNumber ? aircraft?.serialNumber : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Tail Number:</span> {aircraft?.TailNumber ? aircraft?.TailNumber : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Hobbs Total:</span> {aircraft?.hobbsTotal ? aircraft?.hobbsTotal : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Tach Total:</span> {aircraft?.tachTotal ? aircraft?.tachTotal : ""}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>AirportTax:</span> {aircraft?.airportTax}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Tax Percentage:</span> {aircraft?.taxPercentage}</h5>
						<h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Status:</span> <span className="badge bg-light-success">{aircraft?.aircraftStatus}</span></h5>

					</div>
				</div>
			</Col>
			{/* <Col md="12">
				<hr className="section_breakline" />
			</Col>
			<Col sm="12">
				<div className="mb-3 userview_div">
					<h4 className="card-title mb-3 headingcard">Maintenance Status</h4>
					<Row className="mb-3">
						<Col md="4"><h5 className="mb-0 fw-bold">Billing Follows:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">Hobbs</h5></Col>
					</Row>
					<Row className="mb-3">
						<Col md="4"><h5 className="mb-0 fw-bold">Round 2 Decimal Places:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">Hobbs</h5></Col>
					</Row>
					<Row className="mb-3">
						<Col md="4"><h5 className="mb-0 fw-bold">Hobbs Total:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">4288.00</h5></Col>
					</Row>
					<Row className="mb-3">
						<Col md="4"><h5 className="mb-0 fw-bold">Tach Total:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">6049.00</h5></Col>
					</Row>
					<Row className="mb-3">
						<Col md="4"><h5 className="mb-0 fw-bold">TTAF:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">0</h5></Col>
					</Row>
					<Row className="mb-3">
						<Col md="4"><h5 className="mb-0 fw-bold">TTAF Follows:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">Tach</h5></Col>
					</Row>
					<Row>
						<Col md="4"><h5 className="mb-0 fw-bold">Record Air TIme At Checkin:</h5></Col>
						<Col md="8"><h5 className="fw-normal mb-0">No</h5></Col>
					</Row>
				</div>
			</Col> */}
			<Col md="12">
				<hr className="section_breakline" />
			</Col>
			<Col sm="12">
				{/* <h4 className="card-title mb-3 headingcard">Work Schedule</h4> */}
				{/* {
					aircraft?.aircraftTimmings?.map((item, index) => (
						<div key={index}>
							{Object.keys(item).map(day => {
								// Exclude 'aircraftId' from rendering
								if (day !== 'aircraftId') {
									return (
										<div className="workdetail_div" key={day}>
											<h5 className="dayhead">{day}</h5>
											<div className="detailslot_div">
												{item[day]?.time_slot?.map((time, idx) => (
													<span className="slot_badge" key={idx}>{time.from} - {time.to}</span>
												))}
											</div>
										</div>
									);
								}
								return null; // Skip rendering 'aircraftId'
							})}
						</div>
					))
				} */}
				{/* {
					aircraft?.aircraftTimmings?.map((item) => (
						<>
							<div className="workdetail_div">
								<h5 className="dayhead">Monday</h5>
								<div class="detailslot_div">
									<span className="slot_badge">09:00 am</span>
									<span className="slot_badge">11:00 am</span>
									<span className="slot_badge">01:30 pm</span>
									<span className="slot_badge">04:00 am</span>
								</div>
							</div>
							<div className="workdetail_div">
								<h5 className="dayhead">Tuesday</h5>
								<div class="detailslot_div">
									<span className="slot_badge">09:00 am</span>
									<span className="slot_badge">11:00 am</span>
									<span className="slot_badge">01:30 pm</span>
									<span className="slot_badge">04:00 am</span>
								</div>
							</div>
						</>

					))

				} */}


			</Col>
		</Row>
	)
}

export default AircraftworkingschedulePages;
