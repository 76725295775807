const data = [
  {
    id: 1,
    date: '	Aug 29, 2023',
    from: 'James Vince',
    subject: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  {
    id: 2,
    date: '	Aug 28, 2023',
    from: 'David Smith',
    subject: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  {
    id: 3,
    date: '	Aug 27, 2023',
    from: 'Steve Jones',
    subject: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  {
    id: 4,
    date: '	Aug 26, 2023',
    from: 'Johnson Charles',
    subject: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
  {
    id: 5,
    date: '	Aug 25, 2023',
    from: 'Maria James',
    subject: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  },
];

export default data;
