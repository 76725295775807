// import Singleton from '../../../src/Singleton';
import { ADMIN_DETAIL_SUCCESS, ADMIN_LIST_SUCCESS, } from './types'
import { APIClient } from '../../Api/ApiClient';
import Singleton from '../../Singleton';
import { ADMIN_ADD_AIRCRAFT, ADMIN_ADMIN_LIST, ADMIN_AIRCRAFT_DETAIL, ADMIN_AIRCRAFT_LIST, ADMIN_AIRCRAFT_UPDATE, ADMIN_CHANGE_PASSWORD, ADMIN_CREATE_ADMIN, ADMIN_DELETE_ADMIN, ADMIN_DELETE_AIRCRAFT, ADMIN_FETCH_PERMISSON, ADMIN_GET_PROFILE, ADMIN_HOURS_AIRCRAFT, ADMIN_UPDATE_PERMISSON,ADMIN_UPDATE_PROFILE, ADMIN_UPDATE_USER } from '../../constant/Endpoints';



const AdminGetSuccess = (data) => {
    return {
        type: ADMIN_LIST_SUCCESS,
        payload: data
    };
};

const AdminListSuccess = (data) => {
    return {
        type: ADMIN_DETAIL_SUCCESS,
        payload: data.data
    };
};





export const AdminGetAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_ADMIN_LIST, data)
                .then(response => {
                    dispatch(AdminGetSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const AdminCreateAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .filePost(ADMIN_CREATE_ADMIN, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    // alert(error.msg)
                })
        })
    }
}


export const AdminListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_GET_PROFILE, data)
                .then(response => {
                    dispatch(AdminListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const AdminDeleteAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DELETE_ADMIN, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    // alert(error.msg)
                })
        })
    }
}

export const AdminUpdateProfileAction = (data) => {
    console.log("data=============>",data);
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .filePost(ADMIN_UPDATE_PROFILE, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    // alert(error.msg)
                })
        })
    }
}

export const AdminChangePasswordAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_CHANGE_PASSWORD, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject(error);
                    // alert(error.msg)
                })
        })
    }
}


export const AdminFetchPermissionAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_FETCH_PERMISSON, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    // alert(error.msg)
                })
        })
    }
}

export const AdminUpdatePermissionAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_UPDATE_PERMISSON, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                   
                })
        })
    }
}


// export const ForgotAction = (data) => {
//     return  (dispatch) => {
//         return new Promise((resolve, reject) => {
//             APIClient.getInstance()
//                 .post(ADMIN_FORGOT_PASSWORD, data, '')
//                 .then(response => {
//                     ForgotSuccess({ data: response })
//                     console.log('getAllStories --->>>', response);
//                     resolve(response);
//                 })
//                 .catch(error => {
//                     console.log('getAllStories error--->>>', error);
//                     reject('error');
//                 })
//         })
//     }
// }





// APIClient.getInstance().post(SIGN_UP, data).then(res => dispatch(SignInSuccess({ data: res }))).catch(err => console.log(err))


