import React from "react";
import Sidebar from '../../pages/Include/Sidebar';
import Header from '../../pages/Include/Header';
import Footer from '../../pages/Include/Footer';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

const Layout = ({ children, title }) => {
    return (
        <>
            <div>
                <Header />
                <div className='breadrumbsection'>
                    <Container className='containercustom'>
                        <h1 className="text-capitalize">{title}</h1>
                    </Container>
                </div>
                <div className='instructormain'>
                    <Container className='containercustom'>
                        <Row>
                            <Col md="5" lg="4" xl="3" className='mb-3'>
                                <Sidebar />
                            </Col>
                            <Col md="7" lg="8" xl="9" className='mb-3'>
                                {children}
                            </Col>

                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Layout;