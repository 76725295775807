import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GetInstructorRateAction, UpdateInstructorRateAction } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";

export default function GlobalSetting() {
  const dispatch = useDispatch();

  const [rate, setRate] = useState(0);

  const getRate = () => {
    dispatch(GetInstructorRateAction())
      .then((res) => {
        console.log("======RES======", res?.data.instructorRate);
        setRate(res?.data.instructorRate);
      })
      .catch((error) => {
        console.log("getAllStories error--->>>", error);
      });
  };

  const updateRate = (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    const data = {
      rate: parseFloat(rate), // Convert the rate back to a number
    };

    console.log("data", data);

    dispatch(UpdateInstructorRateAction(data))
      .then((res) => {
        console.log("res", res);
        if (res.status === 0) {
          toast.error(res.msg, {
            position: "top-right",
          });
        } else {
          toast.success("Instructor rate updated successfully", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        console.log("getAllStories error--->>>", error);
      });
  };

  useEffect(() => {
    getRate();
  }, []);

  const handleRateChange = (e) => {
    const value = e.target.value;
    // Remove any non-numeric characters except decimal point
    const numericValue = value.replace(/[^0-9.]/g, "");
    setRate(numericValue);
  };

  return (
    <React.Fragment>
      <Card >
        <Card.Header className="py-4"  style={{ backgroundColor: "#B79B58" }}>
          <h5>Instructor Rate</h5>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={updateRate}>
            <Row>
              <Col lg={12}>
                <Form.Group className="form-group mb-3">
                  <Form.Label>Instructor Rate</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Instructor Rate"
                    value={`$${rate}`} // Display the rate with a $
                    required
                    min={0}
                    onChange={handleRateChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              style={{ backgroundColor: "#B79B58", borderColor: "#B79B58", color: "white" }}
              type="submit"
            >
              Update
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
}


