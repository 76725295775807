import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
  ButtonGroup,
} from "reactstrap";
import { X, Plus } from "react-feather";
import defaultimg from "../../../../assets/image/listimg1.jpg";
import FlyingIcon from "../../../../assets/image/flying.svg";
import statusicon from "../../../../assets/image/status-icon.svg";
import rateicon from "../../../../assets/image/rate_icon.svg";
import taxicon from "../../../../assets/image/tax_icon.svg";
import usericon from "../../../../assets/image/profileuser-1.svg";
import calendaricon from "../../../../assets/image/profilecalendar-1.svg";
import serialnumbericon from "../../../../assets/image/serialnumber_icon.svg";
import { useDispatch } from "react-redux";
import {
  AircraftCategoryAction,
  AircraftDetailAction,
  AircraftNameListAction,
  AircraftTypeAction,
  EditAircraftAction,
} from "../../../../Redux/Actions/AircraftAction";
import { IMAGE_URL } from "../../../../constant/Endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profileimg from "../../../../assets/image/default.jpg";

let timeSlot = [
  { from: "12:00 AM", to: "2:30 AM" },
  { from: "2:30 AM", to: "5:00 AM" },
  { from: "5:00 AM", to: "7:30 AM" },
  { from: "7:30 AM", to: "10:00 AM" },
  { from: "10:00 AM", to: "12:30 PM" },
  { from: "12:30 PM", to: "3:00 PM" },
  { from: "3:00 PM", to: "5:30 PM" },
  { from: "5:30 PM", to: "8:00 PM" },
  { from: "8:00 PM", to: "10:30 PM" },
];

let daySlot = {
  mon: timeSlot,
  tue: timeSlot,
  wed: timeSlot,
  thurs: timeSlot,
  fri: timeSlot,
  sat: timeSlot,
  sun: timeSlot,
};

const Editaircraft = (args) => {
  const [cSelected, setCSelected] = useState({
    mon: [],
    tue: [],
    wed: [],
    thurs: [],
    fri: [],
    sat: [],
    sun: [],
  });

  const params = useParams();
  const dispatch = useDispatch();
  const [aircraftDetail, setAircraftDetail] = useState();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [aircraftname, setAircraftname] = useState("");
  const [aircraftrate, setAircraftrate] = useState("");
  const [preferredname, setPreferredname] = useState("");
  const [aircrafttax, setAircrafttax] = useState("");
  const [hourlyrate, setHourlyrate] = useState("");
  const [engines, setEngines] = useState("");
  const [category, setCategory] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [aircraftNameList, setAircraftNameList] = useState([]);
  const [ICAOModel, setICAOModel] = useState("");
  const [model, setModel] = useState(" ");
  const [make, setMake] = useState("");
  const [year, setYear] = useState("");
  const [serialnumber, setSerialnumber] = useState("");
  const [tailnumber, setTailnumber] = useState("");
  const [status, setStatus] = useState("");
  const [aircraftType, setAircraftType] = useState([]);
  const [aircraftListType, setAircraftListType] = useState([]);
  const [HobbsTotal, setHobbsTotal] = useState("");
  const [TachTotal, setTachTotal] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [rSelected, setRSelected] = useState("");
  const [weekoff, setWeekoff] = useState(true);
  const [airporttax, setAirporttax] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");

  const onCheckboxBtnClick = (selected) => {
    const currentSelections = cSelected[rSelected] || [];

    const index = currentSelections.indexOf(selected);
    if (index < 0) {
      currentSelections.push(selected);
    } else {
      currentSelections.splice(index, 1);
    }
    setCSelected({ ...cSelected, [rSelected]: currentSelections });
  };

  // const [BillingFollows, setBillingFollows] = useState('Hobbs');
  // const [Round2Decimal, setRound2Decimal] = useState('No1');
  // const [TTAF, setTTAF] = useState('0');
  // const [TTAFFollows, setTTAFFollows] = useState('Tach');
  // const [RecordAir, setRecordAir] = useState('No2');
  const [inputFields, setInputFields] = useState([
    {
      dayfield: "Monday",
    },
  ]);
  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        dayfield: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleFileChange = (event) => {
    let a = event.target.files[0]; ////SEND BACKEND
    let b = URL.createObjectURL(a);
    setSelectedFile(event.target.files[0]);
  };

  const getAircraftData = () => {
    const id = params.id;
    let data = {
      aircraftID: id,
    };
    dispatch(AircraftDetailAction(data))
      .then((res) => {
        console.log("res?.data?.aircraftCategory", res?.data?.aircraftCategory);
        console.log("res?.data?.aircraftCategory?._id==========>",res?.data?.aircraftCategory?._id)

        fetchAircraftName(res?.data?.aircraftCategory?._id) ;     

        setAircraftDetail(res?.data);
        // setStartDate(res?.data?.aircraftName)
        // setStartDate2(res?.data)
        setAircraftname(res?.data?.aircraftName?._id);
        setAircraftrate(res?.data?.aircraftRate);
        setPreferredname(res?.data?.preferredName);
        setAircrafttax(res?.data?.aircraftTax);
        setHourlyrate(res?.data?.hourlyRate);
        setEngines(res?.data?.engines);
        setCategory(res?.data?.aircraftCategory?._id);
        setICAOModel(res?.data?.icaoModel);
        setModel(res?.data?.model);
        setMake(res?.data?.make);
        setYear(res?.data?.year);
        setSerialnumber(res?.data?.serialNumber);
        setTailnumber(res?.data?.TailNumber);
        setStatus(res?.data?.aircraftStatus);
        setAircraftType(res?.data?.aircraftType?._id);
        setHobbsTotal(res?.data?.hobbsTotal);
        setTaxPercentage(res?.data?.taxPercentage);
        setTachTotal(res?.data?.tachTotal);
        setAirporttax(res?.data?.airportTax);
        setAirporttax(res?.data?.taxPercentage);
        let daysObj = {};
        let aircraftObj = res?.data?.aircraftTimmings[0];
        Object.keys(aircraftObj).map((key) => {
          if (typeof aircraftObj[key] === "object") {
            if (aircraftObj[key].time_slot) {
              let slots = aircraftObj[key].time_slot.map((i) => i.from);
              daysObj[key] = slots;
            } else {
              daysObj[key] = [];
            }
          }
        });
        setCSelected(daysObj);


      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(id)
  };

  useEffect(() => {
    getAircraftData();
  }, []);

  const editAircraftData = () => {
    const id = params.id;
    const formData = new FormData();
    formData.append("aircraft_Id", id);
    formData.append("aircraftName", aircraftname);
    formData.append("aircraftType", aircraftType);
    formData.append("aircraftRate", aircraftrate);
    formData.append("aircraftTax", aircrafttax);
    formData.append("preferredName", preferredname);
    formData.append("hourlyRate", hourlyrate);
    formData.append("engines", engines);
    formData.append("aircraftCategory", category);
    formData.append("icaoModel", ICAOModel);
    formData.append("model", model);
    formData.append("make", make);
    formData.append("year", year);
    formData.append("serialNumber", serialnumber);
    formData.append("TailNumber", tailnumber);
    formData.append("hobbsTotal", HobbsTotal);
    formData.append("tachTotal", TachTotal);
    formData.append("aircraftStatus", status);
    formData.append("aircraftImage", selectedFile);
    formData.append("airportTax", airporttax);
    formData.append("taxPercentage", taxPercentage);

    for (let day in cSelected) {
      let arr = timeSlot.filter((item) => cSelected[day].includes(item.from));
      if (arr.length > 0)
        formData.append(
          day,
          JSON.stringify({
            weekOFF: weekoff,
            time_slot: arr,
          })
        );
    }

    // console.log(formData)

    dispatch(EditAircraftAction(formData))
      .then((res) => {
        if (res.status == 1) {
          toast.success("Aircraft updated successfully", {
            position: "top-right",
          });
          navigate(-1);
        } else {
          toast.error(res.msg, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
        });
        // console.log(err)
      });
  };

  const fetchCategoryList = () => {
    let data = {
      type: "aircraftList",
    };
    dispatch(AircraftCategoryAction(data))
      .then((res) => {
        console.log("AIRCRAFT CATEGORY========>", res?.data);
        setCategoryList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAircraftName = (el) => {
    let data = {
      categoryId: el,
    };
    dispatch(AircraftNameListAction(data))
      .then((res) => {
        setAircraftNameList(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchAircraftType = () => {
    dispatch(AircraftTypeAction())
      .then((res) => {
        setAircraftListType(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchAircraftType();
    fetchCategoryList();
  }, []);

  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit Aircraft</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Aircrafts">
              <Button className="btn-add">Back</Button>
            </Link>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img
                      className="useravatarimg"
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : aircraftDetail?.aircraftImage
                          ? IMAGE_URL + aircraftDetail?.aircraftImage
                          : profileimg
                      }
                      alt=""
                    />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  >
                    <span className="d-block">Update Picture</span>
                    <input
                      type="file"
                      hidden
                      id="change-img"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Category / Class</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="category"
                  value={category} // Ensure 'category' is set correctly during edit
                  onChange={(e) => {
                    setCategory(e.target.value); // Update the selected category
                    fetchAircraftName(e.target.value); // Fetch aircraft names based on the selected category
                  }}
                >
                  <option value="select">Select Aircraft Category</option>
                  {categoryList?.map((el) => (
                    <option key={el?._id} value={el?._id}>
                      {el?.categoryName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  name="aircraftname"
                  placeholder="Aircraft Name"
                  value={aircraftname}
                  onChange={(e) => setAircraftname(e.target.value)}
                  type="select"
                >
                  <option value="select">Select Aircraft Name</option>
                  {aircraftNameList?.map((el) => {
                    return <option value={el?._id}>{el?.name}</option>;
                  })}
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Tail Number</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={serialnumbericon} alt="" />
                </div>
                <Input
                  name="tailnumber"
                  placeholder="Tail Number"
                  value={tailnumber}
                  onChange={(e) => setTailnumber(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>

            {/* <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Type</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="aircraftType"
                  value={aircraftType}
                  onChange={(e) => setAircraftType(e.target.value)}
                >
                   <option value="select">Select Aircraft Type</option>
                  {aircraftListType?.map((el)=>{
                    console.log("el",el);
                  return <option value={el?._id}>{el?.type}</option>

                  })}
                </Input>
              </FormGroup>
            </Col> */}
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={rateicon} alt="" />
                </div>
                <Input
                  name="aircraftrate"
                  placeholder="Aircraft Rate"
                  value={aircraftrate}
                  onChange={(e) => setAircraftrate(e.target.value)}
                  type="text"
                  pattern="\d*"
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                  }}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Tax</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={taxicon} alt="" />
                </div>
                <Input
                  name="aircrafttax"
                  placeholder="Aircraft Tax"
                  value={aircrafttax}
                  onChange={(e) => setAircrafttax(e.target.value)}
                  type="number"
                  min="0" // Optional: Restrict to non-negative numbers
                  step="0.01"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Preferred Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="preferredname"
                  placeholder="Preferred Name"
                  value={preferredname}
                  onChange={(e) => setPreferredname(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Hourly Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={rateicon} alt="" />
                </div>
                <Input
                  name="hourlyrate"
                  placeholder="Hourly Rate"
                  value={hourlyrate}
                  onChange={(e) => setHourlyrate(e.target.value)}
                  type="number"
                  min="0" // Optional: Restrict to non-negative numbers
                  step="0.01"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Engines</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="engines"
                  value={engines}
                  onChange={(e) => setEngines(e.target.value)}
                >
                  <option value="select">Select Engines</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Input>
              </FormGroup>
            </Col>

            <Col md="12" lg="6">
              <label className="formgroup_label">ICAO Model</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="ICAOModel"
                  placeholder="ICAO Model"
                  value={ICAOModel}
                  onChange={(e) => setICAOModel(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Model</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="model"
                  placeholder="Model"
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Make</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="make"
                  placeholder="Make"
                  value={make}
                  onChange={(e) => setMake(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Year</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <Input
                  name="year"
                  placeholder="Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Serial Number</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={serialnumbericon} alt="" />
                </div>
                <Input
                  name="serialnumber"
                  placeholder="Serial Number"
                  value={serialnumber}
                  onChange={(e) => setSerialnumber(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>

            <Col md="12" lg="6">
              <label className="formgroup_label">Hobbs Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="HobbsTotal"
                  placeholder="Hobbs Total"
                  value={HobbsTotal}
                  onChange={(e) => setHobbsTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Tach Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="TachTotal"
                  placeholder="Tach Total"
                  value={TachTotal}
                  onChange={(e) => setTachTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select">Select Status</option>
                  <option value="Online">Online</option>
                  <option value="Staff Only">Staff Only</option>
                  <option value="Offline">Offline</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Airport Tax</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={taxicon} alt="" />
                </div>
                <Input
                  name="airporttax"
                  placeholder="Airport Tax"
                  value={airporttax}
                  onChange={(e) => setAirporttax(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Tax Percentage </label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="Tax Percentage"
                  placeholder="Tax Percentage"
                  value={taxPercentage}
                  onChange={(e) => setTaxPercentage(e.target.value)}
                  type="number"
                  min="0" // Optional: Restrict to non-negative numbers
                  step="0.01"
                />
              </FormGroup>
            </Col>
            {/* <Col md="12">
              <hr className="section_breakline" />
            </Col> */}
            <Col md="12" className="mt-3">
              {/* <h4 className="headinginner  mb-3">Working Schedule</h4>
              <ButtonGroup className="dayfield">
                <Button
                  onClick={() => setRSelected('mon')}
                  active={rSelected === 'mon'}
                >
                  Mon
                </Button>
                <Button
                  onClick={() => setRSelected('tue')}
                  active={rSelected === 'tue'}
                >
                  Tue
                </Button>
                <Button
                  onClick={() => setRSelected('wed')}
                  active={rSelected === 'wed'}
                >
                  Wed
                </Button>
                <Button
                  onClick={() => setRSelected('thurs')}
                  active={rSelected === 'thurs'}
                >
                  Thu
                </Button>
                <Button
                  onClick={() => setRSelected('fri')}
                  active={rSelected === 'fri'}
                >
                  Fri
                </Button>
                <Button
                  onClick={() => setRSelected('sat')}
                  active={rSelected === 'sat'}
                >
                  Sat
                </Button>
                <Button
                  onClick={() => setRSelected('sun')}
                  active={rSelected === 'sun'}
                >
                  Sun
                </Button>
              </ButtonGroup> */}
              {/* <div className="weekoff_div">
                <FormGroup switch>
                  <Label check>Week Off</Label>
                  <Input
                    type="switch"
                    checked={weekoff}
                    value={weekoff}
                    onClick={() => {
                      setWeekoff(!weekoff);
                    }}
                  />
                </FormGroup>
              </div> */}
              {/* <div className="timeslot_div" > Ensure each item has a unique key */}

              {/* {
                  rSelected && daySlot[rSelected]?.map((item, index) => (
                    <ButtonGroup className="timeslot">
                      <Button
                        style={{ marginRight: '5px' }}
                        onClick={() => onCheckboxBtnClick(item.from)}
                        active={cSelected[rSelected] && cSelected[rSelected].includes(item.from)}
                      >
                        {item.from} - {item.to}
                      </Button>
                    </ButtonGroup>
                  )) */}
              {/* } */}
              {/* </div> */}
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button
              onClick={() => {
                editAircraftData();
              }}
              className="primarybutton  maxwidth-555"
            >
              Save Now
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
  );
};

export default Editaircraft;
