import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Label, Form, FormGroup, Input, Row } from "reactstrap";
import defaultimg from "../../../assets/image/default.jpg";
import calendaricon from "../../../assets/image/profilecalendar-1.svg";
import cameraicon from "../../../assets/image/profilecamera-1.svg";
import emailicon from "../../../assets/image/profileemail-1.svg";
import licenseicon from "../../../assets/image/profilelicense-1.svg";
import phoneicon from "../../../assets/image/profilephone-1.svg";
import usericon from "../../../assets/image/profileuser-1.svg";
import locationicon from "../../../assets/image/profilelocation-1.svg";
import gendericon from "../../../assets/image/profilegender-1.svg";
import statusicon from "../../../assets/image/status-icon.svg";
import passwordicon from "../../../assets/image/password.svg";
import eyehideicon from "../../../assets/image/eye-hide.svg";
import eyeshowicon from "../../../assets/image/eye-show.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { AddUserAction } from "../../../Redux/Actions";
import { addUserValidationSchema } from "../../../validationSchema";
import { useFormik } from "formik";
import PageLoader from "../../customcomponent/loader";
import moment from "moment";

const Adduser = (args) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [error, setError] = useState("");     

  const handleDateChange = (date) => {
    console.log("dateeee",date)
    
    //Convert DOB 
  
     setSelectedDate(date);

  };
  
  const [loading, setLoading] = useState(false);
  var googleKey = process.env.REACT_APP_GOOGLE_PLACE_KEY

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const autocompleteRef = useRef(null);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneno, setPhoneno] = useState();
  const [inputemail, setInputemail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedGender, setSelectedGender] = useState("Select Gender");
  const [selectedLicense, setSelectedLicense] = useState(
    "Select License Level"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [selectedCertiFile2, setSelectedCertifFile2] = useState([]);

  const [selectedGovt, setSelectedGovt] = useState(null);

  const [selectedGovtFile2, setSelectedGovtfFile2] = useState([]);
  const [selectedMedical, setSelectedMedical] = useState(null);
  const [selectedMedicFile2, setSelectedMedicfFile2] = useState([]);
  const phoneNumberAsInteger = parseInt(phoneno, 10);

  const handleFileChange = (event) => {
    let a = event.target.files[0]; ////SEND BACKEND
    let b = URL.createObjectURL(a);
    setSelectedFile(event.target.files[0]);
  };

  const handleCerificate = (event) => {
    const files = event.target.files;
    let imgArr = [];
    let backendImageArr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }

    setSelectedCertificate(imgArr);
    setSelectedCertifFile2(backendImageArr);
  };

  const handleGovtCertificate = (event) => {
    const files = event.target.files;
    let imgArr = [];
    let backendImageArr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }
    setSelectedGovt(imgArr);
    setSelectedGovtfFile2(backendImageArr);
  };

  const handleMedical = (event) => {
    const files = event.target.files;
    let imgArr = [];
    let backendImageArr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }
    setSelectedMedical(imgArr);
    setSelectedMedicfFile2(backendImageArr);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleLicenseChange = (event) => {
    setSelectedLicense(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const navigate = useNavigate();
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("select");
  const today = new Date();

  const dispatch = useDispatch();

  const formatSelectedDate = (date) => {
    return moment(date).format("DD-MM-YYYY"); 
  };


  const initialValues = {
    email: "",
    password: "",
    phoneNumber: "",
    exampleSelect: "",
    fname: "",
    lname: "",
    dateField: "",
    location: "",
    status: "",
    licenceLevel: "",
    gender: "",
    DOB: "",
  };
  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: addUserValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);

      // const formData = new FormData();
      const formData = new FormData();

      selectedCertiFile2.forEach((val) => {
        formData.append("certificate", val);
      });
      selectedGovtFile2.forEach((val) => {
        formData.append("governmentID", val);
      });

      selectedMedicFile2.forEach((val) => {
        formData.append("medicalCertificate", val);
      });

      if(selectedDate==null){
        setLoading(false);
        return toast('Please select date of birth')
      } 

      formData.append("firstName", values.fname);
      formData.append("lastName", values.lname);
      formData.append("email", values.email);
      formData.append("phone_number", values.phoneNumber);
      formData.append("password", values.password);
      formData.append("DOB", formatSelectedDate(selectedDate));
      formData.append("licencseLevel", values.licenceLevel);
      formData.append("userType", "false");
      formData.append("gender", values.gender);
      formData.append("status", values.status);
      formData.append("address", values.location);
      formData.append("profile", selectedFile);

      if (formData != null) {
        dispatch(AddUserAction(formData))
          .then((res) => {
            console.log("res--------------->", res);
            if (res.status === 1) {
              setLoading(false);
              toast.success("User added successfully", {
                position: "top-right",
              });
              navigate(-1);
              setSelectedCertificate([]);
              setSelectedCertifFile2([]);
              setSelectedGovt([]);
              setSelectedGovtfFile2([]);
              setSelectedMedical([]);
              setSelectedMedicfFile2([]);

            } else {
              setLoading(false);

              toast.error(res.msg, {
                position: "top-right",
              });
            
            }
          })
          .catch((err) => {
            setLoading(false);

            console.log("err======>", err);
            toast.error("Please provide all fields", {
              position: "top-right",
            });
          });
      }
    },
  });


  useEffect(() => {
    console.log("GOOGLE",googleKey)
    var input = document.querySelector('.location');
  
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`, () => {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, input);
      
      autocomplete.addListener('place_changed', () => {
        console.log("autocomaplete",autocomplete)
        const place = autocomplete.getPlace();
        console.log("place",place)
        if (place.address_components) {
          handleChange({ target: { name: 'location', value: place.formatted_address } });
        }
      });
    });
  }, []);

  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Add User</h4>
          </Col>
          {error && (
            <p className="text-danger" style={{ textAlign: "right" }}>
              {error != "" ? error : error.msg}
            </p>
          )}

          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Users">
              <Button className="btn-add">Back</Button>
            </Link>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img
                      className="useravatarimg"
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : defaultimg
                      }
                      alt=""
                    />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  >
                    <span className="d-block">Update Picture</span>
                    <input
                      type="file"
                      hidden
                      id="change-img"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">First Name</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    name="fname"
                    placeholder="First Name"
                    // value={firstname}
                    // onChange={(e) => setFirstname(e.target.value)}
                    value={values.fname}
                    onChange={handleChange}
                    className="input-error"
                    type="text"
                  />
                </div>
                <p className="input-error">{errors.fname}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Last Name</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    name="lname"
                    placeholder="Last Name"
                    value={values.lname}
                    onChange={handleChange}
                    className="input-error"
                    type="text"
                  />
                </div>
                <p className="input-error">{errors.lname}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Email Address</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={emailicon} alt="" />
                  </div>
                  <Input
                    name="email"
                    placeholder="Email Address"
                    value={values.email}
                    onChange={handleChange}
                    className="input-error"
                    type="email"
                  />
                </div>
                <p className="input-error">{errors.email}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Phone No.</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={phoneicon} alt="" />
                  </div>
                  <Input
                    name="phoneNumber"
                    placeholder="Phone No"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    className="input-error"
                    type="tel"
                  />
                </div>
                <p className="input-error">{errors.phoneNumber}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Date Of Birth</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={calendaricon} alt="" />
                  </div>
                  <DatePicker
                   id="dateField"
                   selected={selectedDate}
                   onChange={handleDateChange}
                   showMonthDropdown
                   showYearDropdown
                   dropdownMode="select"
                   placeholderText="DD/MM/YYYY"
                   dateFormat="dd-MM-yyyy"
                   popperPlacement="bottom-start"
                  maxDate={today}
                  />
                </div>
                <p className="input-error">{errors.DOB}</p>
              </FormGroup>  
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">License Level</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={licenseicon} alt="" />
                  </div>
                  <Input
                    id="exampleSelect"
                    className="form-control"
                    name="licenceLevel"
                    type="select"
                    value={values.licenceLevel}
                    onChange={handleChange}
                    // className="input-error"
                  >
                    <option value="Select License Level">
                      Select License Level
                    </option>
                    <option value="Student">Student</option>
                    <option value="Private">Private</option>
                    <option value="Instrument">Instrument</option>
                    <option value="Commercial">Commercial</option>
                    <option value="Flight Instructor">Flight Instructor</option>
                  </Input>
                </div>
                <p className="input-error">{errors.licenceLevel}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Gender</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={gendericon} alt="" />
                  </div>
                  <Input
                    id="exampleSelect"
                    className="form-control"
                    name="gender"
                    type="select"
                    // onChange={handleGenderChange}
                    // value={selectedGender}
                    value={values.gender}
                    onChange={handleChange}
                    // className="input-error"
                  >
                    <option value="Select Gender">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Input>
                </div>
                <p className="input-error">{errors.gender}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={statusicon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="status"
                    value={values.status}
                    // onChange={(e) => setStatus(e.target.value)}
                    onChange={handleChange}
                  >
                    <option value="select">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="pending">Pending</option>
                  </Input>
                </div>
                <p className="input-error">{errors.status}</p>
              </FormGroup>
            </Col>

            <Col md="12" lg="12">
              <label className="formgroup_label">Location</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={locationicon} alt="" />
                  </div>
                  <Input
                     className="location"
                     name="location"
                     placeholder="Location"
                     value={values.location}
                     onChange={handleChange}
                     type="text"
                     innerRef={autocompleteRef}
                  />
                </div>
                <p className="input-error">{errors.location}</p>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Password</label>
              <FormGroup className="form-group">
                <div className="position-relative">
                  <div className="iconleft">
                    <img src={passwordicon} alt="" />
                  </div>
                  <Input
                    name="password"
                    placeholder="Password"
                    type={showPassword ? "text" : "password"}
                    // value={inputPassword}
                    // onChange={(e) => setInputPassword(e.target.value)}
                    // onChange={(e) => setPassword(e.target.value)}
                    value={values.password}
                    onChange={handleChange}
                    className="input-error"
                  />
                </div>
                <p className="input-error">{errors.password}</p>

                <div className="iconright" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <img src={eyeshowicon} alt="" />
                  ) : (
                    <img src={eyehideicon} alt="" />
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Upload Certifications</label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg" className="fileupload">
                  <div>
                    {selectedCertificate?.length > 0 ? (
                      selectedCertificate?.map((val) => {
                        return (
                          <img
                            src={val}
                            alt=""
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={cameraicon}
                          alt=""
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <h3>Upload File Here</h3>
                        <p>(image size: Max 2 MB)</p>
                      </>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg"
                  multiple
                  name="uploadimg"
                  style={{ display: "none" }}
                  onChange={handleCerificate}
                  accept="image/jpeg,image/png"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">
                Upload Government Certificates
              </label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg1" className="fileupload">
                  <div>
                    {selectedGovt?.length > 0 ? (
                      selectedGovt?.map((val) => {
                        return (
                          <img
                            src={val}
                            alt=""
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={cameraicon}
                          alt=""
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <h3>Upload Government Here</h3>
                        <p>(image size: Max 2 MB)</p>
                      </>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg1"
                  multiple
                  name="uploadimg1"
                  style={{ display: "none" }}
                  onChange={handleGovtCertificate}
                  accept="image/jpeg,image/png"

                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">
                Upload Medical Certificates
              </label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg2" className="fileupload">
                  <div>
                    {selectedMedical?.length > 0 ? (
                      selectedMedical?.map((val) => {
                        return (
                          <img
                            src={val}
                            alt=""
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={cameraicon}
                          alt=""
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <h3>Upload Medical Certificate Here</h3>
                        <p>(image size: Max 2 MB)</p>
                      </>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg2"
                  multiple
                  name="uploadimg2"
                  style={{ display: "none" }}
                  onChange={handleMedical}
                  accept="image/jpeg,image/png"

                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button
              // onClick={() => {
              //   addUser()
              // }}
              className="primarybutton  maxwidth-555"
            >
              {loading ? <PageLoader /> : "Save Now"}
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
  );
};

export default Adduser;
