import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import avatarimg1 from '../../../assets/image/avatar/1.png'
import zoomdoc1 from "../../../assets/image/zoomdoc.jpg";
import docimg1 from "../../../assets/image/doc1.jpg";
import docimg2 from "../../../assets/image/doc2.jpg";
import calendaricon from "../../../assets/image/profilecalendar-1.svg";
import cameraicon from "../../../assets/image/profilecamera-1.svg";
import emailicon from "../../../assets/image/profileemail-1.svg";
import licenseicon from "../../../assets/image/profilelicense-1.svg";
import phoneicon from "../../../assets/image/profilephone-1.svg";
import usericon from "../../../assets/image/profileuser-1.svg";
import locationicon from "../../../assets/image/profilelocation-1.svg";
import gendericon from "../../../assets/image/profilegender-1.svg";
import statusicon from "../../../assets/image/status-icon.svg";
import passwordicon from '../../../assets/image/password.svg';
import eyehideicon from '../../../assets/image/eye-hide.svg';
import eyeshowicon from '../../../assets/image/eye-show.svg';
import { XCircle, Eye } from 'react-feather'
import { useDispatch } from "react-redux";
import { DeleteCertificate, DeleteGovernmentCertificate, DeleteMedicalCertificate, EditUserAction, UserDetailAction } from "../../../Redux/Actions";
import { IMAGE_URL } from "../../../constant/Endpoints";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import profileimg from "../../../assets/image/default.jpg";
import PageLoader from "../../customcomponent/loader";
import moment from "moment/moment";

const Edituser = (args) => {
  const autocompleteRef = useRef(null);
  var googleKey = process.env.REACT_APP_GOOGLE_PLACE_KEY

  const [selectedDate, setSelectedDate] = useState("");
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const today = new Date();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()
  const [preview, setPreview] = useState([]);
  const [preview1, setPreview1] = useState([]);
  const [preview2, setPreview2] = useState([]);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [inputemail, setInputemail] = useState('');
  const [location, setLocation] = useState('');
  const [status, setStatus] = useState('');
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const params = useParams();
  const [userDetail, setUserDetail] = useState()
  const dispatch = useDispatch()
  const [inputPassword, setInputPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [selectedGender, setSelectedGender] = useState('Select Gender');
  const [selectedLicense, setSelectedLicense] = useState('Select License Level');
  const [selectedFile, setSelectedFile] = useState(null);
const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [selectedCertiFile2, setSelectedCertifFile2] = useState([]);

  const [selectedGovt, setSelectedGovt] = useState([]);

  const [selectedGovtFile2, setSelectedGovtfFile2] = useState([]);
  const [selectedMedical, setSelectedMedical] = useState([]);
  const [selectedMedicFile2, setSelectedMedicfFile2] = useState([]);
  const phoneNumberAsInteger = parseInt(phoneno, 10);
  let id = params.id;

  const handleFileChange = (event) => {
    let a = event.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a)
    setSelectedFile(event.target.files[0]);
  };

  const handleCerificate = (event) => {
    const files = event.target.files;
  
    // Create an array of newly selected certificates
    const newSelectedCertificates = Array.from(files);
  
    // Update the state to include the newly selected certificates
    setSelectedCertificate((prevSelectedCertificates) => [
      ...prevSelectedCertificates,
      ...newSelectedCertificates
    ]);
  
    // Create an array of preview URLs for the newly selected certificates
    const newPreviews = Array.from(files).map((file) => URL.createObjectURL(file));
    console.log("newprevie",newPreviews)
    // Update the state to include the new previews
    setPreview((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };
  

  const handleRemoveCertificate = async (item,index) => {
    console.log("ITEM",item)

    const updatedPreviews = [...preview];
    updatedPreviews.splice(index, 1);
    setPreview(updatedPreviews);

    const updatedSelectedCertificates = [...selectedCertificate];
    updatedSelectedCertificates.splice(index, 1);
    if( typeof item != "object"){
        return
    }
    setSelectedCertificate(updatedSelectedCertificates);
   await dispatch(DeleteCertificate({certificateID: item.id,userId:id})).then((res) => {
      console.log("res",res)
      if (res.status === 1) {
        
        // toast.success("Certificate removed successfully.", {
        //   position: 'top-right',
        // }); 

       }else{

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }
    }
    )

  };

  const handleRemoveGovtCertificate = async(item,index) => {
    const updatedPreviews = [...preview1];
    updatedPreviews.splice(index, 1);
    setPreview1(updatedPreviews);

    const updatedSelectedCertificates = [...selectedCertificate];
    updatedSelectedCertificates.splice(index, 1);
    setSelectedGovt(updatedSelectedCertificates);

    if( typeof item != "object"){
      return
  }
    await dispatch(DeleteGovernmentCertificate({govtID: item.id,userId:id})).then((res) => {
      console.log("res",res)
      if (res.status === 1) {
        // toast.success("Certificate removed successfully.", {
        //   position: 'top-right',
        // }); 

       }else{

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }
    }
    )

  };

  const handleRemoveMedicalCertificate = async (item,index) => {
    const updatedPreviews = [...preview2];
    updatedPreviews.splice(index, 1);
    setPreview2(updatedPreviews);

    const updatedSelectedCertificates = [...selectedCertificate];
    updatedSelectedCertificates.splice(index, 1);
    setSelectedMedical(updatedSelectedCertificates);
    if( typeof item != "object"){
      return
  }
    await dispatch(DeleteMedicalCertificate({medicalID: item.id,userId:id})).then((res) => {
      console.log("res",res)
      if (res.status === 1) {
        // toast.success("Certificate removed successfully.", {
        //   position: 'top-right',
        // }); 
       }else{

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }
    }
    )

  };

  const handleGovtCertificate = (event) => {
    const files = event.target.files;
  
    // Create an array of newly selected certificates
    const newSelectedCertificates = Array.from(files);
  
    // Update the state to include the newly selected certificates
    setSelectedGovt((prevSelectedCertificates) => [
      ...prevSelectedCertificates,
      ...newSelectedCertificates
    ]);
  
    // Create an array of preview URLs for the newly selected certificates
    const newPreviews = Array.from(files).map((file) => URL.createObjectURL(file));
  
    setPreview1((prevPreviews) => [...prevPreviews, ...newPreviews]);


  };

  const handleMedical = (event) => {
    const files = event.target.files;
  
    // Create an array of newly selected certificates
    const newSelectedCertificates = Array.from(files);
  
    // Update the state to include the newly selected certificates
    setSelectedMedical((prevSelectedCertificates) => [
      ...prevSelectedCertificates,
      ...newSelectedCertificates
    ]);
  
    // Create an array of preview URLs for the newly selected certificates
    const newPreviews = Array.from(files).map((file) => URL.createObjectURL(file));
  
    setPreview2((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleGenderChange = (event) => {

    setSelectedGender(event.target.value);
  }

  const handleLicenseChange = (event) => {
    setSelectedLicense(event.target.value);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getUserData = () => {
  
    let data = {
      instructorID: id
    }
    dispatch(UserDetailAction(data)).then((res) => {



      console.log("response========>",res);
      setUserDetail(res)

      const date = moment.utc(res?.data?.DOB, 'DD-MM-YYYY');
         const eventDate = date.local();

      console.log("date",date, eventDate, eventDate.toDate());
      setSelectedDate(eventDate.toDate())
      setFirstname(res?.data?.firstName)
      setLastname(res?.data?.lastName)
      setInputemail(res?.data?.email)
      setSelectedLicense(res?.data?.licencseLevel)
      setSelectedGender(res?.data?.gender)
      setStatus(res?.data?.account_status)
      setLocation(res?.data?.address)
      setPhoneno(parseInt(res?.data?.phone_number, 10))
      // setPreview(res?.data?.certificate_path)
      let certificate_arr=[];
      let government_arr=[];
      let medical_arr=[];

      res?.data?.certificate_path.map((el)=>{
        let imageUrl= IMAGE_URL+el?.path
        let obj ={}
        obj.id= el?.id
        obj.imageUrl=imageUrl
        certificate_arr.push(obj);
      })
      setPreview(certificate_arr);
      res?.data?.govt_id_path.map((el)=>{
        let imageUrl= IMAGE_URL+el?.path
        let obj ={}
        obj.id= el?.id
        obj.imageUrl=imageUrl
        government_arr.push(obj);
      })
      setPreview1(government_arr)
      res?.data?.medical_certificate_path.map((el)=>{
        let imageUrl= IMAGE_URL+el?.path
        let obj ={}
        obj.id= el?.id
        obj.imageUrl=imageUrl
        medical_arr.push(obj);
      })
      setPreview2(medical_arr)
      // setSelectedFile(res?.data?.profilePic == null ? profileimg : res?.data?.profilePic)
      // setInputPassword(res?.data?.password)

    }).catch((err) => { console.log(err) })
    console.log(id)
  }
 

  const formatSelectedDate = (date) => {
    return moment(date).format("DD-MM-YYYY"); 
  };

  const EditUser = () => {
    // const formData = new FormData();
    const id = params.id;

    const formData = new FormData()
    formData.append("firstName", firstname)
    formData.append("lastName", lastname)
    formData.append("email", inputemail)
    formData.append("phone_number", phoneno)
    formData.append("password", inputPassword)
    formData.append("DOB", formatSelectedDate(selectedDate))
        formData.append("licencseLevel", selectedLicense)
    formData.append("userType", "false")
    formData.append("gender", selectedGender)
    formData.append("account_status", status)
    formData.append("address", location)
    formData.append('profile', selectedFile);
    selectedCertificate?.map((el)=>{
      formData.append('certificate', el);

    })
    
    selectedGovt?.map((el)=>{
      formData.append('governmentID', el);

    })

    selectedMedical?.map((el)=>{
      formData.append('medicalCertificate', el);

    })
    formData.append('user_Id', id);
    setLoading(true)
    dispatch(EditUserAction(formData)).then((res) => {
      if (res.status == 1) {
        setLoading(false);

        toast.success("User profile updated successfully", {
          position: 'top-right',
        });      
        navigate(-1)

      }else{
        setLoading(false);

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }     
    }).catch((err) => {
      setLoading(false);

      toast.error(err, {
        position: 'top-right',
      });  
            console.log(err)
    })
  }

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };


  useEffect(() => {
    getUserData()

    var input = document.querySelector('.location');

    loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`, () => {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, input);
      
      autocomplete.addListener('place_changed', () => {
        console.log("autocomaplete",autocomplete)
        const place = autocomplete.getPlace();
        console.log("place",place)
        if (place.address_components) {
          handleLocationChange({ target: { name: 'location', value: place.formatted_address } });
        }
      });
    });

  }, [])


  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit User</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Users"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                  <img
                        className="useravatarimg"
                        src={
                          selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : userDetail?.data?.profilePic ?
                             `${IMAGE_URL}${userDetail?.data?.profilePic}`
                            : profileimg
                        }
                        alt=""
                      />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  >
                    <span className="d-block">Update Picture</span>
                    <input type="file" hidden id="change-img" accept="image/*" onChange={handleFileChange} />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">First Name</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="fname"
                  placeholder={"First Name"}
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  type="text"
                />
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Last Name</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="lname"
                  placeholder={"Last Name"}
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  type="text"
                />
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Email Address</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={emailicon} alt="" />
                </div>
                <Input
                  name="email"
                  placeholder={"email"}
                  value={inputemail}
                  onChange={(e) => setInputemail(e.target.value)}
                  type="email"
                />
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Phone No.</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={phoneicon} alt="" />
                </div>
                <Input
                  name="phoneno"
                  placeholder={"Phone NO"}
                  value={phoneno}
                  onChange={(e) => setPhoneno(e.target.value)}
                  type="tel"
                />
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Date Of Birth</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText={"Select Date"}
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  maxDate={today}
                />
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">License Level</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={licenseicon} alt="" />
                </div>
                <Input
                  id="exampleSelect"
                  className="form-control"
                  name="select"
                  type="select"
                  onChange={handleLicenseChange}
                  value={selectedLicense}
                >
                  <option disabled>
                    Select License Level
                  </option>
                  <option value="Student">Student</option>
                  <option value="Private">Private</option>
                  <option value="Instrument">Instrument</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Flight Instructor">Flight Instructor</option>

                </Input>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Gender</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={gendericon} alt="" />
                </div>
                <Input
                  id="exampleSelect"
                  className="form-control"
                  name="select"
                  type="select"
                  onChange={handleGenderChange}
                  value={selectedGender}
                >
                  <option>
                    Select Gender
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>

                </Input>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {/* <option>Select Status</option> */}
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="pending">Pending</option>

                </Input>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Location</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={locationicon} alt="" />
                </div>
                <Input
                  name="location"
                  placeholder={"Location"}
                  value={location}
                  onChange={handleLocationChange}
                  type="text"
                  innerRef={autocompleteRef}

                />
                </div>
              </FormGroup>
            </Col>
            {/* <Col md="12" lg="6">
              <label className="formgroup_label">Password</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={passwordicon} alt="" />
                </div>
                <Input
                  name="password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                // onChange={(e) => setPassword(e.target.value)}
                />
                <div className="iconright" onClick={togglePasswordVisibility}>
                  {showPassword ? <img src={eyeshowicon} alt="" /> : <img src={eyehideicon} alt="" />}
                </div>
              </FormGroup>
            </Col> */}

            <Col md="12" lg="12">
              <label className="formgroup_label">Upload Certifications</label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg" className='fileupload'>
                  <div>
                    <img src={cameraicon} alt="" />
                    <h3>Upload Certifications</h3>
                    <p>(image size: Max 2 MB)</p>
                  </div>
                </label>
                <input type="file" id="uploadimg"  multiple   accept="image/jpeg,image/png" name="uploadimg" style={{ display: 'none' }} onChange={handleCerificate} />
                <div className="uploadeddocs">
                  {/* <div className="uplaoddoc_img">
                    <XCircle className="uplaoddocclose_icon" />
                    {/* <img src={selectedCertificate ? URL.createObjectURL(selectedCertificate) : cameraicon} alt="" /> */}
                    {/* <span className="docview_btn" onClick={toggle2}><Eye /></span>
                  </div> */} 

{preview?.map((item,index)=>{
  console.log("itemmmm",item)
  
  return  ( <div className="uplaoddoc_img" key ={index}>

<Button onClick={() => handleRemoveCertificate(item ,index)}>
              <XCircle className="uplaoddocclose_icon" />
            </Button>

          
                      <img src={typeof item === "object" ? item.imageUrl : item}  alt="" />
                     
                      {/* <span className="docview_btn" onClick={toggle2}><Eye /></span> */}
                    </div>)
})}

                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Upload Government Certificates</label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg1" className='fileupload'>
                  <div>
                    {/* <img src={selectedGovt ? URL.createObjectURL(selectedGovt) : cameraicon} alt="" /> */}
                    <h3>Upload Government Certificate</h3>
                    <p>(image size: Max 2 MB)</p>
                  </div>
                </label>
                <input type="file" id="uploadimg1"  multiple   accept="image/jpeg,image/png" name="uploadimg1" style={{ display: 'none' }} onChange={handleGovtCertificate} />
                <div className="uploadeddocs">
                  {/* <div className="uplaoddoc_img">
                    <XCircle className="uplaoddocclose_icon" />
                    <img src={docimg2} alt="" />
                    <span className="docview_btn" onClick={toggle2}><Eye /></span>
                  </div> */}
                {preview1?.map((item,index)=>{
  return  ( <div className="uplaoddoc_img" key ={index}>

<Button onClick={() => handleRemoveGovtCertificate(item,index)}>
              <XCircle className="uplaoddocclose_icon" />
            </Button>

  
            <img src={typeof item === "object" ? item.imageUrl : item}  alt="" />
                     
                      {/* <span className="docview_btn" onClick={toggle2}><Eye /></span> */}
                    </div>)
})}

                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Upload Medical Certificates</label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg2" className='fileupload'>
                  <div>
                    {/* <img src={selectedMedical ? URL.createObjectURL(selectedMedical) : cameraicon} alt="" /> */}
                    <h3>Upload Medical Certificate</h3>
                    <p>(image size: Max 2 MB)</p>
                  </div>
                </label>
                <input type="file" id="uploadimg2" multiple   accept="image/jpeg,image/png" name="uploadimg2" style={{ display: 'none' }} onChange={handleMedical} />
                <div className="uploadeddocs">

                {preview2?.map((item,index)=>{
  return  ( <div className="uplaoddoc_img" key ={index}>

<Button onClick={() => handleRemoveMedicalCertificate(item,index)}>
              <XCircle className="uplaoddocclose_icon" />
            </Button>

  
            <img src={typeof item === "object" ? item.imageUrl : item}  alt="" />
                     
                      {/* <span className="docview_btn" onClick={toggle2}><Eye /></span> */}
                    </div>)
})}
</div>
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button
              onClick={() => {
                EditUser()
              }}
              className="primarybutton  maxwidth-555">
                          {loading ? <PageLoader /> : "Update"}

            </Button>
          </div>
        </Form>
      </div>
      <Modal centered className="modalimg_zoom"
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <XCircle className="modalzoom_close_icon" onClick={toggle2} />
        <ModalBody>

          <img src={zoomdoc1} alt="" />
        </ModalBody>
      </Modal>
      <ToastContainer />

    </>
  );
};

export default Edituser;
