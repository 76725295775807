import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Button,
  Toast,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import usericon from "../../../assets/image/profileuser-1.svg";
import emailicon from "../../../assets/image/profileemail-1.svg";
import phoneicon from "../../../assets/image/profilephone-1.svg";
import profileimg from "../../../assets/image/default.jpg";
import { useDispatch } from "react-redux";
import {IMAGE_URL} from "../../../constant/Endpoints"
import { AdminListAction, AdminUpdateProfileAction } from "../../../Redux/Actions";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../customcomponent/loader";



const ProfileSetting = (args) => {

  const [profile, setProfile] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    phoneno: '',
    inputemail: '',
    profilePhoto: null
    // other profile data...
  });

  const [ profilePic,setProfilePic] = useState();
  const [ userPic,setUserProfile] = useState(null);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState('');

  const handleUpdateProfilePhoto = (newProfilePhoto) => {
    console.log("newProfilePhoto",newProfilePhoto)
    setProfile({ ...profile, profilePhoto: newProfilePhoto });
  };

  const handlePhoto = (e) => {
    console.log("-> Handle photo",e)
    let a = e.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a) ////FOR SHOW
    console.log("-------------",b)
    setProfile({ ...profile, profilePhoto: b});
    setProfilePic(a);
    setUserProfile(b);
  };

  const [adminItem, setAdminItem] = useState([])
  const dispatch = useDispatch()

  const getAdminList = () => {
    dispatch(AdminListAction()).then(res => {
      console.log(res)
      setAdminItem(res?.data)
      // setUserProfile(IMAGE_URL+(res?.data?.profilePic)):setUserProfile(null)
      setProfile({
        ...profile, username: res?.data?.userName,
        inputemail: res?.data?.email,
        firstname: res?.data?.firstName,
        lastname: res?.data?.lastName,
        phoneno: res?.data?.phone_number,
        // profilePhoto: res?.data?.profilePic,
      });

    }).catch(err => {
      console.log(err)
    })
  }

  const onSubmitPassword = async (e) => {
    setLoading(true)

    const formData = new FormData()

    e.preventDefault()
    const { firstname, lastname ,username} = profile;
    let data = {
      firstName: firstname,
      lastName: lastname,
      userName:username,
      profile:profilePic
    }
    formData.append('firstName', data.firstName)
    formData.append('lastName', data.lastName)
    formData.append('userName', data.userName)
    formData.append('profile', data.profile)

    console.log("formData",formData)
    
    dispatch(AdminUpdateProfileAction(formData)).then(res => {
      if (res.status == 1) {
        setLoading(false)

        // console.log(res.msg);
        window.location.reload()
        toast.success("Profile updated successfully", {
          position: 'top-right',
        });  
    } else {
      setLoading(false)

    // toast(res.msg);
    setError(res.msg)
    console.log("res.msg",res.msg)
    }
    }).catch(err => setError("Something went wrong"))
  
     
  };




  useEffect(() => {
    getAdminList()
  }, [])





  return (
    <>
      <div className="instructorpanel">
        <h2 class="headingmain  mb-3">Profile Setting</h2>
        {error && <p className="text-danger"  style={{ textAlign:"right"}}>{error != "" ? error : error.msg}</p>}
       
          <Row>
            <Col md="12" lg="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    {/* {profile.profilePhoto != null? */}
                    
                    <img className="useravatarimg" src={profilePic ? URL.createObjectURL(profilePic) :adminItem?.profilePic != null ? IMAGE_URL + adminItem?.profilePic : profileimg} alt="PROFILE"
                    style={{ objectFit: 'revert' }}
                  /> 
                  {/* :
                  <img className="useravatarimg" src={userPic} alt="PROFILE"
                  style={{ objectFit: 'revert' }}
                />  */}
                    {/* } */}
                    
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                    onClick={handleUpdateProfilePhoto}
                  >
                    <span className="d-block">Update Picture</span>
                    <input type="file" hidden id="change-img" accept="image/*"
                      onChange={handlePhoto}


                    />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">User Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="username"
                  placeholder="User Name"
                  defaultValue={profile.username}
                  value={profile.username}
                  onChange={(e) => setProfile({ ...profile, username: e.target.value })}
                  // onChange={(e) => setUsername(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">First Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="fname"
                  placeholder="First Name"
                  value={profile.firstname}
                  // onChange={(e) => setFirstname(e.target.value)}
                  onChange={(e) => setProfile({ ...profile, firstname: e.target.value })}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Last Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="lname"
                  placeholder="Last Name"
                  value={profile.lastname}
                  onChange={(e) => setProfile({ ...profile, lastname: e.target.value })}
                  // onChange={(e) => setLastname(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Email Address</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={emailicon} alt="" />
                </div>
                <Input
                  name="email"
                  placeholder="Email Address"
                  value={profile.inputemail}
                  readOnly
                  onChange={(e) => setProfile({ ...profile, inputemail: e.target.value })}
                  // onChange={(e) => setInputemail(e.target.value)}
                  type="email"

                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Phone No.</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={phoneicon} alt="" />
                </div>
                <Input
                  name="phoneno"
                  placeholder="Phone No"
                  value={profile.phoneno}
                  readOnly  
                  // onChange={(e) => setProfile({ ...profile, phoneno: e.target.value })}
                  // onChange={(e) => setPhoneno(e.target.value)}
                  type="tel"
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button className="primarybutton  maxwidth-555"  onClick={(e) => onSubmitPassword(e)}>
            {loading ? <PageLoader/> : " Update Now"}
            </Button>
          </div>
       
      </div>
      <ToastContainer />
    </>
  );
};

export default ProfileSetting;
