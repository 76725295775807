import React from "react"
import SearchIcon from '../../assets/image/search.svg';
import {
  Row,
  Col,
  Input,
} from "reactstrap"


const FilterComponent = ({ filterText, onFilter }) => {
  return (
    <>
      <Row>
        <Col xl="12" md="12" className="text-end mb-1">
          <div className="filtersearch_div ms-auto" style={{ maxWidth: '300px' }}>
            <Input
              id="search"
              type="text"
              placeholder="Search..."
              value={filterText}
              onChange={onFilter}
              style={{ height: '42px' }}
            />
            <img className="searchiconbtn" src={SearchIcon} alt="" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FilterComponent;
