import React, { useState, useMemo, useEffect } from "react";
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
} from "reactstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, XCircle } from "react-feather";
import { Link } from "react-router-dom";
import Loader from "../customcomponent/pageLoader";
import {
  AircraftCategoryAction,
  CategoryDeleteAction,
  CategoryEditAction,
} from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import PageLoader from "../customcomponent/loader";

const Table = (props) => {
  const columns = [
    {
      name: "Name",
      minWidth: "300px",
      selector: "name",
      sortable: true,
      cell: (row) => row?.categoryName,
    },
    {
      name: "Status",
      minWidth: "300px",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <span className={row?.catgeoryStatus + " badge"}>
          {row?.catgeoryStatus}
        </span>
      ),
    },
    {
      name: "Actions",
      minWidth: "180px",
      center: "center",
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/ViewCategory/" + row?._id}>
              <Button className="tablebtnview">
                <Eye className="tableicon" />
              </Button>
            </Link>
            <Link>
              <Button
                className="tablebtnedit"
                onClick={() => handleEditClick(row?._id, row?.categoryName,row?.catgeoryStatus)}
              >
                <Edit className="tableicon" />
              </Button>
            </Link>
            {/* <Button
              className="tablebtndelete"
              onClick={() => {
                handleDeleteClick(row?._id);
              }}
            >
              <Trash className="tableicon" />
            </Button> */}
          </>
        </div>
      ),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editcategory, setEditcategory] = useState("");
  const [id, setcategoryId] = useState("");
  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [aircraftCategory, setAircraftCategory] = useState([]);
  const [status, setStatus] = useState("");

  const dispatch = useDispatch();

  const toggle2 = () => setModal2(!modal2);
  const modaltoggle = () => setModal(!modal);

  const filteredItems = props?.data?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const fetchCategoryList = () => {
    setLoading(true);
    let data = {
      search: filterText,
      type:"List"

    };
    dispatch(AircraftCategoryAction(data))
      .then((res) => {
        setAircraftCategory(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const editName = () => {
    const data = {
      categoryId: id,
      name: editcategory,
      categoryStatus: status
    };

    dispatch(CategoryEditAction(data))
      .then((res) => {
        if (res.status === 1) {
          toast.success("Category updated successfully", {
            position: "top-right",
          });
          modaltoggle();
          fetchCategoryList(); // Reload the list
        } else {
          toast.error(res.msg, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
        });
      });
  };

  const deleteCategory = () => {
    const data = {
      categoryId: id,
    };

    dispatch(CategoryDeleteAction(data))
      .then((res) => {
        if (res.status === 1) {
          toast.success("Category deleted successfully", {
            position: "top-right",
          });
          toggle2();
          fetchCategoryList(); // Reload the list
        } else {
          toast.error(res.msg, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        toast.error(err, {
          position: "top-right",
        });
      });
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");}
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText,resetPaginationToggle]);


  const handleEditClick = (categoryId, categoryName, categoryStatus) => {
    setcategoryId(categoryId);
    setEditcategory(categoryName);
    setStatus(categoryStatus);
    modaltoggle();
  };

  const handleDeleteClick = (categoryId) => {
    setcategoryId(categoryId);
    toggle2();
  };

  useEffect(() => {
    fetchCategoryList(filterText);
  }, [filterText]);

  return (
    <React.Fragment>
      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={aircraftCategory}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader />}
        progressPending={loading}
        subHeader
        subHeaderComponent={subHeaderComponent}

      />
      <Modal centered isOpen={modal} toggle={modaltoggle}>
        <ModalBody className="p-4">
          <div className="Emailmodal">
            <h2 className="email_head pb-3 text-center">Edit Category</h2>
            <form>
              <Row>
                <Col sm="12">
                  <label className="formgroup_label">Category</label>
                  <FormGroup className="form-group">
                    <Input
                      name="editcategory"
                      placeholder="Enter category name"
                      className="px-4" 
                      value={editcategory}
                      defaultValue={editcategory}
                      onChange={(e) => setEditcategory(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <label className="formgroup_label">Category Status</label>
                  <FormGroup className="form-group">
                
                   <select
        name="status"
        className="px-4 form-control"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="Active">Active</option>
        <option value="Inactive">Inactive</option>
                   </select>
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-cancel-ok pt-3 text-center">
                <Button className="btn-cancel me-1" onClick={modaltoggle}>
                  Cancel
                </Button>
                <Button className="btn-save" onClick={editName}>
                  Update
                </Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <Modal centered isOpen={modal2} toggle={toggle2}>
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure?</h2>
            <p>
              You want to delete this record. You will not be able to recover
              it.
            </p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>
                Cancel
              </Button>
              <Button className="btn-ok" onClick={deleteCategory}>
                Proceed
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Table;
