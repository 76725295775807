import React, { useState, useEffect} from 'react';
import { NavLink } from 'react-router-dom';
import userimg from '../../../assets/image/user1.png';
import closetogglebar from '../../../assets/image/closetogglebar.svg';
import opentogglebar from '../../../assets/image/togglebar.svg';
import usericon from '../../../assets/image/navusericon.svg';
import dashboardicon from '../../../assets/image/navdashoard_icon.svg';
import aircrafticon from '../../../assets/image/navaircrafticon.svg';
import transactionicon from '../../../assets/image/navtransactionicon.svg';
import pageicon from '../../../assets/image/navpageicon.svg';
import settingicon from '../../../assets/image/Setting-1.svg';
import calendaricon1 from '../../../assets/image/calendar.svg';
import administratoricon from '../../../assets/image/Navadministrator_icon.svg';
import navannouncementicon from '../../../assets/image/navannouncement_icon.svg';
import { ChevronRight } from 'react-feather';


const Sidebar = () => {
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const toggleDropdown = (dropdownId) => {
    if (openDropdowns === dropdownId) {
      setOpenDropdowns(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdowns(dropdownId); // Open the clicked dropdown
    }
  };

  const closeAllDropdowns = () => {
    setOpenDropdowns([]);
  };

  const handleOutsideClick = (event) => {
    if (openDropdowns !== null && !event.target.closest('.dropdown')) {
      closeAllDropdowns();
    }    
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [openDropdowns]);


  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  React.useEffect(() => {
    document.body.classList.toggle("no-scroll", sidebarOpen);
  }, [sidebarOpen]);
  const handleNavLinkClick = () => {
    setSidebarOpen(false);
    closeAllDropdowns();
    if (!sidebarOpen) {
      toggleSidebar();
      
    }
  };
  const dropdownData = [
    {
      id: 'Dashboard',
      title: 'Dashboard',
      sidebaricon: dashboardicon,
      link: '/Dashboard',
      isDropdown: false
    },
    {
      id: 'Administrator',
      title: 'Administrators',
      sidebaricon: administratoricon,
      link: '/Administrator',
      isDropdown: false
    },
    {
      id: 'Users',
      title: 'Users',
      sidebaricon: usericon,
      isDropdown: true,
      links: [
        {
          id: 'Users1',
          label: 'User',
          link:'/Users'
        },
        {
          id: 'Instructor',
          label: 'Instructor',
          link:'/Instructor'
        },
      ],
    },
    {
      id: 'Aircrafts',
      title: 'Aircrafts',
      sidebaricon: aircrafticon,
      isDropdown: false,
      link: '/Aircrafts',
    },
    {
      id: 'Category',
      title: 'Category',
      sidebaricon: aircrafticon,
      isDropdown: false,
      link: '/AircraftCategory',
    },
    {
      id: 'Reservations',
      title: 'Reservations',
      sidebaricon: calendaricon1,
      isDropdown: false,
      link: '/Reservations',
    },
    {
      id: 'Transactions',
      title: 'Transactions',
      sidebaricon: transactionicon,
      isDropdown: false,
      link: '/Transactions',
    },
    {
      id: 'Announcement',
      title: 'Announcements',
      sidebaricon: navannouncementicon,
      isDropdown: false,
      link: '/Announcement',
    },
    {
      id: 'Community',
      title: 'Community',
      sidebaricon: usericon,
      isDropdown: false,
      link: '/Post',
    },
    {
      id: 'GlobalSetting',
      title: 'Global Settings',
      sidebaricon: settingicon,
      isDropdown: false,
      link: '/GlobalSetting',
    },
    
    {
      id: 'Events',
      title: 'Events',
      sidebaricon: calendaricon1,
      isDropdown: false,
      link: '/Events',
    },
    {
      id: 'Pages',
      title: 'Content Management System',
      sidebaricon: pageicon,
      isDropdown: false,
      link: '/Allpages',
    },
  ];
  return (
    <>
            <button onClick={toggleSidebar} className="btnopen" style={{ display: 'none' }}>
        <img src={opentogglebar} alt="" />
      </button>
      <div className={sidebarOpen ? "sidebar sidebar-open" : "sidebar sidebar-closed"}>
      <button className="btnclose mb-3" onClick={toggleSidebar} style={{ display: 'none' }}>
          <img src={closetogglebar} alt="" />
        </button>
        {/* <div className="userinfo mb-3">
          <div className='userimg'>
            <img src={userimg} alt="" />
          </div>
          <h3>John</h3>
          <p>john22doe@gmail.com</p>
        </div> */}
        <ul>
          {dropdownData.map((dropdown) => (
            <li
              key={dropdown.id}
              className={`dropdown ${openDropdowns === dropdown.id ? 'open' : ''}`}
            >
              {dropdown.isDropdown ? (
                <>
                  <button
                    className="dropdown-toggle"
                    onClick={() => toggleDropdown(dropdown.id)}
                  ><img src={dropdown.sidebaricon} alt="" />
                    {dropdown.title}<ChevronRight />
                  </button>
                  {openDropdowns === dropdown.id && (
                    <ul className="dropdown-links dropdown-menu">
                      {dropdown.links.map((link) => (
                        <li key={link.id}>
                          <NavLink
                            to={link.link}
                            onClick={toggleSidebar}
                            className="dropdown-link"
                          >
                            {link.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              ) : (
                <NavLink
                  to={dropdown.link}
                  onClick={() => handleNavLinkClick(dropdown.id)}
                ><img src={dropdown.sidebaricon} alt="" />
                  {dropdown.title}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};


export default Sidebar;