import React from "react";
import { TailSpin } from "react-loader-spinner";

function Loader() {
  return (
    <div className="d-flex align-items-center justify-content-center">
      <TailSpin
        color="#000000"
        height={60}
        width={80}
        visible={true}
        ariaLabel="tail-spin-loading"
      />
    </div>
  
  );
}

export default Loader;
