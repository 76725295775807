import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Eye, XCircle } from 'react-feather'
import { AnnouncementListAction, DeleteAnnouncedAction, ReadAnnouncedAction, UnReadAnnouncedAction } from "../../Redux/Actions/AnnouncementAction";
import { useDispatch } from "react-redux";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
const Table = props => {
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Date",
      minWidth: "120px",
      selector: "date",
      sortable: true,
      cell: (row) => row?.announcementDate
    },
    {
      name: "From",
      minWidth: "130px",
      selector: "from",
      sortable: true,
      cell: (row) => row?.from
    },
    {
      name: "Subject",
      minWidth: "240px",
      selector: "subject",
      sortable: true,
      cell: (row) => row?.subject
    },
    {
      name: "Actions",
      minWidth: "80px",
      center: 'center',
      cell: (row) => (
        <div className="tableaction">
          <>
            <Button className="tablebtnview" onClick={() => detailannouncementtoggle(row)}><Eye className="tableicon" /></Button>
          </>
        </div>
      )
    }
  ]

  const [filterText, setFilterText] = React.useState("");
  const [stausFilter, setStausFilter] = React.useState("Inbox");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };






    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        stausFilter={stausFilter}
        onStatus={e => setStausFilter(e.target.value)}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const [deletemodal, setDeletemodal] = useState(false);
  const [modelList, setModelList] = useState([])
  const [detailannouncementmodal, setDetailannouncementmodal] = useState(false);
  const detailannouncementtoggle = (item) => {
    setDetailannouncementmodal(!detailannouncementmodal)
    setModelList(item)
  }
  const [closeAll, setCloseAll] = useState(false);
  const [announceList, setAnnounceList] = useState()
  const dispatch = useDispatch()
  const deletetoggle = () => {
    setDeletemodal(!deletemodal);
    setCloseAll(false);
  };
  const toggleAll = () => {
    setDeletemodal(!deletemodal);
    setCloseAll(true);
  };

  const adminAnnounceList = () => {
    setLoading(true);

    let data = {
      search: filterText,
      filter: stausFilter
    }
    dispatch(AnnouncementListAction(data)).then(res => {
      setLoading(false);

      setAnnounceList(res?.data)
      console.log(res)
    }).catch(err => {
      setLoading(false);

      console.log(err)
    })
  }

  useEffect(() => {
    adminAnnounceList(filterText)
  }, [filterText, stausFilter])


  const deleteAdminAnnounceList = (id) => {

    let data = {
      announcementID: id
    }
    dispatch(DeleteAnnouncedAction(data)).then(res => {
      setDetailannouncementmodal(!detailannouncementmodal)
      adminAnnounceList(filterText)
      // setAnnounceList(res?.data)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }


  const markUnReadAnnounceList = (id) => {
    let data = {
      announcementID: id
    }
    dispatch(UnReadAnnouncedAction(data)).then(res => {
      setDetailannouncementmodal(!detailannouncementmodal)
      adminAnnounceList(filterText)
      // setAnnounceList(res?.data)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }


  return  (

    <React.Fragment>

      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={announceList}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader/>}
        progressPending={loading} 
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered
        className="modal_md"
        isOpen={detailannouncementmodal}
        toggle={() => setDetailannouncementmodal(!detailannouncementmodal)}
      >
        <ModalBody className="announcedtl_modal p-4">

          <Row className="mb-3">
            <Col sm="4" md="3">
              <h5 className="announce_label">Name:</h5>
            </Col>
            <Col sm="8" md="9">
              <h5 className="announce_desc">{modelList?.from}</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm="4" md="3">
              <h5 className="announce_label">Subject:</h5>
            </Col>
            <Col sm="8" md="9">
              <h5 className="announce_desc">{modelList?.subject}</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm="4" md="3">
              <h5 className="announce_label">Date/Time:</h5>
            </Col>
            <Col sm="8" md="9">
              <h5 className="announce_desc">{modelList?.announcementDate}</h5>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col sm="4" md="3">
              <h5 className="announce_label">Body:</h5>
            </Col>
            <Col sm="8" md="9">
            <h5 className="announce_desc" dangerouslySetInnerHTML={{ __html: modelList?.body }} />
            </Col>
          </Row>
          <div className="btn-cancel-ok pt-3 text-center">
            <Button className="btn-cancel me-1" onClick={() => { deleteAdminAnnounceList(modelList?._id) }}>Delete</Button>
            <Button className="btn-save" onClick={() => { markUnReadAnnounceList(modelList?._id) }}>Mark Unread</Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal centered
        isOpen={deletemodal}
        toggle={() => setDeletemodal(!deletemodal)}
        onClosed={closeAll ? detailannouncementtoggle : undefined}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure  ?</h2>
            <p>You want to delete this record. You will not be able to recover</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={deletetoggle}>Cancel</Button>
              <Button className="btn-ok" onClick={toggleAll}>Proceed</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Table;
