import React, { useState } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../assets/image/logo.png';
import emailicon from '../../assets/image/email.svg';
import passwordicon from '../../assets/image/password.svg';
import eyehideicon from '../../assets/image/eye-hide.svg';
import eyeshowicon from '../../assets/image/eye-show.svg';
import { useDispatch } from 'react-redux';
import { LoginAction } from '../../../src/Redux/Actions/onBoardingAction'
import Singleton from '../../Singleton';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PageLoader from '../customcomponent/loader';
import Loader from '../customcomponent/pageLoader';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const [formData, setFormData] = useState({
        inputValue1: '',
        password: '',
    });

    const [error, setError] = useState('');

  
    

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleLogin = (e) => {
        e.preventDefault()
        // In a real application, you would perform authentication here.
        // For demonstration purposes, we're using hardcoded credentials.
        setLoading(true);

        const { inputValue1, password } = formData;
        let data = {
            email: inputValue1,
            password: password
        }
        if (Object.values(data).every(field => field.length > 0)) {
            setError('');
        dispatch(LoginAction(data))
            .then((res) => {
                console.log(res, "RR")
                if (res.status) {
                    setLoading(false);

                    toast.success("Logged in successfull", {
                        position: 'top-right',
                      });   

                    Singleton.getInstance().saveData('token', res.data.userToken)
                    window.location.reload()
                   
                    // alert(res.msg);
                } else {
                    setLoading(false);

                    setError(res.msg);
                }
            }).catch(err => setError("Something went wrong"))
         }else{ 
            setLoading(false);

            for (const key in data) {
            if (data[key]?.length === 0) {
                setError('Please enter both email and password');
            }
          }
        }
    };


    return (
        <>
            <div>
                <Container fluid>
                    <Row>
                        <Col md="6" className="p-0">
                            <div className="loginlogo_div">
                                <img class="loginlogoimg" src={logo} alt="" />
                            </div>
                        </Col>
                        <Col className="loginfield_bg" md="6">
                            <div className="loginmain_div">
                                <h3 className="login_head">Login to your account</h3>

                                <Form>
                                    <FormGroup className="form-group">

                                        <div className="iconleft emailicon">
                                            <img src={emailicon} alt="" />
                                        </div>
                                        <Input
                                            name="inputValue1"
                                            placeholder="Email"
                                            value={formData.inputValue1} onChange={handleInputChange}
                                            type="email"
                                            invalid={error.loginEmail && true}
                                        />
                                    </FormGroup>
                                    <FormGroup className="form-group">
                                        <div className="iconleft">
                                            <img src={passwordicon} alt="" />
                                        </div>
                                        <Input
                                            name="password"
                                            placeholder="Password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formData.password}
                                            onChange={handleInputChange}
                                        // onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <div className="iconright" onClick={togglePasswordVisibility}>
                                            {showPassword ? <img src={eyeshowicon} alt="" /> : <img src={eyehideicon} alt="" />}

                                        </div>
                                    </FormGroup>
                                    {error && <div className="text-danger">{error}</div>}

                                    <FormGroup className="form-group">
                                        <div className="forgotpassword_link">
                                            <Link to="/Forgotpassword">Forgot Password?</Link>
                                        </div>
                                    </FormGroup> 
                                    <div className="login_btn">
                                        <Link onClick={handleLogin}>
                                            <Button className="whitebutton mt_50">
                                            {loading ? <PageLoader /> : "Login"}
                                            </Button>

                                        </Link>
                                    </div>
                                </Form>
                                {/* <div className='SignUpWrap'>
                                    <label className='signUpText'>
                                        Don’t have an account? <Link
                                            onClick={() => navigate('Signup')}
                                            style={{ color: 'white' }} ><b><u>Sign Up</u></b></Link>
                                    </label>

                                </div> */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <ToastContainer />
        </>
    );
}

export default Login;
