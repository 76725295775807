// import Singleton from '../../../src/Singleton';
import { FORGOT_PASSWORD, LOG_IN_SUCCESS, RESET_PASSWORD, VERIFY_OTP } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_FORGOT_PASSWORD, ADMIN_LOGIN_IN, ADMIN_RESET_PASSWORD, ADMIN_VERIFY_OTP } from '../../constant/Endpoints';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const LogInSuccess = (data) => {
    console.log('UpdateProps---', data);
    return {
        type: LOG_IN_SUCCESS,
        payload: data
    };
};

const ForgotSuccess = (data) => {
    console.log('UpdateProps---', data);
    return {
        type: FORGOT_PASSWORD,
        payload: data
    };
};


const VerificationSuccess = (data) => {
    console.log('UpdateProps---', data);
    return {
        type: VERIFY_OTP,
        payload: data
    };
};

const ResetSuccess = (data) => {
    console.log('UpdateProps---', data);
    return {
        type: RESET_PASSWORD,
        payload: data
    };
};



export const LoginAction = (data) => {
    return async (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_LOGIN_IN, data, '')
                .then(response => {
                    LogInSuccess({ data: response })
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const ForgotAction = (data) => {
    return async (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_FORGOT_PASSWORD, data, '')
                .then(response => {
                    ForgotSuccess({ data: response })
                    console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}



export const VerifyOtpAction = (data) => {
    return async (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_VERIFY_OTP, data, '')
                .then(response => {
                    VerificationSuccess({ data: response })
                    console.log('Verify --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('Verify error--->>>', error);
                    reject('error');
                })
        })
    }
}

export const ResetPasswordAction = (data) => {
    return async (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_RESET_PASSWORD, data, '')
                .then(response => {
                    ResetSuccess({ data: response })
                    console.log('Verify --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('Verify error--->>>', error);
                    reject('error');
                })
        })
    }
}



// APIClient.getInstance().post(SIGN_UP, data).then(res => dispatch(SignInSuccess({ data: res }))).catch(err => console.log(err))


