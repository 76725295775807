const uploadWithProgress = (uploadUrl, file, onProgress) => {
  return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      // Set up the request
      xhr.open("PUT", uploadUrl);

      // Set the request headers
      xhr.setRequestHeader("Content-Type", file?.type);

      // Track the progress of the upload
      xhr.upload.onprogress = (event) => {
          if (event.lengthComputable && onProgress) {
              const percentComplete = (event.loaded / event.total) * 100;
              onProgress({
                  loaded: event.loaded,
                  total: event.total,
                  percent: percentComplete.toFixed(2),
              });
          }
      };

      // Handle the response
      xhr.onload = () => {
          if (xhr.status >= 200 && xhr.status < 300) {
              try {
                  const responseHeaders = xhr.getAllResponseHeaders();
                  console.log("responseHeaders",responseHeaders)

                  const responseBody = xhr.responseText;
                  resolve({ headers: responseHeaders, body: responseBody });
              } catch (error) {
                  reject(new Error('Failed to parse response'));
              }
          } else {
              reject(new Error(`Failed to upload file: ${xhr.statusText}`));
          }
      };

      // Handle errors
      xhr.onerror = () => reject(new Error('An error occurred during the upload'));

      // Send the file
      xhr.send(file);
  });
};

export default uploadWithProgress;
