import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import Img1 from '../../../assets/image/img1.webp'
import { useDispatch } from "react-redux";
import { AdminFetchEventDetail } from "../../../Redux/Actions/ManagementAction";
import moment from 'moment';
import profileimg from "../../../assets/image/default.jpg";
import { IMAGE_URL } from "../../../constant/Endpoints";
import DetailUserList from "./viewInvitation";


const Viewevent = () => {
  const param = useParams()
  const dispatch = useDispatch()
  const [eventDetail, setEventDetail] = useState()
  const [users, setUserList] = useState([])


  
  const fetchEventDetail = () => {
    let data = {
      eventId: param.id
    }
    dispatch(AdminFetchEventDetail(data)).then(res => {
      console.log(res?.data?.interestedUsers?.dataList)
      
      setEventDetail(res?.data?.eventDetail)
      setUserList(res?.data?.interestedUsers?.dataList)
      console.log("users",users)
    }).catch(err => {
      console.log(err)
    })
  }

  
  useEffect(() => {
    fetchEventDetail()
  }, [])


  return (
    <>
     <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">View Event</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Events"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <div className="d-flex mb-3 userview_div">
          <div className="flex-shrink-0 schooldetailimg_div">
            <img className="schooldetailimg" src={eventDetail?.eventImage != null ? IMAGE_URL + eventDetail?.eventImage : profileimg } alt="" />
          </div>
          <div className="flex-grow-1">
            <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Title:</span>{eventDetail?.title}</h5>
            <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Place:</span> {eventDetail?.place}</h5>
            <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Date:</span> {moment.utc(eventDetail?.eventDate).format('YYYY-MM-DD')}</h5>
            {/* <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Start Time:</span> 11:00 am</h5> */}
            <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Event Time:</span>{eventDetail?.eventTime}</h5>
            <h5 className="fw-normal d-flex flex-column"> <span style={{fontWeight: '600' }}>Description:</span> {eventDetail?.description} </h5>
            
          </div>
        </div>
        <hr className="section_breakline" />
        <DetailUserList user={users} />
      </div>

    </>
  );
};

export default Viewevent;
