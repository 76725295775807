import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, Key, XCircle } from 'react-feather'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { AdminDeleteAction, AdminGetAction } from "../../Redux/Actions";
import { IMAGE_URL } from "../../constant/Endpoints";
import profileimg from "../../assets/image/default.jpg";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
const Table = props => {
  const [loading, setLoading] = useState(false);

  const [modal2, setModal2] = useState(false);
  const [id, setid] = useState("");

  const toggle2 = () => {
    setModal2(!modal2);
  }

 
  const columns = [
    {
      name: "Name",
      minWidth: "220px",
      selector: "name",
      sortable: true,
      cell: (row) =>
        <>
          <img className="table_img rounded-circle img-thumbnail me-1" src={row?.profilePic != null ? IMAGE_URL + row?.profilePic :profileimg } alt="" />
          {row?.firstName} {row?.lastName}
        </>
    },
    {
      name: "Phone No.",
      minWidth: "80px",
      selector: "phone",
      sortable: true,
      cell: (row) => row?.phone_number
    },
    {
      name: "Actions",
      minWidth: "230px",
      center: 'center',
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/Viewadministrator/" + row?._id}><Button className="tablebtnview"><Eye className="tableicon" /></Button></Link>
            <Link to={"/Editadministrator/" + row?._id}><Button className="tablebtnedit"><Edit className="tableicon" /></Button></Link>
            <Link to={"/Permissions/" + row?._id}><Button className="tablebtnpermision"><Key className="tableicon" /></Button></Link>
            <Button className="tablebtndelete" onClick={()=>{
              setModal2(!modal2)
              setid(row?._id)}}  
           ><Trash className="tableicon" /></Button>
          </>
        </div>
      )
    }
  ]

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const dispatch = useDispatch()
  const [adminItem, setAdminItem] = useState([])


  const getAdminList = () => {
    setLoading(true)
    let data = {
      search: filterText
    }
    dispatch(AdminGetAction(data)).then(res => {
      if(res.status === 1){
        setLoading(false);

      setAdminItem(res?.data)
      }
    }).catch(err => {
      setLoading(false)

      // console.log(err)
    })
  }

  useEffect(() => {
    getAdminList(filterText)
  }, [filterText])

  const deleteAdminList = (id) => {
    let data = {
      adminID: id
    }

    dispatch(AdminDeleteAction(data)).then(res => {
      if (res.status === 1) {

      getAdminList();
      setModal2(!modal2);

      toast.success("Admin deleted successfully", {
        position: 'top-right',
      });          
    }else{
      toast.error(res.msg, {
        position: 'top-right',
      });  
      setModal2(!modal2);

    }     
      // setAdminItem(res?.data)
    }).catch(err => {
      // console.log(err)
    })
  }





  return (
    <React.Fragment>

      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={adminItem}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader/>}
        progressPending={loading} 
        subHeader
        subHeaderComponent=
        {subHeaderComponent}
      />
      <Modal centered
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure  ?</h2>
            <p>You want to delete this record. You will not be able to recover</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>Cancel</Button>
              <Button className="btn-ok" onClick={()=>deleteAdminList(id)}>Proceed</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />

    </React.Fragment>

  );
};

export default Table;
