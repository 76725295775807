

import * as Yup from 'yup';


export const changepasswordValidationSchema = Yup.object().shape({
    password1: Yup.string().required('Current password is required'),
    password2: Yup.string().min(6).required('New password is required'),
    password3: Yup.string()
      .oneOf([Yup.ref('password2'), null], 'Passwords must match')
      .required('Confirm new password is required'),
});

export const addCommentValidationSchema = Yup.object().shape({
  text: Yup.string().required('You can not submit empty text'),
});

export const addCategoryValidationSchema = Yup.object().shape({
  addcategory: Yup.string().required('Category name is required'),
 
});


export const addAircraftNameValidationSchema = Yup.object().shape({
  addName: Yup.string().required('Airctaft name is required'),
 
});


export const addAdministratorValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
  phoneno: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  username: Yup.string()
    .required('Username is required'),
    firstname:Yup.string().required("First name is required")
});


export const addUserValidationSchema = Yup.object().shape({
  fname:Yup.string()
  .required('First name is required'),
  lname:Yup.string()
  .required('Last name is required'),
  gender:Yup.string()
  .required('Gender is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
    location: Yup.string()
    .required('Location is required'),
    status: Yup.string()
    .required('User status is required'),
    phoneNumber: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
    licenceLevel: Yup.string()
    .required('Licence is required'),
    // DOB: Yup.string()
    // .required('Date of birth is required'),
});


export const addPostValidationSchema = Yup.object().shape({
  text2:Yup.string()
  .required('Description is required'),
});


export const addEventValidationSchema = Yup.object().shape({
  title:Yup.string()
  .required('Title is required'),
  description:Yup.string()
  .required('Description is required'),
  location:Yup.string()
  .required('Place is required'),
  // dispatchTime:Yup.string()
  // .required('Date is required'),
  // selectedDate:Yup.string()
  // .required('Time is required')
});


export const addInstructorValidationSchema = Yup.object().shape({
  fname:Yup.string()
  .required('First name is required'),
  lname:Yup.string()
  .required('Last name is required'),
  gender:Yup.string()
  .required('Gender is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid email address'),
    location: Yup.string()
    .required('Location is required'),
    status: Yup.string()
    .required('User status is required'),
    phoneno: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
    licenceLevel: Yup.string()
    .required('Licence is required'),
    instructorRate: Yup.string()
    .required('Instructor rate is required'),
    flightRate: Yup.string()
    .required('Flight rate is required'),
    flyingexperience: Yup.string()
    .required('Flying experience is required'),
    craftCategory: Yup.string()
    .required('Craft category is required'),
    groundRate: Yup.string()
    .required('Ground rate is required'),
});


export const addAircraftValidationSchema= Yup.object().shape({
  
  aircraftname:Yup.string()
  .required('Aircraft name is required'),
  aircraftType:Yup.string()
  .required('Aircraft type is required'),
  aircraftrate:Yup.string()
  .required('Aircraft rate is required'),
  aircrafttax: Yup.string()
    .required('Aircraft tax is required'),
    preferredname: Yup.string()
    .required('Preferred name is required'),
    hourlyrate: Yup.string()
    .required('Hourly rate is required'),
    engines: Yup.string()
    .required('Engines is required'),
    category: Yup.string()
    .required('Category is required'),
    ICAOModel: Yup.string()
    .required('ICAO model is required'),
    model: Yup.string()
    .required('Model is required'),
    make: Yup.string()
    .required('Make is required'),
    serialnumber: Yup.string()
    .required('Serial number is required'),
    tailnumber: Yup.string()
    .required('Tail number is required'),
    HobbsTotal: Yup.string()
    .required('Hobbs total is required'),
    TachTotal: Yup.string()
    .required('Tach total is required'),
    status: Yup.string()
    .required('Status is required'),
    airporttax: Yup.string()
    .required('Airport tax is required'),
    taxPercentage: Yup.string()
    .required('Tax percentage is required'),
    year: Yup.string()
    .required('Year is required'),
})