import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Eye, Trash, XCircle } from 'react-feather'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { DeleteTransactionAction, TransactionListAction } from "../../Redux/Actions";
import moment from "moment";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
const Table = props => {
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Transaction ID",
      minWidth: "160px",
      selector: "transactionID",
      sortable: true,
      cell: (row) => row.userId
    },
    {
      name: "Reservation ID",
      minWidth: "138px",
      selector: "reservationID",
      sortable: true,
      cell: (row) => row.bookingID
    },
    {
      name: "User",
      minWidth: "130px",
      selector: "username",
      sortable: true,
      cell: (row) => `${row.userFirstName} ${row.userLastName}`
    },
    {
      name: "Date",
      minWidth: "120px",
      selector: "date",
      sortable: true,
      cell: (row) => moment(row.transactionTime).format("DD/MM/YYYY")
    },
    {
      name: "Status",
      minWidth: "80px",
      selector: "status",
      sortable: true,
      cell: (row) => <span className={row.transactionStatus + ' badge'}  >{row.transactionStatus}</span>
    },
    {
      name: "Actions",
      minWidth: "130px",
      center: 'center',
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/Viewtransactions/" + row._id}><Button className="tablebtnview"><Eye className="tableicon" /></Button></Link>
            <Button className="tablebtndelete" onClick={() => { DeleteTransaction(row._id) }}><Trash className="tableicon" /></Button>
          </>
        </div>
      )
    }
  ]

  const [stausFilter, setStausFilter] = React.useState("");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        stausFilter={stausFilter}
        onStatus={e => setStausFilter(e.target.value)}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const [transactionList, setTransactionList] = useState()
  const dispatch = useDispatch()

  const getTransactionData = () => {
    setLoading(true)

    let data = {
      search: filterText,
      filter: stausFilter

    }
    dispatch(TransactionListAction(data)).then((res) => {
      setLoading(false)

      setTransactionList(res?.data)
      console.log(res.data)
    }).catch((err) => { 
      setLoading(false)
      
      console.log(err) })
    // console.log(id)
  }
  const DeleteTransaction = (id) => {
    let data = {
      transactionID: id
    }
    // console.log(usersList)
    dispatch(DeleteTransactionAction(data)).then(res => {
      // setUsersList(res.data)
      getTransactionData()
    }).catch(err => console.log(err))

  };



  useEffect(() => {
    getTransactionData(filterText)
  }, [filterText, stausFilter])


  return (
    <React.Fragment>
      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={transactionList}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader/>}
        progressPending={loading} 
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure  ?</h2>
            <p>You want to delete this record. You will not be able to recover</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>Cancel</Button>
              <Button className="btn-ok" onClick={toggle2}>Proceed</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Table;
