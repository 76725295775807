import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import defaultimg from '../../../assets/image/default.jpg'
import clockicon from "../../../assets/image/clock_icon.svg";
import locationicon from "../../../assets/image/location.svg";
import calendaricon from "../../../assets/image/calendar1.svg";
import titleicon from "../../../assets/image/eventitle.svg";
import { useFormik } from "formik";
import { addEventValidationSchema } from "../../../validationSchema";
import moment from "moment";
import { AdminAddEventDetail } from "../../../Redux/Actions/ManagementAction";
import { useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../customcomponent/loader";

const Addevent = (args) => {
  const autocompleteRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [dispatchTime, setDispatchTime] = useState(null);
  const [dispatchTime2, setDispatchTime2] = useState(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const today = new Date();
 var googleKey = process.env.REACT_APP_GOOGLE_PLACE_KEY
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const handleTimeChange = (time) => {
    setDispatchTime(time);
  };

  const handleTimeChange2 = (time) => {
    setDispatchTime2(time);
  };

  const handleFileChange = (event) => {
    let a = event.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a)
    setSelectedFile(event.target.files[0]);  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const navigate = useNavigate()

  const formatTimeForBackend = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return moment(date).format('h:mm A'); 
    } else {
      console.error("Invalid Date object");
      return null;
    }
  };

  const dispatch = useDispatch();

  const formatSelectedDate = (date) => {
    return moment(date).format("YYYY-MM-DD"); 
  };

  const initialValues = {
    title: '',
    location: '',
    description: '',
    // dispatchTime:'',
    // selectedDate:''
  }

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: addEventValidationSchema,

    onSubmit: async (values) => {

      console.log("errors",errors)

      console.log("valuessssssssss",values)
      const formattedTime = formatTimeForBackend(dispatchTime);
        setLoading(true)

    const formData = new FormData()
    
    formData.append("eventDate", formatSelectedDate(selectedDate))
    formData.append("eventTime", formattedTime)
    formData.append("title", values.title)
    formData.append("place", values.location)
    formData.append("description", values.description)
    formData.append("eventFile", selectedFile)
    if(formData != null){

    dispatch(AdminAddEventDetail(formData)).then((res) => {

      if (res.status === 1) {
        setLoading(false)

        toast.success("Event added successfully", {
          position: 'top-right',
        });          
        navigate(-1)
      }else{
        setLoading(false)

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }
    }).catch((err) => {
      setLoading(false)

      console.log("err======>",err)
      toast.error("Please provide all fields", {
        position: 'top-right',
      });  
   
  })
}
}

});

useEffect(() => {
  console.log("GOOGLE",googleKey)
  var input = document.querySelector('.location');

  loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`, () => {
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, input);
    
    autocomplete.addListener('place_changed', () => {
      console.log("autocomaplete",autocomplete)
      const place = autocomplete.getPlace();
      console.log("place",place)
      if (place.address_components) {
        handleChange({ target: { name: 'location', value: place.formatted_address } });
      }
    });
  });
}, []);

  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Add Event</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Events"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img className="useravatarimg" src={selectedFile ? URL.createObjectURL(selectedFile) : defaultimg} alt="" />
                  </div>
                  <Button
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                    id="change-img"
                  >
                    <span className="d-block">Update Picture</span>
                    <input type="file" hidden accept="image/*" onChange={handleFileChange} />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <Label for="title" className="formgroup_label">Title</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={titleicon} alt="" />
                </div>
                <Input
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  value={values.title}
                  onChange={handleChange}
                  type="text"
                />
              </FormGroup>
                  <p className="input-error">{errors.title}</p>
            </Col>
            <Col md="12" lg="6">
              <Label for="place" className="formgroup_label">Place</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={locationicon} alt="" />
                </div>
                <Input
                 className="location"
                 name="location"
                 placeholder="Enter place"
                 value={values.location}
                 onChange={handleChange}
                 type="text"
                 innerRef={autocompleteRef}
                />
              </FormGroup>
                  <p className="input-error">{errors.location}</p> 
            </Col>
            <Col md="12" lg="12">
              <Label for="date" className="formgroup_label">Date</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Select date"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  minDate={today} 
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <Label for="time" className="formgroup_label">Event Time</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={dispatchTime}
                  onChange={handleTimeChange}
                  placeholderText="Enter start time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                /></FormGroup>
            </Col>
            {/* <Col md="12" lg="6">
              <Label for="time" className="formgroup_label">End Time</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={dispatchTime2}
                  onChange={handleTimeChange2}
                  placeholderText="Enter end time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                /></FormGroup>
            </Col> */}
            <Col md="12" lg="12">
              <Label for="description" className="formgroup_label">Description</Label>
              <FormGroup className="form-group">
                <Input type="textarea" name="description"  rows="5" placeholder="Enter description"   value={values.description}
                  onChange={handleChange}/>
              </FormGroup>
                   <p className="input-error">{errors.description}</p>
            </Col>
          </Row>
          <div className="text-center mt-2">
            <Button  
            className="primarybutton maxwidth-555">
               {loading ? <PageLoader /> : " Save Now"}  
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />

    </>
  );
};

export default Addevent;
