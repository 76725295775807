import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { X, Plus } from 'react-feather'
import defaultimg from '../../../../assets/image/default.jpg'
import FlyingIcon from "../../../../assets/image/flying.svg";
import statusicon from "../../../../assets/image/status-icon.svg";
import rateicon from "../../../../assets/image/rate_icon.svg";
import taxicon from "../../../../assets/image/tax_icon.svg";
import usericon from "../../../../assets/image/profileuser-1.svg";
import calendaricon from "../../../../assets/image/profilecalendar-1.svg";
import serialnumbericon from "../../../../assets/image/serialnumber_icon.svg";
import { useDispatch } from "react-redux";
import { AddAircraftAction, AircraftCategoryAction, AircraftNameListAction, AircraftTypeAction } from "../../../../Redux/Actions/AircraftAction";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { addAircraftValidationSchema } from "../../../../validationSchema";
import PageLoader from "../../../customcomponent/loader";


let timeSlot =
  [
    { from: "12:00 AM", to: "2:30 AM" },
    { from: "2:30 AM", to: "5:00 AM" },
    { from: "5:00 AM", to: "7:30 AM" },
    { from: "7:30 AM", to: "10:00 AM" },
    { from: "10:00 AM", to: "12:30 PM" },
    { from: "12:30 PM", to: "3:00 PM" },
    { from: "3:00 PM", to: "5:30 PM" },
    { from: "5:30 PM", to: "8:00 PM" },
    { from: "8:00 PM", to: "10:30 PM" },
  ]

let daySlot = {
  mon: timeSlot,
  tue: timeSlot,
  wed: timeSlot,
  thurs: timeSlot,
  fri: timeSlot,
  sat: timeSlot,
  sun: timeSlot
}



const Addaircraft = (args) => {
  const [cSelected, setCSelected] = useState({
    mon: [],
    tue: [],
    wed: [],
    thurs: [],
    fri: [],
    sat: [],
    sun: []
  });
  const [rSelected, setRSelected] = useState('');
  const [weekoff, setWeekoff] = useState(true);
  const onCheckboxBtnClick = (selected) => {
    const index = cSelected[rSelected].indexOf(selected);
    if (index < 0) {
      cSelected[rSelected].push(selected);
    } else {
      cSelected[rSelected].splice(index, 1);
    }
    setCSelected({ ...cSelected, [rSelected]: cSelected[rSelected] });
  };

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const [aircraftname, setAircraftname] = useState('');
  const [aircraftrate, setAircraftrate] = useState('');
  const [preferredname, setPreferredname] = useState('');
  const [aircrafttax, setAircrafttax] = useState('');
  const [airporttax, setAirporttax] = useState('');
  const [hourlyrate, setHourlyrate] = useState('');
  const [engines, setEngines] = useState('select')
  const [category, setCategory] = useState('select')
  const [ICAOModel, setICAOModel] = useState('');
  const [taxPercentage, setTaxPercentage] = useState('');
  const [model, setModel] = useState('');
  const [make, setMake] = useState('');
  const [year, setYear] = useState('');
  const [serialnumber, setSerialnumber] = useState('');
  const [tailnumber, setTailnumber] = useState('');
  const [status, setStatus] = useState('select');
  const [aircraftListType, setAircraftType] = useState([]);
  const [aircraftNameListType, setAircraftNameList] = useState([]);

  const [aircraftCategory, setAircraftCategory] = useState([]);

  const [HobbsTotal, setHobbsTotal] = useState('');
  const [TachTotal, setTachTotal] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [BillingFollows, setBillingFollows] = useState('select');
  const [Round2Decimal, setRound2Decimal] = useState('select');
  const [TTAF, setTTAF] = useState('');
  const [TTAFFollows, setTTAFFollows] = useState('select');
  
  const [RecordAir, setRecordAir] = useState('select');
  const [inputFields, setInputFields] = useState([{
    dayfield: '',
  }]);
  const addInputField = () => {
    setInputFields([...inputFields, {
      dayfield: '',
    }])
  }
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  }
  const handleChangee = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  }

  const handleFileChange = (event) => {
    let a = event.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a)
    setSelectedFile(event.target.files[0]);
  };


  const initialValues = {
    aircraftname: '',
    aircraftType: '',
    aircraftrate: '',
    aircrafttax: '',
    preferredname:'',
    hourlyrate:'',
    engines:'',
    category:'',
    ICAOModel:'',
    model:'',
    make:'',
    serialnumber:'',
    tailnumber:'',
    HobbsTotal:'',
    TachTotal:'',
    status:'',
    airporttax:'',
    taxPercentage:'',
    year:''
  }

  const { values, errors,handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: addAircraftValidationSchema,
    onSubmit: async (values) => {   
      setLoading(true)

    const formData = new FormData()
    formData.append('aircraftName', values.aircraftname)
    formData.append('aircraftType', values.aircraftType)
    formData.append('aircraftRate', values.aircraftrate)
    formData.append('aircraftTax', values.aircrafttax)
    formData.append('preferredName', values.preferredname)
    formData.append('hourlyRate', values.hourlyrate)
    formData.append('engines', values.engines)
    formData.append('aircraftCategory', values.category)
    formData.append('icaoModel', values.ICAOModel)
    formData.append('model', values.model)
    formData.append('make', values.make)
    formData.append('year', values.year)
    formData.append('serialNumber', values.serialnumber)
    formData.append('TailNumber', values.tailnumber)
    formData.append('hobbsTotal', values.HobbsTotal)
    formData.append('tachTotal', values.TachTotal)
    formData.append('aircraftStatus', values.status)
    formData.append('aircraftImage', selectedFile)
    formData.append('airportTax', values.airporttax)
    formData.append('taxPercentage', values.taxPercentage)

    for (let day in cSelected) {
      let arr = timeSlot.filter(item => cSelected[day].includes(item.from))
      if (arr.length > 0)
        formData.append(day, JSON.stringify({
          weekOFF: weekoff,
          time_slot: arr
        }))
    }
    // formData.append('aircraftImage',)

    dispatch(AddAircraftAction(formData)).then(res => {
      // navigate(-1)
      if (res.status == 1) {
        setLoading(false)

        toast.success("Aircraft added successfully", {
          position: 'top-right',
        });       
            navigate(-1)
      }else{
        setLoading(false)

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }     
    }).catch((err) => {
      setLoading(false)

      toast.error(err, {
        position: 'top-right',
      });  

  })
}
});

//Aircraft Type 
const fetchAircraftType = ()=>{
  dispatch(AircraftTypeAction()).then((res) => {
    setAircraftType(res?.data)
   
  }).catch((err) => { console.log(err) })
}


const fetchAircraftName = (el)=>{
  let data = {
    categoryId: el
  }
  dispatch(AircraftNameListAction(data)).then((res) => {
    setAircraftNameList(res?.data)
   
  }).catch((err) => { console.log(err) })
}

//Aircraft Type 
const fetchCategoryList  = ()=>{
  let data ={
    type:"aircraftList"
  }
  dispatch(AircraftCategoryAction(data)).then((res) => {
    console.log("AIRCRAFT CATEGORY========>",res?.data)
    setAircraftCategory(res?.data)
   
  }).catch((err) => { console.log(err) })
}

useEffect(() => {
  fetchAircraftType();
  fetchCategoryList();
}, [])


  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Add Aircraft</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Aircrafts"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img className="useravatarimg" src={selectedFile ? URL.createObjectURL(selectedFile) : defaultimg} alt="" />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  >
                    <span className="d-block">Update Picture</span>
                    <input type="file" hidden id="change-img" accept="image/*" onChange={handleFileChange} />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Category / Class</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="category"
                  value={values.category}
                  // onChange={handleChange}
                  onChange={(e) => {
                    handleChange(e); // Update the form value
                    fetchAircraftName(e.target.value); // Fetch aircraft names based on the selected category
                  }}
                >
           <option value="select">Select Aircraft Category</option>
                  {aircraftCategory?.map((el)=>{
                  return <option value={el?._id}>{el?.categoryName}</option>
                  })}
                </Input>
              </FormGroup>
              <p className="input-error">{errors.category}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  type="select"
                  name="aircraftname"
                  className="form-control"
                  value={values.aircraftname}
                  onChange={handleChange}
                  >
                 <option value="select">Select Aircraft Name</option>
                  {aircraftNameListType?.map((el)=>{
                  return <option value={el?._id}>{el?.name}</option>
                  })}
                </Input>

              </FormGroup>
              <p className="input-error">{errors.aircraftname}</p>

            </Col>
          
              {/*          
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Type</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="aircraftType"
                  value={values.aircraftType}
                  // onChange={(e) => setAircraftType(e.target.value)}
                  onChange={handleChange}
                >
                   
                  <option value="select">Select Aircraft Type</option>
                  {aircraftListType?.map((el)=>{
                    console.log("el",el);
                  return <option value={el?._id}>{el?.type}</option>

                  })}
                
                </Input>
              </FormGroup>
              <p className="input-error">{errors.aircraftType}</p>

            </Col> */}
            <Col md="12" lg="6">
              <label className="formgroup_label">Tail Number</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={serialnumbericon} alt="" />
                </div>
                <Input
                  name="tailnumber"
                  placeholder="Tail Number"
                  value={values.tailnumber}
                  onChange={handleChange}

                  // onChange={(e) => setTailnumber(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.tailnumber}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={rateicon} alt="" />
                </div>
                <Input
                    name="aircraftrate"
                    placeholder="Aircraft Rate"
                    value={values.aircraftrate}
                    onChange={handleChange}
                    type="text"
                    pattern="\d*"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/\D/g, '');
                }}
                  />
              </FormGroup>
              <p className="input-error">{errors.aircraftrate}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Aircraft Tax</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={taxicon} alt="" />
                </div>
              <Input
  name="aircrafttax"
  placeholder="Aircraft Tax"
  value={values.aircrafttax}
  onChange={handleChange}
  type="number"
  min="0"   // Optional: Restrict to non-negative numbers
  step="0.01" // Allows decimal numbers with up to two decimal places
/>
              </FormGroup>
              <p className="input-error">{errors.aircrafttax}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Preferred Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="preferredname"
                  placeholder="Preferred Name"
                  value={values.preferredname}
                  onChange={handleChange}

                  // onChange={(e) => setPreferredname(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.preferredname}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Hourly Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={rateicon} alt="" />
                </div>
                <Input
                  name="hourlyrate"
                  placeholder="Hourly Rate"
                  value={values.hourlyrate}
                  onChange={handleChange}
                  // onChange={(e) => setHourlyrate(e.target.value)}
                  type="number"
                  min="0"   // Optional: Restrict to non-negative numbers
                  step="0.01"
                />
              </FormGroup>
              <p className="input-error">{errors.hourlyrate}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Engines</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="engines"
                  value={values.engines}
                  onChange={handleChange}

                  // onChange={(e) => setEngines(e.target.value)}
                >
                  <option value="select">Select Engines</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </Input>
              </FormGroup>
              <p className="input-error">{errors.engines}</p>

            </Col>
          
            <Col md="12" lg="6">
              <label className="formgroup_label">ICAO Model</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="ICAOModel"
                  placeholder="ICAO Model"
                  value={values.ICAOModel}
                  onChange={handleChange}
                  // onChange={(e) => setICAOModel(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.ICAOModel}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Model</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="model"
                  placeholder="Model"
                  value={values.model}
                  onChange={handleChange}
                  // onChange={(e) => setModel(e.target.value)}
                  // type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.model}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Make</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="make"
                  placeholder="Make"
                  value={values.make}
                  onChange={handleChange}

                  // onChange={(e) => setMake(e.target.value)}
                  // type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.make}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Year</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <Input
                  name="year"
                  placeholder="Year"
                  value={values.year}
                  onChange={handleChange}

                  // onChange={(e) => setYear(e.target.value)}
                  // type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.year}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Serial Number</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={serialnumbericon} alt="" />
                </div>
                <Input
                  name="serialnumber"
                  placeholder="Serial Number"
                  value={values.serialnumber}
                  onChange={handleChange}

                  // onChange={(e) => setSerialnumber(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.serialnumber}</p>

            </Col>
        
            <Col md="12" lg="6">
              <label className="formgroup_label">Hobbs Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="HobbsTotal"
                  placeholder="Hobbs Total"
                  value={values.HobbsTotal}
                  onChange={handleChange}

                  // onChange={(e) => setHobbsTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.HobbsTotal}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Tach Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="TachTotal"
                  placeholder="Tach Total"
                  value={values.TachTotal}
                  onChange={handleChange}
                  // onChange={(e) => setTachTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.TachTotal}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="status"
                  value={values.status}
                  onChange={handleChange}

                  // onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select">Select Status</option>
                  <option value="Online">Online</option>
                  <option value="Staff Only">Staff Only</option>
                  <option value="Offline">Offline</option>
                </Input>
              </FormGroup>
              <p className="input-error">{errors.status}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Airport Tax</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={taxicon} alt="" />
                </div>
                <Input
                  name="airporttax"
                  placeholder="Airport Tax"
                  value={values.airporttax}
                  onChange={handleChange}

                  // onChange={(e) => setAirporttax(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.airporttax}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Tax Percentage </label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="taxPercentage"
                  placeholder="Tax Percentage"
                  value={values.taxPercentage}
                  onChange={handleChange}

                  // onChange={(e) => setTaxPercentage(e.target.value)}
                  type="number"
                  min="0"   // Optional: Restrict to non-negative numbers
                  step="0.01"
                />
              </FormGroup>
              <p className="input-error">{errors.taxPercentage}</p>

            </Col>
            <Col md="12">
              <hr className="section_breakline" />
              <h4 className="headinginner  mb-3">Maintenance Status</h4>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Billing Follows</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="BillingFollows"
                  value={BillingFollows}
                  onChange={handleChange}

                  // onChange={(e) => setBillingFollows(e.target.value)}
                >
                  <option value="select">Select Billing Follows</option>
                  <option value="Hobbs">Hobbs</option>
                  <option value="Tach">Tach</option>
                </Input>
                
              </FormGroup>
              <div className='form-check mb-3'>
                <Input type='checkbox' name='billing' id='Billingcheckbox' />
                <Label className='form-check-label' for='Billingcheckbox'>
                  Only use 1 meter for billing and maintenance. Note: set Tach and Hobbs to same value.
                </Label>
              </div>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Round 2 Decimal Places</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="Round2Decimal"
                  value={Round2Decimal}
                  onChange={(e) => setRound2Decimal(e.target.value)}
                >
                  <option value="select">Select Round 2 Decimal Places</option>
                  <option value="No1">No</option>
                  <option value="Yes1">Yes</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Hobbs Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="HobbsTotal"
                  placeholder="Hobbs Total"
                  value={values.HobbsTotal}
                  onChange={handleChange}

                  // onChange={(e) => setHobbsTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.HobbsTotal}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Tach Total</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="TachTotal"
                  placeholder="Tach Total"
                  value={values.TachTotal}
                  onChange={handleChange}

                  // onChange={(e) => setTachTotal(e.target.value)}
                  type="text"
                />
              </FormGroup>
              <p className="input-error">{errors.TachTotal}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">TTAF</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="TTAF"
                  placeholder="TTAF"
                  value={TTAF}
                  onChange={(e) => setTTAF(e.target.value)}
                  type="text"
                />
                
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">TTAF Follows</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="TTAFFollows"
                  value={TTAFFollows}
                  onChange={(e) => setTTAFFollows(e.target.value)}
                >
                  <option value="select">Select TTAF Follows</option>
                  <option value="Tach">Tach</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Record Air Time at Checkin</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="RecordAir"
                  value={RecordAir}
                  onChange={(e) => setRecordAir(e.target.value)}
                >
                  <option value="select">Select Record Air Time at Checkin</option>
                  <option value="No2">No</option>
                  <option value="Yes2">Yes</option>
                </Input>
              </FormGroup>
            </Col>

            {/* <Col md="12">
              <hr className="section_breakline" />
            </Col> */}
            <Col md="12" className="mt-3">
              {/* <h4 className="headinginner  mb-3">Working Schedule</h4> */}
              {/* <ButtonGroup className="dayfield">
                <Button
                  onClick={() => setRSelected('mon')}
                  active={rSelected === 'mon'}
                >
                  Mon
                </Button>
                <Button
                  onClick={() => setRSelected('tue')}
                  active={rSelected === 'tue'}
                >
                  Tue
                </Button>
                <Button
                  onClick={() => setRSelected('wed')}
                  active={rSelected === 'wed'}
                >
                  Wed
                </Button>
                <Button
                  onClick={() => setRSelected('thurs')}
                  active={rSelected === 'thurs'}
                >
                  Thu
                </Button>
                <Button
                  onClick={() => setRSelected('fri')}
                  active={rSelected === 'fri'}
                >
                  Fri
                </Button>
                <Button
                  onClick={() => setRSelected('sat')}
                  active={rSelected === 'sat'}
                >
                  Sat
                </Button>
                <Button
                  onClick={() => setRSelected('sun')}
                  active={rSelected === 'sun'}
                >
                  Sun
                </Button>
              </ButtonGroup>
              <div className="weekoff_div">
                <FormGroup switch>
                  <Label check>Week Off</Label>
                  <Input
                    type="switch"
                    checked={weekoff}
                    value={weekoff}
                    onClick={() => {
                      setWeekoff(!weekoff);
                    }}
                  />
                </FormGroup>
              </div> */}
              {/* <div className="timeslot_div" > Ensure each item has a unique key */}

                {/* {
                  rSelected && daySlot[rSelected]?.map((item, index) => (
                    <ButtonGroup className="timeslot">
                      <Button
                        style={{ marginRight: '5px' }}
                        onClick={() => onCheckboxBtnClick(item.from)}
                        active={cSelected[rSelected].includes(item.from)}
                      >
                        {item.from} - {item.to}
                      </Button>
                    </ButtonGroup>
                  ))
                } */}
              {/* </div> */}
            </Col>

          </Row>
          <div className="text-center mt-5">
            <Button
              // onClick={() => {
              //   addAircraftData()
              // }}
              className="primarybutton  maxwidth-555">
                 {loading ? <PageLoader /> : "Save Now"}
            </Button>
          </div>
        </Form>
      </div >
      <ToastContainer />

    </>
  );
};

export default Addaircraft;
