import React from "react";
import { Row, Col, Container } from "reactstrap";

const TermsCondition = () => {

  return (
    
    <Container className="terms-container">
      <div className="welcome-div">
        <Row className="justify-content-center">
          <Col lg="8" className="text-center mb-4">
            <h1 className="terms-title">About Application</h1>
          </Col>
          <Col lg="8">
            <div className="terms-content">
              <h2>Terms and Conditions</h2>
              <ol>
                <li>
                  <strong>Acceptance of Terms</strong>
                  <p>
                    By downloading, installing, and using the Inflight Pilot Training App (the "App"), you agree to be bound by these Terms and Conditions ("Terms"). If you do not agree with any part of these Terms, you must not use the App.
                  </p>
                </li>
                <li>
                  <strong>Changes to Terms</strong>
                  <p>
                    We reserve the right to modify these Terms at any time. Your continued use of the App following any changes indicates your acceptance of the new Terms.
                  </p>
                </li>
                <li>
                  <strong>Use of the App</strong>
                  <p>
                    You must be at least 18 years old to use the App.
                    You agree to use the App only for lawful purposes and in accordance with these Terms.
                    You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
                  </p>
                </li>
                <li>
                  <strong>Intellectual Property</strong>
                  <p>
                    The App and its original content, features, and functionality are and will remain the exclusive property of Inflight Pilot Training and its licensors. The App is protected by copyright, trademark, and other laws of both the United States and foreign countries.
                  </p>
                </li>
                <li>
                  <strong>Termination</strong>
                  <p>
                    We may terminate or suspend your access to the App immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
                  </p>
                </li>
                <li>
                  <strong>Limitation of Liability</strong>
                  <p>
                    In no event shall Inflight Pilot Training, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your use of the App.
                  </p>
                </li>
                <li>
                  <strong>Governing Law</strong>
                  <p>
                    These Terms shall be governed and construed in accordance with the laws of Minnesota, United States, without regard to its conflict of law provisions.
                  </p>
                </li>
                <li>
                  <strong>Contact Us</strong>
                  <p>
                    If you have any questions about these Terms, please contact us at <a href="mailto:fly@inflightpilottraining.com">fly@inflightpilottraining.com</a>.
                  </p>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default TermsCondition;
