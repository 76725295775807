// import Singleton from '../../../src/Singleton';
import { ADD_ANNOUNCEMENT, ANNOUNCEMENT } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_ADD_ANNOUNCEMENT, ADMIN_ANNOUNCEMENT, ADMIN_DELETE_ANNOUNCEMENT, ADMIN_READ_ANNOUNCEMENT, ADMIN_UNREAD_ANNOUNCEMENT } from '../../constant/Endpoints';
import Singleton from '../../Singleton';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const addAnnouncementSuccess = (data) => {
    return {
        type: ANNOUNCEMENT,
        payload: data
    };
};



export const AnnouncementListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_ANNOUNCEMENT, data)
                .then(response => {
                    // dispatch(ManagementListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}



export const AddAnnouncedAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .filePost(ADD_ANNOUNCEMENT, data)
                .then(response => {
                    dispatch(addAnnouncementSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    alert(error.msg)
                })
        })
    }
}

export const DeleteAnnouncedAction = (data) => {
    // console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DELETE_ANNOUNCEMENT, data)
                .then(response => {
                    // dispatch(addAnnouncementSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    alert(error.msg)
                })
        })
    }
}

export const UnReadAnnouncedAction = (data) => {
    // console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_UNREAD_ANNOUNCEMENT, data)
                .then(response => {
                    // dispatch(addAnnouncementSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    alert(error.msg)
                })
        })
    }
}
