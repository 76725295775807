import React, { useState } from "react";
import { Image } from "react-bootstrap";

function Images({ src, className, alt, onLoad }) {
  const [isLandScape, setIsLandScape] = useState(true);
  const handleImageLoad = (event) => {
    const img = event.target;

    if (img.naturalWidth > img.naturalHeight) {
      setIsLandScape(true); // Image is wider than it is tall
    } else {
      setIsLandScape(false); // Image is taller than it is wide
    }
  };
  return (
    <Image
      src={src}
      className={className}
      alt={alt}
      onLoad={handleImageLoad}
      style={!isLandScape ? { objectFit: 'contain' } : undefined}
      // onClick={() => navigate(`/post?key=${post._id}`)}
    />
  );
}

export default Images;
