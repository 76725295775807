import React, { useState } from "react"
import SearchIcon from '../../../assets/image/search.svg';
import {
  Row,
  Col,
  Input,
  Label,
} from "reactstrap"


const FilterComponent = ({ filterText, onFilter, statusFilter, onStatus }) => {

  const [status, setStatus] = useState("select")
  return (
    <>
      <Row> 
       <Col xl="6" md="6" xs="6" className="mb-1">
          <div className="filterformgroup">
            <Label>Status:</Label>
            <Input
              type="select"
              name="status"
              id="status"
              value={statusFilter}
              onChange={onStatus}
              style={{ height: '42px', maxWidth: '200px' }}
            >
              <option value="select" disabled>Select Status</option>
              <option value="" selected>All</option>
              <option value="Online" >Online</option>
              <option value="Staff">Staff Only</option>
              <option value="Offline">Offline</option>
            </Input>
          </div>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end mb-1 alignselfend">
          <div className="filtersearch_div ms-auto" style={{ maxWidth: '300px' }}>
            <Input
              id="search"
              type="text"
              placeholder="Search..."
              value={filterText}
              onChange={onFilter}
              style={{ height: '42px' }}
            />
            <img className="searchiconbtn" src={SearchIcon} alt="" />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default FilterComponent;
