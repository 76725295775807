import React, { useState,useRef } from 'react';
import {
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    Input,
    Button,
} from 'reactstrap';
import { Link, useNavigate,useLocation } from 'react-router-dom';
import "../../assets/css/style.css";
import verifyotpimg from '../../assets/image/verifyotp.svg';
import backicon from '../../assets/image/back-icon.svg';
import { VerifyOtpAction } from '../../Redux/Actions/onBoardingAction';
import { useDispatch } from 'react-redux';
import PageLoader from '../customcomponent/loader';
const VerifyOTP = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [inputValue1, setInputValue1] = useState('');
    const [inputValue2, setInputValue2] = useState('');
    const [inputValue3, setInputValue3] = useState('');
    const [inputValue4, setInputValue4] = useState('');
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get('email');
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);

    const handleChange1 = (e) => {
        const { value } = e.target;
        setInputValue1(value);
        if (value.length === 1) {
            inputRef2.current.focus();
        }
    };
    
    const handleChange2 = (e) => {
        const { value } = e.target;
        setInputValue2(value);
        if (value.length === 1) {
            inputRef3.current.focus();
        }
    };
    
    const handleChange3 = (e) => {
        const { value } = e.target;
        setInputValue3(value);
        if (value.length === 1) {
            inputRef4.current.focus();
        }
    };
    
    const handleChange4 = (e) => {
        const { value } = e.target;
        setInputValue4(value);
    };
    
  


    const onVerification = () => {
        setLoading(true)
        const otp = inputValue1 + inputValue2 + inputValue3 + inputValue4
        let data = {
            email: email,
            OTP: otp
        }
        console.log("data",data)
        if (Object.values(data).every(field => field.OTP != '')) {
            console.log("IF")
        dispatch(VerifyOtpAction(data))
            .then((res) => {
                console.log(res.data, "RR")
                if (res.status) {
                    setLoading(false)
                    navigate("/Resetpassword?email=" + email,)
                    // Singleton.getInstance().saveData('token', res.data.userToken)
                    // window.location.reload()
                } else {
                    setLoading(false)
                    console.log(res.msg);
                    setError(res.msg);
                }
            }).catch(err => setError("Something went wrong"))
        }else{ 
            setLoading(false)
            console.log("Error")
                setError('Please enter OTP');
            
          
        }
    }
    return (
        <>
            <div>
                <Container fluid>
                    <Row>
                        <Col md="6" className="p-0">
                            <div className="loginlogo_div">
                                <img className="forgotimg" src={verifyotpimg} alt="" />
                            </div>
                        </Col>
                        <Col className="loginfield_bg" md="6">
                            <div className="loginmain_div position_relative">
                                <div className="backbtn_div">
                                    <Link to="/Forgotpassword"><img src={backicon} alt="" /></Link>
                                </div>
                                <div className="login_headsec">
                                    <h4>Verify OTP</h4>
                                    <p>An OTP has been sent to your registered E-mail account, please check.</p>
                                </div>

                                <Form action="javascript:void(0)">
                                    <FormGroup className="otpfield">
                                        <Input name="otp1" placeholder="" maxLength={1} value={inputValue1}   onChange={handleChange1} type="text" />
                                        <Input name="otp2" placeholder="" maxLength={1} value={inputValue2}  ref= {inputRef2} onChange={handleChange2} type="text" />
                                        <Input name="otp3" placeholder="" maxLength={1} value={inputValue3}  ref= {inputRef3} onChange={handleChange3} type="text" />
                                        <Input name="otp4" placeholder="" maxLength={1} value={inputValue4}   ref= {inputRef4} onChange={handleChange4} type="text" />
                                    </FormGroup>
                                    {error && <div style={{ color: 'red', textAlign: 'center'}}>{error}</div>}

                                    <div className="login_btn">
                                    <Button className="whitebutton mt_120" onClick={onVerification}>
                                    {loading ? <PageLoader /> : "Verify"}
                                            
                                        </Button>

                                    </div>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default VerifyOTP;