import { ADMIN_DETAIL_SUCCESS, ADMIN_LIST_SUCCESS, AIRCRAFT_LIST_SUCCESS, INSTRUCTOR_LIST_SUCCESS, LOG_IN_SUCCESS, USER_LIST_SUCCESS } from "../Actions/types";

const INITIAL_STATE = {
    SignUp: {},
    Profile: {},
    AircraftList: [],
    userEmail: [],
    instructorEmail: []

};

/************************************** Register reducer ****************************************************/
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOG_IN_SUCCESS:
            return { ...state, SignUp: action.payload };
        case ADMIN_DETAIL_SUCCESS:
            return { ...state, Profile: action.payload }
        case AIRCRAFT_LIST_SUCCESS:
            return { ...state, AircraftList: action.payload }
        case USER_LIST_SUCCESS:
            return { ...state, userEmail: action.payload }
        case INSTRUCTOR_LIST_SUCCESS:
            return { ...state, instructorEmail: action.payload }
        default:
            return state;
    }
};


