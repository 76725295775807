import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Input,
  ModalBody,
} from 'reactstrap';
import data from "./data";
import Table from "./Datatables";
import { Link, useParams } from "react-router-dom"
import { AddAircraftNameAction, CategoryDetailAction } from '../../../Redux/Actions';
import { useDispatch } from 'react-redux';
import { toast,ToastContainer } from 'react-toastify';
import PageLoader from '../../customcomponent/loader';
import { useFormik } from 'formik';
import { addAircraftNameValidationSchema } from '../../../validationSchema';
import Loader from "../../customcomponent/pageLoader";
const ViewCategory = (args) => {
  const [modal, setModal] = useState(false);

  const param = useParams();
  const [catdetail, setDetail] = useState([]);
  const dispatch = useDispatch();
  const modaltoggle = () => { 
    formik.values.addName=""
    setModal(!modal)
  };
  const [loading2, setLoading2] = useState(false);
  const [loading, setLoading] = useState(false);


  const detail = ()=>{
    setLoading2(true);

    const data = {
      categoryId: param.id
    };

    console.log("data=========?",data)

    dispatch(CategoryDetailAction(data))
      .then((res) => {
        setLoading2(false);

        console.log("res",res)
        if (res.status === 1) {
          setDetail(res?.data[0])
        } else {
          toast.error(res.msg, {
            position: 'top-right',
          });
        }
      })
      .catch((err) => {

        toast.error(err, {
          position: 'top-right',
        });
        setLoading2(false);

      })
  }


  const formik = useFormik({
    initialValues: {
      addName: '',
      categoryId: param.id

    },
    validationSchema: addAircraftNameValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      // setLoading(true)
      const data = {
        name: values.addName,
        categoryId:values.categoryId

      };

      dispatch(AddAircraftNameAction(data))
        .then((res) => {
          // setLoading2(false);

          if (res.status === 1) {
            toast.success('Aircraft added successfully', {
              position: 'top-right',
            });
            resetForm();
            modaltoggle();
            detail()

          } else {
            toast.error(res.msg, {
              position: 'top-right',
            });
          }
        })
        .catch((err) => {
          // setLoading2(false);

          toast.error(err, {
            position: 'top-right',
          });
        });
    },
  });



  useEffect(() => {
    detail()
  }, []);



  return loading2 ? (
    <Loader />
  ) : (
    <div className='instructorpanel'>
      <Row className="mb-4">
        <Col xl="6" md="6" xs="6" className="align-self-center">
          <h4 className="card-title my-1  headingmain">{catdetail?.categoryName}</h4>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end">
  <Link>
    <Button className="btn-add" onClick={modaltoggle}>Add</Button>
  </Link>
  <Link to="/AircraftCategory">
    <Button className="btn-add ms-2">Back</Button>
  </Link>
</Col>
      
      </Row>
      <div>
        <Table data={catdetail?.aircraftData} reloadCategories={detail} />
      </div> 
      <Modal centered isOpen={modal} toggle={modaltoggle}>
        <ModalBody className="p-4">
          <div className="Emailmodal">
            <h2 className="email_head pb-3 text-center">Add Aircraft Name</h2>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm="12">
                  <label className="formgroup_label">Aircraft Name</label>
                  <FormGroup className="form-group">
                    <Input
                      name="addName"
                      placeholder="Enter aircraft name"
                      className="px-4"
                      value={formik.values.addName}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.addName && (
                      <p className="input-error">{formik.errors.addName}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-cancel-ok pt-3 text-center">
                <Button className="btn-cancel me-1" onClick={modaltoggle}>Cancel</Button>
                <Button type="submit" className="btn-save">Add</Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal> 
      <ToastContainer />
    </div>
    
  );
}

export default ViewCategory;
