import React from "react";
import { Row, Col, Container } from "reactstrap";

const PrivacyPolicy = () => {
  return (
    <Container className="privacy-container">
      <div className="welcome-div">
        <Row className="justify-content-center">
          <Col lg="8" className="text-center mb-4">
            <h1 className="privacy-title">About Application</h1>
          </Col>
          <Col lg="8">
            <div className="privacy-content">
              <h2>Privacy Policy</h2>
              <ol>
                <li>
                  <strong>Introduction</strong>
                  <p>
                    Welcome to the Inflight Pilot Training mobile app ("App"). Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our App.
                  </p>
                </li>
                <li>
                  <strong>Information We Collect</strong>
                  <p>
                    We may collect the following types of information:
                  </p>
                  <ul>
                    <li>Personal Information: Name, email address, phone number, and other information you provide when you register or contact us.</li>
                    <li>Usage Data: Information on how you use the App, including features used, time spent, and training progress.</li>
                    <li>Device Information: Information about your device, such as IP address, operating system, and device type.</li>
                  </ul>
                </li>
                <li>
                  <strong>How We Use Your Information</strong>
                  <p>
                    We use the information we collect to:
                  </p>
                  <ul>
                    <li>Provide and improve our App</li>
                    <li>Personalize your experience</li>
                    <li>Communicate with you, including for customer support and updates</li>
                    <li>Process transactions</li>
                    <li>Ensure compliance with legal obligations</li>
                  </ul>
                </li>
                <li>
                  <strong>Sharing Your Information</strong>
                  <p>
                    We may share your information:
                  </p>
                  <ul>
                    <li>With service providers who assist us in operating the App</li>
                    <li>To comply with legal requirements</li>
                    <li>To protect our rights and safety, and those of our users</li>
                  </ul>
                </li>
                <li>
                  <strong>Security</strong>
                  <p>
                    We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security system is completely secure.
                  </p>
                </li>
                <li>
                  <strong>Data Retention</strong>
                  <p>
                    We retain your information as long as necessary to provide you with the App and fulfill the purposes described in this Privacy Policy.
                  </p>
                </li>
                <li>
                  <strong>Your Rights</strong>
                  <p>
                    You have the right to:
                  </p>
                  <ul>
                    <li>Access the personal information we hold about you</li>
                    <li>Request corrections to any inaccurate or incomplete information</li>
                    <li>Request deletion of your personal information, subject to certain conditions.</li>
                  </ul>
                </li>
                <li>
                  <strong>Contact Us</strong>
                  <p>
                    If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:fly@inflightpilottraining.com">fly@inflightpilottraining.com</a>.
                  </p>
                </li>
              </ol>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default PrivacyPolicy;
