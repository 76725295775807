import React, { useState, useMemo, useEffect } from "react";
import { Modal, ModalBody, Button } from "reactstrap";
import DataTable from "react-data-table-component";
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, XCircle } from "react-feather";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AircraftDetailAction,
  AircraftListAction,
  DeleteAircraftAction,
} from "../../../Redux/Actions/AircraftAction";
import { IMAGE_URL } from "../../../constant/Endpoints";
import aircraftimg from "../../../assets/image/plane.svg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../../customcomponent/pageLoader";
import PageLoader from "../../customcomponent/loader";
const Table = (props) => {
  const [id, setid] = useState("");
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Name",
      minWidth: "280px",
      selector: "imgurl",
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-1"
            src={
              row.aircraftImage != null
                ? IMAGE_URL + row.aircraftImage
                : aircraftimg
            }
            alt=""
          />
          {row?.aircraftName}
        </>
      ),
    },
    {
      name: "Tail",
      minWidth: "100px",
      selector: "tail",
      sortable: true,
      cell: (row) => row?.TailNumber,
    },
    {
      name: "Status",
      minWidth: "80px",
      selector: "status",
      sortable: true,
      cell: (row) => (
        <span className={row?.aircraftStatus + " badge"}>
          {row?.aircraftStatus}
        </span>
      ),
    },
    {
      name: "Actions",
      minWidth: "180px",
      center: "center",
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/Viewaircraft/" + row?._id}>
              <Button className="tablebtnview">
                <Eye className="tableicon" />
              </Button>
            </Link>
            <Link to={"/Editaircraft/" + row?._id}>
              <Button className="tablebtnedit">
                <Edit className="tableicon" />
              </Button>
            </Link>
            <Button
              className="tablebtndelete"
              onClick={() => {
                setModal2(!modal2);
                setid(row?._id);
              }}
            >
              <Trash className="tableicon" />
            </Button>
          </>
        </div>
      ),
    },
  ];
  const [stausFilter, setStausFilter] = React.useState("");
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);
  const filteredItems = props.data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        stausFilter={stausFilter}
        onStatus={(e) => setStausFilter(e.target.value)}
      />
    );
  }, [filterText, resetPaginationToggle]);
  
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aircraftList, setAirCraftList] = useState();

  const getAircraft = () => {
    setLoading(true);
    let data = {
      search: filterText,
      filter: stausFilter,
    };

    dispatch(AircraftListAction(data))
      .then((res) => {
        if (res.status === 1) {
          setLoading(false);
          setAirCraftList(res?.data);
          // console.log(res)
        }
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err)
      });
  };

  const deleteAircraft = (id) => {
    let data = {
      aircraftID: id,
    };
    dispatch(DeleteAircraftAction(data))
      .then((res) => {
        if (res.status === 1) {
          // console.log(res)
          getAircraft();
          setModal2(!modal2);

          toast.success("Aircraft deleted successfully", {
            position: "top-right",
          });
        } else {
          toast.error(res.msg, {
            position: "top-right",
          });
          setModal2(!modal2);
        }
      })
      .catch((err) => {
        // console.log(err)
      });
  };

  useEffect(() => {
    getAircraft(filterText);
  }, [filterText, stausFilter]);

  return (
    <React.Fragment>
      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={aircraftList}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader />}
        progressPending={loading}
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered isOpen={modal2} toggle={() => setModal2(!modal2)}>
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure ?</h2>
            <p>
              You want to delete this record. You will not be able to recover
            </p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>
                Cancel
              </Button>
              <Button className="btn-ok" onClick={() => deleteAircraft(id)}>
                Proceed
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Table;
