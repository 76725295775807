const data = [
  {
    id: 1,
    name: "About Us",
    status: 'Inactive',
  },
  {
    id: 2,
    name: "Privacy Policy",
    status: 'Active',
  },
];

export default data;
