import React from 'react';
import {
  Row,
  Col,
  Button,
} from 'reactstrap';
import data from "./data";
import Table from "./Datatables";
import { Link } from "react-router-dom"
const Users = (args) => {

  return (
    <>
      <div className='instructorpanel'>
      <Row className="mb-4">
        <Col xl="6" md="6" xs="6" className="align-self-center">
          <h4 className="card-title my-1  headingmain">Users</h4>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end">
          <Link to="/Adduser"><Button className="btn-add">Add</Button></Link>
        </Col>
      </Row>        
      <div>
        <Table data={data} />
      </div>
      </div>
    </>
  );
}

export default Users;
