import React, { useEffect, useRef, useState } from 'react';
import Hls from 'hls.js';
import videoPlaceholder from "../../assets/image/videoPlaceholder.png";

const VideoPlayer = ({ src, thumbnailUrl }) => {
  const videoRef = useRef(null);
  const observedDivRef = useRef(null);
  const resizingDelayTimer = useRef(null);
  const [matchingDivWidth, setMatchingDivWidth] = useState(null);
  const [isThumbnailVisible, setIsThumbnailVisible] = useState(true);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) {
      return;
    }

    const hls = new Hls();
    hls.loadSource(src);

    hls.attachMedia(videoElement);
    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      videoElement.play();
    });

    videoElement.addEventListener('timeupdate', () => {
      // Your timeupdate logic here
    });

    const observer = new ResizeObserver(() => {
      clearTimeout(resizingDelayTimer.current);
      resizingDelayTimer.current = setTimeout(() => {
        if (observedDivRef.current) {
          setMatchingDivWidth(observedDivRef.current.clientWidth);
        }
      }, 100);
    });

    observer.observe(observedDivRef.current);

    return () => {
      if (hls) {
        hls.destroy();
      }
        if (observer) {
      observer.disconnect();
    }
    };
  }, [src]);

  const handleThumbnailClick = () => {
    setIsThumbnailVisible(false);
  };

  return (
    <div className="video-container">
      {isThumbnailVisible && thumbnailUrl ? (
        <div className="thumbnail" onClick={handleThumbnailClick}>
          <img src={videoPlaceholder} style={{width:"50%",height:"140px"}} alt="Video Thumbnail" className="thumbnail-image" />
        </div>
      ) : (
        <div ref={observedDivRef}>
          <video ref={videoRef} controls className="video-player" />
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;
