import React from "react";
import { TailSpin } from "react-loader-spinner";

function PageLoader() {
  return (
    <div
    //   style={{ height: "80vh" }}
      className="d-flex align-items-center justify-content-center"
    >
      <TailSpin
        visible={true}
        height={30}
        width={30}
        color="#000000" // Change color to hexadecimal notation or color name recognized by CSS
        ariaLabel="tail-spin-loading"
        radius={0.0001}
        wrapperStyle={{}}
        wrapperClass=""
      />
    </div>
  );
}

export default PageLoader;


// import React from "react";
// import ContentLoader from "react-content-loader";

// const ShimmerLoader = (props) => (
//   <ContentLoader
//     speed={2}
//     width="100%"
//     height={160}
//     viewBox="0 0 100% 160"
//     backgroundColor="#f3f3f3"
//     foregroundColor="#ecebeb"
//     {...props}
//   >
//     <rect x="0" y="0" rx="5" ry="5" width="100%" height="100" />
//   </ContentLoader>
// );

// function PageLoader() {
//   return (
//     <div
//       className="d-flex align-items-center justify-content-center"
//       style={{ width: "100%", height: "100px" }} 
//     >
//       <ShimmerLoader />
//     </div>
//   );
// }

// export default PageLoader;
