import AsyncStorage from '@react-native-async-storage/async-storage';
import { Alert } from 'reactstrap';
import * as Constants from '../src/constant/Constants';
import localStorage from 'redux-persist/es/storage';

export default class Singleton {
    access_token = '';
    alertInstance = null;
    static isCameraOpen = false; s
    static myInstance = null;
    static socket;
    data = null; //use anywhere in the project...change name accordingly
    static getInstance() {
        if (Singleton.myInstance == null) {
            Singleton.myInstance = new Singleton();
        }
        return this.myInstance;
    }
    saveData(key, value) {
        // console.log(key,'get=Data=value=-=-=-=>>',value);
             localStorage.setItem(key, value)
                
       
    }

    getData(key) {
         return new Promise((resolve, reject) => {
            localStorage.getItem(key)
                .then(response => {
                    // console.log('get Data response $$$$$');
                    // console.log(response);
                    // console.log('get response $$$$$');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    clearStorage() {
        return new Promise((resolve, reject) => {
            localStorage.removeItem()
                .then(response => {
                    // console.log('get response $$$$$');
                    // console.log(response);
                    // console.log('get response $$$$$');
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
    static showAlert(msg) {
        // Singleton.getInstance().alertInstance.showAlert(msg)
        alert(
            msg.toString(),
         );
    }



}