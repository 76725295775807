import React, { useEffect, useState } from "react"
import {
	Row,
	Col,
	Button,
	Modal,
	FormGroup,
	Input,
	ModalBody,
} from "reactstrap"
import DatePicker from "react-datepicker";
import calendaricon from "../../../../assets/image/profilecalendar-1.svg";
import clockicon from "../../../../assets/image/clock_icon.svg";
import FlyingIcon from "../../../../assets/image/flying.svg";
import pageicon from "../../../../assets/image/pagefield.svg";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AircraftDetailAction } from "../../../../Redux/Actions/AircraftAction";



const data = [
	{
		id: 1,
		date: '	29 August 2023 14:00 ',
		user: 'Maria James',
		type: 'Rental',
		hobbsinout: '+1.40',
		hobbstotal: '6,044.70',
		Tachinout: '+0.90',
		Tachtotal: '2,632.99',
	},
	{
		id: 2,
		date: '	28 August 2023 12:30 ',
		user: 'James Vince',
		type: 'Non Revenue',
		hobbsinout: '+1.40',
		hobbstotal: '6,044.70',
		Tachinout: '+0.90',
		Tachtotal: '2,632.99',
	},
	{
		id: 3,
		date: '	27 August 2023 07:51 ',
		user: 'Stephen George',
		type: 'Rental',
		hobbsinout: '+1.40',
		hobbstotal: '6,044.70',
		Tachinout: '+0.90',
		Tachtotal: '2,632.99',
	},
	{
		id: 4,
		date: '	26 August 2023 16:00 ',
		user: 'Johnson Charles',
		type: 'Non Revenue',
		hobbsinout: '+1.40',
		hobbstotal: '6,044.70',
		Tachinout: '+0.90',
		Tachtotal: '2,632.99',
	},
	{
		id: 5,
		date: '	25 August 2023 11:45 ',
		user: 'Steve Jones',
		type: 'Rental',
		hobbsinout: '+1.40',
		hobbstotal: '6,044.70',
		Tachinout: '+0.90',
		Tachtotal: '2,632.99',
	},
];
const AircrafthourslogPages = ({ hourLog }) => {


	const [chargemodal, setChargemodal] = useState(false);
	const chargetoggle = () => setChargemodal(!chargemodal);
	const [HobbsAdjustment, setHobbsAdjustment] = useState('');
	const [HobbsEnding, setHobbsEnding] = useState('');
	const [TachAdjustment, setTachAdjustment] = useState('');
	const [TachEnding, setTachEnding] = useState('');
	const [description, setDescription] = useState('');
	const [starttime, setStarttime] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [typeselect, setTypeselect] = useState('select');


	// console.log(hourLog, "HR")






	return (
		<>
			<Row>
				<Col sm="12" className="text-end mb-4">
					<Button className="btn-add" onClick={chargetoggle}>Add</Button>
				</Col>
				<Col sm="12">
					<div className="table-responsive">
						<table class="table">
							<thead class="table-light">
								<tr>
									<th className="text-nowrap">Date</th>
									<th className="text-nowrap">User</th>
									<th className="text-nowrap">Type</th>
									<th className="text-nowrap">Hobbs +/-</th>
									<th className="text-nowrap">Hobbs Total</th>
									<th className="text-nowrap">Tach +/-</th>
									<th className="text-nowrap">Tach Total</th>
								</tr>
							</thead>
							<tbody>
								{hourLog?.map((item, index) => (
									<tr key={index}>
										<td><b>{item.dispatchDate ? item.dispatchDate : ""}</b></td>
										<td>{item.userName}</td>
										<td>{item.type ? item.type : ""}</td>
										<td>{item.addedHobbs ? item.addedHobbs : ""}</td>
										<td>{item.hobbsTotal}</td>
										<td>{item.addedTach ? item.addedTach : ""}</td>
										<td>{item.tachTotal}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
			<Modal centered
				isOpen={chargemodal}
				toggle={() => setChargemodal(!chargemodal)}
			>
				<ModalBody className="p-4">
					<div className="Emailmodal">
						<h2 className="email_head pb-3 text-center">Hours Entry</h2>
						<Row>
							<Col sm="12">
								<label className="formgroup_label">Type</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={pageicon} alt="" />
									</div>
									<Input
										className="form-control"
										type="select"
										name="typeselect"
										value={typeselect}
										onChange={(e) => setTypeselect(e.target.value)}
									>
										<option value="select">Select Type</option>
										<option value="Type1">Rental</option>
										<option value="Type2">Non Revenue</option>
									</Input>
								</FormGroup>
							</Col>
							<Col sm="6">
								<label className="formgroup_label">Date</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={calendaricon} alt="" />
									</div>
									<DatePicker
										id="dateField"
										selected={selectedDate}
										onChange={(date) => setSelectedDate(date)}
										showMonthDropdown
										showYearDropdown
										dropdownMode="select"
										placeholderText="DD/MM/YYYY"
										dateFormat="dd/MM/yyyy"
										popperPlacement="bottom-start"
									/>
								</FormGroup>
							</Col>
							<Col sm="6">
								<label className="formgroup_label">Time</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={clockicon} alt="" />
									</div>
									<DatePicker
										id="dateField"
										selected={starttime}
										onChange={(date) => setStarttime(date)}
										placeholderText="Start Time"
										showTimeSelect
										showTimeSelectOnly
										timeIntervals={15}
										timeCaption="Time"
										dateFormat="h:mm aa"
									/>
								</FormGroup>
							</Col>
							<Col sm="6">
								<label className="formgroup_label">Hobbs Adjustment</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={FlyingIcon} alt="" />
									</div>
									<Input
										name="HobbsAdjustment"
										placeholder="Hobbs Adjustment"
										value={HobbsAdjustment}
										onChange={(e) => setHobbsAdjustment(e.target.value)}
										type="text"
									/>
								</FormGroup>
							</Col>
							<Col sm="6">
								<label className="formgroup_label">Hobbs Ending</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={FlyingIcon} alt="" />
									</div>
									<Input
										name="HobbsEnding"
										placeholder="Hobbs Ending"
										value={HobbsEnding}
										onChange={(e) => setHobbsEnding(e.target.value)}
										type="text"
									/>
								</FormGroup>
							</Col>
							<Col sm="6">
								<label className="formgroup_label">Tach Adjustment</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={FlyingIcon} alt="" />
									</div>
									<Input
										name="TachAdjustment"
										placeholder="Tach Adjustment"
										value={TachAdjustment}
										onChange={(e) => setTachAdjustment(e.target.value)}
										type="text"
									/>
								</FormGroup>
							</Col>
							<Col sm="6">
								<label className="formgroup_label">Tach Ending</label>
								<FormGroup className="form-group">
									<div className="iconleft">
										<img src={FlyingIcon} alt="" />
									</div>
									<Input
										name="TachEnding"
										placeholder="Tach Ending"
										value={TachEnding}
										onChange={(e) => setTachEnding(e.target.value)}
										type="text"
									/>
								</FormGroup>
							</Col>
							<Col sm="12">
								<label className="formgroup_label">Reson For Adjustment</label>
								<FormGroup className="form-group">
									<Input
										name="description"
										placeholder="Reson for adjustment"
										rows="6"
										value={description}
										onChange={(e) => setDescription(e.target.value)}
										type="textarea"
									/>
								</FormGroup>
							</Col>
						</Row>
						<div className="btn-cancel-ok pt-3 text-center">
							<Button className="btn-cancel me-1" onClick={chargetoggle}>Cancel</Button>
							<Button className="btn-save" onClick={chargetoggle}>Add Hours Entry</Button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default AircrafthourslogPages;
