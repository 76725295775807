import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import reservationimg from "../../../assets/image/reservationbig1.jpg";
import userimg from "../../../assets/image/default.jpg";
import flightImage from "../../../assets/image/plane.svg";

import { useDispatch } from "react-redux";
import { ReservationDetailAction } from "../../../Redux/Actions";
import Avatar from "react-avatar";
import { IMAGE_URL } from "../../../constant/Endpoints";
import moment from "moment";

const Viewreservation = () => {

  const params = useParams()
  const dispatch = useDispatch()
  const [userDetail, setUserDetail] = useState()

  const getReservationDetail = () => {
    const id = params.booking_id;
    let data = {
      booking_id: id
      // booking_id: "8X05433T"
    }
    dispatch(ReservationDetailAction(data)).then((res) => {
      setUserDetail(res?.data[0])
      console.log(res.data)
    }).catch((err) => { console.log(err) })
    console.log(id)
  }

  useEffect(() => {
    getReservationDetail()
  }, [])



  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Reservation Details</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Reservations"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <div className="reservationimg ">
          <img src={userDetail?.aircraftImage ? IMAGE_URL+ userDetail?.aircraftImage : flightImage} alt="" />
        </div>
        <div className="detailmain">
          <div className="detailinner mt-4">
            <h3 className="detaititle"><Link to="/Viewaircraft">{userDetail?.aircraftName ?userDetail?.aircraftName:"NA"}</Link></h3>
            <p className="detaisubhead">
              Reservation Date :<span className="ms-2">{userDetail?.bookingDate ? moment(userDetail?.bookingDate).format("DD-MM-YYYY") : "NA"}</span>
            </p>
            <p className="detaisubhead">
              Aircraft Time Slot:<span className="ms-2">{userDetail?.aircraftFromTime ? userDetail?.aircraftFromTime:"NA"}</span>
            </p>
            <p className="detaisubhead mb-0">
              Instructor Time Slot :<span className="ms-2">{userDetail?.instructorFromTime ?userDetail?.instructorFromTime:"NA"}</span>
            </p>
          </div>
          <div className="detailinner instructordetail mt-4">
            <div className="w-100">
              <h3 className="detaititle">Customer Details</h3>
              <div className="instructorinner">
                <img src={userimg} className="imguser" alt="" />
                <div className="detailcontent">
                  <h4><Link to="/Viewuser">{userDetail?.userFirstName + " " + userDetail?.userLastName}, {userDetail?.userAge}</Link></h4>
                  <p> Lifetime Flew Record - {userDetail?.lifetimeFlewRecord}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="detailinner  mt-4">
            <h3 className="detaititle">Reservation Details</h3>

            <div className="detaisubhead mb-3 mt-4">
              <Row>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Dispatch Date/ Time</div>
                </Col>
                <Col xs="6" md="6" lg="6" xl="4">
                  <span>{userDetail?.dispatchDate ? userDetail?.dispatchDate :"NA"}, {userDetail?.dispatchTime ? userDetail?.dispatchTime :"NA"} </span>
                </Col>
              </Row>
            </div>
            <div className="detaisubhead mb-3">
              <Row>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Check-In Date/ Time</div>
                </Col>
                <Col xs="6" md="6" lg="6" xl="4">
                  <span> {userDetail?.checkInDate?userDetail?.checkInDate :"NA"}, {userDetail?.checkInTime ? userDetail?.checkInTime: "NA"}</span>
                </Col>
              </Row>
            </div>
            <div className="detaisubhead mb-3">
              <Row>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Aircraft Hobbs Out / In </div>
                </Col>
                <Col xs="6" md="6" lg="6" xl="4">
                  <span>{userDetail?.hobbsIN ? userDetail?.hobbsIN : "NA"}<span className="mx-3">|</span>{userDetail?.hobbsTotal ? userDetail?.hobbsTotal :"NA"}</span>
                </Col>
              </Row>
            </div>
            <div className="detaisubhead mb-3">
              <Row>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Aircraft Tach Out / In </div>
                </Col>
                <Col xs="6" md="6" lg="6" xl="4">
                  <span>{userDetail?.tachTotal ? userDetail?.tachTotal :"NA"}<span className="mx-3">|</span>{userDetail?.TachIn ? userDetail.TachIn: "NA"}</span>
                </Col>
              </Row>
            </div>
            <div className="detaisubhead mb-3">
              <Row>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Flight Hours <span className="ms-lg-2"> {userDetail?.flightHours ? userDetail?.flightHours : "NA"}</span></div>
                </Col>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Ground Time <span className="ms-lg-2"> {userDetail?.groundTime ? userDetail?.groundTime : "NA"}</span></div>
                </Col>
              </Row>
            </div>
            <div className="detaisubhead mb-3">
              <Row> 
                <Col xs="6" md="6" lg="6" xl="4">
                  <div> Total Hours <span className="ms-lg-2"> {userDetail?.totalHours ? userDetail?.totalHours : "NA"}</span> </div>
                </Col>
                <Col xs="6" md="6" lg="6" xl="4">
                  <div>Instructor Rate (Hr) <span className="ms-lg-2">{userDetail?.instructorRate ? userDetail?.instructorRate : "NA"}</span> </div>
                </Col>
              </Row>
            </div>
            <div className="detaisubhead mb-3">
              <div>Airport Tax  <span className="ms-lg-2"> {userDetail?.airportTax}%</span> </div>
            </div>
            <div className="detaisubhead mb-3">
              <div>Instruction Notes</div>
              <p className="mb-0 detailinnertop">{userDetail?.instructorNotes !== undefined || userDetail?.instructorNotes != null ? userDetail.instructorNotes : "NA"} </p>
            </div>
            <div className="detaisubhead mt-4 text-center">
              <h4 className="paidamount">Paid Amount <span className="ms-2">{userDetail?.tachTotal ? userDetail?.tachTotal : "NA" }</span></h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Viewreservation;
