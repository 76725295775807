import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Card,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Col,
  CarouselItem,
  CarouselControl,
  Row,
  FormGroup,
} from "reactstrap";
import fetchProgress from 'fetch-progress';
import { Icon } from "@iconify/react";
import VideoPlayer from "../customcomponent/videoPlayer";
import { Link, useNavigate } from "react-router-dom";
import User1 from "../../assets/image/avatar/13.png";
import User2 from "../../assets/image/user1.png";
import Like from "../../assets/image/thumbup.svg";
import Dislike from "../../assets/image/thumbdown.svg";
import SearchIcon from "../../assets/image/SearchIcon.svg";
import Comment from "../../assets/image/comment.svg";
import PostImg1 from "../../assets/image/img1.webp";
import PostImg2 from "../../assets/image/img2.webp";
import PostImg3 from "../../assets/image/img3.webp";
import PostImg4 from "../../assets/image/img4.webp";
import PostImg5 from "../../assets/image/img5.webp";
import PostImg6 from "../../assets/image/img6.webp";
import Images from "../customcomponent/Images";
import { Image } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import axios from "axios";
import { Sentry } from "react-activity";

// import VideoPlayer from "../customcomponent/Video";
import { Label } from "recharts";
import { useDispatch, useSelector } from "react-redux";
import Hls from "hls.js";
import {
  AdminAddComment,
  AdminAddPOST,
  AdminDELETEPOST,
  AdminDetailPOST,
  AdminFetchCommentReply,
  AdminLikePOSTCOMMENT,
  CallFunction,
  CommentListAction,
  EventListAction,
  PostListAction,
  PresignedURL,
} from "../../Redux/Actions/ManagementAction";
import Spin from ".././../assets/image/spin.svg";
import { Instagram } from 'react-content-loader'

import { ADMIN_DELETE_POST, IMAGE_URL } from "../../constant/Endpoints";
import profileimg from "../../assets/image/default.jpg";
import videoPlaceholder from "../../assets/image/videoPlaceholder.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { addCommentValidationSchema } from "../../validationSchema";
import { XCircle } from "react-feather";
import FilterComponent from "../Events/FilterComponent";
import {
  addSpanToWordsStartingWithHash,
  capitalizeEveryWord,
  formatTimeAgo,
} from "../../utils/HelperFunction";
import HLSVideoPlayer from "../customcomponent/Video";
import VideoThumbnail from "../customcomponent/videothumnail";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
import uploadWithProgress from "../../Api/uploadVid";

const Post = () => {
  const [loading, setLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [visibleComments, setVisibleComments] = useState({});
  // const [commentReply, setCommentVisible] = useState({});
  const [visibleReplyComments, setVisibleReplyComments] = useState({});

  const [isLoadMoreComment, setIsLoadMoreComment] = useState(true);
  const [Activity, setActivity] = useState(false);
  const [isAllComments, setIsAllComments] = useState(false);

  const [id, setid] = useState("");

  const profileData = useSelector((state) => state.onBoardingReducer.Profile);

  const [text2, setText2] = useState("");
  const [taggedText, setTaggedText] = useState("s");
  const handleTextareaChange = (e) => {
    const inputText = e.target.value;
    const processedText = inputText.replace(
      /#(\w+)/g,
      '<span class="tag" style="color: #B79B58;">#$1</span>'
    );
    setTaggedText(processedText);
    setText2(inputText);
  };
  const [modal, setModal] = useState(false);
  const [post, setPost] = useState([]);
  const [comment, setComment] = useState([]);
  const [modal2, setModal2] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const closetoggle = () => {
    getPosts();
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(true);
  };

  const closemodal2 = () => {
    setModal2(!modal2);
  };
  const [modal3, setModal3] = useState(false);
  const toggle3 = () => setModal3(!modal3);
  const [postDetail, setPostDetail] = useState();
  const [text, setText] = useState("");
  const [file, setFiles] = useState([]);
  const dispatch = useDispatch();

  const videoRef = useRef(null);

  useEffect(() => {
    console.log(postDetail, "postDetail>>>");
  }, [postDetail]);
  const items = [
    {
      src: PostImg1,
      altText: "Slide 1",
    },
    {
      src: PostImg2,
      altText: "Slide 2",
    },
  ];
  const handleChangee = (event) => {
    setText(event.target.value);
    // If the text is empty, set the height to its default value
    if (event.target.value === "") {
      event.target.style.height = "44px";
    } else {
      // Otherwise, adjust the height to fit the content
      event.target.style.height = "44px"; // Reset the height to auto to recalculate the height
      event.target.style.height = `${event.target.scrollHeight}px`; // Set the height to match the scroll height
    }
  };
  const [commentReplies, setCommentReply] = useState([]);

  const handleCommentToggle = (postId) => {
    setVisibleComments((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));

    if (!visibleComments[postId]) {
      getComments(postId);
    }
  };

  const handleCommentReplyToggle = (commentId) => {
    console.log("commentID", commentId);
    setVisibleReplyComments((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));

    if (!visibleReplyComments[commentId]) {
      console.log("HIIIIII");

      commentReply(commentId);
    }
  };

  const [activeIndex, setActiveIndex] = useState(0);
  const [replyId, setReplyId] = useState(null);
  const [filterText, setFilterText] = React.useState("");
  const [animating, setAnimating] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFile2, setSelectedFile2] = useState([]);
  const [selectedVideo, setSelectedVideoFile] = useState();
  const [selectedVideo2, setSelectedVideoFile2] = useState();
  const [fullLength, setFullLength] = useState(false);
  const [details, setDetails] = useState(false);
  const [descriptionClick, setPostClick] = useState(false);
  const [commenetedById, setCommentedById] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [fileName, setFileName] = useState("");
  const [contentTest, setreplyComment] = useState("");
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [showProgress, setShowProgress] = useState(false);


 useEffect(()=>{
  console.log(uploadPercentage,"uploadPercentage--->")
 },[uploadPercentage])
  useEffect(() => {
    console.log(descriptionClick, "descriptionClick");
  }, [descriptionClick]);
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === file?.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const handleVideoError = (error) => {
    console.error("Video Error:", error);
    // Handle error (show message to user, etc.)
  };

  const handleVideoEnd = (source) => {
    console.log("Video has ended", source);
    // Handle end of video (move to next video, etc.)
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? file?.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const handleReplyChange = (event) => {
    setreplyComment(event.target.value);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const handleReplyButtonClicked = (e, id) => {
    e.preventDefault();
    const reply_id = replyId;
    // const commented_by = commentList.commented_by;
    // setCommentedById(commented_by);
    if (reply_id === id) {
      setReplyId("");
      setReplyComment("");
    } else {
      setReplyId(id);
      setReplyComment();
      // `@[${capitalizeEveryWord(
      // commented_by?.first_name + " " + commented_by?.last_name
      // )}]
      // (${commented_by?._id})` + " "
    }
    // fetchCommentReplies(commentList._id);
  };

  const handleFileChange = (event) => {
    const files = event.target.files; // FileList object from the file input
    console.log(files, "Files uploaded");

    // Create an array to store the object URLs
    let imgArr = [];
    let backendImageArr = [];

    // Iterate over the FileList
    for (let i = 0; i < files?.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }
    setSelectedFile2(backendImageArr);
    // Set the array of object URLs to state
    setSelectedFile(imgArr);
  };

  // const handleVideoFileChange = (event) => {
  //   const files = event.target.files; // FileList object from the file input
  //   console.log(files, "Files uploaded");

  //   // Create an array to store the object URLs
  //   let videoArr = [];
  //   let backendVideoArr = [];

  //   // Iterate over the FileList
  //   for (let i = 0; i < files?.length; i++) {
  //     const file = files[i];
  //     const objectURL = URL.createObjectURL(file); // Create an object URL
  //     videoArr.push(objectURL);
  //     backendVideoArr.push(file); // Add the object URL to the array
  //   }
  //   setSelectedVideoFile2(backendVideoArr);
  //   // Set the array of object URLs to state
  //   setSelectedVideoFile(videoArr);
  // };

  const handleVideoFileChange = (event) => {
    console.log("Event", event.target.files[0]);
    const file = event.target.files[0];
    const objectURL = URL.createObjectURL(file);

    setSelectedVideoFile2(file);
    setSelectedVideoFile(objectURL);
    setFileName(event.target.files[0]?.name);
  };

  const getPosts = () => {
    setLoading(true);

    let data = {
      searchTerm: filterText,
    };

    dispatch(PostListAction(data))
      .then((res) => {
        setLoading(false);

        setPost(res?.data?.dataList);
        console.log(res?.data);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  const getComments = (postId) => {
    setIsAllComments(true);

    let data = {
      postId: postId,
    };
    setComment([]);
    dispatch(CommentListAction(data))
      .then((res) => {
        setComment(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deletePost = async (postId) => {
    console.log("POSTID", postId);
    const data = {
      postId: postId,
    };

    try {
      const res = await dispatch(AdminDELETEPOST(data));
      if (res.status === 0) {
        toast.error(res.msg, {
          position: "top-right",
        });
        setModal3(!modal3);
        setModal(!modal);
      } else {
        getPosts();
        setModal3(!modal3);
        // setModal(!modal);
        toast.success("Post deleted successfully", {
          position: "top-right",
        });
        // window.location.reload();
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
  };

  const commentReply = async (CommentId) => {
    const data = {
      parentId: CommentId,
    };

    try {
      const res = await dispatch(AdminFetchCommentReply(data));
      if (res.status === 0) {
        toast.error(res.msg, {
          position: "top-right",
        });
      } else {
        // toast.success("Comment fetched successfully", {
        //   position: "top-right",
        // });
        console.log("res.data", res.data);
        setCommentReply(res?.data);
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
  };

  const addLikePost = async (postId) => {
    console.log("IDDDDDDDDDDD", postId);
    const data = {
      postId: postId,
      type: "Post",
    };

    try {
      const res = await dispatch(AdminLikePOSTCOMMENT(data));
      if (res.status === 0) {
        toast.error(res.msg, {
          position: "top-right",
        });
        setModal(!toggle);
      } else {
        getPosts();
        setModal(!toggle);

        // window.location.reload();
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
  };

  const addPost = async () => {
    setButtonLoader(true);

    if (text2?.length > 0) {
      setPostClick(false);
    } else {
      //if data  do not exist
      setButtonLoader(false);

      setPostClick(true);
      return;
    }

    // var files = selectedFile2.concat(selectedVideo2);

    const formData = new FormData();

    selectedFile2?.forEach((val) => {
      formData.append("images", val);
    });
    formData.append("description", text2);

    if (formData != null) {
      dispatch(AdminAddPOST(formData))
        .then((res) => {
          console.log("ressss", res);
          if (res.status === 1) {
            console.log("res?.data?.data?._id", res?.data);
            if (selectedVideo2 != null) {
              UploadVideo(res?.data?._id);
              setButtonLoader(false);
              getPosts();
              setModal2(!modal2);
            } else {
              // setUploadPercentage(0)
              setButtonLoader(false);
              setModal2(!modal2);
              setText2("");
              getPosts();
              setSelectedFile2([]);
              setSelectedFile([]);
              setSelectedVideoFile();
              setSelectedVideoFile2();
            }

            // setButtonLoader(false);
            // toast.success("Post added successfully", {
            //   position: "top-right",
            // });
          } else {
            setButtonLoader(false);
            toast.error(res.msg, {
              position: "top-right",
            });
            setText2("");
            setModal2(!modal2);
            setSelectedFile2([]);
            setSelectedFile([]);
            setSelectedVideoFile();
            setSelectedVideoFile2();
          }
        })
        .catch((err) => {
          setButtonLoader(false);
          console.log("err======>", err);
          toast.error("Please provide all fields", {
            position: "top-right",
          });
        });
    }
  };



  const UploadVideo = async (postId) => {
    console.log("postID", postId);
    try {
      const param = {
        fileName: selectedVideo2?.name,
        fileType: selectedVideo2?.type,
        description: text2 ? text2 : null,
        postId: postId === undefined ? null : postId,
      };

      console.log("param--------UPLOADVIDEO", param);

      dispatch(PresignedURL(param))
        .then(async (res) => {
          if (res.status) {
            console.log(
              res?.data?.data?.postDetail?._id,
              "res.data -----UploadVideo",
              JSON.stringify(res?.data)
            );
            const PostID = res?.data?.postDetail?._id;
            const uploadUrl = res?.data?.url;
            console.log(uploadUrl, "woprking--uploadUrl");

            try {
              const formData = new FormData();

              formData.append("file", selectedVideo2);
              setShowProgress(true)
              const response =  await uploadWithProgress(uploadUrl, selectedVideo2, (progress) => {
                console.log(`Upload progress: ${progress.percent}%`);
                setUploadPercentage(progress.percent);
              })

              // const response = await fetch(uploadUrl, {
              //   method: "PUT",
              //   headers: {
              //     "Content-Type": selectedVideo2.type,
              //   },
              //   body: selectedVideo2,
              // }).then(fetchProgress({
              //   onProgress(progress){
              //     console.log("progress",progress);
              //   }
              // }));

              var heards= response.headers.trim().split('\r\n');
              let etag, AMZ, AMZREQ;
              heards?.forEach(header => {
                const [key, value] = header.split(': ');
                if (key === "etag") {
                    etag = value.slice(1, -1);
                } else if (key === "x-amz-id-2") {
                    AMZ = value;
                } else if (key === "x-amz-request-id") {
                    AMZREQ = value;
                }
            });
                  
                
              // });

              startM3u8fileprocess(etag, PostID, AMZ, AMZREQ);
            } catch (error) {
              console.error("Error:", error);
            }
          } else {
            console.log("res false status --", JSON.stringify(res));
          }
        })
        .catch((err) => {
          console.log(
            err,
            "error in fetchCommunity addPost",
            err?.response?.data
          );
        });
    } catch (error) {
      console.log("eroror ", error);
    }
  };

  const startM3u8fileprocess = async (Etag, PostID, AMZ, AMZREQ) => {
    console.log("working UploadVideo", selectedVideo2);
    try {
      const param = {
        key: selectedVideo2?.name,
        size: selectedVideo2?.size,
        eTag: Etag,
        videoId: PostID,
        "x-amz-id-2": AMZ,
        "x-amz-request-id": AMZREQ,
      };

      console.log(
        param,
        "formData---------startM3u8fileprocess",
        selectedVideo2
      );

      dispatch(CallFunction(param))
        .then((res) => {
          console.log("res", res);

          if (res.success == true) {
            setShowProgress(false)

            setButtonLoader(false);
            setModal2(!modal2);
            setText2("");
            getPosts();
            setSelectedFile2([]);
            setSelectedFile([]);
            setSelectedVideoFile();
            setSelectedVideoFile2();

            // route?.params?.serBarProgress(false);
          } else {
            setShowProgress(false)

            setButtonLoader(false);
            setModal2(!modal2);
          }
        })
        .catch((err) => {
          setShowProgress(false)

          // route?.params?.serBarProgress(false),
          console.log(
            err,
            "error in startM3u8fileprocess addPost",
            err?.response?.data
          );
        });
    } catch (error) {
      setShowProgress(false)

      console.log("eroror ", error);
    }
  };

  
  const addLikeComment = async (id, postId) => {
    const data = {
      commentId: id,
      type: "Comment",
    };

    try {
      const res = await dispatch(AdminLikePOSTCOMMENT(data));
      if (res.status === 0) {
        toast.error(res.msg, {
          position: "top-right",
        });
      } else {
        // toast.success("Comment added successfully", {
        //   position: 'top-right',
        // });
        getComments(postId);
        commentReply(id);
        // window.location.reload();
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
  };

  const addLikeCommentReply = async (id) => {
    const data = {
      commentId: id,
      type: "Comment",
    };

    try {
      const res = await dispatch(AdminLikePOSTCOMMENT(data));
      if (res.status === 0) {
        toast.error(res.msg, {
          position: "top-right",
        });
      } else {
        // toast.success("Comment added successfully", {
        //   position: 'top-right',
        // });
        commentReply(id);
        // window.location.reload();
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
  };

  const initialValues = {
    text: "",
  };

  const addComment = async (parentId, postId) => {
    console.log("parentId", parentId);
    const data = {
      parentId: parentId,
      content: contentTest,
      postId: postId,
    };

    try {
      const res = await dispatch(AdminAddComment(data));
      if (res.status === 0) {
        toast.error(res.msg, {
          position: "top-right",
        });
      } else {
        // toast.success("Comment added successfully", {
        //   position: 'top-right',
        // });
        commentReply(parentId);
        setreplyComment(" ");

        // window.location.reload();
      }
    } catch (err) {
      console.error(err, "ERROR");
    }
  };

  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    // validationSchema: addCommentValidationSchema,
    onSubmit: async (values) => {
      const data = {
        postId: id,
        content: values.text,
      };
      try {
        const res = await dispatch(AdminAddComment(data));
        if (res.status === 0) {
          toast.error(res.msg, {
            position: "top-right",
          });
        } else {
          // toast.success("Comment added successfully", {
          //   position: 'top-right',
          // });
          // getPosts();
          getComments(id);
          resetForm();

          // window.location.reload();
        }
      } catch (err) {
        console.error(err, "ERROR");
      }
    },
  });

  useEffect(() => {
    getPosts();
  }, []);




  return loading ? (
    <Instagram />
  ) : (
    <React.Fragment>
      <div className="search-addpost d-flex flex-wrap  flex-md-nowrap align-items-center justify-content-center gap-3 mb-3">
        <div className="searchpost position-relative w-100">
          {/* <Input name="search" placeholder="Search" type="text"   /> */}
          {/* <img
            src={SearchIcon}
            className="img-fluid position-absolute"
            alt=""
          /> */}
        </div>
        <Button color="primarypost" onClick={toggle2}>
          + Add Post
        </Button>
      </div>
      {/* {showProgress && <progress value={uploadPercentage}/>} */}

      {showProgress && <progress
      
      style={{ width: "100%", height: "5px", backgroundColor:"#B79B58" , borderRadius: "2px", overflow: "hidden" }}
      value={uploadPercentage / 100} max="1"/>}

      <div className="cardposts postscroll">
        {post?.map((item) => {
          const maxDisplayedFiles = 2;
          const displayedFiles = item?.files?.slice(0, maxDisplayedFiles);
          const additionalFiles =
            (item?.files?.length || 0) - maxDisplayedFiles;

          return (
            <>
              <Card className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="mediaUser">
                    <img
                      src={
                        item?.userProfile != null
                          ? IMAGE_URL + item?.userProfile
                          : profileimg
                      }
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                    <div className="link">
                      <h3>
                        {item?.userFirstName} {item?.userlastName}
                      </h3>
                      <h4>{formatTimeAgo(item?.createdAt)}</h4>
                    </div>
                  </div>
                  <UncontrolledDropdown
                    className="menuDostBox"
                    direction="start"
                  >
                    <DropdownToggle className="p-0 bg-transparent border-none border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M7 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0m7 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0m7 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                        />
                      </svg>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setModal3(!modal3);
                          setid(item._id);
                        }}
                      >
                        Delete
                      </DropdownItem>{" "}
                      {/* Corrected */}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                {item.description && (
                  <>
                    <p
                      className="post-description"
                      style={{ whiteSpace: "pre-wrap" }}
                      dangerouslySetInnerHTML={{
                        __html: fullLength
                          ? addSpanToWordsStartingWithHash(item?.description)
                          : addSpanToWordsStartingWithHash(
                              item?.description.slice(0, 400),
                              item?.description?.length > 400
                            ),
                      }}
                    ></p>

                    {item?.description?.length > 400 && (
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          if (post?.media?.length > 0) {
                            return setDetails(true);
                          } else {
                            setFullLength(true);
                          }
                        }}
                      >
                        {!fullLength && <p>Read more</p>}
                      </Link>
                    )}
                  </>
                )}
                {/* <p
                  className="post-description"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {item?.description}
                </p> */}
                {/* <div className="genre-post mb-2"><span># Flying</span><span># Aircraft</span></div> */}

                {item?.files?.length > 0 && (
                  <>
                    <div
                      className="collargeImage"
                      onClick={() => {
                        // getPostDetail(item?._id);

                        setFiles(item?.files);
                        setPostDetail(item);
                        toggle();
                      }}
                    >
                      {item?.files?.length === 1 && (
                        <div className="one-media">
                          {item?.files?.map((media, i) => {

                            return media?.fileType === "image" ? (
                              <div className="gallery-picture" key={i}>
                                <img
                                  src={IMAGE_URL + media?.path}
                                  className="img-fluid bimg pointer"
                                  alt="img"
                                />
                              </div>
                            ) : (
                              <div className="gallery-picture" key={i}>
                                <VideoPlayer
                                  key={i}
                                  src={media?.path}
                                  thumbnailUrl={media?.thumbnail != null ? media?.thumbnail :videoPlaceholder }
                                />
                              </div>
                            );
                          })}
                        </div>
                      )}
                      {item?.files?.length > 2 && (
                        <div className="more-media">
                          <div className="gallery-picture">
                            {item?.files[0]?.fileType === "image" ? (
                              <Image
                                src={IMAGE_URL + item?.files[0]?.path}
                                className="img-fluid pointer"
                                alt="image"
                                key={0}
                                // onClick={() => navigate(`/post?key=${post._id}`)}
                              />
                            ) : (
                              <VideoPlayer
                                // key={i}
                                src={item?.files[0]?.path}
                                thumbnailUrl={item?.files[0]?.thumbnail}
                              />

                              // <video
                              //   controls
                              //   className="img-fluid bimg pointer"
                              // >
                              //   <source
                              //     src={IMAGE_URL + item?.files[0]?.path}
                              //     type="application/x-mpegURL"
                              //     key={0}
                              //   />
                              //   Your browser does not support the video tag.
                              // </video>
                            )}
                          </div>
                          <div
                            className={
                              item?.files?.length === 3
                                ? "more-media_box mediaTwo"
                                : item?.files?.length === 4
                                ? "more-media_box mediaThree"
                                : "more-media_box"
                            }
                          >
                            {item?.files?.map((media, i) =>
                              media.fileType === "image" ? (
                                <>
                                  {i !== 0 && i < 5 && (
                                    <div className="gallery-picture" key={i}>
                                      <Image
                                        src={IMAGE_URL + media?.path}
                                        className="img-fluid pointer"
                                        alt="image"
                                      />

                                      {item?.files?.length > 5 && i === 4 && (
                                        <Link>+ More</Link>
                                      )}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {i !== 0 && i < 5 && (
                                    <div className="gallery-picture" key={i}>
                                      <VideoPlayer
                                        key={i}
                                        src={media?.path}
                                        thumbnailUrl={media?.thumbnail}
                                      />

                                      {item?.files?.length > 5 && i === 4 && (
                                        <Link>+ More...</Link>
                                      )}
                                    </div>
                                  )}
                                </>
                              )
                            )}
                          </div>
                        </div>
                      )}

                      {item?.files?.length === 2 && (
                        <div className="one-media">
                          {item?.files?.map((media, i) =>
                            media?.fileType === "video" ? (
                              <div className="gallery-picture" key={i}>
                                <VideoPlayer
                                  key={i}
                                  src={media?.path}
                                  thumbnailUrl={media?.thumbnail}
                                />
                                {/* <video
                                  controls
                                  className="img-fluid bimg pointer"
                                >
                                  <source
                                    src={IMAGE_URL + media.path}
                                    type="application/x-mpegURL"
                                  />
                                  Your browser does not support the video tag.
                                </video> */}
                              </div>
                            ) : (
                              <div className="gallery-picture" key={i}>
                                <Image
                                  src={IMAGE_URL + media.path}
                                  className="img-fluid bimg pointer"
                                  alt="image"
                                  // onClick={() => navigate(`/post?key=${post._id}`)}
                                />
                              </div>
                            )
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {/* 
                {item?.files?.length > 0 && (
                  <div className="collargeImage" onClick={()=>{
                    getPostDetail(item?._id)
                    setFiles(item?.files)
                    toggle()
                  }}>
                    <div className="more-media">
                      <div className="gallery-picture">
                        <img
                          src={
                            item?.files[0]?.thumbnail
                              ? IMAGE_URL + item?.files[0]?.thumbnail
                              : IMAGE_URL + item?.files[0]?.path
                          }
                          alt="image"
                          className="img-fluid"
                        />
                      </div>
                      {item?.files?.length > 1 &&
                        item.files.slice(1).map((file, index) => (
                          <div className="more-media_box" key={index}>
                            <div className="gallery-picture">
                              <img
                                src={
                                  file?.thumbnail
                                    ? IMAGE_URL + file?.thumbnail
                                    : IMAGE_URL + file?.path
                                }
                                alt={`File ${index}`}
                                className="img-fluid"
                              />
                              {additionalFiles > 0 && (
                                <div className="more-media_box">
                                  <span className="moretext">
                                    + {additionalFiles}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )} */}

                <div className="cardCount">
                  <div className="d-flex align-items-center me-3">
                    <Link
                      className="likebtn"
                      onClick={() => {
                        addLikePost(item._id);
                      }}
                    >
                      {/* {item?.userLiked === true ? ( */}
                      <Icon
                        style={{ margin: 0 }}
                        className="text-primary"
                        icon={
                          item?.userLiked
                            ? "tabler:thumb-up-filled"
                            : "tabler:thumb-up"
                        }
                        fontSize={24}
                      />
                    </Link>
                    <Link>
                      <span>{item?.likeCount}</span>
                    </Link>
                  </div>
                  {/* <Link className="dislikebtn me-3"><img src={Dislike} className="img-fluid" alt="" /> <span>1.6k</span></Link> */}
                  <Link
                    className="commentbtn me-3"
                    onClick={() => {
                      handleCommentToggle(item._id);
                    }}
                  >
                    <img src={Comment} className="img-fluid" alt="" />{" "}
                    <span>{item?.commentCount}</span>
                  </Link>
                </div>
                {visibleComments[item._id] && (
                  <>
                    <div className="replBox">
                      <img
                        src={
                          profileData?.profilePic != null
                            ? IMAGE_URL + profileData?.profilePic
                            : profileimg
                        }
                        alt=""
                        className="img-fluid"
                      />
                      <div className="mb-0 w-100 position-relative">
                        <Input
                          name="text"
                          placeholder="Write a comment..."
                          type="textarea"
                          value={values.text}
                          onChange={handleChange}
                          // onChange={handleChange}
                        />

                        <Link
                          className="commentbtn"
                          onClick={() => {
                            setid(item._id);
                            handleSubmit();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1em"
                            height="1em"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m22 2l-7 20l-4-9l-9-4Zm0 0L11 13"
                            />
                          </svg>
                        </Link>
                      </div>
                      <p className="input-error">{errors.text}</p>
                    </div>

                    {comment?.length > 0 &&
                      comment.map((item) => (
                        <div
                          className="aftContent"
                          style={{ marginLeft: "2.7em" }}
                          key={item._id}
                        >
                          <div className="d-flex gap-2">
                            <img
                              src={
                                item?.userProfile
                                  ? IMAGE_URL + item.userProfile
                                  : profileimg
                              }
                              alt=""
                              className="img-fluid"
                            />
                            <div className="comBox">
                              <div className="repCommnet d-inline-flex align-items-start w-auto justify-content-between">
                                <div className="w-100 pe-3">
                                  <h3>
                                    {item.userFirstName} {item.userlastName}
                                  </h3>
                                  <p>{item.content}</p>
                                </div>
                                <UncontrolledDropdown
                                  className="menuDostBox"
                                  direction="start"
                                >
                                  <DropdownToggle className="p-0 bg-transparent border-none border-0 d-flex">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        fill="currentColor"
                                        d="M7 12a2 2 0 1 1-4 a2 2 0 0 1 4 m7 0a2 2 0 1 1-4 a2 2 0 0 1 4 a2 2 0 1 1 4 0a2 2 a2 2 0a2 2 0 0 1 4 0"
                                      />
                                    </svg>
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem>Delete</DropdownItem>
                                  </DropdownMenu>
                                </UncontrolledDropdown>
                              </div>
                              <div className="replyBox mt-2">
                                <div className="d-flex align-items-center me-2">
                                  <Link
                                    className="likebtn"
                                    onClick={() => {
                                      addLikeComment(item?._id, item?.postId);
                                    }}
                                  >
                                    <Icon
                                      style={{ margin: 0 }}
                                      className="text-primary"
                                      icon={
                                        item?.userLiked
                                          ? "tabler:thumb-up-filled"
                                          : "tabler:thumb-up"
                                      }
                                      fontSize={24}
                                    />
                                  </Link>
                                  <Link>
                                    <span>{item.likeCount}</span>
                                  </Link>
                                  <Icon icon="mdi:dot" fontSize={24} />
                                </div>
                                <Link
                                  onClick={(e) => {
                                    handleCommentReplyToggle(item._id);
                                  }}
                                >
                                  <Icon icon="carbon:reply" fontSize={24} />
                                  <span>{item?.repliesCount}</span>
                                </Link>

                                <Icon icon="mdi:dot" fontSize={24} />
                                <span>{formatTimeAgo(item.createdAt)}</span>
                              </div>
                              {visibleReplyComments[item._id] && (
                                <>
                                  <div className="replBox">
                                    <img
                                      src={
                                        profileData?.profilePic != null
                                          ? IMAGE_URL + profileData?.profilePic
                                          : profileimg
                                      }
                                      alt=""
                                      className="img-fluid"
                                    />
                                    <div className="mb-0 w-100 position-relative">
                                      <Input
                                        name="text"
                                        placeholder="Write a comment..."
                                        type="textarea"
                                        value={contentTest}
                                        onChange={handleReplyChange}
                                      />

                                      <Link
                                        className="commentbtn"
                                        onClick={() => {
                                          addComment(item._id, item?.postId);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="1em"
                                          height="1em"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="none"
                                            stroke="currentColor"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="2"
                                            d="m22 2l-7 20l-4-9l-9-4Zm0 0L11 13"
                                          />
                                        </svg>
                                      </Link>
                                    </div>
                                    <p className="input-error">{errors.text}</p>
                                  </div>
                                  {commentReplies?.length > 0 &&
                                    commentReplies.map((item) => (
                                      <div
                                        className="aftContent"
                                        style={{ marginLeft: "2.7em" }}
                                        key={item._id}
                                      >
                                        <div className="d-flex gap-2">
                                          <img
                                            src={
                                              item?.userProfile
                                                ? IMAGE_URL + item.userProfile
                                                : profileimg
                                            }
                                            alt=""
                                            className="img-fluid"
                                          />
                                          <div className="comBox">
                                            <div className="repCommnet d-inline-flex align-items-start w-auto justify-content-between">
                                              <div className="w-100 pe-3">
                                                <h3>
                                                  {item.userFirstName}{" "}
                                                  {item.userlastName}
                                                </h3>
                                                <p>{item.content}</p>
                                              </div>
                                              <UncontrolledDropdown
                                                className="menuDostBox"
                                                direction="start"
                                              >
                                                <DropdownToggle className="p-0 bg-transparent border-none border-0 d-flex">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="1em"
                                                    height="1em"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <path
                                                      fill="currentColor"
                                                      d="M7 12a2 2 0 1 1-4 a2 2 0 0 1 4 m7 0a2 2 0 1 1-4 a2 2 0 0 1 4 a2 2 0 1 1 4 0a2 2 a2 2 0a2 2 0 0 1 4 0"
                                                    />
                                                  </svg>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                  <DropdownItem>
                                                    Delete
                                                  </DropdownItem>
                                                </DropdownMenu>
                                              </UncontrolledDropdown>
                                            </div>
                                            <div className="replyBox mt-2">
                                              <div className="d-flex align-items-center me-2">
                                                <Link
                                                  className="likebtn"
                                                  onClick={() => {
                                                    addLikeCommentReply(
                                                      item?._id
                                                    );
                                                  }}
                                                >
                                                  <Icon
                                                    style={{ margin: 0 }}
                                                    className="text-primary"
                                                    icon={
                                                      item?.userLiked
                                                        ? "tabler:thumb-up-filled"
                                                        : "tabler:thumb-up"
                                                    }
                                                    fontSize={24}
                                                  />
                                                </Link>
                                                <Link>
                                                  <span>{item.likeCount}</span>
                                                </Link>
                                                <Icon
                                                  icon="mdi:dot"
                                                  fontSize={24}
                                                />
                                              </div>
                                              <Link
                                                onClick={(e) => {
                                                  handleCommentReplyToggle(
                                                    item._id
                                                  );
                                                }}
                                              >
                                                {/* <Icon icon="carbon:reply" fontSize={24} />
                              <span>{item?.repliesCount}</span> */}
                                              </Link>

                                              <span>
                                                {formatTimeAgo(item.createdAt)}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                  </>
                )}
              </Card>
            </>
          );
        })}
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        size="xl"
        className="modal_post_detail"
      >
        <ModalBody className="p-0">
          <Link onClick={closetoggle} className="closeposticon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 21a9 9 0 1 1 9-9a9 9 0 0 1-9 9m0-16.5a7.5 7.5 0 1 0 7.5 7.5A7.5 7.5 0 0 0 12 4.5"
              />
              <path
                fill="currentColor"
                d="M9 15.75a.74.74 0 0 1-.53-.22a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 1.06l-6 6a.74.74 0 0 1-.53.22"
              />
              <path
                fill="currentColor"
                d="M15 15.75a.74.74 0 0 1-.53-.22l-6-6a.75.75 0 0 1 1.06-1.06l6 6a.75.75 0 0 1 0 1.06a.74.74 0 0 1-.53.22"
              />
            </svg>
          </Link>
          <Row className="gx-0 cardposts pt-0">
            <Col lg="12" xl="8" className="detailimgleft maxheight80vh">
              <Carousel
                className="h-100"
                indicators={false}
                interval={null}
                controls={file?.length > 1 ? true : false}
              >
             {file?.length > 0 &&
 file?.map((media, index) => {
  return (
    <Carousel.Item className="h-100" key={index}>
      <div
        className="h-100 w-100 d-flex align-items-center justify-content-center"
        style={{ background: "#1b1f22" }}
      >
        {media?.fileType === "image" && (
          <Image
            src={IMAGE_URL + media?.path}
            className="img-fluid"
            style={{
              objectFit: "contain",
              maxHeight: "100%",
            }}
            alt=""
          />
        )}
        {media?.fileType === "video" && (
          <VideoPlayer
            src={media?.path}
            onError={handleVideoError}
            onEnd={handleVideoEnd}
          />
        )}
      </div>
    </Carousel.Item>
  );
})
}

              </Carousel>
            </Col>
            <Col lg="12" xl="4" className=" detailcontentright maxheight80vh">
              <Card className="p-3 border-0 shadow-none rounded-0 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div className="mediaUser">
                    <img
                      src={
                        postDetail?.userProfile
                          ? IMAGE_URL + postDetail.userProfile
                          : profileimg
                      }
                      alt=""
                      className="img-fluid rounded-circle"
                    />
                    <div className="link">
                      <h3>
                        {postDetail?.userFirstName} {postDetail?.userlastName}
                      </h3>
                      <h4>{formatTimeAgo(postDetail?.createdAt)}</h4>
                    </div>
                  </div>
                  <UncontrolledDropdown
                    className="menuDostBox pe-2"
                    direction="start"
                  >
                    <DropdownToggle className="p-0 bg-transparent border-none border-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="currentColor"
                          d="M7 12a2 2 0 1 1-4 0a2 2 0 0 1 4 0m7 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0m7 0a2 2 0 1 1-4 0a2 2 0 0 1 4 0"
                        />
                      </svg>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={() => {
                          setModal3(!modal3);
                          setid(postDetail?._id);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="detailcontentrightinner">
                  <p
                    className="post-description"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    {postDetail?.description}{" "}
                  </p>
                  {/* <div className="genre-post mb-2"><span># Flying</span><span># Aircraft</span></div> */}
                  <div className="cardCount">
                    <div className="d-flex align-items-center me-3">
                      <Link
                        className="likebtn"
                        onClick={() => {
                          addLikePost(postDetail._id);
                        }}
                      >
                        {/* {item?.userLiked === true ? ( */}
                        <Icon
                          style={{ margin: 0 }}
                          className="text-primary"
                          icon={
                            postDetail?.userLiked
                              ? "tabler:thumb-up-filled"
                              : "tabler:thumb-up"
                          }
                          fontSize={24}
                        />
                      </Link>
                      <Link>
                        <span>{postDetail?.likeCount}</span>
                      </Link>
                    </div>

                    <Link
                      className="commentbtn me-3"
                      // onClick={handleCommentToggle(postDetail?._id)}
                    >
                      <img src={Comment} className="img-fluid" alt="" />{" "}
                      <span>{postDetail?.commentCount}</span>
                    </Link>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={modal2} toggle={toggle2} centered className="postmodal">
        <ModalHeader>
          <span>Add New Post</span>{" "}
          <Link onClick={closemodal2} className="closeposticon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M12 21a9 9 0 1 1 9-9a9 9 0 0 1-9 9m0-16.5a7.5 7.5 0 1 0 7.5 7.5A7.5 7.5 0 0 0 12 4.5"
              />
              <path
                fill="currentColor"
                d="M9 15.75a.74.74 0 0 1-.53-.22a.75.75 0 0 1 0-1.06l6-6a.75.75 0 0 1 1.06 1.06l-6 6a.74.74 0 0 1-.53.22"
              />
              <path
                fill="currentColor"
                d="M15 15.75a.74.74 0 0 1-.53-.22l-6-6a.75.75 0 0 1 1.06-1.06l6 6a.75.75 0 0 1 0 1.06a.74.74 0 0 1-.53.22"
              />
            </svg>
          </Link>
        </ModalHeader>
        <ModalBody className="p-3">
          <FormGroup>
            <Input
              name="addpost"
              rows="6"
              placeholder="What’s on your mind ?"
              type="textarea"
              value={text2}
              onChange={handleTextareaChange}
            />
            {descriptionClick && text2 == "" ? (
              <p style={{ color: "red" }}>
                Please add description to your post
              </p>
            ) : (
              <></>
            )}
          </FormGroup>
          {/* <div
        className="tagged-content"
        dangerouslySetInnerHTML={{ __html: taggedText }}
      ></div> */}
          <FormGroup className="form-group mb-4 postimgvideo">
            <label for="uploadimg">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 512 512"
              >
                <rect
                  width="416"
                  height="352"
                  x="48"
                  y="80"
                  fill="none"
                  stroke="currentColor"
                  stroke-linejoin="round"
                  stroke-width="32"
                  rx="48"
                  ry="48"
                />
                <circle
                  cx="336"
                  cy="176"
                  r="32"
                  fill="none"
                  stroke="currentColor"
                  stroke-miterlimit="10"
                  stroke-width="32"
                />
                <path
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="32"
                  d="m304 335.79l-90.66-90.49a32 32 0 0 0-43.87-1.3L48 352m176 80l123.34-123.34a32 32 0 0 1 43.11-2L464 368"
                />
              </svg>
            </label>
            <label for="uploadvideo" className="mx-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
              >
                <g
                  fill="none"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                >
                  <rect width="20" height="16" x="2" y="4" rx="4" />
                  <path d="m15 12l-5-3v6z" />
                </g>
              </svg>
            </label>
            <Input
              onChange={handleFileChange}
              type="file"
              multiple
              id="uploadimg"
              className="d-none"
              accept="image/jpeg,image/png,image/gif"
            />
            <Input
              onChange={handleVideoFileChange}
              type="file"
              id="uploadvideo"
              className="d-none"
              accept="video/mp4"
            />
            {selectedFile && (
              <div className="imagesDel">
                <div class="imgItems">
                  {/* <Link className="deleteimg">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <rect
                        x="0.985107"
                        width="19.0448"
                        height="20"
                        rx="6"
                        fill="#B79B58"
                      />
                      <path
                        d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link> */}
                  {selectedFile?.map((val) => {
                    return <img src={val} className="img-fluid" alt="" />;
                  })}
                </div>
                {/* <div class="imgItems">
                <Link className="deleteimg">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                    <rect
                      x="0.985107"
                      width="19.0448"
                      height="20"
                      rx="6"
                      fill="#B79B58"
                    />
                    <path
                      d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
                <svg
                  className="playIconVideo"
                  xmlns="http://www.w3.org/2000/svg"
                  width="1em"
                  height="1em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m-2 14.5l6-4.5l-6-4.5z"
                  />
                </svg>
                <img src={PostImg3} className="img-fluid" alt="" />
              </div> */}
              </div>
            )}

            {selectedVideo && (
              <div className="imagesDel">
                <div class="imgItems">
                  {/* <Link className="deleteimg">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none">
                      <rect
                        x="0.985107"
                        width="19.0448"
                        height="20"
                        rx="6"
                        fill="#B79B58"
                      />
                      <path
                        d="M14.1259 6.99976L7.65063 13.7998M7.65063 6.99976L14.1259 13.7998"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </Link> */}

                  <video className="img-fluid bimg pointer" alt="">
                    <source src={selectedVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
            )}
          </FormGroup>
          <Button className="primarybutton" color="" onClick={addPost}>
            {buttonLoader ? <PageLoader /> : "Post Now"}
          </Button>
        </ModalBody>
      </Modal>
      <Modal centered isOpen={modal3} toggle={() => setModal3(!modal3)}>
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure ?</h2>
            <p>
              You want to delete this record. You will not be able to recover
            </p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle3}>
                Cancel
              </Button>
              <Button className="btn-ok" onClick={() => deletePost(id)}>
                Proceed
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default Post;
