import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import defaultimg from '../../../assets/image/default.jpg'
import clockicon from "../../../assets/image/clock_icon.svg";
import locationicon from "../../../assets/image/location.svg";
import calendaricon from "../../../assets/image/calendar1.svg";
import titleicon from "../../../assets/image/eventitle.svg";
import { useDispatch } from "react-redux";
import { AdminEditEventDetail, AdminFetchEventDetail } from "../../../Redux/Actions/ManagementAction";
import { IMAGE_URL } from "../../../constant/Endpoints";
import profileimg from "../../../assets/image/default.jpg";
import moment from "moment";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import PageLoader from "../../customcomponent/loader";

const Editevent = (args) => {
  const autocompleteRef = useRef(null);
  var googleKey = process.env.REACT_APP_GOOGLE_PLACE_KEY

  const [loading, setLoading] = useState(false);

  const params = useParams()
  const dispatch = useDispatch()

  let id = params.id;
  const today = new Date();


  

    const defaultStartTime = new Date();
    defaultStartTime.setHours(9);
    defaultStartTime.setMinutes(0);
    const defaultEndTime = new Date();
    defaultEndTime.setHours(17);
    defaultEndTime.setMinutes(0);
  
  const [selectedDate, setSelectedDate] = useState();
  const [dispatchTime, setDispatchTime] = useState();
  // const [dispatchTime2, setDispatchTime2] = useState(defaultEndTime);
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [location, setLocation] = useState();
  const [selectedFile, setSelectedFile] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {

    setDispatchTime(time);

  };

  // const handleTimeChange2 = (time) => {
  //   setDispatchTime2(time);
  // };

  const formatTimeForBackend = (date) => {
    if (date instanceof Date && !isNaN(date.getTime())) {
      return moment(date).format('h:mm A'); // Converts to 12-hour format with AM/PM
    } else {
      console.error("Invalid Date object");
      return null;
    }
  };

  const formatSelectedDate = (date) => {
    return moment(date).format("YYYY-MM-DD"); 
  };


  const EditEvent = () => {

    const formattedTime = formatTimeForBackend(dispatchTime);
    console.log("formattedTime",formattedTime)
    const formData = new FormData();
    formData.append("eventId", id)
    formData.append("description", description)
    formData.append("place",location)
    formData.append("title",title)
    formData.append("eventDate", formatSelectedDate(selectedDate))
    formData.append("eventTime", formattedTime)
    formData.append("eventFile", selectedFile)
    setLoading(true)

    dispatch(AdminEditEventDetail(formData)).then((res) => {
      if (res.status === 1) {
        setLoading(false)

        toast.success("Event updated successfully", {
          position: 'top-right',
        });      
      }else{
        setLoading(false)

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }     
    }).catch((err) => {
      setLoading(false)

      toast.error(err, {
        position: 'top-right',
      });  
            console.log(err)
    })

  }

  const handleFileChange = (event) => {
    let a = event.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a)
    setSelectedFile(event.target.files[0]);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };
  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add form submission logic here
  };
  const [eventDetail, setEventDetail] = useState()

  function parseTime(timeStr) {
    const [time, modifier] = timeStr.split(" "); // Split into time and AM/PM
    let [hours, minutes] = time.split(":").map(Number); // Split into hours and minutes
    if (modifier === "PM" && hours < 12) {
      hours += 12; // Convert PM hours to 24-hour format
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0; // Convert 12 AM to 0 hours
    }
    // Construct a new Date with the time
    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Set hours, minutes, and reset seconds and milliseconds
    return date;
  }

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const fetchEventDetail = () => {
    let data = {
      eventId: id
    }
    dispatch(AdminFetchEventDetail(data)).then(res => {
      console.log("data-RES---------",res)
      setEventDetail(res?.data?.eventDetail)
      setTitle(res?.data?.eventDetail?.title)
      setDescription(res?.data?.eventDetail?.description)
      setLocation(res?.data?.eventDetail?.place)

      const date = moment.utc(res?.data?.eventDetail?.eventDate)
      const eventDate = date.local();

      setSelectedDate(eventDate.toDate())
     
      const eventTime =res?.data?.eventDetail?.eventTime;
      const parsedTime = parseTime(eventTime);
      console.log("parsedTime",parsedTime)
      setDispatchTime(parsedTime); 

    }).catch(err => {
      console.log(err)
    })
  }

  


  useEffect(() => {
    var input = document.querySelector('.location');

  loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`, () => {
    const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, input);
    
    autocomplete.addListener('place_changed', () => {
      console.log("autocomaplete",autocomplete)
      const place = autocomplete.getPlace();
      console.log("place",place)
      if (place.address_components) {
        handleLocationChange({ target: { name: 'location', value: place.formatted_address } });
      }
    });
  });

    fetchEventDetail()

  }, [])


  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit Event</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Events"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img className="useravatarimg" src={selectedFile ? URL.createObjectURL(selectedFile) : eventDetail?.eventImage ?
                             `${IMAGE_URL}${eventDetail?.eventImage}`
                            : profileimg} alt="" />
                  </div>
                  <Button
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                    id="change-img"
                  >
                    <span className="d-block">Update Picture</span>
                    <input type="file" hidden accept="image/*" onChange={handleFileChange} />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <Label for="title" className="formgroup_label">Title</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={titleicon} alt="" />
                </div>
                <Input
                  id="title"
                  name="title"
                  placeholder="Enter title"
                  value={title}
                  onChange={handleTitleChange}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <Label for="place" className="formgroup_label">Place</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={locationicon} alt="" />
                </div>
                <Input
                  className="location"
                 name="location"
                 placeholder="Enter place"
                 value={location}
                 onChange={handleLocationChange}
                 type="text"
                 innerRef={autocompleteRef}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <Label for="date" className="formgroup_label">Date</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="Select date"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  minDate={today} 

                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <Label for="time" className="formgroup_label">Event Time</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={dispatchTime}
                  onChange={handleTimeChange}
                  placeholderText="Enter event time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={10}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                /></FormGroup>
            </Col>
            {/* <Col md="12" lg="6">
              <Label for="time" className="formgroup_label">End Time</Label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={clockicon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={dispatchTime2}
                  onChange={handleTimeChange2}
                  placeholderText="Enter end time"
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                /></FormGroup>
            </Col> */}
            <Col md="12" lg="12">
              <Label for="description" className="formgroup_label">Description</Label>
              <FormGroup className="form-group">
                <Input type="textarea" name="description"  rows="5" placeholder="Enter description"  value={description} onChange={handleDescriptionChange}/>
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-2">
            <Button type="submit" className="primarybutton maxwidth-555"
             onClick={() => {
              EditEvent()
            }}>
               {loading ? <PageLoader /> : "Update"}  
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />

    </>
  );
};

export default Editevent;
