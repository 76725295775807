import React, { useState } from "react"
import SearchIcon from '../../../assets/image/search.svg';
import {
  Row,
  Col,
  Input,
  Button,
} from "reactstrap"
import { Link } from "react-feather";

const FilterComponent = ({ filterText, onFilter }) => {
  return (
    <>
    <Row>
      <Col xl="6" md="6" xs="6" className="mb-1">
        {/* Empty column */}
      </Col>
      
      <Col xl="6" md="6" xs="6" className="text-end mb-1 align-self-end">
        <div className="filtersearch_div ms-auto" style={{ maxWidth: '300px' }}>
          <Input
            id="search"
            type="text"
            placeholder="Search..."
            value={filterText}
            onChange={onFilter}
            style={{ height: '42px', display: 'inline-block', marginRight: '10px' }}
          />
          <img className="searchiconbtn" src={SearchIcon} alt="" style={{ cursor: 'pointer' }} />
        </div>
      </Col>
  
    </Row>
  </>
  
  );
};

export default FilterComponent;
