import { combineReducers } from 'redux';
import { legacy_createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'
import AsyncStorage from '@react-native-async-storage/async-storage';
import OnBoardingReducer from './OnBoardingReducer';
import { configureStore } from '@reduxjs/toolkit';
// import UserDataReducer from './UserDataReducer';
// import HomeReducer from './HomeReducer';
// import MyProfileReducer from './MyProfileReducer';
// import ContractServicesReducer from './ContractServicesReducer';
// import ChatReducer from './ChatReducer';

const appReducer = combineReducers({
  onBoardingReducer: OnBoardingReducer
  //   UserDataReducer: UserDataReducer,
  //   HomeReducer: HomeReducer,
  //   MyProfileReducer: MyProfileReducer,
  //   contractService: ContractServicesReducer,
  //   ChatReducer:ChatReducer
});

export const rootReducer = (state, action) => {
  // if (action.type === LOGOUT) {
  //   state = undefined;
  //   return appReducer(state, action);
  // }
  return appReducer(state, action);
}; 
// console.log('persistConfig---', persistConfig);
 
// const store = legacy_createStore(rootReducer, {}, applyMiddleware(ReduxThunk,logger));
const store =  configureStore({
  reducer:rootReducer
})
// const persistor = persistStore(store);

export { store };








