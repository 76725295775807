import React from 'react';
import {
  Container,
} from 'reactstrap';
const Footer = () => {
  return (
    <div className='footer'>
        <Container className='containercustom'>
           <p>© Copyright 2023 Inflight Pilot Training. All Rights Reserved</p>
      </Container>
    </div>
  );
}

export default Footer;
