// import React, { useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { Link } from "react-router-dom";
// import {
//   Button,
//   Col,
//   Label,
//   Form,
//   FormGroup,
//   Input,
//   Row,
// } from "reactstrap";
// import defaultimg from '../../../assets/image/default.jpg'
// import calendaricon from "../../../assets/image/profilecalendar-1.svg";
// import cameraicon from "../../../assets/image/profilecamera-1.svg";
// import emailicon from "../../../assets/image/profileemail-1.svg";
// import licenseicon from "../../../assets/image/profilelicense-1.svg";
// import phoneicon from "../../../assets/image/profilephone-1.svg";
// import usericon from "../../../assets/image/profileuser-1.svg";
// import FlyingIcon from "../../../assets/image/flying.svg";
// import { X, Plus } from 'react-feather'
// import locationicon from "../../../assets/image/profilelocation-1.svg";
// import gendericon from "../../../assets/image/profilegender-1.svg";
// import statusicon from "../../../assets/image/status-icon.svg";
// import Select from 'react-select'
import React, { useState } from "react";
import {
  Row,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import usericon from "../../../assets/image/profileuser-1.svg";
import emailicon from "../../../assets/image/profileemail-1.svg";
import phoneicon from "../../../assets/image/profilephone-1.svg";
import profileimg from "../../../assets/image/default.jpg";
import passwordicon from '../../../assets/image/password.svg';
import eyehideicon from '../../../assets/image/eye-hide.svg';
import eyeshowicon from '../../../assets/image/eye-show.svg';
import { useDispatch } from "react-redux";
import { AdminCreateAction } from "../../../Redux/Actions";
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from 'formik';
import { addAdministratorValidationSchema } from "../../../validationSchema/index";
import PageLoader from "../../customcomponent/loader";

const AircraftType = [
  { value: 'Full Flight Simulator (FFS)', label: 'Full Flight Simulator (FFS)', color: '#00B8D9', isFixed: true },
  { value: 'Flight Training Device (FTD)', label: 'Flight Training Device (FTD)', color: '#00B8D9', isFixed: false },
  { value: 'Advanced Aircraft Training Device (AATD)', label: 'Advanced Aircraft Training Device (AATD)', color: '#00B8D9', isFixed: false },
  { value: 'Basic Aviation Training Device (BATD)', label: 'Basic Aviation Training Device (BATD)', color: '#00B8D9', isFixed: false },
  { value: 'Simulator Uncertified', label: 'Simulator Uncertified', color: '#00B8D9', isFixed: false },
]
const orderOptions = values => {
  if (values.length > 0) return values.filter(v => v.isFixed).concat(values.filter(v => !v.isFixed))
}
const Addadministrator = (args) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [loading, setLoading] = useState(false);

  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [phoneno, setPhoneno] = useState('');
  const [inputemail, setInputemail] = useState('');
  const [flyingexperience, setFlyingexperience] = useState('');
  const [flightrate, setFlightrate] = useState('');
  const [groundrate, setGroundrate] = useState('');
  const [location, setLocation] = useState('');
  const [status, setStatus] = useState('select');
  const [fixedValue, setFixedValue] = useState('')
  const fixedOnChange = (value, { action, removedValue }) => {
    switch (action) {
      case 'remove-value':
      case 'pop-value':
        if (removedValue.isFixed) {
          return
        }
        break
      case 'clear':
        value = AircraftType.filter(v => v.isFixed)
        break
      default:
        break
    }

    value = orderOptions(value)
    setFixedValue(value)
  }

  const [inputFields, setInputFields] = useState([{
    dayfield: '',
  }]);

  const navigate = useNavigate()


  const addInputField = () => {
    setInputFields([...inputFields, {
      dayfield: '',
    }])

  }
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  }
  const handleChangee = (index, evnt) => {

    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  }


  const [profile, setProfile] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    phoneno: '',
    inputemail: '',
    showPassword: false,
    profilePhoto: null,
    inputPassword: ''
    // other profile data...
  });

  const dispatch = useDispatch()

  const handleUpdateProfilePhoto = (newProfilePhoto) => {
    setProfile({ ...profile, profilePhoto: newProfilePhoto });
  };

  const handleFileChange = (event) => {
    let a = event.target.files[0] ////SEND BACKEND
    let b = URL.createObjectURL(a)
    setProfile({ ...profile, profilePhoto: event.target.files[0] });
  };

  const togglePasswordVisibility = () => {
    setProfile({ ...profile, showPassword: !profile.showPassword });
  };

  const initialValues = {
    email: '',
    password: '',
    phoneno: '',
    username: '',
    firstname:''
  }


  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    validationSchema: addAdministratorValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      // try{
          const formData = new FormData()
    formData.append('firstName', values.firstname)
    formData.append('lastName', profile.lastname)
    formData.append('email', values.email)
    formData.append('phone_number', values.phoneno)
    formData.append('password', values.password)
    formData.append('userName', values.username)
    formData.append('profile',profile.profilePhoto)

    dispatch(AdminCreateAction(formData)).then(res => {
      if (res.status === 1) {
        setLoading(false)

        toast.success("Admin added successfully", {
          position: 'top-right',
        });          
        
      }else{
        setLoading(false)

          toast.error(res.msg, {
          position: 'top-right',
        });  
      }     
       navigate(-1)
    }).catch(err => {
      setLoading(false)

      toast.error("Please provide all fields", {
        position: 'top-right',
      });  
    })
  },
});

  return (
    <>
      <div className="instructorpanel">
        <h2 class="headingmain  mb-3">Add Admin</h2>
        <Col xl="12" md="6" xs="6" className="text-end">
          <Link to="/Administrator"><Button className="btn-add">Back</Button></Link>
       </Col>
        <Form onSubmit={handleSubmit}>
          <Row>
          
            <Col md="12" lg="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img className="useravatarimg" src={profile.profilePhoto ? URL.createObjectURL(profile.profilePhoto) : profileimg} alt="PROFILE"

                      style={{ objectFit: 'revert' }}
                    />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  // onClick={handleUpdateProfilePhoto}
                  >
                    <span className="d-block">Update Picture</span>
                    <input type="file" hidden id="change-img" accept="image/*"
                      onChange={handleFileChange}


                    />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">User Name</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="username"
                  placeholder="User Name"
                  // value={profile.username}
                  // onChange={(e) => setProfile({ ...profile, username: e.target.value })}
                  // onChange={(e) => setUsername(e.target.value)}
                  value={values.username}
                  onChange={handleChange}
                  className="input-error"
                  type="text"
                />
                </div>

              </FormGroup>
              <p className="input-error">{errors.username}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">First Name</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="firstname"
                  placeholder="First Name"
                  value={values.firstname}
                  onChange={handleChange}
                  // onChange={(e) => setFirstname(e.target.value)}
                  // onChange={(e) => setProfile({ ...profile, firstname: e.target.value })}
                  className="input-error"
                  type="text"
                />
                    </div>
              </FormGroup>
              <p className="input-error">{errors.firstname}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Last Name</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="lname"
                  placeholder="Last Name"
                  value={profile.lastname}
                  onChange={(e) => setProfile({ ...profile, lastname: e.target.value })}
                  // onChange={(e) => setLastname(e.target.value)}
                  type="text"
                />
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Email Address</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={emailicon} alt="" />
                </div>
                <Input
                  name="email"
                  placeholder="Email Address"
                  // value={profile.inputemail}
                  // onChange={(e) => setProfile({ ...profile, inputemail: e.target.value })}
                  // onChange={(e) => setInputemail(e.target.value)}
                  value={values.email}
                  onChange={handleChange}
                  className="input-error"
                  type="email"

                />
                </div>
              </FormGroup>
              <p className="input-error">{errors.email}</p>

            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Phone No.</label>
              <FormGroup className="form-group">
              <div className="position-relative">
                <div className="iconleft">
                  <img src={phoneicon} alt="" />
                </div>
                <Input
                  name="phoneno"
                  placeholder="Phone No"
                  // value={profile.phoneno}
                  // onChange={(e) => setProfile({ ...profile, phoneno: e.target.value })}
                  // onChange={(e) => setPhoneno(e.target.value)}
                  value={values.phoneno}
                  onChange={handleChange}
                  className="input-error"
                  type="tel"
                  
                />
                </div>

              </FormGroup>
              <p className="input-error">{errors.phoneno}</p>

            </Col>
          </Row>
          <Col md="12" lg="6">
            <label className="formgroup_label">Password</label>
            <FormGroup className="form-group">
            <div className="position-relative">
              <div className="iconleft">
                <img src={passwordicon} alt="" />
              </div>
              <Input
                name="password"
                placeholder="Password"
                type={profile.showPassword ? 'text' : 'password'}
                // value={profile.inputPassword}
                // onChange={(e) => setProfile({ ...profile, inputPassword: e.target.value })}
              // onChange={(e) => setPassword(e.target.value)}
              value={values.password}
              onChange={handleChange}
              className="input-error"
              />
              </div>

              <div className="iconright" onClick={togglePasswordVisibility}>
                {profile.showPassword ? <img src={eyeshowicon} alt="" /> : <img src={eyehideicon} alt="" />}
              </div>
            </FormGroup>
            <p className="input-error">{errors.password}</p>

          </Col>


          <div className="text-center mt-5">
            <Button
              // onClick={() => {
              //   addAdmin()
              // }}
              className="primarybutton  maxwidth-555">
               {loading ? <PageLoader /> : "Add"}
                                            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
 
  );
};

export default Addadministrator;
