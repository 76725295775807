import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import JoditEditor from 'jodit-react';
import statusicon from "../../../assets/image/status-icon.svg";
import pageicon from "../../../assets/image/pagefield.svg";
import { useDispatch } from "react-redux";
import { EditManagementAction, SingleManagementAction } from "../../../Redux/Actions/ManagementAction";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const Editpage = (args) => {
  const [firstname, setFirstname] = useState('');
  const [status, setStatus] = useState()
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const config = {
    statusbar: false,
    placeholder: 'Enter Here...',
  }
  const dispatch = useDispatch()
  const [list, setList] = useState([])
  const params = useParams()
  const navigate = useNavigate()

  const listing = () => {
    const type = params.cms_type;
    let data = {
      cmsType: type
    }

    dispatch(SingleManagementAction(data)).then(res => {
      setList(res?.data)
      setFirstname(res?.data?.title)
      setContent(res?.data?.content)
      setStatus(res?.data?.status)
      console.log(res)
    }).catch(err => {
      console.log(err)
    })
  }


  useEffect(() => {
    listing()
  }, [])


  const editListing = () => {
    console.log("LISTTT",list)
    let data = {
      cmsId: list?._id,
      title: list?.title,
      content: content,
      status: status,
    }
    dispatch(EditManagementAction(data)).then(res => {
      // console.log(res)
      // navigate(-1)
      toast.success("Updated successfully", {
        position: 'top-right',
      });  
    }).catch(err => {
      console.log(err)
    })
  }








  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit Page</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Allpages"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md="12" lg="6">
              <label className="formgroup_label">Title</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={pageicon} alt="" />
                </div>
                <Input
                  name="fname"
                  placeholder="Title"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {/* <option >Select Status</option> */}
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Description</label>
              <FormGroup className="form-group">
                <JoditEditor
                  ref={editor}
                  value={content}
                  tabIndex={1}
                  config={config}
                  onBlur={newContent => setContent(newContent)}
                  onChange={newContent => { }}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button
              onClick={() => {
                editListing()
              }}
              className="primarybutton  maxwidth-555">
              Update Now
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
  );
};

export default Editpage;
