import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
  Modal,
  ModalBody,
  ButtonGroup,
} from "reactstrap";
import avatarimg1 from "../../../assets/image/avatar/2.png";
import zoomdoc1 from "../../../assets/image/zoomdoc.jpg";
import calendaricon from "../../../assets/image/profilecalendar-1.svg";
import cameraicon from "../../../assets/image/profilecamera-1.svg";
import emailicon from "../../../assets/image/profileemail-1.svg";
import licenseicon from "../../../assets/image/profilelicense-1.svg";
import phoneicon from "../../../assets/image/profilephone-1.svg";
import usericon from "../../../assets/image/profileuser-1.svg";
import FlyingIcon from "../../../assets/image/flying.svg";
import { X, Plus } from "react-feather";
import locationicon from "../../../assets/image/profilelocation-1.svg";
import gendericon from "../../../assets/image/profilegender-1.svg";
import docimg1 from "../../../assets/image/doc1.jpg";
import docimg2 from "../../../assets/image/doc2.jpg";
import statusicon from "../../../assets/image/status-icon.svg";
import passwordicon from "../../../assets/image/password.svg";
import eyehideicon from "../../../assets/image/eye-hide.svg";
import eyeshowicon from "../../../assets/image/eye-show.svg";
import { XCircle, Eye } from "react-feather";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { InstructorDetailAction } from "../../../Redux/Actions/InstructorAction";
import { AircraftTypeAction, DeleteCertificate, DeleteGovernmentCertificate, DeleteMedicalCertificate, EditUserAction } from "../../../Redux/Actions";
import moment from "moment";
import { IMAGE_URL } from "../../../constant/Endpoints";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import profileimg from "../../../assets/image/default.jpg";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import PageLoader from "../../customcomponent/loader";

const AircraftType = [
  {
    value: "Full Flight Simulator (FFS)",
    label: "Full Flight Simulator (FFS)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Flight Training Device (FTD)",
    label: "Flight Training Device (FTD)",
    color: "#00B8D9",
    isFixed: false,
  },
  {
    value: "Advanced Aircraft Training Device (AATD)",
    label: "Advanced Aircraft Training Device (AATD)",
    color: "#00B8D9",
    isFixed: false,
  },
  {
    value: "Basic Aviation Training Device (BATD)",
    label: "Basic Aviation Training Device (BATD)",
    color: "#00B8D9",
    isFixed: false,
  },
  {
    value: "Simulator Uncertified",
    label: "Simulator Uncertified",
    color: "#00B8D9",
    isFixed: false,
  },
];
const orderOptions = (values) => {
  if (values.length > 0)
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
};

let timeSlot = [
  { from: "12:00 AM", to: "2:30 AM" },
  { from: "2:30 AM", to: "5:00 AM" },
  { from: "5:00 AM", to: "7:30 AM" },
  { from: "7:30 AM", to: "10:00 AM" },
  { from: "10:00 AM", to: "12:30 PM" },
  { from: "12:30 PM", to: "3:00 PM" },
  { from: "3:00 PM", to: "5:30 PM" },
  { from: "5:30 PM", to: "8:00 PM" },
  { from: "8:00 PM", to: "10:30 PM" },
];

let daySlot = {
  mon: timeSlot,
  tue: timeSlot,
  wed: timeSlot,
  thurs: timeSlot,
  fri: timeSlot,
  sat: timeSlot,
  sun: timeSlot,
};

const Editinstructor = (args) => {
  const autocompleteRef = useRef(null);
  var googleKey = process.env.REACT_APP_GOOGLE_PLACE_KEY

  const today = new Date();

  const [cSelected, setCSelected] = useState({});

  const [selectedDate, setSelectedDate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [loading, setLoading] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneno, setPhoneno] = useState("");
  const [inputemail, setInputemail] = useState("");
  const [flyingexperience, setFlyingexperience] = useState("");
  const [flightrate, setFlightrate] = useState("");
  const [groundrate, setGroundrate] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("");
  const [fixedValue, setFixedValue] = useState([]);
  const [rSelected, setRSelected] = useState("");
  const [weekoff, setWeekoff] = useState(true);

  const onCheckboxBtnClick = (selected) => {
    const newCSelected = { ...cSelected };
    var date = moment(rSelected).format("DD-MM-YYYY");

    console.log("newCSelected==========>date", date);

    if (!newCSelected[date]) {
      newCSelected[date] = [];
    }

    // Check if the selected item is already present
    const isSelected = newCSelected[date].some(
      (item) => item.from === selected.from
    );

    if (isSelected) {
      newCSelected[date] = newCSelected[date].filter(
        (item) => item.from !== selected.from
      );
    } else {
      newCSelected[date].push(selected);
    }

    setCSelected(newCSelected);
  };

  const navigate = useNavigate();

  // const fixedOnChange = (value, { action, removedValue }) => {
  //   switch (action) {
  //     case "remove-value":
  //     case "pop-value":
  //       if (removedValue.isFixed) {
  //         return;
  //       }
  //       break;
  //     case "clear":
  //       value = AircraftType.filter((v) => v.isFixed);
  //       break;
  //     default:
  //       break;
  //   }

  //   value = orderOptions(value);
  //   setFixedValue(value);
  // };

  const [inputFields, setInputFields] = useState([
    {
      dayfield: "Monday",
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        dayfield: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const [preview, setPreview] = useState([]);
  const [preview1, setPreview1] = useState([]);
  const [preview2, setPreview2] = useState([]);
  const params = useParams();
  let id = params.id;

  const dispatch = useDispatch();
  const [userDetail, setUserDetail] = useState();
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedGender, setSelectedGender] = useState("Select Gender");
  const [selectedLicense, setSelectedLicense] = useState(
    "Select License Level"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [selectedGovt, setSelectedGovt] = useState([]);
  const [selectedMedical, setSelectedMedical] = useState([]);
  const [testPreview, setTextPreview] = useState([]);
  const phoneNumberAsInteger = parseInt(phoneno, 10);
  const [aircraftList, setAircraftType] = useState([]);

  useEffect(() => {
    console.log(testPreview, "preview>>");
  }, [testPreview]);

  const handleFileChange = (event) => {
    let a = event.target.files[0]; ////SEND BACKEND
    let b = URL.createObjectURL(a);
    setSelectedFile(event.target.files[0]);
  };

  const handleCerificate = (event) => {
    const files = event.target.files;

    const newSelectedCertificates = Array.from(files);
    setSelectedCertificate((prevSelectedCertificates) => [
      ...prevSelectedCertificates,
      ...newSelectedCertificates,
    ]);

    const newPreviews = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setPreview((prevPreviews) => [...prevPreviews, ...newPreviews]);

    // console.log('event.target.files[0]',event.target.files[0])
    // setPreview([URL.createObjectURL(event.target.files[0])]);

    //     setSelectedCertificate(event.target.files[0]);
  };

  const handleRemoveCertificate = async(item,index) => {
    const updatedPreviews = [...preview];
    updatedPreviews.splice(index, 1);
    setPreview(updatedPreviews);

    const updatedSelectedCertificates = [...selectedCertificate];
    updatedSelectedCertificates.splice(index, 1);
    setSelectedCertificate(updatedSelectedCertificates);
    if( typeof item != "object"){
      return
  }
  await dispatch(DeleteCertificate({certificateID: item?.id,userId:id})).then((res) => {
    console.log("res",res)
    if (res.status === 1) {
      
      // toast.success("Certificate removed successfully.", {
      //   position: 'top-right',
      // }); 

     }else{

      toast.error(res.msg, {
        position: 'top-right',
      });  
    }
  }
  )

  };

  const handleRemoveGovtCertificate = async (item,index) => {
    const updatedPreviews = [...preview1];
    updatedPreviews.splice(index, 1);
    setPreview1(updatedPreviews);

    const updatedSelectedCertificates = [...selectedCertificate];
    updatedSelectedCertificates.splice(index, 1);
    setSelectedGovt(updatedSelectedCertificates);
    if( typeof item != "object"){
      return
  }
  console.log("itemmm",item)
    await dispatch(DeleteGovernmentCertificate({govtID: item?.id,userId:id})).then((res) => {
      console.log("res",res)
      if (res.status === 1) {
        // toast.success("Certificate removed successfully.", {
        //   position: 'top-right',
        // }); 

       }else{

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }
    }
    )
  };

  const handleRemoveMedicalCertificate =async (item,index) => {
    const updatedPreviews = [...preview2];
    updatedPreviews.splice(index, 1);
    setPreview2(updatedPreviews);

    const updatedSelectedCertificates = [...selectedCertificate];
    updatedSelectedCertificates.splice(index, 1);
    setSelectedMedical(updatedSelectedCertificates);

    if( typeof item != "object"){
      return
  }
    await dispatch(DeleteMedicalCertificate({medicalID: item?.id,userId:id})).then((res) => {
      console.log("res",res)
      if (res.status === 1) {
        // toast.success("Certificate removed successfully.", {
        //   position: 'top-right',
        // }); 
       }else{

        toast.error(res.msg, {
          position: 'top-right',
        });  
      }
    }
    )

  };
  const handleGovtCertificate = (event) => {
    // setSelectedGovt(event.target.files[0]);
    const files = event.target.files;

    const newSelectedCertificates = Array.from(files);
    setSelectedGovt((prevSelectedCertificates) => [
      ...prevSelectedCertificates,
      ...newSelectedCertificates,
    ]);

    const newPreviews = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setPreview1((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleMedical = (event) => {
    // setSelectedMedical(event.target.files[0]);
    const files = event.target.files;

    const newSelectedCertificates = Array.from(files);
    setSelectedMedical((prevSelectedCertificates) => [
      ...prevSelectedCertificates,
      ...newSelectedCertificates,
    ]);

    const newPreviews = Array.from(files).map((file) =>
      URL.createObjectURL(file)
    );
    setPreview2((prevPreviews) => [...prevPreviews, ...newPreviews]);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleLicenseChange = (event) => {
    setSelectedLicense(event.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getUserData = () => {
    const id = params.id;
    let data = {
      instructorID: id,
    };
    dispatch(InstructorDetailAction(data))
      .then((res) => {
        const data = res.data[0];

        let schedule = data?.instructorTimmings;
        console.log("schedule=====>", schedule);
        var scheduleArray = schedule?.reduce((acc, curr) => {
          acc[curr.day] = curr.slot;
          return acc;
        }, {});

        console.log("scheduleArray", scheduleArray);

        setCSelected(scheduleArray);

        const { certificate_path, govt_id_path, medical_certificate_path } =
          data;

          let certificate_arr=[];
          let government_arr=[];
          let medical_arr=[];
        console.log("datatttt",data)
          data?.certificate_path.map((el)=>{
            let imageUrl= IMAGE_URL+el?.path
            let obj ={}
        obj.id= el?.id
        obj.imageUrl=imageUrl
            certificate_arr.push(obj);
          })
          setPreview(certificate_arr);
          data?.govt_id_path.map((el)=>{
            let imageUrl= IMAGE_URL+el?.path
            let obj ={}
        obj.id= el?.id
        obj.imageUrl=imageUrl
            government_arr.push(obj);
          })
          setPreview1(government_arr)
          data?.medical_certificate_path.map((el)=>{
            let imageUrl= IMAGE_URL+el?.path
            let obj ={}
        obj.id= el?.id
        obj.imageUrl=imageUrl
            medical_arr.push(obj);
          })
          setPreview2(medical_arr)
        // setPreview1(govt_id_path);
        // setPreview(certificate_path);
        // setPreview2(medical_certificate_path);
        setTextPreview(data);
        setUserDetail(data);

        console.log("data?.DOB",data?.DOB)
        const date = moment.utc(data?.DOB,"DD-MM-YYYY")
        const eventDate = date.local();
  
        console.log("data?.craftCategory",data?.craftCategory)
        setSelectedDate(eventDate.toDate())
        setFirstname(data?.firstName);
        setLastname(data?.lastName);
        setInputemail(data?.email);
        setSelectedLicense(data?.licencseLevel);
        setSelectedGender(data?.gender);
        setStatus(data?.account_status);
        setLocation(data?.address);
        setPhoneno(parseInt(data?.phone_number, 10));
        setFlightrate(data?.instructorFlightRate);
        setFlyingexperience(data?.flyingExperience);
        setGroundrate(data?.instructorRate);
        console.log("data?.craftCategory",data?.craftCategory)

          setFixedValue(data?.craftCategory);
      

       
        setInputPassword(data?.password);
        // console.log(data?.DOB ?(String(data?.DOB).split('-').reverse().join('-')):"")
      })
      .catch((err) => {
        console.log(err);
      });
    console.log(id);
  };

  const formatSelectedDate = (date) => {
    return moment(date).format("DD-MM-YYYY"); 
  };


  const aircraftListType = aircraftList?.map((craft) => ({
    value: craft._id,
    label: craft.type,
  }));
  const fixedOnChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    const craftCategoryString = selectedIds.join(','); 
    setFixedValue(selectedIds); // Update selected options in state
    console.log("Selected IDs:", selectedIds); // This array of _id should be sent to backend
  };

  const fetchAircraftType = ()=>{
    dispatch(AircraftTypeAction()).then((res) => {
      setAircraftType(res?.data)
     
    }).catch((err) => { console.log(err) })
  }


  const EditInstructor = () => {
    setLoading(true);

    // const formData = new FormData();
    const id = params.id;
    const formData = new FormData();
    let obj = [];
    console.log("fixedValue",fixedValue)
    fixedValue?.forEach((el) => {
      console.log("el",el)
      if (el) {
        obj.push(el);
      }
    }); 
    console.log("obj",obj)
    formData.append("firstName", firstname);
    formData.append("lastName", lastname);
    formData.append("email", inputemail);
    formData.append("phone_number", phoneNumberAsInteger);
    formData.append("password", inputPassword);
    formData.append("DOB", formatSelectedDate(selectedDate));
    formData.append("licencseLevel", selectedLicense);
    formData.append("userType", "true");
    formData.append("gender", selectedGender);
    formData.append("account_status", status);
    formData.append("address", location);
    formData.append("profile", selectedFile);
    selectedCertificate?.map((el)=>{
      formData.append('certificate', el);

    })
    
    selectedGovt?.map((el)=>{
      formData.append('governmentID', el);

    })

    selectedMedical?.map((el)=>{
      formData.append('medicalCertificate', el);

    })
    formData.append("instructorFlightRate", flightrate);
    formData.append("flyingExperience", flyingexperience);
    formData.append("instructorRate", groundrate);
    formData.append("craftCategory", obj);
    formData.append("user_Id", id);
    formData.append("time_slot", JSON.stringify(cSelected));

    dispatch(EditUserAction(formData))
      .then((res) => {
        if (res.status == 1) {
          setLoading(false);

          toast.success("User profile updated successfully", {
            position: "top-right",
          });
          navigate(-1)
        } else {
          setLoading(false);

          toast.error(res.msg, {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        setLoading(false);

        toast.error(err, {
          position: "top-right",
        });
        console.log(err);
      });
  };


  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };



  useEffect(() => {
    fetchAircraftType()

    getUserData();
    var input = document.querySelector('.location');

    loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`, () => {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, input);
      
      autocomplete.addListener('place_changed', () => {
        console.log("autocomaplete",autocomplete)
        const place = autocomplete.getPlace();
        console.log("place",place)
        if (place.address_components) {
          handleLocationChange({ target: { name: 'location', value: place.formatted_address } });
        }
      });
    });
  }, []);
  const [date, setDate] = useState(new Date());

  const onChange = (newDate) => {
    setDate(newDate);
    setRSelected(newDate.toDateString());
  };

  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Edit Instructor</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Instructor">
              <Button className="btn-add">Back</Button>
            </Link>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img
                      className="useravatarimg"
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : userDetail?.profilePic
                          ? `${IMAGE_URL}${userDetail?.profilePic}`
                          : profileimg
                      }
                      alt=""
                    />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  >
                    <span className="d-block">Update Picture</span>
                    <input
                      type="file"
                      hidden
                      id="change-img"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">First Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="fname"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Last Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="lname"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Email Address</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={emailicon} alt="" />
                </div>
                <Input
                  name="email"
                  placeholder="Email Address"
                  value={inputemail}
                  onChange={(e) => setInputemail(e.target.value)}
                  type="email"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Phone No.</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={phoneicon} alt="" />
                </div>
                <Input
                  name="phoneno"
                  placeholder="Phone No"
                  value={phoneno}
                  onChange={(e) => setPhoneno(e.target.value)}
                  type="tel"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Date Of Birth</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  maxDate={today}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">License Level</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={licenseicon} alt="" />
                </div>
                <Input
                  id="exampleSelect"
                  className="form-control"
                  name="select"
                  type="select"
                  onChange={handleLicenseChange}
                  value={selectedLicense}
                >
                  <option value="Select License Level">
                    Select License Level
                  </option>
                  <option value="Student">Student</option>
                  <option value="Private">Private</option>
                  <option value="Instrument">Instrument</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Flight Instructor">Flight Instructor</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Flying Experience</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="flyingexperience"
                  placeholder="Flying Experience"
                  value={flyingexperience}
                  onChange={(e) => setFlyingexperience(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Instructor Flight Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="flightrate"
                  placeholder="Instructor Flight Rate"
                  value={flightrate}
                  onChange={(e) => setFlightrate(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Instructor Ground Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="groundrate"
                  placeholder="Instructor Ground Rate"
                  value={groundrate}
                  onChange={(e) => setGroundrate(e.target.value)}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Gender</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={gendericon} alt="" />
                </div>
                <Input
                  id="exampleSelect"
                  className="form-control"
                  name="select"
                  type="select"
                  onChange={handleGenderChange}
                  value={selectedGender}
                >
                  <option>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="select Status">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="pending">Pending</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Location</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={locationicon} alt="" />
                </div>
                <Input
                  name="location"
                  placeholder="Location"
                  value={location}
                  onChange={handleLocationChange}
                  type="text"
                  innerRef={autocompleteRef}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Craft Category</label>
              <FormGroup className="form-group">
              <Select
          isClearable={false}
          value={aircraftListType.filter((option) =>
            fixedValue.includes(option.value)
          )}
                      isMulti
          name="craftCategory"
          placeholder="Select Craft Categories"
          options={aircraftListType}
          onChange={fixedOnChange}
          className="multiselect"
          classNamePrefix="select"
        /> 
              </FormGroup>
            </Col>
            {/* <Col md="12" lg="6">
              <label className="formgroup_label">Password</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={passwordicon} alt="" />
                </div>
                <Input
                  name="password"
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                // onChange={(e) => setPassword(e.target.value)}
                />
                <div className="iconright" onClick={togglePasswordVisibility}>
                  {showPassword ? <img src={eyeshowicon} alt="" /> : <img src={eyehideicon} alt="" />}
                </div>
              </FormGroup>
            </Col> */}
            <Col md="12" lg="12">
              <label className="formgroup_label">Upload Certifications</label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg" className="fileupload">
                  <div>
                    <img src={cameraicon} alt="" />
                    <h3>Upload Certifications</h3>
                    <p>(image size: Max 2 MB)</p>
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg"
                  multiple   accept="image/jpeg,image/png" 
                  name="uploadimg"
                  style={{ display: "none" }}
                  onChange={handleCerificate}
                />
                <div className="uploadeddocs">
                  {/* <div className="uplaoddoc_img">
                    <XCircle className="uplaoddocclose_icon" />
                    {/* <img src={selectedCertificate ? URL.createObjectURL(selectedCertificate) : cameraicon} alt="" /> */}
                  {/* <span className="docview_btn" onClick={toggle2}><Eye /></span>
                  </div> */}

                  {preview?.map((item, index) => {
                    return (
                      <div className="uplaoddoc_img" key={index}>
                        <Button onClick={() => handleRemoveCertificate(item ,index)}>
                          <XCircle className="uplaoddocclose_icon" />
                        </Button>

                        <img src={typeof item === "object" ? item.imageUrl : item}  alt="" />


                        {/* <span className="docview_btn" onClick={toggle2}><Eye /></span> */}
                      </div>
                    );
                  })}
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">
                Upload Government Certificates
              </label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg1" className="fileupload">
                  <div>
                    {/* <img src={selectedGovt ? URL.createObjectURL(selectedGovt) : cameraicon} alt="" /> */}
                    <h3>Upload Government Certificate</h3>
                    <p>(image size: Max 2 MB)</p>
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg1"
                  name="uploadimg1"
                  multiple   accept="image/jpeg,image/png" 
                  style={{ display: "none" }}
                  onChange={handleGovtCertificate}
                />
                <div className="uploadeddocs">
                  {/* <div className="uplaoddoc_img">
                    <XCircle className="uplaoddocclose_icon" />
                    <img src={docimg2} alt="" />
                    <span className="docview_btn" onClick={toggle2}><Eye /></span>
                  </div> */}
                  {preview1?.map((item, index) => {
                    return (
                      <div className="uplaoddoc_img" key={index}>
                      <Button onClick={() => handleRemoveGovtCertificate(item,index)}>

                          <XCircle className="uplaoddocclose_icon" />
                        </Button>

                        <img src={typeof item === "object" ? item.imageUrl : item}  alt="" />

                        {/* <span className="docview_btn" onClick={toggle2}><Eye /></span> */}
                      </div>
                    );
                  })}
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">
                Upload Medical Certificates
              </label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg2" className="fileupload">
                  <div>
                    {/* <img src={selectedMedical ? URL.createObjectURL(selectedMedical) : cameraicon} alt="" /> */}
                    <h3>Upload Medical Certificate</h3>
                    <p>(image size: Max 2 MB)</p>
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg2"
                  multiple   accept="image/jpeg,image/png" 
                  name="uploadimg2"
                  style={{ display: "none" }}
                  onChange={handleMedical}
                />
                <div className="uploadeddocs">
                  {preview2?.map((item, index) => {
                    return (
                      <div className="uplaoddoc_img" key={index}>
                       <Button onClick={() => handleRemoveMedicalCertificate(item,index)}>

                          <XCircle className="uplaoddocclose_icon" />
                        </Button>

                        <img src={typeof item === "object" ? item.imageUrl : item}  alt="" />


                        {/* <span className="docview_btn" onClick={toggle2}><Eye /></span> */}
                      </div>
                    );
                  })}
                </div>
              </FormGroup>
            </Col>
            <Col md="12">
              <hr className="section_breakline" />
            </Col>
            <Col md="12" className="mt-3">
              <h4 className="headinginner  mb-3">Working Schedule</h4>
              <Calendar onChange={onChange} value={date} minDate={new Date()} />

              <div className="weekoff_div">
                <FormGroup switch>
                  <Label check>Week Off</Label>
                  <Input
                    type="switch"
                    checked={weekoff}
                    value={weekoff}
                    onClick={() => {
                      setWeekoff(!weekoff);
                    }}
                  />
                </FormGroup>
              </div>
              <div className="timeslot_div">
                {rSelected &&
                timeSlot?.map((item, index) => {
                  var date_formated = moment(rSelected).format("DD-MM-YYYY");
                  const isSelected = cSelected[date_formated]?.some(
                    (selectedItem) => selectedItem.from === item.from
                  );
                  console.log("isSelected", isSelected);
                  return (
                    <ButtonGroup key={index} className="timeslot">
                      <Button
                        style={{
                          marginRight: "5px",
                          fontWeight: isSelected ? "bold" : "normal",
                        }}
                        onClick={() => onCheckboxBtnClick(item)}
                        active={isSelected}
                      >
                        {item.from} - {item.to}
                      </Button>
                    </ButtonGroup>
                  );
                })}
              </div>
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button
              onClick={() => {
                EditInstructor();
              }}
              className="primarybutton  maxwidth-555"
            >
                            {loading ? <PageLoader /> : "Save Now"}

            </Button>
          </div>
        </Form>
      </div>
      <Modal
        centered
        className="modalimg_zoom"
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <XCircle className="modalzoom_close_icon" onClick={toggle2} />
        <ModalBody>
          <img src={zoomdoc1} alt="" />
        </ModalBody>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Editinstructor;
