// import RNFetchBlob from 'rn-fetch-blob';
import Singleton from "../../src/Singleton";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { BASE_URL, LOCAL_URL } from "../constant/Endpoints";
import axios from "axios";
// import { BASE_URL } from '../EndPoints';
// import EncryptedStorage from 'react-native-encrypted-storage';

const APIClient = class APIClient {
  static myInstance = null;
  static getInstance() {
    if (APIClient.myInstance == null) {
      APIClient.myInstance = new APIClient();
    }
    return this.myInstance;
  }

  get(endpoint) {
    // console.log("API Client Called");
    // UserToken=value
    // console.log('get--token', UserToken);
    return new Promise(async (resolve, reject) => {
      let token = await Singleton.getInstance().getData("token");
      // console.log('UserToken', UserToken);
      // console.log("url", `${BASE_URL}${endpoint}`);
      fetch(`${BASE_URL}${endpoint}`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'api-access-token': UserToken,
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (res) => {
          // console.log("res", res);
          let status = res?.status;
          let jsonVal = await res.json();
          if (status < 300 && status >= 200) {
            return resolve(jsonVal);
          } else {
            // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
            //   try {

            //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
            //     console.log("fetchNewRefreshToken---12",ress);
            //     var UserToken = ress['access-token'];
            //     console.log("fetchNewRefreshToken---12",UserToken);
            //     let res_p = await  this.get(endpoint, UserToken);
            //     console.log("_________res p", res_p);
            //     return resolve(res_p)
            //   } catch (error) {
            //     console.log('errr inside' , error);
            //     return reject(error)
            //   }
            // }
            return reject(jsonVal);
          }
        })
        .catch((e) => {
          // let err = e ;
          // if(e.bodyString){
          //   err =  JSON.parse(e.bodyString)
          // }
          // console.log("error caught get", e);
          reject(e);
          // console.log(e.bodyString == 'Unauthorized', "error caught", e.bodyString , err.message);
        });
    });
  }

  post(endpoint, data) {
    return new Promise(async (resolve, reject) => {
      let token = await Singleton.getInstance().getData("token");
      // console.log("TOKEN",token)
      // console.log("url11", `${BASE_URL}${endpoint}`);
      // console.log('params11', BASE_URL, endpoint);
      fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: data != null ? JSON.stringify(data) : null,
      })
        .then(async (res) => {
          var data1 = res?.headers.map;
          let status = res?.status;
          let jsonVal = await res.json();

          // var refreshTokenCheck = data1['refresh-token'];
          // console.log('refresh-token===>', refreshTokenCheck);
          // Singleton.getInstance().saveData(
          //   Constants.refresh_token,
          //   refreshTokenCheck,
          // );
          if (status < 300 && status >= 200) {
            return resolve(jsonVal);
          } else {
            // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
            //   try {

            //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
            //     console.log("fetchNewRefreshToken---12",ress);
            //     var UserToken = ress['access-token'];
            //     console.log("fetchNewRefreshToken---12",UserToken);
            //     let res_p = await  this.post(endpoint, data,UserToken);
            //     console.log("_________res p", res_p);
            //     return resolve(res_p)
            //   } catch (error) {
            //     console.log('errr inside' , error);
            //     return reject(error)
            //   }
            // }
            return reject(jsonVal);
          }
        })
        .catch((err) => {
          // console.log("post-============-error :", JSON.stringify(err));
          reject(err);
        });
    });

    // }
  }



  filePost(endpoint, data) {
    return new Promise(async (resolve, reject) => {
      let token = await Singleton.getInstance().getData("token");

      fetch(`${BASE_URL}${endpoint}`, {
        method: "POST",
        headers: {
          //  'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
        body: data,
      })
        .then(async (res) => {
          var data1 = res?.headers.map;
          let status = res?.status;
          let jsonVal = await res.json();

          // var refreshTokenCheck = data1['refresh-token'];
          // console.log('refresh-token===>', refreshTokenCheck);
          // Singleton.getInstance().saveData(
          //   Constants.refresh_token,
          //   refreshTokenCheck,
          // );
          if (status < 300 && status >= 200) {
            return resolve(jsonVal);
          } else {
            // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
            //   try {

            //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
            //     console.log("fetchNewRefreshToken---12",ress);
            //     var UserToken = ress['access-token'];
            //     console.log("fetchNewRefreshToken---12",UserToken);
            //     let res_p = await  this.post(endpoint, data,UserToken);
            //     console.log("_________res p", res_p);
            //     return resolve(res_p)
            //   } catch (error) {
            //     console.log('errr inside' , error);
            //     return reject(error)
            //   }
            // }
            return reject(jsonVal);
          }
        })
        .catch((err) => {
          // console.log("post--error :", JSON.stringify(err));
          reject(err);
        });
    });

    // }
  }


  put(endpoint, data, UserToken) {
    // console.log('API Client Called put New');
    // UserToken=value
    // console.log('put--token', UserToken);
    return new Promise((resolve, reject) => {
      // console.log("UserToken", UserToken);
      // console.log("url", `${BASE_URL}${endpoint}`);
      fetch(`${BASE_URL}${endpoint}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          // 'api-access-token': UserToken,
          Authorization: `Bearer ${UserToken}`,
        },
        body: data != null ? JSON.stringify(data) : null,
      })
        .then(async (res) => {
          // console.log("res--=-=-=-=-=->>>put", res);
          let status = res?.status;
          let jsonVal = await res.json();
          // console.log("Json Response:::--put--", jsonVal);
          if (status < 300 && status >= 200) {
            return resolve(jsonVal);
          } else {
            // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
            //   try {

            //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
            //     console.log("fetchNewRefreshToken---12",ress);
            //     var UserToken = ress['access-token'];
            //     console.log("fetchNewRefreshToken---12",UserToken);
            //     let res_p = await  this.put(endpoint, data,UserToken);
            //     console.log("_________res p", res_p);
            //     return resolve(res_p)
            //   } catch (error) {
            //     console.log('errr inside' , error);
            //     return reject(error)
            //   }
            // }
            return reject(jsonVal);
          }
        })
        .catch((err) => {
          // let err = e ;
          // if(e.bodyString){
          //   err =  JSON.parse(e.bodyString)
          // }
          reject(err);
          // console.log("error caught-=-=put=-", JSON.stringify(err));
          // console.log(e.bodyString == 'Unauthorized', "error caught", e.bodyString , err.message);
        });
    });
  }

  delete(endpoint, data, UserToken) {
    return new Promise((resolve, reject) => {
      // console.log("UserToken11", UserToken);
      // console.log("url11", `${BASE_URL}${endpoint}`);

      fetch(`${BASE_URL}${endpoint}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserToken}`,
        },
        body: data != null ? JSON.stringify(data) : null,
      })
        .then(async (res) => {
          // console.log("res-->>>delete", res?.status);
          let status = res.status;
          let jsonVal = await res.json();
          if (status < 300 && status >= 200) {
            return resolve(jsonVal);
          } else {
            // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
            //   try {

            //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
            //     console.log("fetchNewRefreshToken---12",ress);
            //     var UserToken = ress['access-token'];
            //     console.log("fetchNewRefreshToken---12",UserToken);
            //     let res_p = await  this.delete(endpoint,data, UserToken);
            //     console.log("_________res p", res_p);
            //     return resolve(res_p)
            //   } catch (error) {
            //     console.log('errr inside' , error);
            //     return reject(error)
            //   }
            // }
            return reject(jsonVal);
          }
        })
        .catch((e) => {
          // let err = e ;
          // if(e.bodyString){
          //   err =  JSON.parse(e.bodyString)
          // }
          // console.log("error caught delete", e);
          // console.log(e.bodyString == 'Unauthorized', "error caught", e.bodyString , err.message);
        });
    });

    // }
  }

  // postFileData(endpoint, data, UserToken) {
  //   // console.log('API Client Called check', UserToken);
  //   return new Promise((resolve, reject) => {
  //     console.log('UserToken', UserToken);
  //     console.log('url', `${BASE_URL}${endpoint}`);
  //     RNFetchBlob.fetch(
  //       'POST',
  //       `${BASE_URL}${endpoint}`,
  //       {
  //         Authorization: `Bearer ${UserToken}`,
  //         'Content-Type': 'multipart/form-data',
  //       },

  //       data,
  //     )
  //       .then(async res => {
  //         console.log('res-->>>postFileData', res.respInfo);
  //         let status = res.respInfo?.status ;
  //         let jsonVal = await res.json();
  //         console.log('Json Response:::--getNew--', jsonVal);
  //         if(status < 300 && status >= 200 ){
  //           return resolve(jsonVal);

  //         }
  //         else{

  //           // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
  //           //   try {

  //           //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
  //           //     console.log("fetchNewRefreshToken---12",ress);
  //           //     var UserToken = ress['access-token'];
  //           //     console.log("fetchNewRefreshToken---12",UserToken);
  //           //     let res_p = await  this.postFileData(endpoint, UserToken);
  //           //     console.log("_________res p", res_p);
  //           //     return resolve(res_p)
  //           //   } catch (error) {
  //           //     console.log('errr inside' , error);
  //           //     return reject(error)
  //           //   }
  //           // }
  //           return reject(jsonVal);

  //         }

  //       })
  //       .catch(e => {
  //         // let err = e ;
  //         // if(e.bodyString){
  //         //   err =  JSON.parse(e.bodyString)
  //         // }
  //         console.log('error caught postFileData', e);
  //         reject(e)
  //         // console.log(e.bodyString == 'Unauthorized', "error caught", e.bodyString , err.message);
  //       });
  //   });
  // }
  // putFileData(endpoint, data, UserToken) {
  //   // console.log('API Client Called check', UserToken);
  //   return new Promise((resolve, reject) => {
  //     // console.log('UserToken', UserToken);
  //     // console.log('url', `${BASE_URL}${endpoint}`);
  //     RNFetchBlob.fetch(
  //       'PUT',
  //       `${BASE_URL}${endpoint}`,
  //       {
  //         Authorization: `Bearer ${UserToken}`,
  //         'Content-Type': 'multipart/form-data',
  //       },

  //       data,
  //     )
  //       .then(async res => {
  //         console.log('res-->>>putFileData', res);
  //         let status = res.respInfo?.status ;
  //         let jsonVal = await res.json();
  //         if(status < 300 && status >= 200 ){
  //           return resolve(jsonVal);

  //         }
  //         else{

  //           // if (jsonVal?.length > 0 && jsonVal[0]?.code == 401) {
  //           //   try {

  //           //     let ress = await Singleton.getInstance().fetchNewRefreshToken()
  //           //     console.log("fetchNewRefreshToken---12",ress);
  //           //     var UserToken = ress['access-token'];
  //           //     console.log("fetchNewRefreshToken---12",UserToken);
  //           //     let res_p = await  this.putFileData(endpoint, UserToken);
  //           //     console.log("_________res p", res_p);
  //           //     return resolve(res_p)
  //           //   } catch (error) {
  //           //     console.log('errr inside' , error);
  //           //     return reject(error)
  //           //   }
  //           // }
  //           return reject(jsonVal);

  //         }
  //       })
  //       .catch(e => {
  //         console.log('error caught putFileData', e);
  //         reject(e)
  //         // console.log(e.bodyString == 'Unauthorized', "error caught", e.bodyString , err.message);
  //       });
  //   });
  // }

  // postFileMultiple(endpoint, data, UserToken) {
  //   // console.log('API Client Called check', UserToken);
  //   if (!global.isConnected || !global.isInternetReachable) {
  //     if (!this.alertPresent) {
  //       this.alertPresent = true;
  //       // Alert.alert(
  //       //   '',
  //       //   Constants.NO_NETWORK,
  //       //   [
  //       //     {
  //       //       text: 'OK',
  //       //       onPress: () => {
  //       //         this.alertPresent = false;
  //       //       },
  //       //     },
  //       //   ],
  //       //   {cancelable: false},
  //       // );
  //     }
  //     return new Promise((resolve, reject) => {
  //       reject({ message: Constants.NO_NETWORK });
  //     });
  //   } else {
  //     //   if (!this.checkIdleTime() && UserToken != null) {
  //     //     return new Promise((resolve, reject) => {
  //     //       this.logout();
  //     //       reject({message: Constants.SESSION_OUT});
  //     //     });
  //     //   } else {
  //     return new Promise((resolve, reject) => {
  //       console.log('UserToken', UserToken);
  //       console.log('url', `${BASE_URL}${endpoint}`);
  //       RNFetchBlob.fetch(
  //         'POST',
  //         `${BASE_URL}${endpoint}`,
  //         {
  //           Authorization: UserToken,
  //           'Content-Type': 'multipart/form-data',
  //         },

  //         data,
  //       )
  //         .then(async res => {
  //           console.log('postFileMultiple-res', res.json());
  //           try {
  //             let jsonVal = await res.json();
  //             return resolve(jsonVal);
  //           } catch (e) {
  //             console.log('ERR----', e);
  //             return reject({ message: Constants.SOMETHING_WRONG });
  //           }
  //         })
  //         .catch((err)=>reject(err));
  //     });
  //   }
  // }

  // logOutAlert() {
  //   // alert(1)
  //   global.isAuthorized = global.isAuthorized + 1;
  //   if (global.isAuthorized >= 1) {
  //     if (!this.alertPresent) {
  //       this.alertPresent = true;
  //       // alert(1)
  //       Alert.alert(
  //         Constants.APP_NAME,
  //         Constants.SESSION_TIMEOUT,
  //         [
  //           {
  //             text: 'OK',
  //             onPress: () => {
  //               // alert(1)
  //               this.alertPresent = false;

  //               // EventRegister.emit('splash', 'SplashScreen');

  //               AsyncStorage.clear();
  //             },
  //           },
  //         ],
  //         { cancelable: false },
  //       );
  //     }
  //   }

  // }
};

export { APIClient };

//////////////////rough code /////////////

// async checkSessionTimeout() {
//   let sessionOutTime = await this.getData(constants.EXPIRE_TIME);
//   sessionOutTime = 1 * sessionOutTime;
//   // console.log("checkSessionTimeout=-=sessionOutTime--", sessionOutTime);
//   let currentTime = new Date().getTime();
//   // console.log("checkSessionTimeout=-=currentTime--", currentTime);
//   let diff = sessionOutTime - currentTime;
//   // console.log("diff--checkSessionTimeout=-==--", diff);
//   // console.log(
//   //   "checkSessionTimeout=current time diff-3594000-",
//   //   diff <= 3594000
//   // );
//   if (diff <= 1500000 && global.fetchRefreshToken == "yes") {
//     global.fetchRefreshToken = "no";

//     this.getDataSecure(constants.REFRESH_TOKEN).then(async (refreshToken) => {
//       // console.log("checkSessionTimeout=+=refreshToken--", refreshToken);

//       let res = await fetch(
//         `${END_POINT.BASE_URL}${END_POINT.REFRESH_SESSION}`,
//         {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             "refresh-token": JSON.parse(refreshToken),
//             // Authorization: accessToken,
//           },
//           // body: JSON.stringify(data),
//         }
//       );
//       console.log("checkSessionTimeout=-=res--", res);

//       if (!res?.ok) {
//         console.log("checkSessionTimeout=-=res--inside---");

//         deleteUserDevice();

//         return false;
//       } else {
//         // var data = res?.headers;
//         var data1 = res?.headers?.map;
//         var data = data1["access-token"];
//         var expTime = data1["access-expire"];
//         var refreshTokenCheck = data1["refresh-token"];

//         let b = expTime.split(" ");

//         expTime = b[0] + "T" + b[1] + "Z";

//         var myDate = new Date(expTime);

//         var checExpTime = new Date(myDate).getTime();
//         this.saveData(constants.EXPIRE_TIME, `${checExpTime}`);

//         this.accessToken = "Bearer " + data;
//         this.saveDataSecure(constants.ACCESS_TOKEN, this.accessToken);

//         this.saveDataSecure(constants.REFRESH_TOKEN, `${refreshTokenCheck}`);

//         this.timeoutId = setTimeout(() => {
//           global.fetchRefreshToken = "yes";
//         }, 10000);
//         clearTimeout(this.timeoutId);
//         this.timeoutId = null;
//         return true;
//       }
//       // });
//     });
//   } else {
//     return true;
//   }
// }
