import React, { useEffect, useState } from 'react';
import { Row, Col, Button, ModalBody, Modal, FormGroup, Input } from 'reactstrap';
import Table from './Datatables';
import { toast, ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import { AircraftCategoryAction, CategoryAddAction } from '../../Redux/Actions'; // Removed fetchCategories since it's unused
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { addCategoryValidationSchema } from '../../validationSchema';
import Loader from "../customcomponent/pageLoader";
import PageLoader from '../customcomponent/loader';

const AircraftCategory = () => {
  const [modal, setModal] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const modaltoggle = () => setModal(!modal);

  const loadCategories = () => {
    setLoading(true);
    let data = {
      type:"List"
    }
    dispatch(AircraftCategoryAction(data)).then((res) => {
      if (res.status === 1) {
        setCategoryList(res.data);
        setLoading(false);

      } else {
        toast.error(res.msg, {
          position: 'top-right',
        });
        setLoading(false);

      }
    }).catch((err) => {
      toast.error(err, {
        position: 'top-right',
      });
      setLoading(false);

    });
  };



  const formik = useFormik({
    initialValues: {
      addcategory: '',
    },
    validationSchema: addCategoryValidationSchema,
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.addcategory,
      };

      dispatch(CategoryAddAction(data))
        .then((res) => {
          if (res.status === 1) {
            toast.success('Category added successfully', {
              position: 'top-right',
            });
            resetForm();
            loadCategories(); // Reload the list
            modaltoggle(); // Close the modal
          } else {
            toast.error(res.msg, {
              position: 'top-right',
            });
          }
        })
        .catch((err) => {
          toast.error(err, {
            position: 'top-right',
          });
        });
    },
  });


    useEffect(() => {
    loadCategories(); // Load categories on component mount
  }, []);
  return (
    <div className="instructorpanel">
      <Row className="mb-4">
        <Col xl="6" md="6" xs="6" className="align-self-center">
          <h4 className="card-title my-1 headingmain">Category</h4>
        </Col>
        <Col xl="6" md="6" xs="6" className="text-end">
          <Link>
            <Button className="btn-add" onClick={modaltoggle}>Add</Button>
          </Link>
        </Col>
      </Row>
      <div>
        <Table data={categoryList} reloadCategories={loadCategories} />
      </div>
      <Modal centered isOpen={modal} toggle={modaltoggle}>
        <ModalBody className="p-4">
          <div className="Emailmodal">
            <h2 className="email_head pb-3 text-center">Add Category</h2>
            <form onSubmit={formik.handleSubmit}>
              <Row>
                <Col sm="12">
                  <label className="formgroup_label">Category</label>
                  <FormGroup className="form-group">
                    <Input
                      name="addcategory"
                      placeholder="Enter category name"
                      className="px-4"
                      value={formik.values.addcategory}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.addcategory && (
                      <p className="input-error">{formik.errors.addcategory}</p>
                    )}
                  </FormGroup>
                </Col>
              </Row>
              <div className="btn-cancel-ok pt-3 text-center">
                <Button className="btn-cancel me-1" onClick={modaltoggle}>Cancel</Button>
                <Button type="submit" className="btn-save">{loading ? <PageLoader /> : "Add"}</Button>
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default AircraftCategory;
