
// export const BASE_URL = 'https://ws-qa.fan-verse.io/';//use your baseurl
export const BASE_URL = 'https://api.inflightpilottraining.com/api/';//use your localurl
// export const BASE_URL = 'http://3.14.185.195:4000/';//use your localurl
export const IMAGE_URL = ' https://inflightbucket.s3.amazonaws.com/' //use your IMAGEurl
export const LOCAL_URL = 'http://localhost:4000/';
// export const BASE_URL = 'http://localhost:4000/';//


export const ADMIN_LOGIN_IN = 'adminLogin';
export const ADMIN_SIGN_UP = 'adminSignup';
export const ADMIN_VERIFY_OTP = 'adminVerifyOTP';
export const ADMIN_FORGOT_PASSWORD = 'adminForgotPassword';
export const ADMIN_RESET_PASSWORD = 'adminResetPassword';
export const ADMIN_LOGOUT = 'adminLogout';
export const ADMIN_GET_PROFILE = 'adminGetProfile';
export const ADMIN_CREATE_ADMIN = 'adminCreateAdmin';
export const ADMIN_ADMIN_LIST = 'adminFetchAdminList';
export const ADMIN_DELETE_ADMIN = 'adminDeleteAdmin';
export const ADMIN_CREATE_USER = 'adminCreateUser';
export const ADMIN_UPDATE_PROFILE = 'admin_updateProfile';
export const ADMIN_UPDATE_USER = 'adminUpdateUser';
export const ADMIN_DELETE_USER = 'adminDeleteUser'
export const ADMIN_USER_LIST = 'adminFetchUserList';
export const ADMIN_USER_DETAIL = 'adminFetchUserDetail';
export const DELETE_CERTIFICATE ="admin_deleteCertificate";
export const DELETE_GOVERNMENTCERTIFICATE= "admin_deleteGovernmentID";
export const DELETE_MEDICALCERTIFICATE= "admin_deleteMedicalCertificate";
export const ADMIN_INSTRUCTOR_LIST = 'adminFetchInstructorList';
export const ADMIN_INSTRUCTOR_DETAIL = 'adminFetchSingleInstructorDetail';
export const ADMIN_DELETE_INSTRUCTOR = 'adminDeleteUser';
export const ADMIN_AIRCRAFT_LIST = 'adminFetchAircraftList';
export const ADMIN_AIRCRAFT_DETAIL = 'adminFetchAircraftDetail';
export const ADMIN_AIRCRAFT_UPDATE = 'adminUpdateAircraft';
export const ADMIN_ADD_AIRCRAFT = 'adminAddAircraft';
export const ADMIN_HOURS_AIRCRAFT = 'adminAircraftHoursLog';
export const ADMIN_DELETE_AIRCRAFT = 'adminDeleteAircraft';
export const ADMIN_RESERVATION = 'adminFetchReservations';
export const ADMIN_RESERVATION_DETAIL = 'adminFetchReservationDetail';
export const ADMIN_RESERVATION_EDIT = 'admin_editReservation';
export const ADMIN_DELETE_RESERVATION = 'adminDeleteReservation';
export const ADMIN_TRANSACTION = 'adminFetchTransactions';
export const ADMIN_TRANSACTION_DETAIL = 'adminTransactionDetail';
export const ADMIN_DELETE_TRANSACTION = 'adminDeleteTransaction';
export const ADMIN_GET_CMS = 'adminGetAllCms';
export const ADMIN_GET_SINGLE_CMS = 'adminGetCms';
export const ADMIN_EDIT_CMS = 'adminEditCms';
export const ADMIN_ANNOUNCEMENT = 'adminAnnouncements';
export const ADMIN_DASHBOARD = 'admin_dashboard';
export const ADMIN_ADD_ANNOUNCEMENT = 'addAnnouncement'
export const ADMIN_DELETE_ANNOUNCEMENT = 'deleteAnnouncement'
export const ADMIN_UNREAD_ANNOUNCEMENT = 'markAsUnread'
export const ADMIN_CHANGE_PASSWORD = 'adminChangePassword'
export const ADMIN_FETCH_PERMISSON = "fetchPermissions"
export const ADMIN_UPDATE_PERMISSON = "updatePermission"
export const ADMIN_DASHBOARD_AIRCRAFT = "aircraftReservationList"
export const ADMIN_SCHEDULE_RESERVATION = "scheduleReservation"
export const ADMIN_FETCH_EVENT = "admin_fetchEvent"
export const ADMIN_DELETE_EVENT = "deleteEvent"
export const ADMIN_DETAIL_EVENT = "fetchEventDetail"
export const ADMIN_EDIT_EVENT = "admin_editEvent"
export const ADMIN_ADD_EVENT = "addEvent"
export const ADMIN_FETCH_POST = "admin_fetchPosts"
export const ADMIN_FETCH_COMMENT = "admin_fetchComments"
export const ADMIN_ADD_COMMENT = "admin_addComment"
export const ADMIN_LIKE_UNLIKE = "admin_likeUnlikePost"
export const ADMIN_DELETE_POST = "admin_deletePost"
export const ADMIN_POST_DETAIL = "admin_fetchPostDetail"
export const ADMIN_POST_ADD = "admin_addPost"
export const ADMIN_FETCH_COMMENT_REPLY = "admin_fetchCommentReplies"
export const PRESIGNEDURL = "preassignedUrl"
export const CALLFUNC = "callfun"
export const AIRCRAFT_TYPE = "aircraftTypes"
export const AIRCRAFT_CATEGORY ="categoryList"

export const AIRCRAFT_NAMELIST ="aircraftNameList"

export const AIRCRAFT_ADD ="addCategory"
export const AIRCRAFT_EDIT ="editCategory"
export const AIRCRAFT_DELETE ="deleteCategory"
export const AIRCRAFT_DETAIL ="categoryDetail"


export const ADD_AIRCRAFTNAME ="addAircraftName"
export const AIRCRAFTNAME_EDIT ="editAircraftName"


export const USER_STATUS_UPDATE = "admin_updateuserstatus"
export const FETCH_INSTRUCTORRATE="instructorrate"
export const UPDATE_INSTRUCTORRATE ="update_instructorRate"

















