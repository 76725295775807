import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, XCircle } from 'react-feather'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { DeleteEventAction, EventListAction } from "../../Redux/Actions/ManagementAction";
import { IMAGE_URL } from "../../constant/Endpoints";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import profileimg from "../../assets/image/default.jpg";
import moment from 'moment';
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
const Table = props => {
  const [id, setid] = useState("");
  const [stausFilter, setStausFilter] = React.useState("");
  const columns = [
    {
      name: "Title",
      minWidth: "200px",
      selector: "imgurl",
      sortable: true,
      cell: (row) =>
        <>
          <img className="table_img rounded-circle img-thumbnail me-2" src={row.eventImage != null ? IMAGE_URL + row.eventImage : profileimg } alt="" />
          {row?.title}
        </>
    },
    {
      name: "Place",
      selector: "place",
      sortable: true,
      cell: (row) => row?.place
    },
    {
      name: "Date",
      selector: "date",
      sortable: true,
      cell: (row) => moment.utc(row?.eventDate).format('YYYY-MM-DD')
    },
    {
      name: "Time",
      selector: "date",
      sortable: true,
      cell: (row) => row?.eventTime
    },
    {
      name: "Actions",
      minWidth: "180px",
      center: 'center',
      cell: (row) => (

        < div className="tableaction" >
          <>
            <Link to={"/Viewevent/" + row?._id}><Button className="tablebtnview"><Eye className="tableicon" /></Button></Link>
            <Link to={"/Editevent/" + row?._id} ><Button className="tablebtnedit"><Edit className="tableicon" /></Button></Link>
            <Button
              className="tablebtndelete" onClick={() => {
                setModal2(!modal2)
                setid(row?._id)}} ><Trash className="tableicon" /></Button>
          </>
        </div >
      )
    }
  ]
  const [filterText, setFilterText] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  // const filteredItems = data.filter(
  //   item => item.name && item.name.includes(filterText)
  // );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        stausFilter={stausFilter}
        onStatus={e => setStausFilter(e.target.value)}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const dispatch = useDispatch()
  const [eventList, setEventList] = useState([])

  const getEvent = () => {
    setLoading(true)
    let data = {
      search: filterText,
      filter: stausFilter
    }

    dispatch(EventListAction(data)).then(res => {
      setLoading(false)

      setEventList(res?.data?.dataList)
      console.log(res?.data)
    }).catch(err => {
      setLoading(false)

      console.log(err)
    })

  }

  const deleteEvent = (id) => {
    let data = {
      eventId: id
    }
    dispatch(DeleteEventAction(data)).then(res => {
      if (res.status === 1) {

      console.log(res)
      getEvent();
      setModal2(!modal2);

      toast.success("Event deleted successfully", {
        position: 'top-right',
      });    
    }else{
      toast.error(res.msg, {
        position: 'top-right',
      });  
      setModal2(!modal2);

    }           
    }).catch(err => {
      console.log(err)
    })

  }

  useEffect(() => {
    getEvent(filterText)
  }, [filterText,stausFilter])



  return  (
    <React.Fragment>
    <DataTable
      noHeader
      columns={columns}
	    className="react-dataTable paddingbox"
      data={eventList}
      defaultSortField="name"
      pagination
      progressComponent={<PageLoader/>}
      progressPending={loading} 
      subHeader
      subHeaderComponent={subHeaderComponent}
    />
       <Modal centered
        isOpen={modal2}
        toggle={toggle2}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
          <XCircle className="deletemodal_icon"  />
            <h2>Are You Sure  ?</h2>
            <p>You will not be able to recover the deleted record!</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>Cancel</Button>
              <Button  className="btn-ok" onClick={()=>deleteEvent(id)}>OK</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ToastContainer />

    </React.Fragment>
  );
};

export default Table;
