// import Singleton from '../../../src/Singleton';
import { DASHBORAD } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_DASHBOARD, ADMIN_DASHBOARD_AIRCRAFT, ADMIN_SCHEDULE_RESERVATION } from '../../constant/Endpoints';
import Singleton from '../../Singleton';
import data from '../../pages/Users/data';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const DashboardListSuccess = (data) => {
    return {
        type: DASHBORAD,
        payload: data
    };
};



export const DashboardListAction = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DASHBOARD)
                .then(response => {
                    dispatch(DashboardListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}

export const DashboardaircraftListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DASHBOARD_AIRCRAFT, data)
                .then(response => {
                    // dispatch(DashboardListSuccess({ data: response.data }))
                    console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const DashboardAircraftScheduleAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_SCHEDULE_RESERVATION, data)
                .then(response => {
                    // dispatch(DashboardListSuccess({ data: response.data }))
                    console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}

