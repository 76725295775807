import React , {useState} from "react"
import {
	Row,
	Col,
	ButtonGroup,
	Button
} from "reactstrap"
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";


let timeSlot = [
	{ from: "12:00 AM", to: "2:30 AM" },
	{ from: "2:30 AM", to: "5:00 AM" },
	{ from: "5:00 AM", to: "7:30 AM" },
	{ from: "7:30 AM", to: "10:00 AM" },
	{ from: "10:00 AM", to: "12:30 PM" },
	{ from: "12:30 PM", to: "3:00 PM" },
	{ from: "3:00 PM", to: "5:30 PM" },
	{ from: "5:30 PM", to: "8:00 PM" },
	{ from: "8:00 PM", to: "10:30 PM" },
  ];

const data = [
	{
		id: 1,
		day: 'Monday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 2,
		day: 'Tuesday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 3,
		day: 'Wednesday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 4,
		day: 'Thursday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 5,
		day: 'Friday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 6,
		day: 'Saturday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
	{
		id: 7,
		day: 'Sunday',
		starttime: '09:00 AM',
		endtime: '05:00 PM',
	},
];



const DetailworkingschedulePages = ({ schedule }) => {
	console.log(schedule, "SSS");

	// Ensure schedule is defined and not empty
	const scheduleArray = (schedule && Array.isArray(schedule)) ? schedule.reduce((acc, curr) => {
		acc[curr.day] = curr.slot;
		return acc;
	}, {}) : {};

	const [date, setDate] = useState(new Date());

	const onChange = newDate => {
		setDate(newDate);
	};

	return (
		<Row>
			<Col sm="12">
				<h4 className="card-title mb-3 headingcard">Work Schedule</h4>
				<Calendar
					onChange={onChange}
					value={date}
					minDate={new Date()}
				/>
				<div className="timeslot_div">
					{timeSlot?.map((item, index) => {
						console.log("scheduleArray", scheduleArray);
						const date_formated = moment(date).format("DD-MM-YYYY");
						console.log("date_formated", date_formated);

						// Safeguard against undefined access
						const isSelected = scheduleArray[date_formated]?.some(selectedItem => selectedItem.from === item.from) || false;
						console.log("isSelected", isSelected);

						return (
							<ButtonGroup key={index} className="timeslot">
								<Button
									style={{ marginRight: '5px', fontWeight: isSelected ? 'bold' : 'normal' }}
									active={isSelected}
								>
									{item.from} - {item.to}
								</Button>
							</ButtonGroup>
						);
					})}
				</div>
			</Col>
		</Row>
	);
};

export default DetailworkingschedulePages;