import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from 'classnames';
import AircraftworkingschedulePages from "./aircraftworkingschedule";
import AircraftflighthistoryPages from "./aircraftflighthistory";
import AircrafthourslogPages from "./aircrafthourslog";
import { useDispatch } from "react-redux";
import { AircraftDetailAction, AircraftHoursAction } from "../../../../Redux/Actions/AircraftAction";

const Viewaircraft = () => {
  // State for current active Tab
  const [currentActiveTab, setCurrentActiveTab] = useState('1');

  // Toggle active state for Tab
  const toggle = tab => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  }

  const params = useParams();
  const dispatch = useDispatch()
  const [aircraftDetail, setAircraftDetail] = useState()
  const [aircraftHours, setAircraftHours] = useState()


  const getAircraftData = () => {
    const id = params.id;
    let data = {
      aircraftID: id
    }
    dispatch(AircraftDetailAction(data)).then((res) => {
      setAircraftDetail(res?.data)
      // console.log(res)
    }).catch((err) => { console.log(err) })
    // console.log(id)
  }

  const getAircraftHours = () => {
    const id = params.id;
    let data = {
      aircraftID: id
    }
    dispatch(AircraftHoursAction(data)).then((res) => {
      setAircraftHours(res?.data)
      console.log(res)
    }).catch((err) => { console.log(err) })
    // console.log(id)
  }


  useEffect(() => {
    getAircraftData()
    getAircraftHours()
  }, [])



  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Aircraft Details</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Aircrafts"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Nav tabs className="instructor_tab">
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab === '1'
              })}
              onClick={() => { toggle('1'); }}
            >
              Personal
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab === '2'
              })}
              onClick={() => { toggle('2'); }}
            >
              Flight History
            </NavLink>
          </NavItem> */}
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  currentActiveTab === '3'
              })}
              onClick={() => { toggle('3'); }}
            >
              Hours Log
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={currentActiveTab}>
          <TabPane tabId="1">
            <div>
              <AircraftworkingschedulePages aircraft={aircraftDetail} />
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div>
              <AircraftflighthistoryPages />
            </div>
          </TabPane>
          <TabPane tabId="3">
            <div>
              <AircrafthourslogPages hourLog={aircraftHours} />
            </div>
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default Viewaircraft;
