
import img1 from '../../assets/image/avatar/1.png'
import img2 from '../../assets/image/avatar/2.png'
import img3 from '../../assets/image/avatar/3.png'
import img4 from '../../assets/image/avatar/4.png'
import img5 from '../../assets/image/avatar/5.png'
const data = [
  {
    id: 1,
    name: "Maria James",
    imgurl: img1,
    email: "maris@gmail.com",
    phone: '+01 1234 5678 901',
    status: 'Active',
  },
  {
    id: 2,
    imgurl: img2,
    name: "Johnson Charles",
    email: "johson@gmail.com",
    phone: '+01 1234 5678 901',
    status: 'Inactive',
  },
  {
    id: 3,
    imgurl: img3,
    name: "Steve Jones",
    email: "steve@gmail.com",
    phone: '+01 1234 5678 901',
    status: 'Active',
  },
  {
    id: 4,
    imgurl: img4,
    name: "David Smith",
    email: "david@gmail.com",
    phone: '+01 1234 5678 901',
    status: 'Pending',
  },
  {
    id: 5,
    imgurl: img5,
    name: "James Vince",
    email: "jamese@gmail.com",
    phone: '+01 1234 5678 901',
    status: 'Active',
  },
];

export default data;
