import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import avatarimg1 from '../../../assets/image/avatar/1.png'
import docimg1 from "../../../assets/image/doc1.jpg";
import zoomdoc1 from "../../../assets/image/zoomdoc.jpg";
import docimg2 from "../../../assets/image/doc2.jpg";
import docimg3 from "../../../assets/image/doc3.jpg";
import DetailtransactioninfoPages from "./detailtransactioninfo";
import { XCircle } from 'react-feather'
import { useDispatch } from "react-redux";
import { UserDetailAction } from "../../../Redux/Actions";
import { IMAGE_URL } from "../../../constant/Endpoints";
import profileimg from "../../../assets/image/default.jpg";
import moment from "moment";


const Viewuser = () => {
  const [modal2, setModal2] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  const toggle2 = () => setModal2(!modal2);

  const handleClick = (imagePath) => {
    setCurrentImage(imagePath);
    toggle2();
  };


  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const handleClickGovt = (imagePath) => {
    setCurrentImage(imagePath);
    toggle();
  };


  const [modal3, setModal3] = useState(false);

  const toggle3 = () => setModal3(!modal3);

  const handleClickMedical = (imagePath) => {
    setCurrentImage(imagePath);
    toggle3();
  };


  const params = useParams();
  const dispatch = useDispatch()
  const [userDetail, setUserDetail] = useState()
const [selectedDate,setSelectedDate] = useState("");
  const getUserData = () => {
    const id = params.id;
    let data = {
      instructorID: id
    }
    dispatch(UserDetailAction(data)).then((res) => {
      setUserDetail(res)
      setSelectedDate(res?.data?.DOB)

      console.log(res)
    }).catch((err) => { console.log(err) })
    console.log(id)
  }
  useEffect(() => {
    getUserData()
    console.log("selectedDate",selectedDate)
  }, [])

  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">User Details</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Users"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <div className="d-flex mb-3 userview_div">
          <div className="flex-shrink-0 schooldetailimg_div">
            <img className="schooldetailimg" src={userDetail?.data?.profilePic ? IMAGE_URL + userDetail?.data?.profilePic:profileimg  } alt="" />
          </div>
          <div className="flex-grow-1">
            <h5 className="fw-normal d-flex"> <span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Name:</span> {userDetail?.data?.firstName} {userDetail?.data?.lastName}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Email:</span> {userDetail?.data?.email}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Phone Number:</span> {userDetail?.data?.phone_number}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Date Of Birth:</span> {selectedDate}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Liscence Level:</span> {userDetail?.data?.licencseLevel}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Gender:</span> {userDetail?.data?.gender}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Address:</span> {userDetail?.data?.address}</h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Status:</span> <span className="badge bg-light-success">{userDetail?.data?.account_status}</span></h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Certificate:</span>
              <div class="idproof_div">
              {userDetail?.data?.certificate_path.map((img, index) => (
        <img
          key={index}
          className="me-1 mb-1"
          src={IMAGE_URL + img?.path}
          alt=""
          onClick={() => handleClick(IMAGE_URL + img?.path)}
        />
      ))}
               </div>
            </h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Government Certificate:</span>
              <div class="idproof_div">
              {userDetail?.data?.govt_id_path.map((img, index) => (
        <img
          key={index}
          className="me-1 mb-1"
          src={IMAGE_URL + img?.path}
          alt=""
          onClick={() => handleClickGovt(IMAGE_URL + img?.path)}
        />
      ))}
       </div>
            </h5>
            <h5 className="fw-normal d-flex"><span style={{ width: '150px', display: 'inline-block', fontWeight: '600' }}>Medical Certificate:</span>
              <div class="idproof_div">
              {userDetail?.data?.medical_certificate_path.map((img, index) => (
        <img
          key={index}
          className="me-1 mb-1"
          src={IMAGE_URL + img?.path}
          alt=""
          onClick={() => handleClickMedical(IMAGE_URL + img?.path)}
        />
      ))}
      </div>
            </h5>
          </div>
        </div>
        <hr className="section_breakline" />
        <DetailtransactioninfoPages transaction={userDetail?.transactions} />
      </div>
      <Modal centered className="modalimg_zoom" isOpen={modal2} toggle={toggle2}>
        <XCircle className="modalzoom_close_icon" onClick={toggle2} />
        <ModalBody>
          <img src={currentImage} alt="" />
        </ModalBody>
      </Modal>

      <Modal centered className="modalimg_zoom" isOpen={modal} toggle={toggle}>
        <XCircle className="modalzoom_close_icon" onClick={toggle} />
        <ModalBody>
          <img src={currentImage} alt="" />
        </ModalBody>
      </Modal>

      <Modal centered className="modalimg_zoom" isOpen={modal3} toggle={toggle3}>
        <XCircle className="modalzoom_close_icon" onClick={toggle3} />
        <ModalBody>
          <img src={currentImage} alt="" />
        </ModalBody>
      </Modal>
    </>
  );
};

export default Viewuser;
