import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  Row,
  Col,
  Card,
  Table,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import user1 from "../../assets/image/stats-user.svg";
import instructor1 from "../../assets/image/stats-instructor.svg";
import aircrafticon from "../../assets/image/stats_aircrafticon.svg";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { Eye, XCircle } from "react-feather";
import "react-big-calendar/lib/css/react-big-calendar.css";
import statusicon from "../../assets/image/status-icon.svg";
import usericon from "../../assets/image/profileuser-1.svg";
import calendaricon from "../../assets/image/profilecalendar-1.svg";
import clockicon from "../../assets/image/clock_icon.svg";
import FlyingIcon from "../../assets/image/flying.svg";
import userimg from "../../assets/image/photo.jpg";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  AdminListAction,
  AircraftListAction,
  DashboardAircraftScheduleAction,
  DashboardListAction,
  DashboardaircraftListAction,
  UserListAction,
} from "../../Redux/Actions";
import OnBoardingReducer from "../../Redux/Reducers/OnBoardingReducer";
import data from "../Users/data";
import { InstructorListAction } from "../../Redux/Actions/InstructorAction";
import ContentLoader from "react-content-loader";

const localizer = momentLocalizer(moment);

const events = [
  {
    id: 1,
    title: "64971",
    description: "Lorem Ipsum",
    start: new Date(2023, 8, 4, 5, 0),
    end: new Date(2023, 8, 4, 9, 0),
  },
  {
    id: 2,
    title: "959GT",
    description: "Lorem Ipsum",
    start: new Date(2023, 8, 5, 3, 0),
    end: new Date(2023, 8, 5, 7, 0),
  },
  {
    id: 3,
    title: "959GT",
    description: "Lorem Ipsum",
    start: new Date(2023, 8, 6, 6, 0),
    end: new Date(2023, 8, 6, 9, 0),
  },
  {
    id: 4,
    title: "959GT",
    description: "Lorem Ipsum",
    start: new Date(2023, 8, 8, 2, 0),
    end: new Date(2023, 8, 8, 5, 0),
  },
];

Chart.register(...registerables);
const state = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Users",
      backgroundColor: ["#b79b58"],
      fill: false,
      lineTension: 0.5,
      borderColor: "#b79b58",
      pointRadius: 10,
      borderWidth: 1,
      data: [80, 150, 180, 270, 210, 160, 160, 202, 265, 210, 270, 255],
    },
    {
      label: "Instructors",
      backgroundColor: ["#7a622a"],
      fill: false,
      lineTension: 0.5,
      borderColor: "#7a622a",
      pointRadius: 10,
      borderWidth: 1,
      data: [80, 125, 105, 130, 215, 195, 140, 160, 230, 300, 220, 170],
    },
  ],
};

const ShimmerLoader = (props) => (
  <ContentLoader
    speed={2}
    width="50%"
    height={20}
    viewBox="0 0 100% 160"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="50%" height="50" />
  </ContentLoader>
);

const Dashboard = (args) => {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const [dashboardList, setDashboardList] = useState([]);

  const [editmodal, setEditmodal] = useState(false);
  const [detailmodal, setDetailmodal] = useState(false);
  const [customHeaders, setCustomHeaders] = useState([]);
  const edittoggle = () => setEditmodal(!editmodal);
  const detailtoggle = () => setDetailmodal(!detailmodal);
  const [status, setStatus] = useState("complete");
  const [aircraft, setAircraft] = useState();
  const [username, setUsername] = useState("");
  const [schedule, setSchedule] = useState("");
  const [InstructorRate, setInstructorRate] = useState("");
  const [HobbsIn, setHobbsIn] = useState("");
  const [HobbsOut, setHobbsOut] = useState("");
  const [TechIn, setTechIn] = useState("");
  const [TechOut, setTechOut] = useState("");
  const [AirportTax, setAirportTax] = useState("");
  const [AirportRate, setAirportRate] = useState("");
  const [Totalhours, setTotalhours] = useState("");
  const [FlightHours, setFlightHours] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [InstructorNotes, setInstructorNotes] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  );
  const [PublicNotes, setPublicNotes] = useState(
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  );
  const [ReservationDate, setReservationDate] = useState(null);
  const [DispatchDate, setDispatchDate] = useState(null);
  const [CheckinDate, setCheckinDate] = useState(null);
  const [Checkintime, setCheckintime] = useState(null);
  const [Groundtime, setGroundtime] = useState(null);
  const [Dispatchtime, setDispatchtime] = useState(null);
  const ReservationDateChange = (date) => {
    setReservationDate(date);
  };
  const DispatchDateChange = (date) => {
    setDispatchDate(date);
  };

  const getDashboard = () => {
    setLoading(true);
    dispatch(DashboardListAction())
      .then((res) => {
        setLoading(false);
        console.log(res);
        setDashboardList(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const getAircraft = () => {
    dispatch(AircraftListAction())
      .then((res) => {
        // setAirCraftList(res?.data)
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const HandleUserList = async () => {
    dispatch(UserListAction())
      .then((res) => {
        console.log(res, "SEAR");
        // setUsersList(res.data)
      })
      .catch((err) => console.log(err));
  };

  const HandleInstructorList = async () => {
    dispatch(InstructorListAction())
      .then((res) => {
        console.log(res, "SEAR");
        // setUsersList(res.data)
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getDashboard();
    getDashboardAircraft();
    getAircraft();
    HandleUserList();
    HandleInstructorList();
  }, []);

  const AircraftListt = useSelector(
    (state) => state?.onBoardingReducer?.AircraftList
  );
  const UserListt = useSelector((state) => state?.onBoardingReducer?.userEmail);
  const instructorListt = useSelector(
    (state) => state?.onBoardingReducer?.instructorEmail
  );

  const currentDate = new Date();

  const [current, setCurrent] = useState(currentDate);
  const [aircraftList, setAircraftList] = useState([]);

  const getDashboardAircraft = (date) => {
    let data = {
      date: date
        ? moment(date).format("YYYY-MM-DD")
        : moment(currentDate).format("YYYY-MM-DD"),
    };
    console.log(data);
    dispatch(DashboardaircraftListAction(data))
      .then((res) => {
        let obj = res?.fetchAIrcrafts;
        if (obj) {
          console.log("obj====>", obj);
          setCustomHeaders(Object.keys(obj));
          let arr = [];
          let counter = 0;
          Object?.keys(obj)?.map((key) => {
            obj[key]?.map((item) => {
              arr.push({
                start: new Date(
                  moment(item.bookingDate + " " + item.aircraftFromTime)
                ),
                end: new Date(
                  moment(item.bookingDate + " " + item.aircraftEndTime)
                ),
                title: item.booking_id,
                description: "",
                id: counter,
              });
              counter++;
            });
          });
          setAircraftList(arr);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sheduleAircraft = () => {
    let data = {
      userEmail: username,
      reservationDate: ReservationDate,
      dispatchDate: DispatchDate,
      dispatchTime: Dispatchtime,
      checkInDate: CheckinDate,
      checkInTime: Checkintime,
      aircraftID: aircraft,
      instructorEmail: schedule,
      groundTime: Groundtime,
      instructorNotes: InstructorNotes,
      TachIn: TechIn,
      TachOut: TechOut,
      hobbsIN: HobbsIn,
      totalHours: Totalhours,
      instructorRate: InstructorRate,
      flightHours: FlightHours,
      grandTotal: GrandTotal,
      reservationStatus: status,
    };

    dispatch(DashboardAircraftScheduleAction(data))
      .then((res) => {
        console.log(res, "RRRRRESSSSS");
        alert(res.msg);
        setEditmodal(!editmodal);
        // setDashboardList(res)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const transformedData = {
    labels: dashboardList?.instructorGraphStats?.map((data) => data.month), // Extracting month names
    datasets: [
      {
        label: "User",
        backgroundColor: "#7a622a",
        fill: false,
        lineTension: 0.5,
        borderColor: "#7a622a",
        pointRadius: 10,
        borderWidth: 1,
        data: dashboardList?.userGraphStats?.map((data) => data.count), // Extracting counts
      },
      {
        label: "Instructors",
        backgroundColor: "#7a622a",
        fill: false,
        lineTension: 0.5,
        borderColor: "#7a622a",
        pointRadius: 10,
        borderWidth: 1,
        data: dashboardList?.instructorGraphStats?.map((data) => data.count), // Extracting counts
      },
    ],
  };

  return (
    <>
      <div className="instructorpanel">
        <div className="mb-5">
          <Row>
            <Col md="12" lg="4">
              <Card className="statscount mb-md-3 mb-lg-0">
                <img src={user1} alt="" />
                <div>
                  <p>Users</p>
                  <h3>
                    {!loading ? (
                      dashboardList?.dashboardCount?.userCount
                    ) : (
                      <div className="statscount mb-md-3 mb-lg-0">
                        <ShimmerLoader />
                      </div>
                    )}
                  </h3>
                </div>
              </Card>
            </Col>
            <Col md="12" lg="4">
              <Card className="statscount mb-md-3 mb-lg-0">
                <img src={instructor1} alt="" />
                <div>
                  <p>Instructors</p>
                  <h3>
                    {!loading ? (
                      dashboardList?.dashboardCount?.InstructorCount
                    ) : (
                      <div className="statscount mb-md-3 mb-lg-0">
                        <ShimmerLoader />
                      </div>
                    )}
                  </h3>
                </div>
              </Card>
            </Col>
            <Col md="12" lg="4">
              <Card className="statscount">
                <img src={aircrafticon} alt="" />
                <div>
                  <p>Aircrafts</p>
                  <h3>
                    {!loading ? (
                      dashboardList?.dashboardCount?.aircraftCount
                    ) :(
                      <div className="statscount mb-md-3 mb-lg-0">
                        <ShimmerLoader />
                      </div>
                    )}
                  </h3>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
        <div className="mb-5">
          <div>
            <Line
              data={transformedData}
              options={{
                legend: {
                  position: "top",
                  align: "start",
                },
              }}
            />
          </div>
        </div>
        <hr className="section_breakline" />
        <div className="mb-3">
          <Row className="mb-2">
            <Col xl="6" md="6" xs="6" className="align-self-center">
              <h4 className="card-title mb-2 headingcard">
                Recent Reservations
              </h4>
            </Col>
            <Col xl="6" md="6" xs="6" className="text-end">
              <Link to="/Reservations">
                <Button className="btn-add">See All</Button>
              </Link>
            </Col>
          </Row>
          <div className="table-responsive">
            <Table className="react-dataTable paddingbox tablestatic">
              <thead>
                <tr>
                  <th>Reservation ID</th>
                  <th>Reservation By</th>
                  <th>Aircraft Name</th>
                  <th>Reservation Date</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {dashboardList?.recentReservations?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <Link
                        className="linkadd"
                        to={"/Viewreservation/" + item.booking_id}
                      >
                        <b>{item.booking_id}</b>
                      </Link>
                    </td>
                    <td>
                      <Link className="linkadd">
                        {item.userName ? item.userName : "NA"}
                      </Link>
                    </td>
                    <td>
                      <Link className="linkadd">
                        {" "}
                        {item.reservationType == 2
                          ? item.subTitle
                          : item.reservationType == 1
                          ? item.title
                          : item.title}
                      </Link>
                    </td>
                    <td>{moment(item.bookingDate).format("DD-MM-YYYY")}</td>
                    <td>
                      <span className={item.booking_status + " badge"}>
                        {item.booking_status}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <hr className="section_breakline" />
        <div className="dashboard_calendar">
          <h4 className="card-title mb-3 headingcard">Aircraft Reservations</h4>
          <Calendar
            localizer={localizer}
            events={aircraftList}
            defaultView="week"
            views={["week"]}
            step={60}
            timeslots={1}
            // onNavigate={(yy) => { getDashboardAircraft(yy) }}
            // defaultDate={currentDate}
            defaultDate={new Date(2023, 8, 4)}
            style={{ height: 500 }}
            components={{
              week: {
                header: ({ date }) => {
                  console.log(date.getDay(), "date-->");
                  return (
                    <div className="custom-header-container">
                      <div className="custom-header">
                        <span>{customHeaders[date.getDay()]}</span>
                        {/* <span>{moment(date).format('MMM D')}</span> */}
                      </div>
                    </div>
                  );
                },
                event: ({ event }) => {
                  return (
                    <div className="custom-event">
                      <strong>{event.title}</strong>
                      <p>
                        {/* {moment(event.start).format('h:mm A')} -{' '}
                    {moment(event.end).format('h:mm A')} */}
                        {event.description}
                      </p>
                      <span className="tableeeye_btn" onClick={detailtoggle}>
                        <Eye />
                      </span>
                    </div>
                  );
                },
              },
            }}
          />
          <div className="text-center mt-4">
            <Button className="btn-add" onClick={edittoggle}>
              Schedule
            </Button>
          </div>
        </div>
      </div>
      <Modal
        centered
        isOpen={detailmodal}
        className="modal-lg"
        toggle={() => setDetailmodal(!detailmodal)}
      >
        <XCircle className="modal_close_icon" onClick={detailtoggle} />
        <ModalBody className="pt-4 p-3">
          <div className="detailmain">
            <div className="detailinner">
              <h3 className="detaititle">
                <Link to="/Viewaircraft">Cessna 172 739BN</Link>
              </h3>
              <p className="detaisubhead">
                Reservation Date :<span className="ms-2">23 march 2023</span>
              </p>
              <p className="detaisubhead">
                Aircraft Time Slot:<span className="ms-2">7 AM To 10 AM</span>
              </p>
              <p className="detaisubhead mb-0">
                Instructor Time Slot :<span className="ms-2">6 AM</span>
              </p>
            </div>
            <div className="detailinner instructordetail mt-4">
              <div className="w-100">
                <h3 className="detaititle">Customer Details</h3>
                <div className="instructorinner">
                  <img src={userimg} className="imguser" alt="" />
                  <div className="detailcontent">
                    <h4>
                      <Link to="/Viewuser">Robert Downey, 25years</Link>
                    </h4>
                    <p> Lifetime Flew Record - 34 hours</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailinner  mt-4">
              <h3 className="detaititle">Reservation Details</h3>
              <div className="detaisubhead mb-3 mt-4">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>Reservation ID</div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <Link className="dashboardID_link" to="/Viewreservation">
                      {" "}
                      IPT15BA
                    </Link>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3 mt-4">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>Dispatch Date/ Time</div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <span> March 30, 2023 8:00AM </span>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>Check-In Date/ Time</div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <span> March 30, 2023 8:00AM </span>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>Aircraft Hobbs Out / In </div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <span>
                      7761.3<span className="mx-3">|</span>7761.3
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>Aircraft Tach Out / In </div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <span>
                      7761.3<span className="mx-3">|</span>7761.3
                    </span>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>
                      Flight Hours <span className="ms-lg-2"> 1.6</span>
                    </div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>
                      Ground Time <span className="ms-lg-2"> 1.0</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3">
                <Row>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>
                      {" "}
                      Total Hours <span className="ms-lg-2"> 1.0</span>{" "}
                    </div>
                  </Col>
                  <Col xs="6" md="6" lg="6" xl="4">
                    <div>
                      Instructor Rate (Hr){" "}
                      <span className="ms-lg-2"> $120</span>{" "}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="detaisubhead mb-3">
                <div>
                  Airport Tax <span className="ms-lg-2"> 7.5%</span>{" "}
                </div>
              </div>
              <div className="detaisubhead mb-3">
                <div>Instruction Notes</div>
                <p className="mb-0 detailinnertop">
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  It is a long established. It is a long established fact that a
                  reader will be distracted by the readable.
                </p>
              </div>
              <div className="detaisubhead mt-4 text-center">
                <h4 className="paidamount">
                  Paid Amount <span className="ms-2">$160</span>
                </h4>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        centered
        isOpen={editmodal}
        className="modal-lg"
        toggle={() => setEditmodal(!editmodal)}
      >
        <ModalBody className="p-3">
          <div className="deleteModal">
            <Row>
              <Col md="12" lg="6">
                <label className="formgroup_label">User Email</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    // name="aircraft"
                    placeholder="User Email"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  >
                    <option value="select">Select userEmail</option>
                    {UserListt.data?.map((itm) => (
                      <option value={itm.email}>{itm.email}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Reservation Date</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={calendaricon} alt="" />
                  </div>
                  <DatePicker
                    id="dateField"
                    selected={ReservationDate}
                    onChange={ReservationDateChange}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Reservation Date"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Dispatch Date</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={calendaricon} alt="" />
                  </div>
                  <DatePicker
                    id="dateField"
                    selected={DispatchDate}
                    onChange={DispatchDateChange}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Dispatch Date"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Dispatch Time</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={clockicon} alt="" />
                  </div>
                  <DatePicker
                    id="dateField"
                    selected={Dispatchtime}
                    onChange={(date) => setDispatchtime(date)}
                    placeholderText="Dispatch Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Check In Date</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={calendaricon} alt="" />
                  </div>
                  <DatePicker
                    id="dateField"
                    selected={CheckinDate}
                    onChange={(date) => setCheckinDate(date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    placeholderText="Check In Date"
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-start"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Check In Time</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={clockicon} alt="" />
                  </div>
                  <DatePicker
                    id="dateField"
                    selected={Checkintime}
                    onChange={(date) => setCheckintime(date)}
                    placeholderText="Check In Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Aircraft</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={FlyingIcon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="aircraft"
                    value={aircraft}
                    onChange={(e) => setAircraft(e.target.value)}
                  >
                    <option value="select">Select Aircraft</option>
                    {AircraftListt.data?.map((itm) => (
                      <option value={itm._id}>{itm.aircraftName}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">instructor Email</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="instructor Email"
                    value={schedule}
                    onChange={(e) => setSchedule(e.target.value)}
                  >
                    <option value="select">Select instructorEmail</option>
                    {instructorListt?.data?.map((itm) => (
                      <option value={itm.email}>{itm.email}</option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Instructor Rate</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    name="InstructorRate"
                    placeholder="Instructor Rate"
                    value={InstructorRate}
                    onChange={(e) => setInstructorRate(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Ground Time</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={clockicon} alt="" />
                  </div>
                  <DatePicker
                    id="dateField"
                    selected={Groundtime}
                    onChange={(date) => setGroundtime(date)}
                    placeholderText="Ground Time"
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="12">
                <label className="formgroup_label">Instructor Notes</label>
                <FormGroup className="form-group">
                  <Input
                    name="InstructorNotes"
                    placeholder="Instructor Notes"
                    rows="6"
                    value={InstructorNotes}
                    onChange={(e) => setInstructorNotes(e.target.value)}
                    type="textarea"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Hobbs In</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    name="HobbsIn"
                    placeholder="Hobbs In"
                    value={HobbsIn}
                    onChange={(e) => setHobbsIn(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Hobbs Out</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={usericon} alt="" />
                  </div>
                  <Input
                    name="HobbsOut"
                    placeholder="Hobbs Out"
                    value={HobbsOut}
                    onChange={(e) => setHobbsOut(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Tach In</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={FlyingIcon} alt="" />
                  </div>
                  <Input
                    name="TechIn"
                    placeholder="Tach In"
                    value={TechIn}
                    onChange={(e) => setTechIn(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Tach Out</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={FlyingIcon} alt="" />
                  </div>
                  <Input
                    name="TechOut"
                    placeholder="Tach Out"
                    value={TechOut}
                    onChange={(e) => setTechOut(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Airport Tax</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={FlyingIcon} alt="" />
                  </div>
                  <Input
                    name="AirportTax"
                    placeholder="Airport Tax"
                    value={AirportTax}
                    onChange={(e) => setAirportTax(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Airport Rate</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={FlyingIcon} alt="" />
                  </div>
                  <Input
                    name="AirportRate"
                    placeholder="Airport Rate"
                    value={AirportRate}
                    onChange={(e) => setAirportRate(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Total Hours</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={clockicon} alt="" />
                  </div>
                  <Input
                    name="Totalhours"
                    placeholder="Total hours"
                    value={Totalhours}
                    onChange={(e) => setTotalhours(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Flight Hours</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={clockicon} alt="" />
                  </div>
                  <Input
                    name="FlightHours"
                    placeholder="Flight Hours"
                    value={FlightHours}
                    onChange={(e) => setFlightHours(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Grand Total</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={clockicon} alt="" />
                  </div>
                  <Input
                    name="GrandTotal"
                    placeholder="Grand Total"
                    value={GrandTotal}
                    onChange={(e) => setGrandTotal(e.target.value)}
                    type="text"
                  />
                </FormGroup>
              </Col>
              <Col md="12" lg="6">
                <label className="formgroup_label">Status</label>
                <FormGroup className="form-group">
                  <div className="iconleft">
                    <img src={statusicon} alt="" />
                  </div>
                  <Input
                    className="form-control"
                    type="select"
                    name="status"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <option value="select">Select Status</option>
                    <option value="dispatch">Dispatch</option>
                    <option value="checkIn">CheckIn</option>
                    <option value="cancel">Cancel</option>
                    <option value="complete">Complete</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md="12" lg="12">
                <label className="formgroup_label">Public Notes</label>
                <FormGroup className="form-group">
                  <Input
                    name="InstructorNotes"
                    placeholder="Public Notes"
                    rows="6"
                    value={PublicNotes}
                    onChange={(e) => setPublicNotes(e.target.value)}
                    type="textarea"
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="btn-cancel-ok text-center">
              <Button className="btn-cancel me-1" onClick={edittoggle}>
                Cancel
              </Button>
              <Button
                className="btn-save"
                onClick={() => {
                  sheduleAircraft();
                }}
              >
                Save Now
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Dashboard;
