import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
  Label,
  FormGroup,
  Input,
  Form,
} from "reactstrap";
import { AdminFetchPermissionAction } from "../../../Redux/Actions";
const Viewadministrator = () => {
  const [fpermission, setFPermission] = useState({})
  const [adminPersmission, setAdminPermission] = useState([])

  const param = useParams()
  const dispatch = useDispatch()

  const fetchPermission = () => {
    let data = {
      adminID: param.id
    }
    dispatch(AdminFetchPermissionAction(data)).then(res => {
      // console.log(res)
      const obj = {};
      res.permissions?.map((i) => {
        obj[i.resource] = [...(obj[i.resource] || [])];
        obj[i.resource].push({ action: i.actions, _id: i._id });
      });
      setFPermission(obj)
      setAdminPermission(res.adminAssignedPermission.permissions)

    }).catch(err => {
      // console.log(err)
    })
  }

  useEffect(() => {
    fetchPermission()
  }, [])


  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Administrator Details</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Administrator"><Button className="btn-add">Back</Button></Link>
          </Col>
        </Row>
        <Form>
          {Object.keys(fpermission).map((key, idx) => (
            <FormGroup className="form-group">
              <Row>
                <Col sm="4">
                  <span className="permissionleft_head">{key}</span>
                </Col>
                <Col sm="8">
                  {fpermission[key].map(item => (
                    <div className='form-check form-check-inline'>
                      <Input
                        type='checkbox'
                        name='permission'
                        id='Userlist'
                        checked={adminPersmission.includes(item._id)}
                        disabled
                        // onChange={() => handleState(item._id)}
                      />
                      <Label className='form-check-label' for='Userlist'>
                        {item.action}
                      </Label>
                    </div>
                  ))}

                </Col>
              </Row>
            </FormGroup>
          ))}
        </Form>
      </div>
    </>
  );
};

export default Viewadministrator;
