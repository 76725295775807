import React from "react"
import {
	Row,
	Col,
} from "reactstrap"
import { Link } from "react-router-dom";
const data = [
	{
		id: 1,
		transactionID: '#12345678',
		reservationID: 'IPT15BA',
		price: '$25',
		datetime: 'Aug 21, 2022 at 03:40 PM',
		status: 'Completed',
	},
	{
		id: 2,
		transactionID: '#12345679',
		reservationID: 'IPT15BA',
		price: '$20',
		datetime: 'Aug 21, 2022 at 03:40 PM',
		status: 'Hold',
	},
	{
		id: 3,
		transactionID: '#12345680',
		reservationID: 'IPT15BA',
		price: '$15',
		datetime: 'Aug 21, 2022 at 03:40 PM',
		status: 'Declined',
	},
	{
		id: 4,
		transactionID: '#12345681',
		reservationID: 'IPT15BA',
		price: '$10',
		datetime: 'Aug 21, 2022 at 03:40 PM',
		status: 'Completed',
	},
];

function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);
  const formattedDate = date.toLocaleDateString(); // Format the date
  const formattedTime = date.toLocaleTimeString(); // Format the time
  return `${formattedDate} ${formattedTime}`;
}



const DetailtransactioninfoPages = ({ transaction }) => {
	return (
		<Row>
			<Col sm="12">
				<h4 className="card-title mb-3 headingcard">Transactions</h4>
				<div className="table-responsive">
					<table class="table">
						<thead class="table-light">
							<tr>
								<th className="text-nowrap">Transaction ID</th>
								<th className="text-nowrap">Reservation ID</th>
								<th className="text-nowrap">Amount</th>
								<th className="text-nowrap">Date/Time</th>
								<th className="text-nowrap">Status</th>
							</tr>
						</thead>
						<tbody>
							{transaction?.map((item, index) => 
							(
								<tr key={index}>
									<td><Link className="linkadd" to="/Viewtransactions"><b>{item.invoiceNumber}</b></Link></td>
									<td><Link className="linkadd" to="/Viewreservation"><b>{item.bookingID}</b></Link></td>
									<td>{item.amount}</td>
									<td>{formatDateTime(item.transactionTime)}</td>
									<td><span className={item.transactionStatus + ' badge'}>{item.transactionStatus}</span></td>
								</tr>
							)
							)}
						</tbody>
					</table>
				</div>
			</Col>
		</Row>
	)
}

export default DetailtransactioninfoPages;
