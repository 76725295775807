// import Singleton from '../../../src/Singleton';
import { ADD_USER_SUCCESS, EDIT_USER_SUCCESS, FORGOT_PASSWORD, INSTRUCTOR_DEATIL_SUCCESS, INSTRUCTOR_LIST_SUCCESS, LOG_IN_SUCCESS, RESET_PASSWORD, USER_DEATIL_SUCCESS, USER_LIST_SUCCESS, VERIFY_OTP } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_DELETE_INSTRUCTOR, ADMIN_INSTRUCTOR_DETAIL, ADMIN_INSTRUCTOR_LIST } from '../../constant/Endpoints';
import Singleton from '../../Singleton';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const InstructorListSuccess = (data) => {
    return {
        type: INSTRUCTOR_LIST_SUCCESS,
        payload: data
    };
};

const InstructorDetailSuccess = (data) => {
    return {
        type: INSTRUCTOR_DEATIL_SUCCESS,
        payload: data
    };
};





export const InstructorListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_INSTRUCTOR_LIST, data)
                .then(response => {
                    dispatch(InstructorListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}


export const InstructorDetailAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_INSTRUCTOR_DETAIL, data)
                .then(response => {
                    dispatch(InstructorDetailSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}



export const DeleteInstructorAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DELETE_INSTRUCTOR, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
          
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                   
                })
        })
    }
}







// export const ForgotAction = (data) => {
//     return  (dispatch) => {
//         return new Promise((resolve, reject) => {
//             APIClient.getInstance()
//                 .post(ADMIN_FORGOT_PASSWORD, data, '')
//                 .then(response => {
//                     ForgotSuccess({ data: response })
//                     console.log('getAllStories --->>>', response);
//                     resolve(response);
//                 })
//                 .catch(error => {
//                     console.log('getAllStories error--->>>', error);
//                     reject('error');
//                 })
//         })
//     }
// }





// APIClient.getInstance().post(SIGN_UP, data).then(res => dispatch(SignInSuccess({ data: res }))).catch(err => console.log(err))


