import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Eye, Edit, Trash, XCircle } from 'react-feather';
import { Link } from "react-router-dom";
import { DeleteReservationAction, ReservationListAction } from "../../Redux/Actions";
import { useDispatch } from "react-redux";
import { IMAGE_URL } from "../../constant/Endpoints";
import profileimg from "../../assets/image/default.jpg";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
import moment from "moment";

const Table = props => {
  const [loading, setLoading] = useState(false);

  const columns = [
    {
      name: "Reservation ID",
      minWidth: "180px",
      selector: "imgurl",
      sortable: true,
      cell: (row) =>
        <>
          <img className="table_img rounded-circle img-thumbnail me-1" src={row?.profilePic != null ? IMAGE_URL + row?.profilePic :profileimg } alt="" />
          {row.booking_id}
        </>
    },
    {
      name: "Reservation By",
      minWidth: "170px",
      selector: "reservedBy",
      sortable: true,
      cell: (row) => <>{row.userName ? row.userName : "NA"}</>
    },
    {
      name: "Aircraft Name",
      minWidth: "150px",
      selector: "aircraftname",
      sortable: true,
      cell: (row) => <>
        {row.reservationType == 0 ? row.title : row.reservationType == 1 ? row.title : row.subTitle}
      </>
    },
    {
      name: "Instructor Name",
      minWidth: "170px",
      selector: "instructorname",
      sortable: true,
      cell: (row) => <>
        {row.reservationType == 0 ? row.subTitle : row.reservationType == 1 ? row.subTitle : row.title}
      </>
    },
    {
      name: "Date",
      minWidth: "160px",
      selector: "reservedDate",
      sortable: true,
      cell: (row) =>  moment(row.bookingDate).format("DD-MM-YYYY")
    },
    {
      name: "Status",
      minWidth: "80px",
      selector: "status",
      sortable: true,
      cell: (row) => <span className={row.booking_status + ' badge'}  >{row.booking_status}</span>
    },
    {
      name: "Actions",
      minWidth: "180px",
      center: 'center',
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/Viewreservation/" + row.booking_id}><Button className="tablebtnview"><Eye className="tableicon" /></Button></Link>
            <Link to={"/Editreservation/" + row.booking_id}><Button className="tablebtnedit"><Edit className="tableicon" /></Button></Link>
            <Button className="tablebtndelete" onClick={() => { DeleteReservationData(row._id) }}><Trash className="tableicon" /></Button>
          </>
        </div>
      )
    }
  ]

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const [reservationList, setReservationList] = useState()
  const dispatch = useDispatch()


  const getReservationData = () => {
    setLoading(true);
    let data = {
      search: filterText
    }
    dispatch(ReservationListAction(data)).then((res) => {
      setLoading(false);

      setReservationList(res?.data)
      console.log(res.data)
    }).catch((err) => { 
      setLoading(false);
      console.log(err) })
    // console.log(id)
  }

  const DeleteReservationData = (id) => {
    let data = {
      reservationID: id
    }
    dispatch(DeleteReservationAction(data)).then((res) => {

      getReservationData()
      // setReservationList(res?.data)
      // console.log(res.data)
    }).catch((err) => { console.log(err) })
    // console.log(id)
  }

  useEffect(() => {
    getReservationData(filterText)
  }, [filterText])


  return (
    <React.Fragment>

      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={reservationList}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader/>}
        progressPending={loading} 
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure  ?</h2>
            <p>You want to delete this record. You will not be able to recover</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>Cancel</Button>
              <Button className="btn-ok" onClick={toggle2}>Proceed</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Table;
