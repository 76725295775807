import React, { useState, useMemo, useEffect } from "react"
import {
  Modal,
  ModalBody,
  Button,
} from "reactstrap"
import DataTable from "react-data-table-component"
import FilterComponent from "./FilterComponent";
import { Edit, Trash, XCircle } from 'react-feather'
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux";
import { ManagementListAction } from "../../Redux/Actions/ManagementAction";
import Loader from "../customcomponent/pageLoader";
import PageLoader from "../customcomponent/loader";
const Table = props => {
  const columns = [
    {
      name: "Name",
      minWidth: "300px",
      selector: "name",
      sortable: true,
      cell: (row) => row?.title
    },
    {
      name: "Status",
      minWidth: "80px",
      selector: "status",
      sortable: true,
      cell: (row) => <span className={row?.status + ' badge'}  >{row?.status}</span>
    },
    {
      name: "Actions",
      minWidth: "180px",
      center: 'center',
      cell: (row) => (
        <div className="tableaction">
          <>
            <Link to={"/Editpage/" + row?.cms_type}><Button className="tablebtnedit"><Edit className="tableicon" /></Button></Link>
            <Button className="tablebtndelete" onClick={toggle2}><Trash className="tableicon" /></Button>
          </>
        </div>
      )
    }
  ]

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(
    false
  );
  const filteredItems = props.data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={e => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);
  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);
  const [managementList, setManagementList] = useState([]);
  const [loading, setLoading] = useState(false);


  const dispatch = useDispatch()

  const manageList = () => {
    setLoading(true)
    let data = {
      search: filterText
    }

    dispatch(ManagementListAction(data)).then(res => {
      setLoading(false)

      setManagementList(res?.data)
      // console.log(res)
    }).catch(err => {
      setLoading(false)

      // console.log(err)
    })
  }

  useEffect(() => {
    manageList(filterText)
  }, [filterText])



  return (
    <React.Fragment>

      <DataTable
        noHeader
        columns={columns}
        className="react-dataTable paddingbox"
        data={managementList}
        defaultSortField="name"
        pagination
        progressComponent={<PageLoader/>}
        progressPending={loading} 
        subHeader
        subHeaderComponent={subHeaderComponent}
      />
      <Modal centered
        isOpen={modal2}
        toggle={() => setModal2(!modal2)}
      >
        <ModalBody className="p-3">
          <div className="deleteModal text-center">
            <XCircle className="deletemodal_icon" />
            <h2>Are You Sure  ?</h2>
            <p>You want to delete this record. You will not be able to recover</p>
            <div className="btn-cancel-ok">
              <Button className="btn-cancel me-1" onClick={toggle2}>Cancel</Button>
              <Button className="btn-ok" onClick={toggle2}>Proceed</Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Table;
