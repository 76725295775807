import React from "react";
import { Link } from "react-router-dom";
import {
	Col,
	Row,
} from "reactstrap";
const data = [
	{
		id: 1,
		reservationID: 'IPT15BA',
		reservationby: 'Stephen George',
		aircraftname: 'Cessna 172 739BN',
		reservationdate: '23 march 2023 , 7:00 AM',
		status: 'Completed',
	},
	{
		id: 2,
		reservationID: 'IPT15BA',
		reservationby: 'Johnson Charles',
		aircraftname: 'Ruth Obrien',
		reservationdate: '23 march 2023 , 7:00 AM',
		status: 'Dispatch',
	},
	{
		id: 3,
		reservationID: 'IPT15BA',
		reservationby: 'Stephen George',
		aircraftname: 'Cessna 172 739BN',
		reservationdate: '23 march 2023 , 7:00 AM',
		status: 'Pending',
	},
	{
		id: 4,
		reservationID: 'IPT15BA',
		reservationby: 'Johnson Charles',
		aircraftname: 'Grace Jain',
		reservationdate: '23 march 2023 , 7:00 AM',
		status: 'Completed',
	},
];
const DetailrecentbookingPages = ({ reservationDetail }) => {
	console.log(reservationDetail, "RRR")
	return (
		<Row>
			<Col sm="12">
				<div className="table-responsive">
					<table class="table">
						<thead class="table-light">
							<tr>
								<th className="text-nowrap">Reservation ID</th>
								<th className="text-nowrap">Reservation By</th>
								<th className="text-nowrap">Aircraft Name</th>
								<th className="text-nowrap">Reservation Date</th>
								<th className="text-nowrap">Status</th>
							</tr>
						</thead>
						<tbody>
							{reservationDetail?.map((item, index) => (
								<tr key={index}>
									<td><Link className="linkadd" to={"/Viewreservation/" + item.booking_id}
									><b>{item.booking_id}</b></Link></td>
									<td><Link className="linkadd">{item.userName}</Link></td>
									<td><Link className="linkadd">{item.reservationType == 0 ? item.title : item.reservationType == 1 ? item.title : item.subTitle}</Link></td>
									<td>{item.bookingDate}</td>
									<td><span className={item.booking_status + ' badge'}>{item.booking_status}</span></td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</Col>
		</Row>
	)
}

export default DetailrecentbookingPages;
