// import Singleton from '../../../src/Singleton';
import {
  AIRCRAFT_DETAIL_SUCCESS,
  AIRCRAFT_LIST_SUCCESS,
  EDIT_USER_SUCCESS,
  FORGOT_PASSWORD,
  INSTRUCTOR_DEATIL_SUCCESS,
  INSTRUCTOR_LIST_SUCCESS,
  LOG_IN_SUCCESS,
  RESET_PASSWORD,
  USER_DEATIL_SUCCESS,
  USER_LIST_SUCCESS,
  VERIFY_OTP,
} from "./types";
import { APIClient } from "../../Api/ApiClient";
import Singleton from "../../Singleton";
import {
  ADD_AIRCRAFTNAME,
  ADMIN_ADD_AIRCRAFT,
  ADMIN_AIRCRAFT_DETAIL,
  ADMIN_AIRCRAFT_LIST,
  ADMIN_AIRCRAFT_UPDATE,
  ADMIN_DELETE_AIRCRAFT,
  ADMIN_HOURS_AIRCRAFT,
  ADMIN_UPDATE_USER,
  AIRCRAFT_ADD,
  AIRCRAFT_CATEGORY,
  AIRCRAFT_DELETE,
  AIRCRAFT_DETAIL,
  AIRCRAFT_EDIT,
  AIRCRAFT_NAMELIST,
  AIRCRAFT_TYPE,
  AIRCRAFTNAME_EDIT,
} from "../../constant/Endpoints";

const AircraftListSuccess = (data) => {
  return {
    type: AIRCRAFT_LIST_SUCCESS,
    payload: data,
  };
};

const AircraftDetailSuccess = (data) => {
  return {
    type: AIRCRAFT_DETAIL_SUCCESS,
    payload: data,
  };
};

export const AircraftListAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_AIRCRAFT_LIST, data)
        .then((response) => {
          dispatch(AircraftListSuccess({ data: response.data }));
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};


export const AddAircraftNameAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADD_AIRCRAFTNAME, data)
        .then((response) => {
          // dispatch(AircraftListSuccess({ data: response.data }));
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};


export const AddAircraftAction = (data) => {
  console.log(data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .filePost(ADMIN_ADD_AIRCRAFT, data)
        .then((response) => {
          // dispatch(AddUserSuccess({ data: response.data }))
          // console.log('getAllStories --->>>', response);
          resolve(response);
          // alert(response.msg)
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
          // alert(error.msg)
        });
    });
  };
};

export const EditAircraftAction = (data) => {
  console.log(data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .filePost(ADMIN_AIRCRAFT_UPDATE, data)
        .then((response) => {
          // dispatch(AddUserSuccess({ data: response.data }))
          // console.log('getAllStories --->>>', response);
          resolve(response);
          // alert(response.msg)
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
          // alert(error.msg)
        });
    });
  };
};

export const AircraftDetailAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_AIRCRAFT_DETAIL, data)
        .then((response) => {
          dispatch(AircraftDetailSuccess({ data: response.data }));
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AircraftTypeAction = () => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .get(AIRCRAFT_TYPE)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AircraftCategoryAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFT_CATEGORY,data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AircraftNameListAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFT_NAMELIST,data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const CategoryEditAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFT_EDIT, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AircraftNameEditAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFTNAME_EDIT, data)
        .then((response) => {

          resolve(response);

        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};


export const CategoryDeleteAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFT_DELETE, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const CategoryDetailAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFT_DETAIL, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const CategoryAddAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(AIRCRAFT_ADD, data)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const AircraftHoursAction = (data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_HOURS_AIRCRAFT, data)
        .then((response) => {
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

export const DeleteAircraftAction = (data) => {
  console.log(data);
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      APIClient.getInstance()
        .post(ADMIN_DELETE_AIRCRAFT, data)
        .then((response) => {
          // dispatch(AddUserSuccess({ data: response.data }))
          // console.log('getAllStories --->>>', response);
          resolve(response);
        })
        .catch((error) => {
          console.log("getAllStories error--->>>", error);
          reject("error");
        });
    });
  };
};

// export const ForgotAction = (data) => {
//     return  (dispatch) => {
//         return new Promise((resolve, reject) => {
//             APIClient.getInstance()
//                 .post(ADMIN_FORGOT_PASSWORD, data, '')
//                 .then(response => {
//                     ForgotSuccess({ data: response })
//                     console.log('getAllStories --->>>', response);
//                     resolve(response);
//                 })
//                 .catch(error => {
//                     console.log('getAllStories error--->>>', error);
//                     reject('error');
//                 })
//         })
//     }
// }

// APIClient.getInstance().post(SIGN_UP, data).then(res => dispatch(SignInSuccess({ data: res }))).catch(err => console.log(err))
