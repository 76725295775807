import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Label,
  Form,
  FormGroup,
  Input,
  Row,
  ButtonGroup,
} from "reactstrap";
import defaultimg from "../../../assets/image/default.jpg";
import calendaricon from "../../../assets/image/profilecalendar-1.svg";
import cameraicon from "../../../assets/image/profilecamera-1.svg";
import emailicon from "../../../assets/image/profileemail-1.svg";
import licenseicon from "../../../assets/image/profilelicense-1.svg";
import phoneicon from "../../../assets/image/profilephone-1.svg";
import usericon from "../../../assets/image/profileuser-1.svg";
import FlyingIcon from "../../../assets/image/flying.svg";
import { X, Plus } from "react-feather";
import locationicon from "../../../assets/image/profilelocation-1.svg";
import gendericon from "../../../assets/image/profilegender-1.svg";
import statusicon from "../../../assets/image/status-icon.svg";
import passwordicon from "../../../assets/image/password.svg";
import eyehideicon from "../../../assets/image/eye-hide.svg";
import eyeshowicon from "../../../assets/image/eye-show.svg";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { AddUserAction, AircraftTypeAction } from "../../../Redux/Actions";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addInstructorValidationSchema } from "../../../validationSchema";
import { useFormik } from "formik";
import PageLoader from "../../customcomponent/loader";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
let timeSlot = [
  { from: "12:00 AM", to: "2:30 AM" },
  { from: "2:30 AM", to: "5:00 AM" },
  { from: "5:00 AM", to: "7:30 AM" },
  { from: "7:30 AM", to: "10:00 AM" },
  { from: "10:00 AM", to: "12:30 PM" },
  { from: "12:30 PM", to: "3:00 PM" },
  { from: "3:00 PM", to: "5:30 PM" },
  { from: "5:30 PM", to: "8:00 PM" },
  { from: "8:00 PM", to: "10:30 PM" },
];

let daySlot = {
  mon: timeSlot,
  tue: timeSlot,
  wed: timeSlot,
  thurs: timeSlot,
  fri: timeSlot,
  sat: timeSlot,
  sun: timeSlot,
};

const AircraftType = [
  {
    value: "Full Flight Simulator (FFS)",
    label: "Full Flight Simulator (FFS)",
    color: "#00B8D9",
    isFixed: true,
  },
  {
    value: "Flight Training Device (FTD)",
    label: "Flight Training Device (FTD)",
    color: "#00B8D9",
    isFixed: false,
  },
  {
    value: "Advanced Aircraft Training Device (AATD)",
    label: "Advanced Aircraft Training Device (AATD)",
    color: "#00B8D9",
    isFixed: false,
  },
  {
    value: "Basic Aviation Training Device (BATD)",
    label: "Basic Aviation Training Device (BATD)",
    color: "#00B8D9",
    isFixed: false,
  },
  {
    value: "Simulator Uncertified",
    label: "Simulator Uncertified",
    color: "#00B8D9",
    isFixed: false,
  },
];

const orderOptions = (values) => {
  if (values.length > 0)
    return values
      .filter((v) => v.isFixed)
      .concat(values.filter((v) => !v.isFixed));
};

const Addinstructor = (args) => {
  const [loading, setLoading] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [startDate2, setStartDate2] = useState(null);
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const today = new Date();

  const [cSelected, setCSelected] = useState({});
  const [rSelected, setRSelected] = useState("");
  const [weekoff, setWeekoff] = useState(true);

  const onCheckboxBtnClick = (selected) => {
    const newCSelected = { ...cSelected };
    var date = moment(rSelected).format("DD-MM-YYYY")
    // Initialize the array for the date if it doesn't exist
    if (!newCSelected[date]) {
      newCSelected[date] = [];
    }

    // Check if the selected item is already present
    const isSelected = newCSelected[date].some(item => item.from === selected.from);

    if (isSelected) {
      newCSelected[date] = newCSelected[date].filter(item => item.from !== selected.from);
    } else {
      newCSelected[date].push(selected);
    }

    setCSelected(newCSelected);

  };


  const navigate = useNavigate();

  var googleKey = process.env.REACT_APP_GOOGLE_PLACE_KEY

  const loadScript = (url, callback) => {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = url;
    script.onload = callback;
    document.head.appendChild(script);
  };

  const autocompleteRef = useRef(null);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
      const [phoneno, setPhoneno] = useState("");
  const [inputemail, setInputemail] = useState("");
  const [flyingexperience, setFlyingexperience] = useState("");
  const [flightrate, setFlightrate] = useState("");
  const [groundrate, setGroundrate] = useState("");
  const [location, setLocation] = useState("");
  const [status, setStatus] = useState("select");
  const [fixedValue, setFixedValue] = useState([]);
  const [inputPassword, setInputPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedGender, setSelectedGender] = useState("Select Gender");
  const [selectedLicense, setSelectedLicense] = useState(
    "Select License Level"
  );
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCertificate, setSelectedCertificate] = useState([]);
  const [selectedCertiFile2, setSelectedCertifFile2] = useState([]);
  const [aircraftList, setAircraftType] = useState([]);

  const [selectedGovt, setSelectedGovt] = useState(null);

  const [selectedGovtFile2, setSelectedGovtfFile2] = useState([]);
  const [selectedMedical, setSelectedMedical] = useState(null);
  const [selectedMedicFile2, setSelectedMedicfFile2] = useState([]);

  const phoneNumberAsInteger = parseInt(phoneno, 10);

  const handleFileChange = (event) => {
    let a = event.target.files[0]; ////SEND BACKEND
    let b = URL.createObjectURL(a);
    setSelectedFile(event.target.files[0]);
  };

  const handleCerificate = (event) => {
    const files = event.target.files;
    let imgArr = [];
    let backendImageArr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }

    setSelectedCertificate(imgArr);
    setSelectedCertifFile2(backendImageArr);
  };

  const handleGovtCertificate = (event) => {
    const files = event.target.files;
    let imgArr = [];
    let backendImageArr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }
    setSelectedGovt(imgArr);
    setSelectedGovtfFile2(backendImageArr);
  };

  const handleMedical = (event) => {
    const files = event.target.files;
    let imgArr = [];
    let backendImageArr = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i]; // Get the current file
      const objectURL = URL.createObjectURL(file); // Create an object URL
      imgArr.push(objectURL);
      backendImageArr.push(file); // Add the object URL to the array
    }
    setSelectedMedical(imgArr);
    setSelectedMedicfFile2(backendImageArr);
  };

  const handleGenderChange = (event) => {
    setSelectedGender(event.target.value);
  };

  const handleLicenseChange = (event) => {
    setSelectedLicense(event.target.value);
  };

  const aircraftListType = aircraftList?.map((craft) => ({
    value: craft._id,
    label: craft.type,
  }));
  const fixedOnChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.value);
    setFixedValue(selectedOptions); // Update selected options in state
    console.log("Selected IDs:", selectedIds); // This array of _id should be sent to backend
  };

  // const fixedOnChange = (value, { action, removedValue }) => {
  //   switch (action) {
  //     case "remove-value":
  //     case "pop-value":
  //       if (removedValue.isFixed) {
  //         return;
  //       }
  //       break;
  //     case "clear":
  //       value = AircraftType.filter((v) => v.isFixed);
  //       break;
  //     default:
  //       break;
  //   }

  //   value = orderOptions(value);
  //   setFixedValue(value);
  // };

  const [inputFields, setInputFields] = useState([
    {
      dayfield: "",
    },
  ]);

  const fetchAircraftType = ()=>{
    dispatch(AircraftTypeAction()).then((res) => {
      setAircraftType(res?.data)
     
    }).catch((err) => { console.log(err) })
  }

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        dayfield: "",
      },
    ]);
  };
  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };
  const handleChangee = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const dispatch = useDispatch();

  const formatSelectedDate = (date) => {
    return moment(date).format("DD-MM-YYYY"); 
  };

  const initialValues = {
    email: "",
    password: "",
    phoneno: "",
    exampleSelect: "",
    fname: "",
    lname: "",
    dateField: "",
    location: "",
    status: "",
    licenceLevel: "",
    gender: "",
    DOB: "",
    instructorRate: "",
    flightRate: "",
    flyingexperience: "",
    craftCategory: "",
    groundRate: "",
  };

  // const { values, errors, handleChange, handleSubmit } = useFormik({
  //   initialValues: initialValues,
  //   validationSchema: addInstructorValidationSchema,
  //   onSubmit: async (values) => {

      
      const addInstructor = async (event) => {
        console.log("fixedValue",fixedValue)
        event.preventDefault(); // Prevent the default form submission
    
        setLoading(true);
    
        const formData = new FormData();
        selectedCertiFile2.forEach((val) => {
          formData.append("certificate", val);
        });
        selectedGovtFile2.forEach((val) => {
          formData.append("governmentID", val);
        });
        selectedMedicFile2.forEach((val) => {
          formData.append("medicalCertificate", val);
        });
        let obj = [];
        fixedValue.forEach((el) => {
          if (el?.value) {
            obj.push(el.value);
          }
        });

if(selectedDate==null){
  setLoading(false);
  return toast('Please select date of birth')
} 



        formData.append("firstName", firstname);
        formData.append("lastName", lastname);
        formData.append("email", inputemail);
        formData.append("phone_number", phoneno);
        formData.append("password", inputPassword);
        formData.append("DOB", formatSelectedDate(selectedDate));
        formData.append("licencseLevel", selectedLicense);
        formData.append("userType", "true");
        formData.append("gender", selectedGender);
        formData.append("status", status);
        formData.append("address", location?.target?.value);
        formData.append("profile", selectedFile);
        formData.append("instructorFlightRate", flightrate);
        formData.append("flyingExperience", flyingexperience);
        formData.append("instructorRate", groundrate);
        formData.append("craftCategory", obj);
        formData.append("time_slot",JSON.stringify(cSelected)
            )
           
    
        if (formData) {
          try {
            const res = await dispatch(AddUserAction(formData));
            console.log("resssss", res);
            if (res.status === 1) {
              setLoading(false);
              toast.success("User added successfully", {
                position: "top-right",
              });
              navigate(-1);
              setSelectedCertificate([]);
              setSelectedCertifFile2([]);
              setSelectedGovt([]);
              setSelectedGovtfFile2([]);
              setSelectedMedical([]);
              setSelectedMedicfFile2([]);
              setCSelected({})

            } else {
              console.log("hiiiiii-> under else");
              setLoading(false);
              toast.error(res.msg, {
                position: "top-right",
              });
            }
          } catch (err) {
            setLoading(false);
            toast.error("Please provide all fields", {
              position: "top-right",
            });
            console.log(err);
          }
        } else {
          setLoading(false);
          toast.error("Please fill all fields", {
            position: "top-right",
          });
        }
      };
  // });
  const [date, setDate] = useState(new Date());

  const onChange = newDate => {
    console.log("NEWDATE",newDate)
    setDate(newDate);
    setRSelected(newDate.toDateString());
  };

  useEffect(() => {
    fetchAircraftType()
    console.log("aircraftListType",aircraftListType);
    console.log("GOOGLE",googleKey)
    var input = document.querySelector('.location');
  
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`, () => {
      const autocomplete = new window.google.maps.places.Autocomplete(autocompleteRef.current, input);
      
      autocomplete.addListener('place_changed', () => {
        console.log("autocomaplete",autocomplete)
        const place = autocomplete.getPlace();
        console.log("place",place)
        if (place.address_components) {
          setLocation({ target: { name: 'location', value: place.formatted_address } });
        }
      });
    });
  }, [])

  
  return (
    <>
      <div className="instructorpanel">
        <Row className="mb-4">
          <Col xl="6" md="6" xs="6" className="align-self-center">
            <h4 className="card-title my-1  headingmain">Add Instructor</h4>
          </Col>
          <Col xl="6" md="6" xs="6" className="text-end">
            <Link to="/Instructor">
              <Button className="btn-add">Back</Button>
            </Link>
          </Col>
        </Row>
        <Form>
          <Row>
            <Col md="12">
              <FormGroup className="form-group">
                <div className="user-avatar-section d-flex align-items-center">
                  <div className="useravatar1 d-inline-flex flex-column me-1 align-items-center justify-content-center">
                    <img
                      className="useravatarimg"
                      src={
                        selectedFile
                          ? URL.createObjectURL(selectedFile)
                          : defaultimg
                      }
                      alt=""
                    />
                  </div>
                  <Button
                    id="change-img"
                    tag={Label}
                    outline
                    className="mb-0"
                    color="primary"
                  >
                    <span className="d-block">Update Picture</span>
                    <input
                      type="file"
                      hidden
                      id="change-img"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Button>
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">First Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="fname"
                  placeholder="First Name"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  // value={fname}
                  // onChange={handleChange}
                  className="input-error"
                  type="text"
                />
                {/* <p className="input-error">{errors.fname}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Last Name</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={usericon} alt="" />
                </div>
                <Input
                  name="lname"
                  placeholder="Last Name"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  // value={lname}
                  // onChange={handleChange}
                  className="input-error"
                  type="text"
                />
                {/* <p className="input-error">{errors.lname}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Email Address</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={emailicon} alt="" />
                </div>
                <Input
                  name="email"
                  placeholder="Email Address"
                  value={inputemail}
                  onChange={(e) => setInputemail(e.target.value)}
                  // value={email}
                  // onChange={handleChange}
                  className="input-error"
                  type="email"
                />
                {/* <p className="input-error">{errors.email}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Phone No.</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={phoneicon} alt="" />
                </div>
                <Input
                  name="phoneno"
                  placeholder="Phone No"
                  value={phoneno}
                  onChange={(e) => setPhoneno(e.target.value)}
                  // value={phoneno}
                  // onChange={handleChange}
                  className="input-error"
                  type="tel"
                />
                {/* <p className="input-error">{errors.phoneno}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Date Of Birth</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={calendaricon} alt="" />
                </div>
                <DatePicker
                  id="dateField"
                  selected={selectedDate}
                  onChange={handleDateChange}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  placeholderText="DD/MM/YYYY"
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-start"
                  maxDate={today}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">License Level</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={licenseicon} alt="" />
                </div>
                <Input
                  id="exampleSelect"
                  className="form-control"
                  name="licenceLevel"
                  type="select"
                  onChange={handleLicenseChange}
                  value={selectedLicense}
                  // value={licenceLevel}
                  // onChange={handleChange}
                >
                  <option value="Select License Level">
                    Select License Level
                  </option>
                  <option value="Student">Student</option>
                  <option value="Private">Private</option>
                  <option value="Instrument">Instrument</option>
                  <option value="Commercial">Commercial</option>
                  <option value="Flight Instructor">Flight Instructor</option>
                </Input>
                {/* <p className="input-error">{errors.licenceLevel}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Flying Experience</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="flyingexperience"
                  placeholder="Flying Experience"
                  value={flyingexperience}
                  onChange={(e) => setFlyingexperience(e.target.value)}
                  // value={flyingexperience}
                  // onChange={handleChange}
                  className="input-error"
                  type="text"
                />
              </FormGroup>
              {/* <p className="input-error">{errors.flyingexperience}</p> */}
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Instructor Flight Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="flightRate"
                  placeholder="Instructor Flight Rate"
                  value={flightrate}
                  onChange={(e) => setFlightrate(e.target.value)}
                  // value={flightRate}
                  // onChange={handleChange}
                  className="input-error"
                  type="text"
                />
                {/* <p className="input-error">{errors.flightRate}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Instructor Ground Rate</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={FlyingIcon} alt="" />
                </div>
                <Input
                  name="groundRate"
                  placeholder="Instructor Ground Rate"
                  value={groundrate}
                  onChange={(e) => setGroundrate(e.target.value)}
                  // value={groundRate}
                  // onChange={handleChange}
                  className="input-error"
                  type="text"
                />
                {/* <p className="input-error">{errors.groundRate}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Gender</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={gendericon} alt="" />
                </div>
                <Input
                  id="exampleSelect1"
                  className="form-control"
                  name="gender"
                  type="select"
                  onChange={handleGenderChange}
                  value={selectedGender}
                  // value={gender}
                  // onChange={handleChange}
                >
                  <option>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Input>
                {/* <p className="input-error">{errors.gender}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Status</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={statusicon} alt="" />
                </div>
                <Input
                  className="form-control"
                  type="select"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  // value={status}
                  // onChange={handleChange}
                >
                  <option value="select">Select Status</option>
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                  <option value="pending">Pending</option>
                </Input>
                {/* <p className="input-error">{errors.status}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Location</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={locationicon} alt="" />
                </div>
                <Input
                     className="location"
                     name="location"
                     placeholder="Location"
                     value={location?.target?.value}
                     onChange={(e) => setLocation(e.target.value)}
                     type="text"
                     innerRef={autocompleteRef}
                  />
                {/* <p className="input-error">{errors.location}</p> */}
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Craft Category</label>
              <FormGroup className="form-group">
        <Select
          isClearable={false}
          value={fixedValue}
          isMulti
          name="craftCategory"
          placeholder="Select Craft Categories"
          options={aircraftListType}
          onChange={fixedOnChange}
          className="multiselect"
          classNamePrefix="select"
        />
      </FormGroup>
            </Col>
            <Col md="12" lg="6">
              <label className="formgroup_label">Password</label>
              <FormGroup className="form-group">
                <div className="iconleft">
                  <img src={passwordicon} alt="" />
                </div>
                <Input
                  name="password"
                  placeholder="Password"
                  type={showPassword ? "text" : "password"}
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  // onChange={(e) => setPassword(e.target.value)}
                  // value={password}
                  // onChange={handleChange}
                  className="input-error"
                />
                {/* <p className="input-error">{errors.password}</p> */}
                <div className="iconright" onClick={togglePasswordVisibility}>
                  {showPassword ? (
                    <img src={eyeshowicon} alt="" />
                  ) : (
                    <img src={eyehideicon} alt="" />
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">Upload Certifications</label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg" className="fileupload">
                  <div>
                    {selectedCertificate?.length > 0 ? (
                      selectedCertificate?.map((val) => {
                        return (
                          <img
                            src={val}
                            alt=""
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={cameraicon}
                          alt=""
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <h3>Upload File Here</h3>
                        <p>(image size: Max 2 MB)</p>
                      </>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  multiple
                  accept="image/jpeg,image/png"
                  id="uploadimg"
                  name="uploadimg"
                  style={{ display: "none" }}
                  onChange={handleCerificate}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">
                Upload Government Certificates
              </label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg1" className="fileupload">
                  <div>
                    {selectedGovt?.length > 0 ? (
                      selectedGovt?.map((val) => {
                        return (
                          <img
                            src={val}
                            alt=""
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={cameraicon}
                          alt=""
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <h3>Upload Government Here</h3>
                        <p>(image size: Max 2 MB)</p>
                      </>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  multiple
                  accept="image/jpeg,image/png"
                  id="uploadimg1"
                  name="uploadimg1"
                  style={{ display: "none" }}
                  onChange={handleGovtCertificate}
                />
              </FormGroup>
            </Col>
            <Col md="12" lg="12">
              <label className="formgroup_label">
                Upload Medical Certificates
              </label>
              <FormGroup className="form-group">
                <label htmlFor="uploadimg2" className="fileupload">
                  <div>
                    {selectedMedical?.length > 0 ? (
                      selectedMedical?.map((val) => {
                        return (
                          <img
                            src={val}
                            alt=""
                            style={{ maxWidth: "100px", maxHeight: "100px" }}
                          />
                        );
                      })
                    ) : (
                      <>
                        <img
                          src={cameraicon}
                          alt=""
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <h3>Upload Medical Certificate Here</h3>
                        <p>(image size: Max 2 MB)</p>
                      </>
                    )}
                  </div>
                </label>
                <input
                  type="file"
                  id="uploadimg2"
                  name="uploadimg2"
                  style={{ display: "none" }}
                  onChange={handleMedical}
                  multiple
                  accept="image/jpeg,image/png"
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <hr className="section_breakline" />
            </Col>
            <Col md="12" className="mt-3">
              <h4 className="headinginner  mb-3">Working Schedule</h4>
              <Calendar
                onChange={onChange}
                value={date}
                minDate={today}
              />
              
              {/* <div className="weekoff_div">
                <FormGroup switch>
                  <Label check>Week Off</Label>
                  <Input
                    type="switch"
                    checked={weekoff}
                    value={weekoff}
                    onClick={() => {
                      setWeekoff(!weekoff);
                    }}
                  />
                </FormGroup>
              </div> */}
              <div className="timeslot_div">
              {rSelected &&
            timeSlot?.map((item, index) => {
              var date = moment(rSelected).format("DD-MM-YYYY")

              const isSelected = cSelected[date]?.some(selectedItem => selectedItem.from === item.from);

            return (
              <ButtonGroup key={index} className="timeslot">
              <Button
               style={{ marginRight: '5px', fontWeight: isSelected ? 'bold' : 'normal' }}
                onClick={() => onCheckboxBtnClick(item)}
                active={isSelected}
              >
                {item.from} - {item.to}
              </Button>
            </ButtonGroup>
            );
          })}

              </div>
             
            </Col>
          </Row>
          <div className="text-center mt-5">
            <Button type="submit" 
                   onClick={addInstructor}


              className="primarybutton  maxwidth-555"
            >
              {loading ? <PageLoader /> : "Save Now"}
            </Button>
          </div>
        </Form>
      </div>
      <ToastContainer />
    </>
  );
};

export default Addinstructor;
