// import Singleton from '../../../src/Singleton';
import { EDIT_USER_SUCCESS, RESERVATION_DEATIL_SUCCESS, RESERVATION_SUCCESS, TRANSACTION_SUCCESS } from './types'
import { APIClient } from '../../Api/ApiClient';
import { ADMIN_DELETE_RESERVATION, ADMIN_DELETE_TRANSACTION, ADMIN_RESERVATION, ADMIN_RESERVATION_DETAIL, ADMIN_TRANSACTION, ADMIN_TRANSACTION_DETAIL } from '../../constant/Endpoints';
import Singleton from '../../Singleton';


// import { SIGN_UP } from '../../EndPoints';

////////////////////post Api Action//////////////////////////////////
// export const updateSignIn = ({ prop, value }) => {
//     // console.log('UpdateProps---', prop, value);
//     return {
//         type: LOGIN_SUCCESS,
//         payload: { prop, value },
//     };
// };

const TransactionListSuccess = (data) => {
    return {
        type: TRANSACTION_SUCCESS,
        payload: data
    };
};



export const TransactionListAction = (data) => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_TRANSACTION, data)
                .then(response => {
                    dispatch(TransactionListSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                })
        })
    }
}



export const DeleteTransactionAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_DELETE_TRANSACTION, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    alert(error.msg)
                })
        })
    }
}


export const DetailTransactionAction = (data) => {
    console.log(data)
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            APIClient.getInstance()
                .post(ADMIN_TRANSACTION_DETAIL, data)
                .then(response => {
                    // dispatch(AddUserSuccess({ data: response.data }))
                    // console.log('getAllStories --->>>', response);
                    resolve(response);
                    // alert(response.msg)
                })
                .catch(error => {
                    console.log('getAllStories error--->>>', error);
                    reject('error');
                    // alert(error.msg)
                })
        })
    }
}








